import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { Label } from "../../../../../components/typography";
import {
  TimePicker,
  CheckboxButton,
  Button
} from "../../../../../components/UI";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";
import { workDaysBuilder } from "../../../../../builders/general-branch-settings-manage-schedule.builder";

// Form Models
import { WorkDayFormModel } from "../../../../../models/app/forms";

// Calls
import {
  fetchWorkDays,
  updateWorkDays
} from "../../../../../api/calls/general-branch-settings.call";

// Styles
import { WorkingDaysStyles } from "./workingDays.styles";

const WorkingDays = ({ branchId }) => {
  const [initalFormValues, setInitalFormValues] = useState([]);

  const getWorkDays = useCallback(async () => {
    const response = await fetchWorkDays(branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setInitalFormValues(
      response.workDays.map(day => new WorkDayFormModel(day))
    );
  }, [branchId]);

  useEffect(() => {
    getWorkDays();
  }, [getWorkDays]);

  const updateWorkingDaysHandler = async values => {
    const response = await updateWorkDays(values, branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Branch work days successfully updated.");
    setInitalFormValues(
      response.workDays.map(day => new WorkDayFormModel(day))
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initalFormValues,
    onSubmit: updateWorkingDaysHandler
    // validate: branchValidation
  });

  const { values, handleSubmit, setValues } = formik;

  const covertTimeStringToDateObject = timeString => {
    const parts = timeString.match(/(\d+):(\d+) (AM|PM)/);
    if (parts) {
      const startTime = new Date();
      let hours = parseInt(parts[1], 10);
      const minutes = parseInt(parts[2], 10);
      const tt = parts[3];

      if (tt === "PM" && hours < 12) hours += 12;
      if (tt === "AM" && hours === 12) hours = 0;

      const dateObject = new Date(startTime);
      dateObject.setHours(hours, minutes, 0, 0);

      return dateObject;
    }
    return new Date();
  };

  const handleTimeChange = (dateObject, dayOfTheWeek, fieldName) => {
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    const formatedHours =
      hours === 0
        ? "12"
        : hours > 12
        ? (hours - 12).toString().padStart(2, "0")
        : hours.toString().padStart(2, "0");
    const formatedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const amPm = hours < 12 ? "AM" : "PM";
    const formatedValue = `${formatedHours}:${formatedMinutes} ${amPm}`;

    setValues(
      values.map(value =>
        value.dayOfTheWeek === dayOfTheWeek
          ? new WorkDayFormModel({ ...value, [fieldName]: formatedValue })
          : value
      )
    );
  };

  const handleCheckboxChange = dayOfTheWeek => {
    const chosenWorkDay = workDaysBuilder.find(
      day => day.dayOfTheWeek === dayOfTheWeek
    );
    const chosenDays = values.map(day => day.dayOfTheWeek);
    if (chosenDays.includes(dayOfTheWeek)) {
      setValues(prevValues =>
        prevValues.filter(value => value.dayOfTheWeek !== dayOfTheWeek)
      );
      return;
    }

    setValues(prevValues => [...prevValues, chosenWorkDay]);
  };

  return (
    <WorkingDaysStyles>
      <form onSubmit={handleSubmit}>
        {workDaysBuilder.map((workDay, i) => {
          const chosenWorkDay = values.find(
            value => value.dayOfTheWeek === workDay.dayOfTheWeek
          );
          const isActive = chosenWorkDay
            ? workDay.dayOfTheWeek === chosenWorkDay?.dayOfTheWeek
            : false;
          return (
            <div className="inputsContainer" key={i}>
              <div
                style={{
                  minHeight: 86,
                  display: i !== 0 && "flex",
                  alignItems: i !== 0 && "center"
                }}
              >
                {i === 0 && <Label>Select Days</Label>}
                <div style={{ marginTop: i === 0 && 7 }}>
                  <CheckboxButton
                    style={{ textTransform: "capitalize", width: 103 }}
                    handleChange={() =>
                      handleCheckboxChange(workDay.dayOfTheWeek)
                    }
                    value={isActive}
                    text={workDay.dayOfTheWeek}
                  />
                </div>
              </div>
              <div className="timePickersContainer">
                {isActive && (
                  <>
                    <div className="inputContainer">
                      <Label>Start Time</Label>
                      <TimePicker
                        value={covertTimeStringToDateObject(
                          chosenWorkDay.opensAt
                        )}
                        handleChange={date =>
                          handleTimeChange(
                            date,
                            workDay.dayOfTheWeek,
                            "opensAt"
                          )
                        }
                        dateFormat="hh : mm aa"
                      />
                    </div>
                    <div className="inputContainer">
                      <Label>End Time</Label>
                      <TimePicker
                        value={covertTimeStringToDateObject(
                          chosenWorkDay.closesAt
                        )}
                        handleChange={date =>
                          handleTimeChange(
                            date,
                            workDay.dayOfTheWeek,
                            "closesAt"
                          )
                        }
                        dateFormat="hh : mm aa"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}

        <div className="buttonContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
      </form>
    </WorkingDaysStyles>
  );
};

export default WorkingDays;
