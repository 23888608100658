import { forwardRef } from "react";
import DatePicker from "react-datepicker";
// icons
import { Clock } from "../../../SVGS";
// package styling
import "react-datepicker/dist/react-datepicker.css";
// styles
import { TimePickerStyles } from "./timePicker.styles";

const CustomTimePicker = ({
  value,
  name,
  touched,
  error,
  dateFormat = "HH  :  mm     aa",
  handleChange
}) => {
  const CustomInput = forwardRef(({ value, onClick, placeholderText }, ref) => (
    <div className={`input ${value && "selected"}`} onClick={onClick} ref={ref}>
      <div className="valueContainer">
        <div className="iconContainer">
          <Clock />
        </div>
        {value ? (
          <p className="value">{value}</p>
        ) : (
          <p className="placeholder">{placeholderText}</p>
        )}
      </div>
    </div>
  ));
  return (
    <TimePickerStyles>
      <DatePicker
        className="timePicker"
        selected={value}
        onChange={date => handleChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        customInput={<CustomInput placeholderText="Select date" />}
        dateFormat={dateFormat}
      />
      <p className="errorMessage">{touched && error && error}</p>
    </TimePickerStyles>
  );
};

export default CustomTimePicker;
