import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addNewServiceRequest } from "../../../redux/actions/service-requests.actions";
import history from "../../../history";

export default function useServiceRequestHandler() {
  const dispatch = useDispatch();

  function handleServiceRequest(data) {
    const toastOptions = {
      autoClose: 4000,
      closeOnClick: false,
      draggable: false,
      theme: "colored"
    };

    if (history?.location?.pathname === "/service-requests") {
      toast.info("You have new service request!", toastOptions);
      dispatch(addNewServiceRequest(data?.service));
    } else {
      toast.info(
        <div
          onClick={() => {
            history.push("/service-requests");
            toast.dismiss();
          }}
        >
          New service requests! Click here to see.
        </div>,
        toastOptions
      );
    }
  }

  return { handleServiceRequest };
}
