//Domain models
import { Sender } from ".";

// Utils
import { convertTimeStampToDateWithSlash } from "../../../utils/timeStampToDate";
import { convertTimeStampToTime } from "../../../utils/timeStampToDate";

export class ChatMessage {
  constructor(message = {}) {
    this.id = message.id;
    this.type = message.type;
    this.body = message.body;
    this.createdAt = new Date(message.created_at);
    this.date = convertTimeStampToDateWithSlash(message.created_at / 1000);
    this.time = convertTimeStampToTime(message.created_at / 1000);
    this.roomId = message.room_id;
    this.serviceId = message.service_id;
    this.sender = message?.sender ? new Sender(message.sender) : null;
  }
}
