import styled from "styled-components";

export const ServiceRequestsStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;

    .tableHead {
      display: flex;
      align-items: center;

      .tableHeadTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9fadc3;
      }
    }
  }

  .ID {
    /* width: 15%; */
    width: 12.6%;
  }

  .user {
    /* width: 20%; */
    width: 12.6%;
    text-align: center;
  }

  .serviceType {
    /* width: 15%; */
    width: 16.6%;
    text-align: center;
  }

  .bookedFor {
    /* width: 20%; */
    width: 18.6%;
    text-align: center;
  }

  .messages {
    /* width: 12%; */
    width: 18.6%;
    text-align: center;
  }

  .actions {
    /* width: 18%; */
    width: 19.6%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .newRequestsContainer {
    margin-top: 14px;

    .newRequestsLabel {
      padding: 2px 6px;
      border-radius: 3px 3px 3px 0px;
      background: #e2e2eb;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #172b4d;
      width: 94px;
    }
  }

  .list {
    padding: 0;
  }

  .requests {
    margin-top: 24px;
  }

  .modalCard {
    width: 400px !important;
  }

  .estimationCard {
    height: unset;
  }
`;
