import { useHistory } from "react-router-dom";
// components
import { Card, Button } from "../components/UI";
import { Paragraph, Heading2 } from "../components/typography";
// icons
import { NotFoundIcon } from "../SVGS";
//builders
import * as buttonDesignType from "../builders/buttonDesign.types";
// styles
import { NotFoundPageStyles } from "../pagesStyles/notFound.styles";

const NotFound = () => {
  const history = useHistory();
  return (
    <NotFoundPageStyles>
      <Card className="wrapper">
        <div className="topContent">
          <NotFoundIcon />
          <div className="notFoundTitleContainer">
            <h2 className="title">404</h2>
            <Heading2 className="text">Page not found.</Heading2>
          </div>
        </div>
        <Paragraph className="notFoundText">
          Sorry, we couldn’t find the page you are looking for.
        </Paragraph>
        {/* // TODO add link to root */}
        <Button
          buttonDesignType={buttonDesignType.PRIMARY}
          style={{ width: "100%" }}
          onClick={() => history.replace("/")}
        >
          Back to Home
        </Button>
      </Card>
    </NotFoundPageStyles>
  );
};

export default NotFound;
