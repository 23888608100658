import PlacesAutocomplete from "react-places-autocomplete";
import { InputStyles } from "./googlePlacesAutocomplete.styles";

const GooglePlacesAutoComplete = ({
  value,
  handleChange,
  handleSelect,
  error,
  touched
}) => {
  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: { country: "us" }
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <InputStyles>
          <input
            {...getInputProps()}
            autoComplete="off"
            placeholder="Address"
            className={`input ${touched && error && "error"}`}
          />
          {suggestions.length > 0 && (
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, i) => (
                <div
                  {...getSuggestionItemProps(suggestion)}
                  key={i}
                  className="option"
                >
                  <span className="value">{suggestion.description}</span>
                </div>
              ))}
            </div>
          )}

          <p className="errorMessage">{touched && error && error}</p>
        </InputStyles>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutoComplete;
