import { toast } from "react-toastify";

// Action types
import * as actionTypes from "../actionTypes/manager.types";

export const addNewManager = managerFormValues => {
  return {
    type: actionTypes.ADD_MANAGER_REQUEST,
    managerFormValues
  };
};

export const addNewManagerSuccess = managers => {
  toast.success("Manager successfully created!");
  return {
    type: actionTypes.ADD_MANAGER_SUCCESS,
    managers
  };
};

export const getManagers = () => {
  return {
    type: actionTypes.FETCH_MANAGERS_REQUEST
  };
};

export const getManagersSuccess = managers => {
  return {
    type: actionTypes.FETCH_MANAGERS_SUCCESS,
    managers
  };
};

export const editManager = (chosenManager, modalType) => {
  return {
    type: actionTypes.EDIT_MANAGER,
    chosenManager,
    modalType
  };
};

export const updateManager = (managerFormValues, initialValues, id) => {
  return {
    type: actionTypes.UPDATE_MANAGER_REQUEST,
    managerFormValues,
    initialValues,
    id
  };
};

export const updateManagerSuccess = managers => {
  toast.success("Manager successfully updated!");
  return {
    type: actionTypes.UPDATE_MANAGER_SUCCESS,
    managers
  };
};

export const deleteManager = id => {
  return {
    type: actionTypes.DELETE_MANAGER_REQUEST,
    id
  };
};

export const deleteManagerSuccess = managers => {
  toast.success("Manager successfully deleted!");
  return {
    type: actionTypes.DELETE_MANAGER_SUCCESS,
    managers
  };
};

export const viewManagerBranches = (chosenManager, modalType) => {
  return {
    type: actionTypes.VIEW_MANAGER_BRANCHES,
    chosenManager,
    modalType
  };
};

export const openModal = modalType => {
  return {
    type: actionTypes.OPEN_MODAL,
    modalType
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL
  };
};
