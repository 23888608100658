// styles
import { GraphDataItemStyles } from "./graphDataItem.styles";

const GrapghDataItem = ({ onClick, color, type = "BG", name, number }) => {
  return (
    <GraphDataItemStyles onClick={onClick} type={type}>
      <div className="dot" style={{ background: color }} />
      <p className="text" style={{ marginRight: 12 }}>
        {name}
      </p>
      <hr className="hr" />
      {number && (
        <p className="text" style={{ marginLeft: 12 }}>
          {number}
        </p>
      )}
    </GraphDataItemStyles>
  );
};

export default GrapghDataItem;
