import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { Header, Body } from "../../../../../components/UI";
import { Heading2 } from "../../../../../components/typography";
import RoleForm from "../RoleForm/RoleForm";

// Icons
import { Close } from "../../../../../SVGS";

// Services
import { roleValidation } from "../../../../../services/validations/role.validation";

// Calls
import { createRoleRequest } from "../../../../../api/calls/general-branch-settings.call";

// Form Models
import { RoleFormModel } from "../../../../../models/app/forms";

// Styles
import { AddRoleStyles } from "./addRole.styles";

const AddRole = ({
  closeModal,
  setRolesAfterCreateAndUpdateRole,
  branchId,
  permissions
}) => {
  const createRoleHandler = async values => {
    const response = await createRoleRequest(branchId, values);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Role successfully created.");
    setRolesAfterCreateAndUpdateRole(response.roles);
  };

  const formik = useFormik({
    initialValues: new RoleFormModel(),
    onSubmit: createRoleHandler,
    validate: roleValidation
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange
  } = formik;
  return (
    <AddRoleStyles>
      <Header className="formHeader">
        <Heading2>ADD NEW ROLE</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <RoleForm
            permissions={permissions}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            buttonText="Add Role"
          />
        </form>
      </Body>
    </AddRoleStyles>
  );
};

export default AddRole;
