import styled from "styled-components";

export const FiltersStyles = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .employeeSearchInputFullWidth {
    width: calc(100% - 195px);
  }
  .employeeSearchInput {
    margin-left: 24px;

    .employeeSearch {
      margin-top: 2px;
      border: 1px solid #dfe1e6;
      border-radius: 3px;
      display: flex;
      align-items: center;

      .searchContainer {
        width: 212px;
        position: relative;

        .searchInputContainer {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
        }

        .icon {
          position: absolute;
          left: 15px;
          z-index: 2;
        }

        .searchInput {
          position: relative;
          width: 100%;
          border: none;
          border-radius: 3px;
          outline: none;
          padding: 8px 8px 8px 43px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #292359;
        }

        .searchResultsContainer {
          position: absolute;
          max-height: 250px;
          align-self: flex-end;
          width: 100%;
          background: #ffffff;
          border: 1px solid #dfe1e6;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
          border-radius: 6px;
          margin-top: 1px;

          .searchResult {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #292359;
            border: none;
            outline: none;
            background: #fff;
            padding: 9px 15px;
            width: 100%;
            text-align: left;
            cursor: pointer;

            &:first-child {
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
            }

            &:last-child {
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
            }

            &:hover {
              background: #f2f9fd;
            }
          }
        }
      }
      /* Hide scrollbar for Chrome, Safari and Opera */
      .chosenEmployeesContainer::-webkit-scrollbar {
        display: none;
      }

      .chosenEmployeesContainer {
        width: calc(100% - 212px);
        border-left: 1px solid #dfe1e6;
        padding: 0 9px;
        display: flex;
        align-items: center;
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        cursor: pointer;

        .employeePill {
          padding: 4px 12px;
          background: #f2f9fd;
          border: 1px solid #74bde9;
          border-radius: 20px;
          display: flex;
          align-items: center;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }

          .employeeName {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #172b4d;
            margin-right: 10px;
            white-space: nowrap;
          }

          .removeEmployeeIcon {
            width: 15px;
            height: 15px;
          }

          .removeEmployeeIcon path {
            fill: #74bde9;
          }
        }
      }
    }

    .focusedField {
      border: 1px solid #74bde9;
    }
  }
`;
