import styled from "styled-components";

export const ExpandingDescriptionStyles = styled.div`
  .titleAndArrow {
    display: flex;
    align-items: center;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #44444f;
      margin-right: 17px;
    }

    .arrow {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      transform: rotate(180deg);
    }

    .arrowUp {
      transform: rotate(0deg);
    }
  }

  .description {
    height: 0px;
    margin: 12px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #9fadc3;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
  }
`;
