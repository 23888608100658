import styled from "styled-components";

export const SubscriptionPlanPriceStyles = styled.div`
  display: flex;

  .dollar {
    font-style: normal;

    ${({ designType }) => {
      switch (designType) {
        case "md":
          return `
            font-size: 13px;
            line-height: 15px;
            font-weight: normal;
          `;
        case "bg":
          return `
            font-size: 15px;
            line-height: 15px;
            font-weight: bold;
          `;
        default:
          return `
            font-size: 11px;
            line-height: 13px;
            font-weight: normal;
          `;
      }
    }}

    letter-spacing: 0.1px;
    color: #171725;
  }

  .price {
    font-style: normal;
    ${({ designType }) => {
      switch (designType) {
        case "md":
          return `
            font-size: 18px;
            line-height: 21px;
            font-weight: normal;
          `;
        case "bg":
          return `
            font-size: 24px;
            line-height: 24px;
            font-weight: bold;
          `;
        default:
          return `
            font-size: 16px;
            line-height: 19px;
            font-weight: normal;
          `;
      }
    }}
    letter-spacing: 0.1px;
    color: #171725;
  }

  .time {
    font-style: normal;
    font-weight: normal;
    ${({ designType }) => {
      switch (designType) {
        case "md":
          return `
            font-size: 13px;
            line-height: 15px;
          `;
        case "bg":
          return `
            font-size: 14px;
            line-height: 17px;
          `;
        default:
          return `
            font-size: 11px;
            line-height: 13px;
          `;
      }
    }}
    letter-spacing: 0.1px;
    color: #171725;
    align-self: flex-end;
  }
`;
