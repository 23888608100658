import { useState } from "react";
// components
import { Heading2, Label, Paragraph } from "../../../../../typography";
import { Body, Card, CentralModal, Header } from "../../../../../UI";
// icons
import { Close } from "../../../../../../SVGS";
// constants
import * as serviceTypes from "../../../../../../constants/services.constants";
// styles
import { ServiceTimeFrameStyles } from "./serviceTimeFrame.styles";

const ServiceTimeFrame = ({
  employee,
  start,
  id,
  type = serviceTypes.FILTERS_IN_PROGRESS,
  typeOfService = "inProgress",
  startIndex,
  length,
  isActive = false,
  setActiveService,
  openTicketAction,
  hasMore
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMoreOptionsHovered, setIsMoreOptionsHovered] = useState(false);
  const [isUnactiveBarHovered, setIsUnactiveBarHovered] = useState(false);
  const doesHaveMore = hasMore && hasMore.length > 0;
  const headerAction = () => {
    if (hasMore) {
      setIsModalOpen(true);
    }
    setActiveService(employee.id, id, typeOfService);
  };

  const progressBarAction = () => {
    if (isActive) {
      openTicketAction();
    } else {
      setActiveService(employee.id, id, typeOfService);
    }
  };

  return (
    <ServiceTimeFrameStyles
      isActive={isActive}
      startPosition={startIndex}
      progressLength={length}
      type={type}
      doesHaveMore={doesHaveMore}
    >
      <div className="header" onClick={headerAction}>
        <p
          className={`headerId  ${
            isMoreOptionsHovered && "headerIdBeforeHovered"
          } ${!isActive && isUnactiveBarHovered && "cardHover"}`}
          onMouseOver={() => setIsUnactiveBarHovered(true)}
          onMouseOut={() => setIsUnactiveBarHovered(false)}
          id="headerId"
        >{`ID: ${id}`}</p>
        {hasMore && (
          <p
            className="moreOptions"
            onMouseOver={() => setIsMoreOptionsHovered(true)}
            onMouseOut={() => setIsMoreOptionsHovered(false)}
          >{`+ ${hasMore.length - 1}`}</p>
        )}
      </div>
      <div
        className={`progressBar ${
          !isActive && isUnactiveBarHovered && "cardHover"
        }`}
        onMouseOver={() => setIsUnactiveBarHovered(true)}
        onMouseOut={() => setIsUnactiveBarHovered(false)}
        onClick={progressBarAction}
      >
        <Paragraph className="serviceID">ID : {id}</Paragraph>
      </div>
      {isModalOpen && hasMore && (
        <CentralModal closeModal={() => setIsModalOpen(false)}>
          <Card className="serviceCardModal">
            <Header className="activeServiceHeader">
              <Heading2>CHOOSE SERVICE</Heading2>
              <Close onClick={() => setIsModalOpen(false)} />
            </Header>
            <Body>
              <Label>
                Chose active service for <b>{employee.name}</b> at{" "}
                <b>{start}</b>
              </Label>
              <div style={{ marginTop: 15 }}>
                {hasMore.map(service => (
                  <div
                    key={service.id}
                    className={`service ${service.isActive && "activeService"}`}
                    onClick={() => {
                      setActiveService(employee.id, service.id, typeOfService);
                      setIsModalOpen(false);
                    }}
                  >
                    ID : {service.id}
                  </div>
                ))}
              </div>
            </Body>
          </Card>
        </CentralModal>
      )}
    </ServiceTimeFrameStyles>
  );
};

export default ServiceTimeFrame;
