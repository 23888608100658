// Domain Model
import { File } from ".";

export class Worker {
  constructor(worker = {}) {
    this.id = worker.id;
    this.email = worker.email;
    this.firstName = worker.first_name;
    this.lastName = worker.last_name;
    this.contactNumber = worker.contact_number;
    this.profileImage = new File(worker.profile_image);
    this.fullName = this.fullName();
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
