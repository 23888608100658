export class BranchGeneralSettingsFormModel {
  constructor(branch = {}) {
    this.images = branch?.images || [];
    this.hasParking = branch?.hasParking || false;
    this.isActive = branch?.isActive || false;
    this.truckManufacturers = branch?.truckManufacturers || [];
    this.trailerManufacturers = branch?.trailerManufacturers || [];
    this.trailerTypes = branch?.trailerTypes || [];
    this.paymentTypes = branch?.paymentTypes || [];
    this.description = branch?.description || "";
    this.speciality = branch?.speciality || "";
    this.hourlyPriceFrom = branch?.hourlyPriceFrom || "";
    this.hourlyPriceTo = branch?.hourlyPriceTo || "";
  }
}
