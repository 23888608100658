const AddPresetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#172B4D"
        d="M7 0C3.136 0 0 3.136 0 7s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm3.5 7.7H7.7v2.8H6.3V7.7H3.5V6.3h2.8V3.5h1.4v2.8h2.8v1.4z"
      ></path>
    </svg>
  );
};

export default AddPresetIcon;
