import styled from "styled-components";
import * as color from "../../constants/color.constants";

const LabelStyles = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

const Label = ({ children, className, style }) => (
  <LabelStyles className={className} style={{ ...style }}>
    {children}
  </LabelStyles>
);

export default Label;
