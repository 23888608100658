// Action types
import { updateObject } from "../../utils";
import * as actionTypes from "../actionTypes/chat.types";

const initialState = {
  newMessage: undefined
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHAT_NEW_MESSAGE:
      return setNewChatMessage(state, action);
    default:
      return state;
  }
};

export const setNewChatMessage = (state, { message }) => {
  return updateObject(state, {
    newMessage: message
  });
};
