import styled from "styled-components";

export const WorkersFormStyles = styled.div`
  .workersHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .input {
    margin-top: 2px;
  }

  .workersList {
    margin-top: 2px;

    .workersItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .workerName {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #171725;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .submitButton {
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      padding: 10px 18px;
    }
  }
`;
