import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Body, Card, Header } from "../components/UI";
import { Heading2 } from "../components/typography";

// Page components
import {
  Schedule,
  Filters
} from "../components/PageSpecificComponents/pagesComponents/employeeSchedule";

// Builders
import { filters } from "../builders/employeeSchedule.builder";

// Actions
import {
  getSchedule,
  resetScheduleOnPageLeave
} from "../redux/actions/services.actions";

// Utils
import { isEmpty } from "../utils";

// Styles
import { EmployeeScheduleStyles } from "../pagesStyles/employeeSchedule.styles";

const EmployeeSchedule = () => {
  const dispatch = useDispatch();
  const { shouldRefetchServicesAfterAction } = useSelector(
    state => state.servicesReducer
  );
  const { activeBranch } = useSelector(state => state.app);

  // Employees schedule filter queryies
  const [dateQuery, setDateQuery] = useState(new Date());
  const [chosenEmployees, setChosenEmployees] = useState([]);
  const [chosenFilter, setChosenFilter] = useState(filters[0].value);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const convertDateToString = date => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = `${date.getFullYear()}`;

    const formatedDay = day < 10 ? `0${day}` : `${day}`;
    const formatedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${year}-${formatedMonth}-${formatedDay}`;
  };

  const getWorkersSchedule = useCallback(() => {
    dispatch(
      getSchedule(convertDateToString(dateQuery), chosenFilter, chosenEmployees)
    );
  }, [dateQuery, chosenFilter, chosenEmployees, dispatch]);

  useEffect(() => {
    if (
      !isEmpty(dateQuery) &&
      !isEmpty(chosenFilter) &&
      !isEmpty(chosenEmployees)
    ) {
      getWorkersSchedule();
    }

    return () => {
      dispatch(resetScheduleOnPageLeave());
    };
  }, [dateQuery, chosenFilter, chosenEmployees, dispatch, getWorkersSchedule]);

  useEffect(() => {
    setChosenEmployees([]);
  }, [activeBranch]);

  useEffect(() => {
    if (shouldRefetchServicesAfterAction === "refetch") {
      getWorkersSchedule();
    }
  }, [shouldRefetchServicesAfterAction, getWorkersSchedule]);

  return (
    <EmployeeScheduleStyles>
      <PageHeader title="Employee Schedule" />
      <Card className="card">
        <Header className="sectionHeader">
          <Heading2>EMPLOYEE SCHEDULE</Heading2>
        </Header>
        <Header className="filters">
          <Filters
            dateQuery={dateQuery}
            setDateQuery={setDateQuery}
            chosenEmployees={chosenEmployees}
            setChosenEmployees={setChosenEmployees}
          />
        </Header>
        <Body className="statusFilters">
          {filters.map(({ value, title }, i) => (
            <button
              key={i}
              type="button"
              onClick={() => setChosenFilter(value)}
              className={`tab ${chosenFilter === value && "activeTab"}`}
            >
              {title}
            </button>
          ))}
        </Body>
      </Card>
      <Schedule
        dateQuery={dateQuery}
        isChatOpen={isChatOpen}
        setIsChatOpen={setIsChatOpen}
      />
    </EmployeeScheduleStyles>
  );
};

export default EmployeeSchedule;
