import validator from "validator";

export const myProfileValidation = ({
  firstName,
  lastName,
  email,
  contactNumber
}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName = "* Required";
  }

  if (!lastName) {
    errors.lastName = "* Required";
  }

  if (!email) {
    errors.email = "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  if (!contactNumber) {
    errors.contactNumber = "* Required";
  }

  return errors;
};
