import { apiService } from "../api.service";

// Requests
import { ManagerRequest } from "../../models/api/requests";

// Responses
import { ManagersResponse } from "../../models/api/responses";

/**
 * Create manager Request
 * @param managerFormValues - ManagerFormModel
 * @return - ManagersResponse || ErrorResponse
 */
export const createManagerRequest = async managerFormValues => {
  const payload = new ManagerRequest(managerFormValues);
  const response = await apiService.post("/managers", payload);
  if (response.hasError) {
    return response;
  }
  return new ManagersResponse(response);
};

/**
 * Update manager Request
 * @param managerFormValues - ManagerFormModel
 * @param initialValues - ManagerFormModel
 * @param id - string
 * @return - ManagersResponse || ErrorResponse
 */
export const updateManagerRequest = async (
  managerFormValues,
  initialValues,
  id
) => {
  const payload = new ManagerRequest(managerFormValues);
  payload.removeSameValues(initialValues);
  const response = await apiService.patch(`/managers/${id}`, payload);
  if (response.hasError) {
    return response;
  }
  return new ManagersResponse(response);
};

/**
 * Delete manager Request
 * @param id - string
 * @return - ManagersResponse || ErrorResponse
 */
export const deleteManagersRequest = async id => {
  const response = await apiService.delete(`/managers/${id}`);
  if (response.hasError) {
    return response;
  }
  return new ManagersResponse(response);
};

/**
 * Fetch manangers Request
 * @return - ManagersResponse || ErrorResponse
 */
export const fetchManagersRequest = async () => {
  const response = await apiService.get("/managers");
  if (response.hasError) {
    return response;
  }
  return new ManagersResponse(response);
};
