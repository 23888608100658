import { useHistory } from "react-router";

// Components
import { Button, IconTextTooltip } from "../../../../UI";
import { Paragraph } from "../../../../typography";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";

// Icons
import { DarkPin, ManagerIcon, PhoneIcon, Trash } from "../../../../../SVGS";

// Styles
import { BranchCardStyles } from "./branchCard.styles";
import { isEmpty } from "../../../../../utils";

const BranchCard = ({
  id,
  name,
  location,
  contactNumber,
  managers,
  deleteBranch,
  isActive
}) => {
  const history = useHistory();

  return (
    <BranchCardStyles isActive={isActive}>
      <div className="topContent">
        <h3 className="branchName">{name}</h3>
        <div style={{ height: 26 }}>
          <Button
            onClick={deleteBranch}
            className="deleteButton"
            buttonDesignType={buttonDesignType.DANGER}
          >
            <Trash />
          </Button>
        </div>
      </div>
      <div className="branchDetails">
        <div className="branchLeftInfo">
          <div className="iconAndText" style={{ marginBottom: 10 }}>
            <DarkPin />
            <Paragraph className="text">{location.address}</Paragraph>
          </div>
          <div className="iconAndText">
            <PhoneIcon />
            <Paragraph className="text">{contactNumber}</Paragraph>
          </div>
          <div>
            <Paragraph className="managersLabel">Managed By:</Paragraph>
            <div className="managersList">
              {isEmpty(managers) ? (
                <Paragraph className="managersPlaceholder">
                  No Assigned managers
                </Paragraph>
              ) : (
                managers.map((manager, index) => (
                  <IconTextTooltip
                    Icon={ManagerIcon}
                    className="managersIcon"
                    style={{ marginRight: 8 }}
                    key={manager.id}
                    id={`manager${index}`}
                    isTooltipVisible
                    tooltipContent={`${manager.firstName} ${manager.lastName}`}
                    tooltipUpperContent={`${manager.email}`}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        {/* <div className="branchRightInfo">
         
        </div> */}
      </div>
      <div className="buttonsContainer">
        <Button
          className="bottomButtons"
          onClick={() => history.push(`/branch-settings/${id}`)}
          style={{ marginRight: 12 }}
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Branch Settings
        </Button>
        <Button
          className="bottomButtons"
          onClick={() => history.push(`/branches/${id}`)}
          buttonDesignType={buttonDesignType.SECONDARY}
        >
          Edit Branch
        </Button>
      </div>
    </BranchCardStyles>
  );
};

export default BranchCard;
