import { CurrentSubscription } from "./CurrentSubscription.model";
export class DashboardData {
  constructor(data = {}) {
    this.verificationStatus = data?.verification_status;
    this.subscriptionPlanName = data?.subscription_plan_name;
    this.currentSubscription = data?.current_subscription
      ? new CurrentSubscription(data.current_subscription)
      : undefined;
  }
}
