export const resetPasswordValidate = ({ newPassword, confirmNewPassword }) => {
  const errors = {};
  if (!newPassword) {
    errors.newPassword = "* Required";
  }

  if (!confirmNewPassword) {
    errors.confirmNewPassword = "* Required";
  } else if (confirmNewPassword !== newPassword) {
    errors.confirmNewPassword = "* No Match";
  }

  return errors;
};
