import { toast } from "react-toastify";

// Custom history
import history from "../../history";

// Action types
import * as actionTypes from "../actionTypes/app.types";

// AUTH ACTIONS
export const register = registerFormValues => {
  return { type: actionTypes.REGISTER_REQUEST, registerFormValues };
};

export const registerSuccess = authenticationResponseData => {
  toast.success("You have been registered successfully");
  return {
    type: actionTypes.REGISTER_SUCCESS,
    authenticationResponseData
  };
};

export const login = loginFormValues => {
  return { type: actionTypes.LOGIN_REQUEST, loginFormValues };
};

export const loginSuccess = authenticationResponseData => {
  toast.success("You have logged in successfully");
  return { type: actionTypes.LOGIN_SUCCESS, authenticationResponseData };
};

export const fetchAuthenticatedUserData = () => {
  return { type: actionTypes.FETCH_AUTHENTICATED_USER_REQUEST };
};

export const fetchAuthenticatedUserDataSuccess = userResponseData => {
  return {
    type: actionTypes.FETCH_AUTHENTICATED_USER_SUCCESS,
    userResponseData
  };
};

export const logout = () => {
  history.replace("/");
  return { type: actionTypes.LOGOUT };
};

// PROFILE ACTIONS
export const updateProfile = (profileFormValues, initialProfileData) => {
  return {
    type: actionTypes.UPDATE_PROFILE_REQUEST,
    profileFormValues,
    initialProfileData
  };
};

export const updateProfileSuccess = userResponse => {
  toast.success("Profile updated successfully");
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    userResponse
  };
};

// BRANCHES ACTIONS
export const createBranch = branchFormValues => {
  return {
    type: actionTypes.CREATE_BRANCH_REQUEST,
    branchFormValues
  };
};

export const createBranchSuccess = branch => {
  toast.success("Branch successfully created.");
  return {
    type: actionTypes.CREATE_BRANCH_SUCCESS,
    branch
  };
};

export const updateBranch = (branchFormValues, initialValues, id) => {
  return {
    type: actionTypes.UPDATE_BRANCH_REQUEST,
    branchFormValues,
    initialValues,
    id
  };
};

export const updateBranchSuccess = branch => {
  toast.success("Branch successfully updated.");
  return {
    type: actionTypes.UPDATE_BRANCH_SUCCESS,
    branch
  };
};

export const fetchBranches = () => {
  return {
    type: actionTypes.FETCH_BRANCHES_REQUEST
  };
};

export const fetchBranchesSuccess = branches => {
  return {
    type: actionTypes.FETCH_BRANCHES_SUCCESS,
    branches
  };
};

export const setActiveBranch = branch => {
  return {
    type: actionTypes.SET_ACTIVE_BRANCH,
    branch
  };
};

export const deleteBranch = id => {
  return {
    type: actionTypes.DELETE_BRANCH,
    id
  };
};

// SYSTEM COMPONENTS ACTIONS
export const fetchSystemComponents = queryParams => {
  return {
    type: actionTypes.FETCH_SYSTEM_COMPONENTS,
    queryParams
  };
};

export const fetchSystemComponentsSuccess = systemComponentsResponse => {
  return {
    type: actionTypes.FETCH_SYSTEM_COMPONENTS_SUCCESS,
    systemComponentsResponse
  };
};

// SOCKET ACTIONS

export const connectToSocket = () => {
  return {
    type: actionTypes.FETCH_CHAT_AUTH_TOKEN_REQUEST
  };
};

export const connectToSocketSuccess = socket => {
  return {
    type: actionTypes.CONNECT_TO_SOCKET_SUCCESS,
    socket
  };
};
