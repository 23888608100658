import styled from "styled-components";
// constants
import * as modalTypes from "../constants/managers.constants";

export const ManagersStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tableHead {
      display: flex;
      align-items: center;

      .tableHeadTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9fadc3;
      }
    }
  }

  .list {
    margin-top: 24px;
  }

  .firstName {
    width: 15%;
  }

  .lastName {
    width: 15%;
  }

  .permissions {
    width: 12%;
  }

  .branches {
    width: 14%;
  }

  .phone {
    width: 14%;
  }

  .email {
    width: 25%;
  }

  .actions {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .modalCard {
    width: ${({ modalType }) =>
      modalType === modalTypes.VIEW_BRANCHES ? "537px" : "681px"} !important;
    max-height: 90% !important;

    .card {
      overflow: unset;
    }
  }
`;
