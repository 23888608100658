import { useEffect } from "react";

const useDraggableHorizontalScroll = ref => {
  let mouseDown = false;
  let startX, scrollLeft;

  const startDragging = e => {
    mouseDown = true;
    startX = e.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
  };

  const stopDragging = () => {
    mouseDown = false;
  };

  const pageOffsetOnMouseMove = e => {
    e.preventDefault();
    if (!mouseDown) {
      return;
    }
    const x = e.pageX - ref.current.offsetLeft;
    const scroll = x - startX;
    ref.current.scrollLeft = scrollLeft - scroll;
  };

  useEffect(() => {
    const elementRef = ref;
    if (!ref.current) {
      return;
    }
    elementRef.current.addEventListener("mousemove", pageOffsetOnMouseMove);
    elementRef.current.addEventListener("mousedown", startDragging, false);
    elementRef.current.addEventListener("mouseup", stopDragging, false);
    elementRef.current.addEventListener("mouseleave", stopDragging, false);
    return () => {
      if (!elementRef.current) {
        return;
      }
      elementRef.current.removeEventListener(
        "mousemove",
        pageOffsetOnMouseMove
      );
      elementRef.current.removeEventListener("mousedown", startDragging, false);
      elementRef.current.removeEventListener("mouseup", stopDragging, false);
      elementRef.current.removeEventListener("mouseleave", stopDragging, false);
    };
  });
};

export default useDraggableHorizontalScroll;
