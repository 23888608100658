// SVGS
import { CircledBell } from "../../SVGS";
// styles
import { NotificationStyles } from "./notification.styles";

const Notification = ({ text }) => {
  return (
    <NotificationStyles>
      <div className="bellContainer">
        <CircledBell />
      </div>
      <p className="notfication">{text}</p>
    </NotificationStyles>
  );
};

export default Notification;
