import styled from "styled-components";

const BodyStyles = styled.div`
  padding: 24px;
`;

const Body = ({ children, style, className }) => (
  <BodyStyles style={{ ...style }} className={className}>
    {children}
  </BodyStyles>
);

export default Body;
