export const roleValidation = ({ name, description }) => {
  const errors = {};

  if (!name) {
    errors.name = "* Required";
  }

  if (!description) {
    errors.description = "* Required";
  }

  return errors;
};
