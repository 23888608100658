import { memo } from "react";
import styled from "styled-components";
import * as color from "../../constants/color.constants";

const SpanStyles = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

const Span = ({ children, className, style, onClick }) => (
  <SpanStyles onClick={onClick} className={className} style={{ ...style }}>
    {children}
  </SpanStyles>
);

export default memo(Span);
