import { useState, useCallback } from "react";

// Components
import { Header, Card, Body, CentralModal, Slider } from "../components/UI";
import { Heading1, Heading2, Paragraph } from "../components/typography";

// Containers
import LoginForm from "../containers/forms/login/Login.from";
import ForgotPasswordForm from "../containers/forms/forgotPassword/ForgotPassword.form";

// Icons
import { TruckFixLogo, Close } from "../SVGS";

// Styles
import { LoginStyles } from "../pagesStyles/login.styles";

const images = [
  "https://erbgroup.com/wp-content/uploads/2018/06/Blog-photo-baden-shop.jpg",
  "https://cdn.dealerspike.com/imglib/v1/800x600/imglib/Assets/Blog/a1/23/a123fdbc-3a93-4a86-bc8e-fed83c7c3a58.jpg",
  "https://www.tindolford.com/static/dealer-14653/903629.jpg"
];

const Login = () => {
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);

  const closeForgotPassword = () => {
    setIsForgotPasswordOpen(false);
  };

  const openForgotPassword = () => {
    setIsForgotPasswordOpen(true);
  };

  const memoizedCloseForgotPassword = useCallback(closeForgotPassword, []);
  const memoizedOpenForgotPassword = useCallback(openForgotPassword, []);

  return (
    <LoginStyles>
      <div className="formContainer">
        <TruckFixLogo />
        <Heading1 style={{ margin: "30px 0" }}>Log In to TruckFix</Heading1>
        <LoginForm openForgotPassword={memoizedOpenForgotPassword} />
      </div>
      <div className="sliderContainer">
        <Slider images={images} />
      </div>
      {isForgotPasswordOpen && (
        <CentralModal closeModal={memoizedCloseForgotPassword}>
          <Card className="forgotPasswordModal">
            <Header className="modalHeader">
              <Heading2>FORGOT PASSWORD</Heading2>
              <Close onClick={memoizedCloseForgotPassword} />
            </Header>
            <Body>
              <Paragraph style={{ marginBottom: 24, wordBreak: "unset" }}>
                Enter your email address to retrieve your password reset link.
              </Paragraph>
              <ForgotPasswordForm closeModal={memoizedCloseForgotPassword} />
            </Body>
          </Card>
        </CentralModal>
      )}
    </LoginStyles>
  );
};

export default Login;
