import styled from "styled-components";

export const ManageScheduleStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    padding: 0;

    .scheduleTabs {
      padding: 20px 0 18px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #44444f;
      cursor: pointer;
      width: 150px;
      border-bottom: 2px solid transparent;
    }

    .activeScheduleTab {
      border-bottom: 2px solid #74bde9;
      color: #74bde9;
    }
  }

  .body {
    padding: 0;
  }
`;
