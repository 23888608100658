// Components
import { Paragraph } from "../../typography";
import { Button, IconTextTooltip } from "../../UI";

// Constants
import * as permissionsEnum from "../../../constants/permissions.constants";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Icons
import { BusinessProfileIcon, BranchSettingsIcon, Trash } from "../../../SVGS";

// Utils
import { isEmpty } from "../../../utils";

// Styles
import { ManagerItemStyles } from "./managerItem.styles";

const ManagerItem = ({
  item,
  showBranchesAction,
  editManager,
  deleteManager
}) => {
  return (
    <ManagerItemStyles>
      <Paragraph className="firstName fn">{item.firstName}</Paragraph>
      <Paragraph className="lastName ln">{item.lastName}</Paragraph>
      <div className="permissions perm">
        {isEmpty(item.permissions)
          ? "/"
          : item.permissions.map(permission => (
              <IconTextTooltip
                key={permission.key}
                id={
                  permission.key === permissionsEnum.BUSINESS_PROFILE
                    ? "businessProfile"
                    : "branchSettings"
                }
                style={{ marginRight: 10 }}
                Icon={
                  permission.key === permissionsEnum.BUSINESS_PROFILE
                    ? BusinessProfileIcon
                    : BranchSettingsIcon
                }
                isTooltipVisible
                tooltipContent={
                  permission.key === permissionsEnum.BUSINESS_PROFILE
                    ? "Business Profile"
                    : "Access to Branches & Managers"
                }
              />
            ))}
      </div>
      <p className="branches br" onClick={showBranchesAction}>
        View Branches
      </p>
      <Paragraph className="phone ph">{item.contactNumber}</Paragraph>
      <Paragraph className="email em">{item.email}</Paragraph>
      <div className="actions">
        <Button onClick={editManager}>Edit</Button>
        <Button
          onClick={deleteManager}
          className="deleteButton"
          buttonDesignType={buttonDesignType.DANGER}
        >
          <Trash />
        </Button>
      </div>
    </ManagerItemStyles>
  );
};

export default ManagerItem;
