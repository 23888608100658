import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import {
  CheckboxButtons,
  DragAndDropUpload,
  Button,
  CentralModal,
  Card,
  Textarea
} from "../../../../components/UI";
import { Label, Paragraph } from "../../../../components/typography";
import GeneralSettingsListModal from "./GeneralSettingsListModal";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Calls
import {
  branchActivationRequest,
  fetchBranchGeneralSettings,
  updateBranchGeneralSettingsRequest
} from "../../../../api/calls/general-branch-settings.call";

// Form Models
import { BranchGeneralSettingsFormModel } from "../../../../models/app/forms";

// Constants
import * as modalTypes from "../../../../constants/generalSettingsModal.constants";

// Styles
import { GeneralSettingsFormStyles } from "./generalSettingsForm.styles";

const GeneralSettingsForm = ({ branchId }) => {
  const { systemComponents } = useSelector(state => state.app);
  const [modalType, setModalType] = useState(undefined);
  const [initialGeneralSettingsValue, setInitialGeneralSettingsValue] =
    useState(new BranchGeneralSettingsFormModel());

  const getBranchGeneralSettings = useCallback(async () => {
    const response = await fetchBranchGeneralSettings(branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setInitialGeneralSettingsValue(
      new BranchGeneralSettingsFormModel(
        response.branch.generateBranchGeneralSettingsForm()
      )
    );
  }, [branchId]);

  useEffect(() => {
    getBranchGeneralSettings();
  }, [getBranchGeneralSettings]);

  const updateGeneralSettingsHandler = async values => {
    const generalSettingsResponse = await updateBranchGeneralSettingsRequest(
      values,
      initialGeneralSettingsValue,
      branchId
    );

    if (generalSettingsResponse.hasError) {
      toast.error(generalSettingsResponse.error.message);
      return;
    }

    toast.success("General setting successfully updated.");
    setInitialGeneralSettingsValue(
      new BranchGeneralSettingsFormModel(
        generalSettingsResponse.branch.generateBranchGeneralSettingsForm()
      )
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialGeneralSettingsValue,
    onSubmit: updateGeneralSettingsHandler
    // validate: branchValidation
  });

  const { values, setFieldValue, handleBlur, handleChange, handleSubmit } =
    formik;

  const saveModalChanges = async value => {
    switch (modalType) {
      case modalTypes.SUPPORTED_TRUCKS:
        const supportedTrucksResponse =
          await updateBranchGeneralSettingsRequest(
            { supportedTrucks: value.map(truck => truck.id) },
            values,
            branchId
          );
        if (supportedTrucksResponse.hasError) {
          toast.error(supportedTrucksResponse.error.message);
          return;
        }
        toast.success("Supported trucks successfully updated.");
        setInitialGeneralSettingsValue(
          new BranchGeneralSettingsFormModel(
            supportedTrucksResponse.branch.generateBranchGeneralSettingsForm()
          )
        );
        break;
      case modalTypes.SUPPORTED_TRAILERS:
        const supportedTrailersResponse =
          await updateBranchGeneralSettingsRequest(
            { supportedTrailers: value.map(trailer => trailer.id) },
            values,
            branchId
          );
        if (supportedTrailersResponse.hasError) {
          toast.error(supportedTrailersResponse.error.message);
          return;
        }
        toast.success("Supported trailers successfully updated.");
        setInitialGeneralSettingsValue(
          new BranchGeneralSettingsFormModel(
            supportedTrailersResponse.branch.generateBranchGeneralSettingsForm()
          )
        );
        break;
      case modalTypes.SUPPORTED_TRAILER_TYPES:
        const supportedTrailerTypesResponse =
          await updateBranchGeneralSettingsRequest(
            { supportedTrailerTypes: value.map(trailerType => trailerType.id) },
            values,
            branchId
          );
        if (supportedTrailerTypesResponse.hasError) {
          toast.error(supportedTrailerTypesResponse.error.message);
          return;
        }
        toast.success("Supported trailer types successfully updated.");
        setInitialGeneralSettingsValue(
          new BranchGeneralSettingsFormModel(
            supportedTrailerTypesResponse.branch.generateBranchGeneralSettingsForm()
          )
        );
        break;
      case modalTypes.SUPPORTED_PAYMENT_TYPES:
        const supportedPaymentTypesResponse =
          await updateBranchGeneralSettingsRequest(
            {
              supportedPaymentTypes: value.map(paymentType => paymentType.key)
            },
            values,
            branchId
          );
        if (supportedPaymentTypesResponse.hasError) {
          toast.error(supportedPaymentTypesResponse.error.message);
          return;
        }
        toast.success("Supported payment options successfully updated.");
        setInitialGeneralSettingsValue(
          new BranchGeneralSettingsFormModel(
            supportedPaymentTypesResponse.branch.generateBranchGeneralSettingsForm()
          )
        );
        break;
      default:
        break;
    }

    setModalType(undefined);
  };

  const hasParkingChanage = async (name, value) => {
    const response = await updateBranchGeneralSettingsRequest(
      { [name]: value },
      values,
      branchId
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Branch parking successfully updated.");
    setInitialGeneralSettingsValue(
      new BranchGeneralSettingsFormModel(
        response.branch.generateBranchGeneralSettingsForm()
      )
    );
  };

  const handleImagesChange = async (name, urls) => {
    const response = await updateBranchGeneralSettingsRequest(
      { [name]: urls },
      values,
      branchId
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Branch images successfully updated.");
    setInitialGeneralSettingsValue(
      new BranchGeneralSettingsFormModel(
        response.branch.generateBranchGeneralSettingsForm()
      )
    );
  };

  const handleBranchActivation = async (name, value) => {
    const response = await branchActivationRequest({ [name]: value }, branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Branch activation successfully updated.");
    setFieldValue(name, value);
  };

  return (
    <GeneralSettingsFormStyles>
      <form onSubmit={handleSubmit}>
        <div className="inputsFlexContainer isActive">
          <div className="inputContainer">
            <Label>Show This Business In The Application?</Label>
            <div className="checkboxesContainer">
              <CheckboxButtons
                name="isActive"
                value={values.isActive}
                yesText="Yes"
                noText="No"
                handleChange={handleBranchActivation}
              />
            </div>
          </div>
        </div>
        <div className="inputsFlexContainer inputSeparator">
          <div className="inputContainer">
            <Label>Supported Trucks</Label>
            <Button
              onClick={() => setModalType(modalTypes.SUPPORTED_TRUCKS)}
              className="button"
            >
              View Supported Trucks
            </Button>
          </div>
          <div className="inputContainer">
            <Label>Supported Trailers</Label>
            <Button
              onClick={() => setModalType(modalTypes.SUPPORTED_TRAILERS)}
              className="button"
            >
              View Supported Trailers
            </Button>
          </div>
        </div>
        <div className="inputsFlexContainer">
          <div className="inputContainer">
            <Label>Supported Trailer Type</Label>
            <Button
              onClick={() => setModalType(modalTypes.SUPPORTED_TRAILER_TYPES)}
              className="button"
            >
              View Supported Trailer Types
            </Button>
          </div>
          <div className="inputContainer">
            <Label>Payment Options</Label>
            <Button
              onClick={() => setModalType(modalTypes.SUPPORTED_PAYMENT_TYPES)}
              className="button"
            >
              View Payment Options
            </Button>
          </div>
        </div>
        <div className="inputsFlexContainer">
          <div className="inputContainer">
            <Label>Branch Price Range</Label>
            <div className="priceRangeContainer">
              <span className="priceSpan">
                <input
                  className="priceInput"
                  type="number"
                  value={values.hourlyPriceFrom}
                  onBlur={handleBlur}
                  name="hourlyPriceFrom"
                  onChange={handleChange}
                  placeholder="0"
                />
              </span>
              <span className="separator"> - </span>
              <span className="priceSpan">
                <input
                  className="priceInput"
                  type="number"
                  value={values.hourlyPriceTo}
                  onBlur={handleBlur}
                  name="hourlyPriceTo"
                  onChange={handleChange}
                  placeholder="0"
                />
              </span>
            </div>
          </div>
          <div className="inputContainer">
            <Label>Has Parking?</Label>
            <div className="checkboxesContainer">
              <CheckboxButtons
                name="hasParking"
                value={values.hasParking}
                yesText="Yes"
                noText="No"
                handleChange={hasParkingChanage}
              />
            </div>
          </div>
        </div>
        <div className="inputsFlexContainer"></div>
        <div>
          <Label>Branch Description</Label>
          <Textarea
            className={"branchDescription"}
            value={values.description}
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Describe this branch"
          />
        </div>
        <div>
          <Label>Branch Speciality</Label>
          <Textarea
            className={"branchSpeciality"}
            value={values.speciality}
            name="speciality"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="What are you specially good at"
          />
        </div>
        <div>
          <Label>Add Branch Pictures</Label>
          <Paragraph className="dragAndDropText">
            Please browse for images or drag & drop them. Images must be minimum
            1080 pixels wide. Max 3MB in size. Must be type: jpg, jpeg, png or
            gif.
          </Paragraph>
          <DragAndDropUpload
            images={values.images}
            name="images"
            handleChange={handleImagesChange}
          />
        </div>
        <div className="submitContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
        {modalType && (
          <CentralModal
            className="modalCard"
            closeModal={() => setModalType(undefined)}
          >
            <Card className="card">
              <GeneralSettingsListModal
                truckOptions={systemComponents.truckManufacturers}
                trailerOptions={systemComponents.trailerManufacturers}
                trailerTypeOptions={systemComponents.trailerTypes}
                paymentOptions={systemComponents.paymentTypes}
                trucks={values.truckManufacturers}
                trailers={values.trailerManufacturers}
                trailerTypes={values.trailerTypes}
                paymentTypes={values.paymentTypes}
                modalType={modalType}
                saveModalChanges={saveModalChanges}
                closeModal={() => setModalType(undefined)}
              />
            </Card>
          </CentralModal>
        )}
      </form>
    </GeneralSettingsFormStyles>
  );
};

export default GeneralSettingsForm;
