import { apiService } from "../api.service";

// Requests
import { UpdateBussinessProfileRequest } from "../../models/api/requests";

// Responses
import { BussinessResponse } from "../../models/api/responses";

/**
 * Fetch business
 * @return - BussinessResponse || ErrorResponse
 */
export const fetchBusinessRequest = async () => {
  const response = await apiService.get("/businesses?with_subscription=true");
  if (response.hasError) {
    return response;
  }
  return new BussinessResponse(response);
};

/**
 * Update business profile
 * @return - BussinessResponse || ErrorResponse
 */
export const updateBusinessProfileRequest = async (
  businessProfileFormValues,
  businessProfileInitialValues
) => {
  const payload = new UpdateBussinessProfileRequest(businessProfileFormValues);
  payload.removeSameValues(businessProfileInitialValues);
  const response = await apiService.patch("/businesses", payload);
  if (response.hasError) {
    return response;
  }
  return new BussinessResponse(response);
};
