import { apiService } from "../api.service";

// Responses
import { PermissionsResponse } from "../../models/api/responses";

/**
 * Fetch Permissions Request
 * @return - PermissionsResponse || ErrorResponse
 */
export const fetchPermissionsRequest = async queryParams => {
  let query = "";
  queryParams.forEach(
    (param, i) => (query += `${i === 0 ? "?" : "&"}assignable_to=${param}`)
  );
  const response = await apiService.get(`/permissions${query}`);
  if (response.hasError) {
    return response;
  }
  return new PermissionsResponse(response);
};
