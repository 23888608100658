export const readUrlFromFile = (file, elementId) => {
  if (file) {
    const reader = new FileReader();

    reader.onload = function t(e) {
      const imageEl = document.getElementById(elementId);
      if (imageEl) {
        imageEl.src = e.target.result;
      }
    };

    reader.readAsDataURL(file);
  }
};
