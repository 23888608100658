export const specialDayValidation = ({ name, date }) => {
  const errors = {};

  if (!name) {
    errors.name = "* Required";
  }

  if (!date) {
    errors.date = "* Required";
  }

  return errors;
};
