// styles
import { MessageStyles } from "./message.styles";

const Message = ({ isMyMessage, text, time }) => {
  return (
    <MessageStyles isMyMessage={isMyMessage}>
      <div>
        <div className="messageContainer">
          <p className="time">{time}</p>
          <p className="message">{text}</p>
        </div>
      </div>
    </MessageStyles>
  );
};

export default Message;
