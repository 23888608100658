// components
import { Button, ExpandingDescription } from "../../UI";
// builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";
// icons
import { Trash } from "../../../SVGS";
// styles
import styled from "styled-components";
import { Paragraph } from "../../typography";

const RoleItemStyles = styled.div`
  border-top: 1px solid #f1f1f5;
  padding: 12px 24px 0 24px;

  &:first-child {
    border-top: none;
  }
  .topContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .roleName {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      .buttons {
        height: 41px;
      }

      .buttons:hover path {
        fill: #ffffff;
      }
    }
  }

  .permissionsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin-bottom: 14px;

    .permission {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #74bde9;
      margin: 0 24px 12px 0;
    }
  }
`;

const RoleItem = ({
  name,
  permissions,
  description,
  deleteAction,
  editAction
}) => {
  return (
    <RoleItemStyles>
      <div className="topContent">
        <h3 className="roleName">{name}</h3>
        <div className="buttonsContainer">
          <Button onClick={editAction} className="buttons">
            Edit Role
          </Button>
          <Button
            onClick={deleteAction}
            buttonDesignType={buttonDesignType.DANGER}
            className="buttons"
            style={{ marginLeft: 8 }}
          >
            <Trash />
          </Button>
        </div>
      </div>
      <div className="permissionsContainer">
        {permissions.map((permission, i) => (
          <Paragraph key={i} className="permission">
            {permission.name}
          </Paragraph>
        ))}
      </div>
      <ExpandingDescription title="Description" text={description} />
    </RoleItemStyles>
  );
};

export default RoleItem;
