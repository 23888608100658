import io from "socket.io-client";

const NOTIFICATION_SOCKET_URL = "https://api.notifications.truckfix.net";

export function initializeSocket(token) {
  if (!token) {
    console.error("Token is missing. Cannot initialize socket.");
    return null;
  }

  return io(NOTIFICATION_SOCKET_URL, {
    extraHeaders: {
      Authorization: `Bearer ${token}`
    },
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000
  });
}
