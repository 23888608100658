// styles
import { NotificationsCounterStyles } from "./notificationCounter.styles";

const NotificationsCounter = ({ className, counter = 0 }) => {
  return (
    <NotificationsCounterStyles className={className}>
      {counter >= 100 ? "99+" : counter}
    </NotificationsCounterStyles>
  );
};

export default NotificationsCounter;
