// Domain Models
import { Price, SubscriptionPlanFeature } from ".";

export class SubscriptionPlan {
  constructor(subscriptionPlan = {}) {
    this.id = subscriptionPlan.id;
    this.name = subscriptionPlan.name;
    this.description = subscriptionPlan.description;
    this.recommended = subscriptionPlan.recommended;
    this.active = subscriptionPlan.active;
    this.allowedNumberOfBranches = subscriptionPlan.allowed_number_of_branches;
    this.accessToAnalytics = subscriptionPlan.access_to_analytics;
    this.price = new Price(subscriptionPlan.price);
    this.subscriptionPlanFeatures =
      subscriptionPlan.subscription_plan_features.map(
        feature => new SubscriptionPlanFeature(feature)
      );
  }
}
