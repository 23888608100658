import { apiService } from "../api.service";

// Responses
import {
  ServicesResponse,
  ServiceResponse,
  MessageResponse,
  WorkersResponse,
  WorkersScheduleResponse
} from "../../models/api/responses";

// Requests
import { ServiceRequest } from "../../models/api/requests";

// Constants
import * as filtersTypes from "../../constants/services.constants";

// Utils
import { isEmpty } from "../../utils";

/**
 * Fetch services Request
 * @return - ServicesResponse || ErrorResponse
 */
export const fetchServicesRequest = async (
  dateQuery,
  groupQuery,
  idQuery,
  limit,
  lastService,
  isSupportRole
) => {
  let query = `?pagination[per_page]=${limit}&group=${groupQuery}`;
  if (idQuery) {
    query = query + `&id=${idQuery}`;
  }

  if (isEmpty(idQuery) && dateQuery) {
    query = query + `&date=${dateQuery}`;
  }

  if (!isEmpty(lastService)) {
    query = query + `&pagination[from]=${lastService}`;
  }

  const url = isSupportRole
    ? `/services/support/all${query}`
    : `/services${query}`;

  const response = await apiService.get(url);
  if (response.hasError) {
    return response;
  }
  return new ServicesResponse(response);
};

/**
 * Fetch service  Request
 * @return - ServiceResponse || ErrorResponse
 */
export const fetchServiceDetailsRequest = async (serviceId, branchId) => {
  const response = await apiService.get(`/services/${serviceId}`, branchId);
  if (response.hasError) {
    return response;
  }
  return new ServiceResponse(response);
};

/**
 * Cancel service  Request
 * @return - MessageResponse || ErrorResponse
 */
export const cancelServiceRequest = async serviceId => {
  const response = await apiService.post(`/services/${serviceId}/cancel`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Start service  Request
 * @return - MessageResponse || ErrorResponse
 */
export const startServiceRequest = async serviceId => {
  const response = await apiService.post(`/services/${serviceId}/start`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Pause service  Request
 * @return - MessageResponse || ErrorResponse
 */
export const pauseServiceRequest = async serviceId => {
  const response = await apiService.post(`/services/${serviceId}/pause`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Resume service  Request
 * @return - MessageResponse || ErrorResponse
 */
export const resumeServiceRequest = async serviceId => {
  const response = await apiService.post(`/services/${serviceId}/resume`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Finish service  Request
 * @return - MessageResponse || ErrorResponse
 */
export const finishServiceRequest = async serviceId => {
  const response = await apiService.post(`/services/${serviceId}/finish`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Update service Request
 * @return - WorkersResponse || ErrorResponse
 */
export const updateServiceRequest = async (formValues, serviceId) => {
  const payload = new ServiceRequest(formValues);
  payload.removeUnusedValues();
  const response = await apiService.patch(`/services/${serviceId}`, payload);
  if (response.hasError) {
    return response;
  }
  return new ServiceResponse(response);
};

// SCHEDULE requests
/**
 * Fetch workers Request
 * @return - WorkersResponse || ErrorResponse
 */
export const fetchWorkersRequest = async queryParams => {
  let query = "";
  if (queryParams) {
    query = `?query=${queryParams}`;
  }
  const response = await apiService.get(`/branches/staff/workers${query}`);
  if (response.hasError) {
    return response;
  }
  return new WorkersResponse(response);
};

/**
 * Fetch workers schedule Request
 * @return - WorkersResponse || ErrorResponse
 */
export const fetchWorkersScheduleRequest = async (
  date,
  status,
  chosenEmployees
) => {
  let query = `?with_working_hours=true&date=${date}`;
  if (status === filtersTypes.FILTERS_ALL) {
    query =
      query +
      `&statuses[0]=${filtersTypes.FILTERS_ACCEPTED}&statuses[1]=${filtersTypes.FILTERS_IN_PROGRESS}&statuses[2]=${filtersTypes.FILTERS_PAUSED}`;
  } else {
    query = query + `&statuses=${status}`;
  }
  chosenEmployees.map(
    (employee, i) => (query = query + `&workers[${i}]=${employee.id}`)
  );

  const response = await apiService.get(`/services/schedule${query}`);
  if (response.hasError) {
    return response;
  }
  return new WorkersScheduleResponse(response);
};
