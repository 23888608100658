// Request Models
import {
  LocationRequest,
  BranchServiceTypeRequest,
  WorkDayRequest,
  SpecialDayRequest
} from ".";

// Utils
import { isEmpty } from "../../../utils";

export class BranchRequest {
  constructor(branchFormValues = {}) {
    this.name = branchFormValues.name;
    this.images = branchFormValues.images;
    this.contact_number = branchFormValues.contactNumber;
    this.managers = branchFormValues.managers;
    this.location = new LocationRequest({
      state: branchFormValues?.state,
      city: branchFormValues?.city,
      postalCode: branchFormValues?.postalCode,
      address: branchFormValues?.address,
      longitude: branchFormValues?.longitude,
      latitude: branchFormValues?.latitude
    });
    this.has_parking = branchFormValues?.hasParking;
    this.branch_service_types = branchFormValues?.branchServiceTypes?.map(
      branchServiceType => new BranchServiceTypeRequest(branchServiceType)
    );
    this.truck_manufacturers = branchFormValues.supportedTrucks;
    this.trailer_manufacturers = branchFormValues.supportedTrailers;
    this.trailer_types = branchFormValues.supportedTrailerTypes;
    this.work_days = branchFormValues?.workDays?.map(
      workDay => new WorkDayRequest(workDay)
    );
    this.special_days = branchFormValues?.specialDays?.map(
      specialDay => new SpecialDayRequest(specialDay)
    );
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (
        (key !== "location" &&
          key !== "managers" &&
          key !== "images" &&
          key !== "branch_service_types" &&
          key !== "work_days" &&
          key !== "special_days" &&
          initValuesArr.includes(value)) ||
        isEmpty(value)
      ) {
        delete this[key];
      }
    });

    this.location.removeSameValues(initValues);
    if (isEmpty(Object.keys(this.location))) delete this.location;
  }
}
