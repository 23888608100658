// Domain Models
import { Subscription } from "../../app/domain";

export class ActiveSubscriptionResponse {
  constructor(response = {}) {
    this.activeSubscription = response?.data?.subscription
      ? new Subscription(response?.data?.subscription)
      : undefined;
  }
}
