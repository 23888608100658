import styled from "styled-components";

export const ServiceFormStyles = styled.div`
  .inputsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputContainer {
      width: 48%;
    }

    .checkboxesContainer {
      height: 66px;
      margin-top: 2px;
      padding-top: 10px;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
