import styled from "styled-components";

export const ChoosePlanStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  cursor: pointer;

  .container {
    display: flex;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #172b4d;
      margin-top: 8px;
      width: 200px;
    }
  }

  .arrowContainer {
    transform: rotate(-270deg);
  }
`;
