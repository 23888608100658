// constants
import * as statusTypes from "../../../constants/status.constants";
// styles
import styled from "styled-components";

export const StatusPillStyles = styled.div`
  padding: 2px 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  ${({ status }) => {
    switch (status) {
      case statusTypes.IN_PROGRESS:
        return `
        min-width: 80px;
        background: #E3FCEF;
        color: #006644;
        border: 1px solid #ABF5D1;
      `;

      case statusTypes.PENDING:
        return `
        min-width: 62px;
        background: #E3E3E3;
        color: #535353;
        border: 1px solid #9E9E9E;
      `;

      case statusTypes.PAUSED:
        return `
        min-width: 58px;
        background: #FFF0B3;
        color: #494D17;
        border: 1px solid #FFE380;
      `;

      case statusTypes.CANCELED:
        return `
        min-width: 69px;
        background: #FFEBE6;
        color: #B92500;
        border: 1px solid #FFBDAD;
      `;

      case statusTypes.FINISHED:
        return `
        min-width: 66px;
        background: #E3F3FC;
        color: #152F63;
        border: 1px solid #ABD6F5;
      `;

      default:
        return `
        min-width: 80px;
        background: #E3FCEF;
        color: #006644;
        border: 1px solid #ABF5D1;
      `;
    }
  }};
`;
