// Components
import { Header, Body } from "../../../../UI";
import { Heading2 } from "../../../../typography";

// Containers
import GeneralSettingsForm from "../../../../../containers/forms/generalBranchSettingsForms/GeneralSettings/GeneralSettings.form";

// Styles
import styled from "styled-components";

const GeneralSettingsStyles = styled.div``;

const GeneralSettings = ({ branchId }) => {
  return (
    <GeneralSettingsStyles>
      <Header>
        <Heading2>GENERAL SETTINGS</Heading2>
      </Header>
      <Body>
        <GeneralSettingsForm branchId={branchId} />
      </Body>
    </GeneralSettingsStyles>
  );
};

export default GeneralSettings;
