import { apiService } from "../api.service";

// Responses
import { ChatMessagesResponse } from "../../models/api/responses";

/**
 * Fetch chat messages Request
 * @return - ChatMessagesResponse || ErrorResponse
 */
export const fetchChatMessagesRequest = async (serviceId, from) => {
  let fromQuery = "";
  if (from) {
    fromQuery = `&from=${from}`;
  }
  const response = await apiService.get(
    `/chat/${serviceId}?limit=20${fromQuery}`
  );
  if (response.hasError) {
    return response;
  }
  return new ChatMessagesResponse(response);
};
