import styled from "styled-components";

export const ManagerItemStyles = styled.div`
  display: flex;
  align-items: center;

  .fn {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .ln {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .perm {
    display: flex;
    align-items: center;
  }

  .br {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
    text-decoration: underline;
    cursor: pointer;
  }

  .ph {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #44444f;
  }

  .em {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #44444f;
  }

  .deleteButton {
    margin-left: 8px;
    padding: 8px 9px;
  }

  .deleteButton:hover path {
    fill: #ffffff;
  }
`;
