import styled from "styled-components";

export const ServicesStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;

    .filters {
      display: flex;
      align-items: center;

      .searchContainer {
        position: relative;
        display: flex;
        align-items: center;
        width: 250px;
        height: 36px;
        margin-top: 2px;

        .icon {
          position: absolute;
          top: 10px;
          left: 14px;
          z-index: 1;
        }

        .para {
          position: absolute;
          top: 10px;
          left: 44px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.1px;
          color: #202650;
          z-index: 1;
        }

        .searchInput {
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid #f2f6f9;
          border-radius: 6px;
          padding: 14px 10px 14px 65px;
          height: 100%;
          width: 100%;
          outline: none;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.1px;
          color: #202650;
        }
      }
    }
  }

  .statusFilters {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .statusFiltersContainer {
      display: flex;
      align-items: center;
      .tab {
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9fadc3;
        outline: none;
        border: none;
        background: transparent;
        margin-right: 44px;
        cursor: pointer;
      }

      .activeTab {
        color: #171725;
      }
    }
  }

  .tableHead {
    padding: 8px 24px;
    display: flex;
    align-items: center;

    .headTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #9fadc3;
    }
  }

  .start {
    /* width: 13.5%; */
    width: 11.2%;
  }

  .finish {
    width: 11.2%;
    /* width: 13.5%; */
    /* text-align: center; */
  }

  .ID {
    width: 8.2%;
    /* width: 7.5%; */
    text-align: center;
  }

  .user {
    /* width: 14.5%; */
    width: 18.2%;
    text-align: center;
  }

  .serviceType {
    width: 20.2%;
    /* width: 17.5%; */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .status {
    width: 10.2%;
    /* width: 10.5%; */
    text-align: center;
  }

  .messages {
    width: 20.2%;
    /* width: 12.5%; */
    text-align: center;
  }

  .actions {
    width: 12.2%;
    /* width: 10.5%; */
    text-align: right;
  }

  .list {
    margin-top: 24px;
  }

  .listRow {
    cursor: pointer;
    position: relative;
  }

  .listRow:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12),
      2px 0px 4px 1px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .modalCard {
    width: 440px !important;
  }

  .estimationCard {
    height: unset;
  }
`;
