import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Heading2 } from "../../../../components/typography";
import { Body, Header } from "../../../../components/UI";
import ManagerForm from "../ManagerForm/Manager.form";

// Icons
import { Close } from "../../../../SVGS";

// Form Models
import { ManagerFormModel } from "../../../../models/app/forms";

// Actions
import { updateManager } from "../../../../redux/actions/managers.actions";

// Services
import { managerValidation } from "../../../../services/validations/manager.validation";

// Styles
import { EditManagerStyles } from "./editManager.styles";

const EditManager = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { chosenManager } = useSelector(state => state.managersPage);
  const initialValues = new ManagerFormModel(
    chosenManager.generateManagerForm()
  );

  const updateManagerHandler = values => {
    dispatch(updateManager(values, initialValues, chosenManager.id));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: updateManagerHandler,
    validate: managerValidation
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditManagerStyles>
      <Header className="modalHeader">
        <Heading2>EDIT MANAGER</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <ManagerForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            buttonText="Save Changes"
          />
        </form>
      </Body>
    </EditManagerStyles>
  );
};

export default EditManager;
