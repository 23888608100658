import * as actionTypes from "../actionTypes/users.types";
import { updateObject } from "../../utils/global.utils";

const initialState = {
  users: []
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_SUCCESS:
      return setUsers(state, action);
    default:
      return state;
  }
};

const setUsers = (state, action) => {
  return updateObject(state, {
    users: action.users
  });
};
