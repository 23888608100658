import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRouter from "./Router";

import "./index.css";

// let socket = io.connect("https://dev.socket.truckfix-chat.bstorm.app");

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppRouter />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
