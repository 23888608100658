import styled from "styled-components";

export const BranchCardStyles = styled.div`
  width: 48.3%;

  background: ${({ isActive }) => (isActive ? "#F2F9FD" : "#ffffff")};
  border: 1px solid ${({ isActive }) => (isActive ? "#74BDE9" : "#e2e2ea")};
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 24px;

  .topContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    .branchName {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    .deleteButton:hover path {
      fill: #ffffff;
    }
  }

  .branchDetails {
    margin-bottom: 24px;
    display: flex;

    .branchLeftInfo {
      margin-right: 40px;

      .iconAndText {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .text {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.1px;
          color: #44444f;
          margin-left: 8px;
        }
      }

      .managersLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #9fadc3;
        margin-bottom: 10px;
      }

      .managersPlaceholder {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #9fadc3;
      }

      .managersList {
        display: flex;
        align-items: center;

        .managersIcon {
          margin-right: 8px;
        }
      }
    }

    .branchRightInfo {
      .managersLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #9fadc3;
        margin-bottom: 10px;
      }

      .managersList {
        display: flex;
        align-items: center;

        .managersIcon {
          margin-right: 8px;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;

    .bottomButtons {
      padding: 7px 12px;
      border-radius: 6px;
    }
  }
`;
