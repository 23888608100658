import { useCallback, useState } from "react";

// Components
import { Slider } from "../components/UI";

// Containers
import RegisterStepOneForm from "../containers/forms/register/RegisterStepOne.form";
import RegisterStepTwoForm from "../containers/forms/register/RegisterStepTwo.form";

// Forms Models
import { RegisterAccountForm, BussinesForm } from "../models/app/forms";

// icons
import { TruckFixLogo } from "../SVGS";

// styles
import { RegisterStyles } from "../pagesStyles/register.styles";

const images = [
  "https://erbgroup.com/wp-content/uploads/2018/06/Blog-photo-baden-shop.jpg",
  "https://cdn.dealerspike.com/imglib/v1/800x600/imglib/Assets/Blog/a1/23/a123fdbc-3a93-4a86-bc8e-fed83c7c3a58.jpg",
  "https://www.tindolford.com/static/dealer-14653/903629.jpg"
];

const Register = () => {
  const [registerPageState, setRegisterPageState] = useState({
    accountForm: new RegisterAccountForm(),
    bussinessForm: new BussinesForm(),
    isStepOne: true
  });

  const handleBack = useCallback(step2FormData => {
    setRegisterPageState(prevState => {
      return {
        ...prevState,
        bussinessForm: { ...prevState.bussinessForm, ...step2FormData },
        isStepOne: true
      };
    });
  }, []);

  const handleNext = useCallback(step1FormData => {
    setRegisterPageState(prevState => {
      return {
        ...prevState,
        accountForm: { ...prevState.accountForm, ...step1FormData },
        isStepOne: false
      };
    });
  }, []);

  return (
    <RegisterStyles>
      <div className="formContainer">
        <TruckFixLogo />
        <div className="container">
          {registerPageState.isStepOne ? (
            <RegisterStepOneForm
              handleNext={handleNext}
              accountForm={registerPageState.accountForm}
            />
          ) : (
            <RegisterStepTwoForm
              handleBack={handleBack}
              bussinessForm={registerPageState.bussinessForm}
              accountForm={registerPageState.accountForm}
            />
          )}
        </div>
      </div>
      <div className="sliderContainer">
        <Slider images={images} />
      </div>
    </RegisterStyles>
  );
};

export default Register;
