import GoogleMapReact from "google-map-react";

// Config
import { mapStyling } from "./map.styling";

// SVGS
import { MapMarker } from "../../../SVGS";

// Styles
import styled from "styled-components";

const MapStyles = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;

  .gm-fullscreen-control {
    display: none;
  }

  .gm-style-cc {
    display: none !important;
  }
`;
const AnyReactComponent = () => <MapMarker />;

const Map = ({ center }) => {
  return (
    <MapStyles style={{ height: "100%", width: "100%", pointerEvents: "none" }}>
      <GoogleMapReact
        options={{
          styles: mapStyling
        }}
        center={center}
        zoom={10}
        draggable={false}
        scrollwheel={false}
        navigationControl={false}
        mapTypeControl={false}
        scaleControl={false}
      >
        <AnyReactComponent lat={center.lat} lng={center.lng} />
      </GoogleMapReact>
    </MapStyles>
  );
};

export default Map;
