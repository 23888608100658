import { toast } from "react-toastify";

// Action types
import * as actionTypes from "../actionTypes/bussiness.types";

export const fetchBusinessData = () => {
  return { type: actionTypes.FETCH_BUSINESS_REQUEST };
};

export const fetchBusinessDataSuccess = businessResponseData => {
  return {
    type: actionTypes.FETCH_BUSINESS_SUCCESS,
    businessResponseData
  };
};

export const updateBusinessProfile = (
  businessProfileFormValues,
  businessProfileInitialValues
) => {
  return {
    type: actionTypes.UPDATE_BUSINESS_PROFILE_REQUEST,
    businessProfileFormValues,
    businessProfileInitialValues
  };
};

export const updateBusinessProfileSuccess = businessResponseData => {
  toast.success("Business profile updated successfully!");
  return {
    type: actionTypes.UPDATE_BUSINESS_PROFILE_SUCCESS,
    businessResponseData
  };
};

export const updateBusinessProfileFailed = errorResponse => {
  return {
    type: actionTypes.UPDATE_BUSINESS_PROFILE_FAILED,
    errorResponse
  };
};

export const openModal = modalType => {
  return {
    type: actionTypes.OPEN_MODAL,
    modalType
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL
  };
};

export const fetchActiveSubscription = () => {
  return {
    type: actionTypes.FETCH_ACTIVE_SUBSCRIPTION_REQUEST
  };
};

export const fetchActiveSubscriptionSuccess = activeSubscription => {
  return {
    type: actionTypes.FETCH_ACTIVE_SUBSCRIPTION_SUCCESS,
    activeSubscription
  };
};
