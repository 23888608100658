import styled from "styled-components";

export const ManagerFormStyles = styled.div`
  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputContainer {
      width: 48%;
    }
  }

  .input {
    margin-top: 2px;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .selectedBranches {
    margin-bottom: 24px;
  }
  .branchesList {
    margin-top: 5px;
    max-height: 300px;
    padding-top: 2px;
    overflow: auto;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
