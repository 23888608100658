import { useState } from "react";
import { toast } from "react-toastify";
import MoonLoader from "react-spinners/MoonLoader";

// Components
import Button from "../Button/Button";

// Icons
import { FileIcon, Trash } from "../../../SVGS";

// Builders
import * as buttonTypes from "../../../builders/buttonDesign.types";

// Calls
import { fileUpload } from "../../../api/calls/file-upload.call";

// Styles
import { FileUplaodStyles } from "./fileUpload.styles";

const FileUpload = ({
  placeholder = "Upload file",
  name,
  id,
  value,
  touched,
  error,
  setFieldValue
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const openFileUpload = () => {
    const input = document.getElementById(id);
    if (input) input.click();
  };

  const handleChange = async e => {
    setIsLoading(true);
    if (e.target.files.length > 0) {
      const fileUploadResponse = await fileUpload(e.target.files[0]);
      if (fileUploadResponse.hasError) {
        toast.error(fileUploadResponse.error.message);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setFieldValue(name, fileUploadResponse.url);
    }
  };

  const removeFile = () => {
    const input = document.getElementById(id);
    if (input) {
      input.value = "";
    }
    setFieldValue(name, null);
  };

  return (
    <FileUplaodStyles value={value}>
      <div className={`inputContainer ${touched && error && "error"}`}>
        <div className="valueContainer">
          {isLoading ? (
            <MoonLoader color={"#202650"} loading={isLoading} size={15} />
          ) : (
            <>
              {value && <FileIcon style={{ marginRight: 8 }} />}
              <p className={`value ${value && "valueTextColor"}`}>
                {value ? value : placeholder}
              </p>
            </>
          )}
        </div>
        {value ? (
          <Button
            className="delete"
            buttonDesignType={buttonTypes.DANGER}
            onClick={removeFile}
          >
            <Trash />
          </Button>
        ) : (
          <Button className="addButton" onClick={openFileUpload}>
            + Add File
          </Button>
        )}
      </div>
      <input
        type="file"
        name={name}
        id={id}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <p className="errorMessage">{touched && error && error}</p>
    </FileUplaodStyles>
  );
};

export default FileUpload;
