// icons
import { TruckFixLogo } from "../SVGS";
// styles
import { PrivacyPolicyStyles } from "../pagesStyles/privacyPolicy.styles";

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyStyles>
      <div className="container">
        <TruckFixLogo />
        <h1 className="mainTitle">Privacy Policy</h1>
        <p className="text">
          This Privacy Policy describes how TruckFix App, Inc. and its
          subsidiaries (collectively, <b>“TruckFix,”</b> <b>“we,”</b>{" "}
          <b>“our”</b> or <b>“us”</b>) collect, use, process and share personal
          information. This Policy applies to visitors and users (individually,{" "}
          <b>“you”</b>) of TruckFix’s websites, applications, social media
          accounts, and other services (collectively, our <b>“Services”</b>).
          You can identify the TruckFix company that hosts a particular Service
          and is responsible for your information under this Policy here. Please
          note that your use of our Services is additionally subject to the
          TruckFix Terms of Use.
        </p>
        <p className="text">
          As the world’s leading provider of online restaurant reservations,
          TruckFix helps connect drivers and truck service providers. This
          involves sharing personal information with third parties, including
          truck shops, towing companies, and tire shops, amongst others. In some
          cases, these third parties may collect personal information directly
          from you and share it with us.
        </p>
        <p className="text">
          For more information about the information we share with third parties
          and the recipients of such information, please refer to the How We
          Share Your Information section of this Policy. Please note that this
          Policy does not otherwise cover the use of personal information by
          third parties or their services, and we do not take responsibility for
          their privacy practices.
        </p>
        <p className="text">
          You may have certain rights or choices that relate to your personal
          information, including choices about how we share your personal
          information with others. For more information, please refer to the
          Your Choices and Rights section of this Policy.
        </p>
        <h2 className="title">1. INFORMATION WE COLLECT AND USE</h2>
        <p className="text">
          “Personal information” is data that identifies, relates to, describes,
          can be used to contact, or could reasonably be linked directly or
          indirectly to you. For purposes of this Policy, there is no meaningful
          distinction between the terms “personal information” and “personal
          data.”
        </p>
        <p className="text">
          <b>Personal Information We Collect Directly From You.</b> As you visit
          or use our Services, we collect the following categories of personal
          information directly from you:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Personal details</b> (such as your name and birthday)
          </li>
          <li className="listText">
            <b>Contact information</b> (such as email address, postal address,
            phone number)
          </li>
          <li className="listText">
            <b>Service information</b> (such as primary servicing city; current
            and past service reservation details; service preferences; special
            service requests; service activity, including frequency, service
            type, and cancellations)
          </li>
          <li className="listText">
            <b>Account information</b> (such as account settings and passwords)
          </li>
          <li className="listText">
            <b>Social media data</b> (if you choose to link your TruckFix
            account with a social media account, we may collect personal
            information such as name, age, gender, photograph, and other
            personal information relating to your social media account
          </li>
          <li className="listText">
            <b>Billing information</b> (such as credit, debit, or other payment
            card information, as may be required to use certain Services)
          </li>
          <li className="listText">
            <b>Your contacts</b> (such as contact information of people you add
            to, or notify of, your restaurant reservations through our Services)
          </li>
          <li className="listText">
            <b>Your preferences</b> (including survey and marketing responses)
          </li>
          <li className="listText">
            <b>Photos of you</b> (such as if you add a photo of yourself to your
            profile, upload photos to a review or restaurant’s profile, or link
            your social media account to your TruckFix account)
          </li>
          <li className="listText">
            <b>Communications you send us</b> (such as customer support and
            other requests you make through our Services and through service
            providers)
          </li>
          <li className="listText">
            <b>Reviews and content</b> (including reviews, photos, an alias of
            your choosing that you can use when submitting reviews, and other
            information you provide through our Services)
          </li>
          <li className="listText">
            <b>Promotion information</b> (if you choose to participate in a
            contest, sweepstakes, or similar campaign, we will collect any
            information you provide in relation to such activity, such as
            photos, images, captions, or other content, in accordance with the
            terms provided at that time)
          </li>
          <li className="listText">
            <b>Other information you may provide</b> (including other
            information you provide about yourself or others through our
            Services or to which you provide us with access via third-party
            platforms)
          </li>
        </div>
        <p className="text">
          You may choose not to provide some of the personal information
          described above. Please note, however, that many of our Services
          require some personal information to operate, so if you choose not to
          provide the personal information necessary to operate and provide you
          with a particular Service or feature of that Service, you may not be
          able to use that Service or feature.
        </p>
        <p className="text">
          <b>Personal Information Generated By Us.</b> As you use our Services,
          we generate certain personal information about you, including through
          automatic data collection and by inferences based on the information
          we collect about you and your activity. We may automatically collect
          information about your interactions with the Services or
          communications you receive (such as email) using certain technologies,
          such as cookies, web beacons and other technologies (see our Cookies
          and Interest-Based Advertising Policy for more details). We generate
          the following categories of personal information about you:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Device information.</b> When you visit or use our Services, we
            automatically collect certain information about your device (e.g.,
            your mobile device, computer, or tablet), including information
            about your hardware and software, device configuration, and nearby
            networks. Such data may include data about your device operating
            systems, browsers, and other software installed on your device;
            device identifiers, such as IP address, IMEI number, MAC address, or
            other device identifier; country of origin, region and language
            settings; and information about domain servers and wireless or
            network access points near your device.
          </li>
          <li className="listText">
            <b>Usage and performance information.</b> We also automatically
            collect personal information about your usage of the Services,
            including information about your searches or interactions with
            features of our Services; sites or restaurant pages visited; booking
            path; access times; and performance of our Services.
          </li>
          <li className="listText">
            <b>Location information.</b> If you use our Services, we
            automatically collect generic location information about you (such
            as city or neighborhood) or, with your consent, precise geographic
            location data from your mobile device when the app is running and
            when it is not running, depending on the choices you make when you
            are asked to consent to our collection of location information. For
            example, we may receive this information when you select truck shop
            search locations, enter your local city in your account profile,
            when you are in proximity to certain beacons, choose to publish your
            location in reviews you leave for restaurants on the Services, or in
            your comments or other communications with us. We may use and store
            this information to provide and improve features of our Services,
            for example, to tailor our Services on a needs-based manner to
            better facilitate your requests and to provide you with more
            relevant content about service providers in the cities you visit.
            Please see the Your Rights and Choices section below for more
            information about how to adjust your preferences, including those
            related to location information.
          </li>
          <li className="listText">
            <b>Inferences about you.</b> We combine the information we collect,
            generate, or otherwise obtain to draw inferences about your
            preferences and interests in order to provide and personalize our
            Services and tailor the offers we and our partners provide to you.
          </li>
        </div>
        <p className="text">
          <b>Personal Information We Obtain from Third Parties.</b> We may also
          receive certain categories of personal information from third parties,
          such as third-party websites, applications, social media networks, and
          services (which may include publicly available sources; each of these
          is a “third-party platform”), and other third parties. If you are an
          existing TruckFix customer, we will combine this information with
          information we collect through our Services and use and share it for
          the purposes described below. The categories of personal information
          we may obtain from third parties include:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Your name</b>
          </li>
          <li className="listText">
            <b>Email address or other contact information</b>
          </li>
          <li className="listText">
            <b>Social media data</b>, if you connect to our Services using a
            social media third-party platform or interact with TruckFix by
            liking or commenting on our social media pages or content, or if any
            of your social media data is available to the public, we may receive
            information from that social media third-party platform or a
            third-party service about those interactions and your profile
          </li>
          <li className="listText">
            <b>Browsing, usage and advertising details</b>, including how you
            use our Services and/or third-party websites, applications, and
            services; and information relating to your interactions with our
            advertising and marketing materials
          </li>
        </div>
        <h2 className="title">2. HOW WE USE YOUR INFORMATION</h2>
        <p className="text">
          We use your personal information for the following purposes (
          <b>“Purposes”</b>), to:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Provide the Services</b>, which includes providing you with the
            services, products, and functionality offered through our Services
            and fulfilling your requests, including but not limited to: making
            reservations, reviewing service providers, making special requests
            through the Services
          </li>
          <li className="listText">
            <b>Authenticate your account credentials and identify you</b>, as
            necessary to log you in to the Services and ensure the security of
            your account
          </li>
          <li className="listText">
            <b>Communicate with you</b> about your account or use of our
            Services, products, and/or functionality; respond to, or follow up
            on, your comments and questions; and otherwise provide customer
            service (see below for more detail on Electronic Communications)s
          </li>
          <li className="listText">
            <b>Send you marketing communications</b>, including communicating
            with you about services or products offered by TruckFix, or our
            business partners and other marketing communications that we believe
            you would be interested in, as permitted by law (see below for more
            detail on Electronic Communications and Your Choices and Rights)
          </li>
          <li className="listText">
            <b>
              Operate and improve our Services and develop new products and
              services
            </b>
            , including using analytics to better understand how you use our
            Services for purposes of product, website, application and service
            development and to enhance the user experience
          </li>
          <li className="listText">
            <b>Show your bookings</b> made through the websites, applications,
            and services of our group companies on your account page
          </li>
          <li className="listText">
            <b>
              Provide services, products, and information to service providers{" "}
            </b>
          </li>
          <li className="listText">
            <b>Tailor your experience with our Services</b>, such as by making
            inferences or combining different pieces of information we have
            collected about you to suggest restaurants that you may be
            interested in or otherwise tailor our Services to you according to
            your preferences or restrictions.
          </li>
          <li className="listText">
            <b>Provide you more relevant advertising on and off our Services</b>
            , including to show you personalized offers on and off our Services.
            For example, if you frequently search for or book services in a
            particular city, we may display advertising for a local service
            providers to you on our Services or work with our business partners
            to display advertisements for service providers to you on other
            websites you visit
          </li>
          <li className="listText">
            <b>
              Protect against, investigate, and deter fraudulent, unauthorized,
              or illegal activity
            </b>
          </li>
          <li className="listText">
            <b>Comply with our policies, procedures and legal obligations</b>,
            including complying with law enforcement or government authority
            requests, addressing litigation-related issues, and exercising
            rights or obligations conferred by law
          </li>
          <li className="listText">
            <b>
              As otherwise consented to by you and as required or permitted by
              applicable law.
            </b>{" "}
            If you give your consent to any further use of personal information,
            you can withdraw that consent at any time by contacting us using the
            details set out below.
          </li>
        </div>
        <p className="text">
          You have choices about your personal information, and in some
          circumstances, you may have the right to opt-out or object to our uses
          of your personal information for these Purposes. For more information,
          or to exercise these or other rights (where available), see the Your
          Choices and Rights section below.
        </p>
        <p className="text">
          <b>Electronic Communications.</b> Consistent with the above Purposes
          and as permitted by applicable law, we may communicate with you via
          electronic messages, including email, text message, or mobile push
          notification to:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Send you information relating to our products and Services.</b>{" "}
            This may include reservation confirmations, reminders and updates,
            receipts, technical notices, updates, security alerts, and support
            and administrative messages.
          </li>
          <li className="listText">
            <b>Send you marketing communications.</b> In other situations,
            subject to the Your Choices and Rights section below and applicable
            law, we may communicate with you about contests, offers, promotions,
            rewards, upcoming events, and other news about products and Services
            offered by TruckFix, service providers, and other business partners.
          </li>
        </div>
        <p className="text">
          With your consent, where required, we may contact you at the mobile
          phone number that you provide to us by way of direct dial calls,
          autodialed and prerecorded message calls, text messages and push
          notifications in connection with the above Purposes.
        </p>
        <p className="text">
          <b>Our Role as Data Controller and Data Processor.</b> For purposes of
          European Union law and similar data protection regimes, we generally
          act as a data controller, meaning we determine the purposes and means
          of processing your personal information through our Services. Under
          certain TruckFix programs, however, service providers may engage us to
          provide them with certain processing services related to information
          owned or controlled by the service provider. We seek assurances from
          service providers that they will process information in accordance
          with applicable laws, but we are not responsible for any service
          provider’s use of information (including information used by its
          affiliates and service providers) for which it is an owner or
          controller. To learn more about how a restaurant may use such
          information, you should review its privacy notice.
        </p>
        <p className="text">
          <b>Processing Bases and Consequences.</b> When we process your
          personal information, we rely on the following legal bases:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Performance of the contract we have with you</b> (such as if you
            use our Services to make a booking, we will use your information to
            carry out our obligation to complete and administer your booking by
            sharing your information with the appropriate service provider)
          </li>
          <li className="listText">
            <b>Compliance with legal obligations to which we are subject</b>{" "}
            (such as tax obligations, and when we are obliged to comply with
            lawful requests from competent authorities such as law enforcement).
          </li>
          <li className="listText">
            <b>To serve our legitimate interests</b> (such as tailoring your
            experience with our Services, carrying out online advertising, and
            for fraud detection), provided that such processing does not
            outweigh your rights and freedoms. The processing may also be
            pursuant to other applicable legal bases for data processing
            especially provisions set out under local law.
          </li>
        </div>
        <p className="text">
          Where we use personal information to meet our legitimate interests, we
          take steps to ensure that your rights with respect to your personal
          information are not infringed. You can contact us using the details
          set out in the How to Contact Us section below for more information
          about the steps we take to ensure these rights are not infringed. You
          also have the right to object to such processing as described in the
          Your Choices and Rights section below.
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Consent.</b> To the extent that a legal ground described above
            would not apply to processing of your personal information by us, we
            will seek your consent for such specific purpose in accordance with
            applicable law (such as sending direct marketing messages by
            electronic means, like email, without an exception from the
            requirement to obtain consent).
          </li>
        </div>
        <p className="text">
          Where we use personal information to meet our legitimate interests, we
          take steps to ensure that your rights with respect to your personal
          information are not infringed. You can contact us using the details
          set out in the How to Contact Us section below for more information
          about the steps we take to ensure these rights are not infringed. You
          also have the right to object to such processing as described in the
          Your Choices and Rights section below.
        </p>
        <p className="text">
          <b>Aggregate Information.</b> We aggregate personal information
          collected directly from you, information generated about you by us,
          and information obtained from third parties (with your consent, where
          required) with personal information collected about other users in
          order to produce general statistics that cannot be linked to you or
          any other specific user. Information that has been aggregated and
          de-identified is no longer considered “personal information” and may
          be subsequently used for any purpose.
        </p>
        <p className="text">
          <b>Anonymized Information.</b> We may process information that cannot
          be linked to you or any other specific user using any means available
          to us, either because it was collected anonymously or has been
          subsequently anonymized. Information that is anonymous or has been
          anonymized is no longer considered “personal information” and may be
          subsequently used for any purpose.
        </p>
        <h2 className="title">3. HOW WE SHARE YOUR INFORMATION</h2>
        <p className="text">
          We disclose the personal information we collect (or otherwise generate
          or obtain) as follows:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>With service providers and service providers’ affiliates.</b> We
            share your information with service providers and their affiliates
            to provide the Services (such as connecting truck drivers and owners
            with service providers to make an online reservation and sharing
            your service activity and history, preferences, requests,
            restrictions, and other information with the service provider) and
            for the service provider or service provider group’s own purposes,
            which may include marketing or advertising purposes (see below for
            more detail on Sharing with Service Provider and Service Providers’
            Affiliates).
          </li>
          <li className="listText">
            <b>With our group companies.</b> We share your information with our
            group companies, including but not limited to for the Purposes
            described above, to provide you with integrated products and
            services, and for our and their marketing purposes (see below for
            more detail on Sharing with Our Group Companies).
          </li>
          <li className="listText">
            <b>With other business partners.</b> We share information with other
            third-party business partners for their own marketing purposes,
            including sharing with online advertisers or advertising technology
            (“ad tech”) companies to provide you with targeted advertising and
            marketing communications, where permitted under law (see below for
            more detail on Sharing with Our Business Partners).
          </li>
          <li className="listText">
            <b>With social networking services.</b> We share (or facilitate your
            sharing of) your information with social networking services when
            you use our Services to connect and share your information publicly
            or with friends, or when you use our Services to connect with us on,
            share on, or use third-party social networking platforms and
            services (see below for more detail on Sharing with Social
            Networking Services).
          </li>
          <li className="listText">
            <b>
              Third-party services or applications you use to log into your
              account.
            </b>{" "}
            If you use a third-party service or application (e.g., Facebook) to
            log into your TruckFix account, we share certain personal
            information with that third party, such as your name and email
            address.
          </li>
          <li className="listText">
            <b>Reviews you submit.</b> If you provide us with a review of your
            service reservation, you authorize us to publish it on all our
            Services under the screenname you provided, to aggregate your review
            with other reviews, and to share it with restaurants.
          </li>
          <li className="listText">
            <b>
              Messages and other communications you submit to service providers.
            </b>
            If you submit a message or other communication to a service provider
            through our Services, you authorize us to share your message or
            communications with the relevant service provider, and you authorize
            the service provider to respond to you through our Services.
          </li>
          <li className="listText">
            <b>With other service providers.</b> We share information with
            third-party vendors, consultants, and other service providers who
            perform services or functions on our behalf (see below for more
            detail on Sharing with Other Service Providers).
          </li>
          <li className="listText">
            <b>In the event of a corporate transaction.</b> We may disclose or
            transfer your information to a third party if we sell, transfer,
            divest, or disclose all or a portion of our business or assets to
            another company in connection with or during negotiation of any
            merger, financing, acquisition, bankruptcy, dissolution,
            transaction, or proceeding.
          </li>
          <li className="listText">
            <b>To protect rights and property.</b> We may disclose your
            information to third parties when we believe in good faith that
            disclosure is necessary to protect our rights, our property, the
            integrity of the Services, personal safety, or the interests of you
            or any other person, and to detect, prevent and/or otherwise address
            fraud, risk management, security or technical issues.
          </li>
          <li className="listText">
            <b>To comply with and as required by law.</b> We may disclose your
            personal information to government authorities or other relevant
            third parties in order to comply with applicable legal requirements,
            judicial proceedings, court orders, legal process, or lawful
            requests from governmental authorities.
          </li>
          <li className="listText">
            <b>Aggregate information.</b> We share aggregate statistical data
            for the improvement of our Services. We may also share aggregate or
            de-identified information with third parties at our discretion.
          </li>
          <li className="listText">
            <b>At your request.</b> We also share your information as directed
            or requested by you, or subject to your consent.
          </li>
        </div>
        <p className="text">
          In some circumstances, you may have the right to opt-out or object to
          our sharing of your information with certain third parties. For more
          information, or to exercise these or other rights, see the Your
          Choices and Rights section below.
        </p>
        <p className="text">
          <b>
            Sharing with Service Providers and Service Providers’ Affiliates as
            a controller of your personal information.
          </b>{" "}
          When you make a request through our Services or through a service
          provider, we necessarily provide certain categories of your
          information to the service provider to facilitate that request. For
          requests to service, this information may include your name, profile,
          time and date of visit, truck and trailer information, your phone
          number, any special requests, information or comments that you choose
          to submit (if any), and your email address. Your information is
          provided to the service provider, just as it would be if you contacted
          the service provider directly. If you provide a mobile phone number in
          connection with your request, service providers may send you text
          messages regarding your request. Some service providers also require
          you to provide credit or debit card account information to secure your
          reservation. When you make a reservation at a service provider, we may
          also share additional information about your dining activity at that
          service in the past, your preferences and/or information that we
          collect from you and third parties that may be relevant to that
          reservation.
        </p>
        <p className="text">
          In addition to providing you with more customized Services, we may
          receive certain information from participating service providers from
          their Point of Sale terminals that we analyze to provide aggregate
          information to the service provider about their customers.
        </p>
        <p className="text">
          We also share with service providers summary reports of feedback from
          users. If you provide comments about a service provider through our
          Services, these comments may be shared with or may be accessible by
          that service provider, and the service provider may respond to you. We
          will not tie your comments with other information that can identify
          you directly, but a service provider may be able to tell who you are
          from your comments, particularly if you give your name in the comments
          or provide contact information, such as an email address.
        </p>
        <p className="text">
          <b>
            Sharing with Service Providers and Service providers’ Affiliates as
            a processor or service provider on their behalf.
          </b>{" "}
          We act as a processor or service provider for Service Providers and
          Service Providers’ Affiliates in certain circumstances as described
          above (see above for more detail on Our Role as Data Controller and
          Data Processor). In providing these services, we may, at the direction
          of the service provider, share certain of your information with the
          service provider’s affiliates (such as affiliated brands), service
          group and/or service providers with the same brand or parent-brand
          (collectively, such service provider’s <b>“service group”</b>), or
          other entities associated with the service provider (such as the
          parent entity of the service group) and/or their service providers
          (collectively, the associated entities and service providers, the
          service provider’s <b>"service affiliates"</b>) under TruckFix’s
          programs for the following purposes:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Customized services:</b> service providers may share your
            information (such as road service, truck shop requests) with their
            service affiliates and service group to enhance the hospitality the
            service group provides you when you service with them (such as
            special requests, etc).
          </li>
          <li className="listText">
            <b>Operations & Service Improvements:</b> to support operations,
            improve the shift planning, improve their hospitality services,
            including supporting a loyalty program you have chosen to
            participate in.
          </li>
          <li className="listText">
            <b>Marketing:</b> as permitted by applicable law, to perform
            analytics and tailor marketing to you.
          </li>
        </div>
        <p className="text">
          For more information, please feel free to contact the restaurants with
          which you dine or book reservations, or contact us using the contact
          information listed in the How to Contact Us section below. To learn
          more about your choices related to how we share your information with
          service groups and service affiliates under our programs, please see
          the Your Choices and Rights section below.
        </p>
        <p className="text">
          <b>Sharing with Our Business Partners.</b> We share your information
          with other third-party business partners for their own marketing
          purposes. These third parties include online advertisers or ad tech
          companies, who may provide you with targeted advertising and marketing
          communications, where permitted under law. The information we share
          includes information collected through your use of our Services (e.g.,
          bookings, reservations, or other purchases) and information we collect
          about you through the use of cookies and similar technologies (e.g.,
          information about the websites you visit; information about your
          searches, including the cities or neighborhoods you search in, the
          type of service you searched for, price range, and intended service
          date).
        </p>
        <p className="text">
          To learn more about your choices related to how we share your
          information with our business partners, please see the Your Choices
          and Rights section below.
        </p>
        <p className="text">
          <b>Sharing with Social Networking Services.</b> Our Services allow you
          to connect and share your actions, comments, content, and information
          publicly or with friends. Our Services may also allow you to connect
          with us on, share on, and use third-party platforms, including those
          on which TruckFix has a presence. Please be mindful of your personal
          privacy needs and the privacy needs of others as you choose whom to
          connect with and what to share and make public. We cannot control the
          privacy or security of information you choose to make public or share
          with others. TruckFix also does not control the privacy practices of
          third-party platforms. Please contact those sites and services
          directly to learn about their privacy practices.
        </p>
        <p className="text">
          <b>Sharing with Other Service Providers.</b> We share information with
          third-party vendors, consultants, and other service providers who
          perform services or functions on our behalf (e.g., hosting or
          operating our Services, data collection, reporting, ad response
          measurement, site analytics, data analysis, delivering marketing
          messages and advertisements, processing credit card payments, and
          providing fraud detection services). We do not authorize these third
          parties to use or disclose your information for purposes other than
          for which it has been provided. We require these third parties to
          maintain and implement security measures to protect your information
          from unauthorized access or processing.
        </p>
        <h2 className="title">4. HOW WE STORE AND PROTECT YOUR INFORMATION</h2>
        <p className="text">
          TruckFix maintains commercially-reasonable technical, administrative,
          and physical security measures designed to protect your information
          from loss, misuse, unauthorized access, disclosure, alteration, and
          destruction.
        </p>
        <p className="text">
          You play an important role in keeping your information secure. You
          should not share your user name, password, or other security
          information for your TruckFix account with anyone. If we receive
          instructions using your user name and password, we will assume you
          have authorized the instructions. If you have reason to believe that
          your interaction with us is no longer secure (e.g., if you feel that
          the security of any account you might have with us has been
          compromised), please contact us immediately as detailed in the How to
          Contact Us section below.
        </p>
        <p className="text">
          <b>Retention.</b> We may retain your personal information for as long
          as your account is active and for a period of time thereafter to allow
          you to re-activate your account without loss of information. We may
          also retain your personal information as necessary to:
        </p>
        <div className="listContainers">
          <li className="listText">
            Maintain logs and business records for analysis, security, and/or
            audit purposes
          </li>
          <li className="listText">
            Comply with record retention requirements under the law
          </li>
          <li className="listText">
            Deal with any complaints regarding the Services; and
          </li>
          <li className="listText">
            Comply with our legal obligations, protect or defend our rights,
            resolve disputes and enforce our contracts
          </li>
        </div>
        <h2 className="title">5. COOKIES</h2>
        <p className="text">
          When you use or visit the Services, we collect information about your
          usage and activity using cookies, web beacons, and other technologies.
          Third parties may also view, edit, or set their own cookies. We and
          our third-party service providers, our group companies, and other
          business partners may also place web beacons for these third parties.
          The use of these technologies by third parties is subject to their own
          privacy policies and is not covered by this Policy, except as required
          by law. See our Cookies and Interest-Based Advertising Policy for more
          details.
        </p>
        <h2 className="title">6. YOUR CHOICES AND RIGHTS</h2>
        <p className="text">
          <b>Email.</b> If you no longer want to receive marketing and
          promotional emails from TruckFix, you may click on the “unsubscribe”
          link in such emails to opt-out of future marketing email
          communications. If you have an TruckFix account, you may also opt-out
          of marketing emails in your account settings. Please note that even if
          you opt-out of receiving marketing communications from one or all of
          our Services, we will still send you service-related communications,
          such as confirmations of any future reservations you make.
        </p>
        <p className="text">
          <b>Push Notifications (on Mobile Devices).</b> You can use the
          settings on your mobile device to enable or turn off mobile push
          notifications from TruckFix.
        </p>
        <p className="text">
          <b>Text Messages.</b> If you no longer want to receive text messages
          from TruckFix, reply <b>STOP</b> (or as otherwise instructed) to the
          text message. If you have a TruckFix account, you may also adjust your
          account settings to opt-out of text messages.
        </p>
        <p className="text">
          <b>Cookies and Interest-Based Advertising.</b> To exercise choices
          regarding cookies set through our websites or Services, as well as
          other types of online tracking and online advertising, see our Cookies
          and Interest-Based Advertising Policy for more details. We currently
          do not employ technology that recognizes “do-not-track” signals from
          your browser.
        </p>
        <p className="text">
          <b>Application Location.</b> As explained in more detail in the
          Information We Collect and Use section above, we collect information
          about your location if you enable location services through the
          settings in your mobile device, or with your consent, as may be
          required by law. You can change the privacy settings of your device at
          any time to turn off the sharing of this location information with our
          Services. If you choose to turn off location services, this could
          affect certain features of our Services. If you have specific
          questions about the privacy settings of your device, we suggest you
          contact the manufacturer of your device or your mobile service
          provider for help.
        </p>
        <p className="text">
          <b>
            Choices about the collection of information from service providers
            point of sale terminals.
          </b>{" "}
          As described above, if you make a booking with certain participating
          service provider through our Services we will receive information
          about your service experience from the service provider’s point of
          sale terminal. You can opt-out of us receiving this information via
          the booking confirmation page or your TruckFix account preferences.
        </p>
        <p className="text">
          <b>Customized Dining Experiences.</b> As described in the Sharing with
          Service Providers and Service Providers Affiliates section of the
          privacy policy - in Part III above, when you book with service
          provider we share your information with them to ensure your booking is
          recorded. These <b>service providers</b> may share your dining
          information with their <b>service affiliates</b> or broader{" "}
          <b>service group or</b> through the TruckFix platform for the purpose
          of improving and personalizing your dining experience on future visits
          if you book at a service provider affiliate or within the same service
          provider group. You can opt-out of such sharing with service
          affiliates and service groups for customized dining experiences by
          changing your TruckFix account preferences.
        </p>
        <p className="text">
          Please note that if you opt-out of this sharing, we may continue to
          assist service providers in sharing information with service
          affiliates and service groups for them to use for other internal
          purposes, such as their internal analytics.
        </p>
        <p className="text">
          <b>Sharing with third parties.</b> As described above in the How We
          Share Your Information section, we share your information with third
          parties for their own purposes.
        </p>
        <p className="text">
          You can opt-out of us sharing your information with:
        </p>
        <div className="listContainers">
          <li className="listText">
            <b>Our group companies</b>, for their own marketing purposes;
          </li>
          <li className="listText">
            <b>Other business partners</b>, who use information for their own
            marketing purposes.
          </li>
        </div>
        <p className="text">
          You can opt-out of such sharing by changing your TruckFix account
          preferences. You may also send such opt-out requests to us as
          described in the How to Contact Us section below.
        </p>
        <p className="text">
          <b>Access and Correction of Your Information.</b> If you have created
          an online account with us and would like to update the information you
          have provided to us, you can access your account to view and update
          your information. You may also contact us as described in the How to
          Contact Us section below.
        </p>
        <p className="text">
          <b>Legal Rights.</b> Where applicable under local law, you may have
          the following rights regarding your personal information: the right to
          access personal information we hold, and in some situations, the right
          to have that personal information corrected or updated, erased,
          restricted, or delivered to you or a third party in a usable
          electronic format (the right to data portability). Where applicable,
          you may also object to how we use your personal information if the
          legal basis for processing that information is our legitimate
          interest. Where we are using your personal information on the basis of
          your consent, and where applicable under local law, you have the right
          to withdraw that consent at any time. Where you have granted consent
          to receive direct marketing communications from us, and where
          applicable under local law, you may withdraw that consent at any time.
        </p>
        <p className="text">
          If you wish to exercise legal rights you may have under applicable
          law, please submit your request to <b>info@truckfixapp.com</b>. So
          that we can better process your request, please provide the email you
          use to log into your TruckFix account. If you do not have an TruckFix
          account, please provide the email you used to make requests or to use
          our Services.
        </p>
        <h2 className="title">7. INTERNATIONAL TRANSFERS OF INFORMATION</h2>
        <p className="text">
          Information about you will be transferred to, or accessed by, entities
          located around the world as described in this Policy. Some of these
          entities may be located in countries (such as the United States) that
          do not provide an equivalent level of protection for personal
          information as your home country.
        </p>
        <p className="text">
          We have put in place safeguards to provide adequate protection for
          transfers of certain information, in accordance with applicable legal
          requirements. For more information on the appropriate safeguards in
          place, or to request a copy of these safeguards, please contact us
          using the contact details listed in the How to Contact Us section
          below.
        </p>
        <h2 className="title">8. LINKS TO OTHER WEBSITES</h2>
        <p className="text">
          Our Services contain links to other websites or services that are not
          owned or controlled by TruckFix, including links to websites of
          restaurants and restaurant affiliates and our advertisers, our group
          companies, and other business partners. This Policy only applies to
          information collected by our Services. We have no control over these
          third party websites, and your use of third party websites and
          features are subject to privacy policies posted on those websites. We
          are not responsible or liable for the privacy or business practices of
          any third party websites linked to our Services. Your use of third
          parties' websites linked to our Services is at your own risk, so we
          encourage you to read the privacy policies of any linked third party
          websites when you leave one of our Services.
        </p>
        <h2 className="title">9. CHILDREN</h2>
        <p className="text">
          Our Services are not directed at or intended for use by children. We
          do not knowingly collect information from, children under 16 years of
          age. If you become aware that your child or any child under your care
          has provided us with information without your consent, please contact
          us at using the contact details listed in the How to Contact Us
          section below.
        </p>
        <h2 className="title">10. CHANGES TO THIS POLICY</h2>
        <p className="text">
          Except to the extent limited by applicable law, we will update this
          Privacy Policy from time to time to reflect changes in our privacy
          practices, legal requirements, and other factors by prominently
          posting notice of the update on our Services. Changes to our Privacy
          Policy will be effective when posted and the new effective date will
          be identified.
        </p>
        <p className="text">
          If we make any changes to the Privacy Policy that materially impact
          previously collected personal information about you, we will make
          reasonable efforts to provide notice and obtain consent to any such
          changes as may be required by law.
        </p>
        <p className="text">
          To request a copy of this Policy, or to request a copy of the Privacy
          Policy in place at the time you signed up for an account, please
          contact us at the details below.
        </p>
        <h2 className="title">11. HOW TO CONTACT US</h2>
        <p className="text">
          If you have any questions about this Policy or the way in which your
          personal information has been used, please contact us by email at
          <b>info@truckfixapp.com</b> or by postal mail at:
        </p>
        <div className="listContainers">
          <li className="listText">TruckFix</li>
          <li className="listText">6428 Joliet Road, Suite 104</li>
          <li className="listText">Countryside, IL 60525</li>
          <li className="listText">USA</li>
        </div>
      </div>
    </PrivacyPolicyStyles>
  );
};

export default PrivacyPolicy;
