import { LocationRequest } from "./location.request";

// Utils
import { isEmpty } from "../../../utils";

export class BussinessRequest {
  constructor(data = {}) {
    this.name = data?.name;
    this.proof_document_url = data?.proofDocumentationUrl;
    this.website_url = data?.websiteUrl;
    this.tax_id = data?.taxId;
    this.location = new LocationRequest({
      state: data?.state,
      city: data?.city,
      postalCode: data?.postalCode,
      address: data?.address,
      longitude: data?.longitude,
      latitude: data?.latitude
    });
  }

  removeEmptyValues() {
    Object.entries(this).forEach(([key, value]) => {
      if (isEmpty(value)) {
        delete this[key];
      }
    });
  }
}
