import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Action types
import * as types from "../actionTypes/service-requests.types";

// Actions
import {
  getServiceRequestsSuccess,
  approveServiceRequestsSuccess,
  declineServiceRequestsSuccess,
  openTicketDetailsSuccess,
  updateServiceRequestSuccess
} from "../actions/service-requests.actions";

// Calls
import {
  fetchServiceRequestsRequest,
  approveServiceRequest,
  declineServiceRequest,
  fetchServiceDetailsRequest,
  updateServiceRequest
} from "../../api/calls/service-requests.call";

// Fetch service-requests logic
function* fetchServiceRequests(action) {
  const response = yield call(
    fetchServiceRequestsRequest,
    action.isSupportRole
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_SERVICE_REQUESTS_FAILED
    });
  }
  yield put(getServiceRequestsSuccess(response.requests));
}

function* fetchServiceRequestsWatcher() {
  yield takeLatest(types.FETCH_SERVICE_REQUESTS_REQUEST, fetchServiceRequests);
}

// Approve service-requests logic
function* approveServiceRequestsCall(action) {
  const response = yield call(
    approveServiceRequest,
    action.serviceId,
    action.branchId
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.APPROVE_SERVICE_REQUESTS_FAILED
    });
  }
  yield put(approveServiceRequestsSuccess(action.serviceId, response.message));
}

function* approveServiceRequestsWatcher() {
  yield takeLatest(
    types.APPROVE_SERVICE_REQUESTS_REQUEST,
    approveServiceRequestsCall
  );
}

// Decline service-requests logic
function* declineServiceRequestsCall(action) {
  const response = yield call(
    declineServiceRequest,
    action.serviceId,
    action.declineReasonFormValues,
    action.branchId
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.DECLINE_SERVICE_REQUESTS_FAILED
    });
  }
  yield put(declineServiceRequestsSuccess(action.serviceId, response.message));
}

function* declineServiceRequestsWatcher() {
  yield takeLatest(
    types.DECLINE_SERVICE_REQUESTS_REQUEST,
    declineServiceRequestsCall
  );
}

// Open service tiket details logic
function* openServiceDetailsRequestsCall(action) {
  const response = yield call(
    fetchServiceDetailsRequest,
    action.serviceId,
    action.branchId
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.OPEN_TICKET_DETAILS_FAILED
    });
  }
  yield put(openTicketDetailsSuccess(response.service));
}

function* openServiceDetailsRequestsWatcher() {
  yield takeLatest(types.OPEN_TICKET_DETAILS, openServiceDetailsRequestsCall);
}

// Update service-request bookedFor, Eta, isSeen logic
function* updateServiceRequestCall(action) {
  const response = yield call(
    updateServiceRequest,
    action.serviceId,
    action.payload
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.UPDATE_SERVICE_REQUEST_FAILED
    });
  }
  yield put(updateServiceRequestSuccess(response.service));
}

function* updateServiceRequestCallWatcher() {
  yield takeLatest(
    types.UPDATE_SERVICE_REQUEST_REQUEST,
    updateServiceRequestCall
  );
}

export default function* serviceRequestsSaga() {
  yield all([
    fetchServiceRequestsWatcher(),
    approveServiceRequestsWatcher(),
    declineServiceRequestsWatcher(),
    openServiceDetailsRequestsWatcher(),
    updateServiceRequestCallWatcher()
  ]);
}
