// icons
import { Close } from "../../../SVGS";
// styles
import styled from "styled-components";

const SpecialDayItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content {
    display: flex;
    align-items: center;
    width: 100%;

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
      margin-right: 10px;
      width: 25%;
    }

    .date {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #44444f;
    }
  }
`;

const SpecialDayItem = ({ dayName, date, removeAction }) => {
  return (
    <SpecialDayItemStyles>
      <div className="content">
        <h3 className="name">{dayName}</h3>
        <p className="date">{date}</p>
      </div>
      <Close onClick={removeAction} />
    </SpecialDayItemStyles>
  );
};

export default SpecialDayItem;
