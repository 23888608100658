// Components
import { Button } from "../UI";

// Builders
import * as buttonDesignType from "../../builders/buttonDesign.types";

// Constants
import * as statusTypes from "../../constants/status.constants";

// Styles
import { HeaderActionsStyles } from "./headerActions.styles";

const HeaderActions = ({
  status,
  approveAction,
  declineAction,
  cancelServiceAction,
  startServiceAction,
  pauseServiceAction,
  resumeServiceAction,
  finishServiceAction,
  toggleChat
}) => {
  switch (status) {
    case statusTypes.IN_PROGRESS:
      return (
        <HeaderActionsStyles style={{ flexDirection: "column" }}>
          <div className="topButtons">
            <Button
              buttonDesignType={buttonDesignType.SECONDARY}
              className="button"
              onClick={pauseServiceAction}
            >
              Pause
            </Button>
            <Button
              buttonDesignType={buttonDesignType.DANGER}
              className="button danger"
              onClick={cancelServiceAction}
            >
              Cancel
            </Button>
          </div>
          <Button
            buttonDesignType={buttonDesignType.SECONDARY}
            className="button finishButton"
            onClick={finishServiceAction}
          >
            Finish
          </Button>
        </HeaderActionsStyles>
      );
    case statusTypes.ACCEPTED:
      return (
        <HeaderActionsStyles>
          <Button
            buttonDesignType={buttonDesignType.SECONDARY}
            className="button"
            onClick={startServiceAction}
          >
            Start
          </Button>
          <Button
            buttonDesignType={buttonDesignType.DANGER}
            className="button danger"
            onClick={cancelServiceAction}
          >
            Cancel
          </Button>
        </HeaderActionsStyles>
      );
    case statusTypes.PAUSED:
      return (
        <HeaderActionsStyles>
          <Button
            buttonDesignType={buttonDesignType.SECONDARY}
            className="button"
            onClick={resumeServiceAction}
          >
            Resume
          </Button>
          <Button
            buttonDesignType={buttonDesignType.DANGER}
            className="button danger"
            onClick={cancelServiceAction}
          >
            Cancel
          </Button>
        </HeaderActionsStyles>
      );
    case statusTypes.FINISHED:
      return (
        <HeaderActionsStyles>
          <Button
            buttonDesignType={buttonDesignType.SECONDARY}
            onClick={toggleChat}
            className="button history"
          >
            Chat History
          </Button>
        </HeaderActionsStyles>
      );
    case statusTypes.CANCELED:
      return (
        <HeaderActionsStyles>
          <Button
            buttonDesignType={buttonDesignType.SECONDARY}
            className="button history"
            onClick={toggleChat}
          >
            Chat History
          </Button>
        </HeaderActionsStyles>
      );
    default:
      return (
        <HeaderActionsStyles>
          <Button
            buttonDesignType={buttonDesignType.PRIMARY}
            className="button"
            onClick={approveAction}
          >
            Approve
          </Button>
          <Button
            buttonDesignType={buttonDesignType.SECONDARY}
            className="button"
            onClick={declineAction}
          >
            Decline
          </Button>
        </HeaderActionsStyles>
      );
  }
};

export default HeaderActions;
