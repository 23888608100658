import styled from "styled-components";

export const NotificationsCounterStyles = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #ff4747;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 8px;
  color: #ffffff;
`;
