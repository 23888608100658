// components
import { SubscriptionPlanPrice, Button } from "../../../UI";
import { Paragraph } from "../../../typography";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";
// icons
import Accepted from "../../../../images/accepted.png";
import Declined from "../../../../images/declined.png";
// styles
import { PlanCardStyles } from "./planCard.styles";

const PlanCard = ({
  id,
  name,
  description,
  price,
  subscriptionPlanFeatures,
  isSelected,
  activePlanExists,
  subscribeToPlan,
  cancelSubscribe,
  activeSubscription,
  resumeSubscription,
  changeSubscriptionPlan
}) => {
  const shouldShowResume = isSelected && activeSubscription.canceledAt;
  return (
    <PlanCardStyles selected={isSelected}>
      <h2 className="planTitle">{name}</h2>
      <SubscriptionPlanPrice
        designType="bg"
        price={Math.round(price.unitAmount / 100)}
      />
      <p className="planDescription">{description}</p>
      <div>
        {subscriptionPlanFeatures.map(
          ({ subscriptionFeature, supported }, i) => {
            return (
              <div className="featureContainer" key={i}>
                <img
                  src={supported ? Accepted : Declined}
                  alt={supported ? "accepted" : "declined"}
                />
                <Paragraph
                  className="featureName"
                  style={{ color: supported ? "#006644" : "#B92500" }}
                >
                  {subscriptionFeature.title}
                </Paragraph>
              </div>
            );
          }
        )}
      </div>
      {isSelected ? (
        <>
          {shouldShowResume ? (
            <Button
              className="choosePlanButton cancelSubscription"
              buttonDesignType={buttonDesignType.DANGER}
              onClick={resumeSubscription}
            >
              Resume Subscription
            </Button>
          ) : (
            <Button
              className="choosePlanButton cancelSubscription"
              buttonDesignType={buttonDesignType.DANGER}
              onClick={cancelSubscribe}
            >
              Cancel Subscription
            </Button>
          )}
        </>
      ) : (
        <Button
          className="choosePlanButton"
          onClick={() =>
            activePlanExists ? changeSubscriptionPlan() : subscribeToPlan()
          }
        >
          {activePlanExists && !isSelected ? "Change Plan" : "Chose Plan"}
        </Button>
      )}
    </PlanCardStyles>
  );
};

export default PlanCard;
