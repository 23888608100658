import NotFound from "./NotFound";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import PrivacyPolicy from "./PrivacyPolicy";
import BusinessProfile from "./BusinessProfile";
import SubscriptionPlans from "./SubscriptionPlans";
import AccountSettings from "./AccountSettings";
import ManageBranches from "./ManageBranches";
import AddBranch from "./AddBranch";
import EditBranch from "./EditBranch";
import GeneralBranchSettings from "./GeneralBranchSettings";
import Managers from "./Managers";
import ServiceRequests from "./ServiceRequests";
import Services from "./Services";
import EmployeeSchedule from "./EmployeeSchedule";
import Analytics from "./Analytics";
import Dashboard from "./Dashboard";

export {
  NotFound,
  Login,
  Register,
  ResetPassword,
  PrivacyPolicy,
  BusinessProfile,
  SubscriptionPlans,
  AccountSettings,
  ManageBranches,
  AddBranch,
  EditBranch,
  GeneralBranchSettings,
  Managers,
  ServiceRequests,
  Services,
  EmployeeSchedule,
  Analytics,
  Dashboard
};
