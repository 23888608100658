import styled from "styled-components";

export const TimePickerStyles = styled.div`
  width: 100%;

  .timePicker {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    padding: 8px 12px;
  }

  .error {
    border: 1px solid #ca2c2c !important;
  }

  .errorMessage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ca2c2c;
    margin-top: 4px;
    height: 24px;
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    padding: 8px 9px;
    cursor: pointer;

    .valueContainer {
      display: flex;
      align-items: center;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      .value {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #292359;
      }

      .placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #292359;
      }
    }

    .arrowContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(180deg);
    }
  }

  /* picker style */
  .react-datepicker {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #292359;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    width: 15px;
    height: 15px;
    top: 8px;
    margin: 0 7px;
  }

  .react-datepicker__navigation--next {
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;

    border-left: 7px solid #171725;
  }

  .react-datepicker__navigation-icon--next::before {
    display: none;
  }

  .react-datepicker__navigation--previous {
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;

    border-right: 7px solid #171725;
  }

  .react-datepicker__navigation-icon--previous::before {
    display: none;
  }

  .react-datepicker__header {
    background: #fff;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #497bb0;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #497bb0;
    color: white;
    font-weight: normal;
  }

  .react-datepicker__header--time {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__time-container .react-datepicker__time {
    height: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    height: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc !important;
  }

  .react-datepicker-wrapper {
    display: flex;
  }

  .react-datepicker__input-container {
    width: 100%;
  }
`;
