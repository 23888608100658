// Components
import { IconTextTooltip } from "../../UI";

// Constants
import * as permissionsEnum from "../../../constants/permissions.constants";

// Icons
import { Close, BusinessProfileIcon, BranchSettingsIcon } from "../../../SVGS";

// Utils
import { isEmpty } from "../../../utils";

// Styles
import { AssignedManagerItemStyles } from "./assignedManagerItem.styles";

const AssignedManagerItem = ({ fullName, email, removeItem, permissions }) => {
  return (
    <AssignedManagerItemStyles>
      <h3 className="managerName">{fullName}</h3>
      <p className="email">{email}</p>
      <div className="permissionsContainer">
        {isEmpty(permissions)
          ? "/"
          : permissions.map(permission => (
              <IconTextTooltip
                key={permission.key}
                id={
                  permission.key === permissionsEnum.BUSINESS_PROFILE
                    ? "businessProfile"
                    : "branchSettings"
                }
                style={{ marginRight: 24 }}
                Icon={
                  permission.key === permissionsEnum.BUSINESS_PROFILE
                    ? BusinessProfileIcon
                    : BranchSettingsIcon
                }
                isTextVisible
                text={
                  permission.key === permissionsEnum.BUSINESS_PROFILE
                    ? "Business Profile"
                    : "Access to Branches & Managers"
                }
              />
            ))}
      </div>
      <div className="closeContainer">
        <Close onClick={removeItem} />
      </div>
    </AssignedManagerItemStyles>
  );
};

export default AssignedManagerItem;
