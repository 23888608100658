import styled from "styled-components";

export const TabStyles = styled.div`
  width: 100%;
  cursor: pointer;
  padding: ${({ isActive }) =>
    isActive ? "18px 20px 18px 18px" : "18px 20px"};
  display: flex;
  align-items: center;
  background: ${({ isActive }) =>
    isActive ? "rgba(127, 202, 250, 0.1)" : " #ffffff"};
  ${({ isActive }) => {
    if (isActive) {
      return `
        border-left: 3px solid #7fcafa;
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        `;
    }
    return `
        border: 1px solid #dfe1e6;
      `;
  }}

  .tabName {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #171725;
    margin-left: 12px;
  }
`;
