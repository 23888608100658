import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

// Components
import BranchForm from "../branchForm/branch.form";

// Services
import { branchValidation } from "../../../../services/validations/branch.validation";

// Form Models
import { BranchFormModel } from "../../../../models/app/forms";

// Actions
import { createBranch } from "../../../../redux/actions/app.actions";

// Styles
import { AddBranchFormStyles } from "./addBranchForm.styles";

const AddBranchFrom = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createBranchHandler = values => {
    dispatch(createBranch(values));
    setTimeout(() => {
      history.push("/branches");
    }, 100);
  };

  const formik = useFormik({
    initialValues: new BranchFormModel(),
    onSubmit: createBranchHandler,
    validate: branchValidation
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    setValues,
    handleBlur
  } = formik;

  return (
    <AddBranchFormStyles>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <BranchForm
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleChange={handleChange}
          setValues={setValues}
          handleBlur={handleBlur}
          submitText="Add Branch"
        />
      </form>
    </AddBranchFormStyles>
  );
};

export default AddBranchFrom;
