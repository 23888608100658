import { useSelector, useDispatch } from "react-redux";

// Components
import { Paragraph } from "../../typography";
import { Button, StatusPill } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Constants
import * as serviceTypes from "../../../constants/serviceTypes.constants";

// Icons
import {
  TireIcon,
  TruckIcon,
  TowingIcon,
  TrailerIcon,
  RoadIcon
} from "../../../SVGS";

// Utils
import {
  convertTimeStampToTime,
  convertTimeStampToDate
} from "../../../utils/timeStampToDate";

// Styles
import { ServiceItemStyles } from "./serviceItem.styles";

// Actions
import { openWorkersForm } from "../../../redux/actions/services.actions";

// Utils
import { isEmpty } from "../../../utils";

const ServiceItem = ({
  workers,
  status,
  serviceTypeKey,
  id,
  time,
  eta,
  mobility,
  newMessagesCount,
  driver
}) => {
  const hasWorkers = !isEmpty(workers);
  const dispatch = useDispatch();
  const { systemComponents } = useSelector(state => state.app);
  const serviceType = systemComponents.serviceTypes.find(
    type => type.typeKey === serviceTypeKey
  );
  const generateIcon = () => {
    switch (serviceTypeKey) {
      case serviceTypes.TRUCK_SHOP:
        return <TruckIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.ROAD_ASSISTANCE:
        return <RoadIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.TOWING:
        return <TowingIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.TIRE_REPAIR:
        return <TireIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.TRAILER_REPAIR:
        return <TrailerIcon style={{ width: 16, marginRight: 8 }} />;

      default:
        return null;
    }
  };
  return (
    <ServiceItemStyles>
      <div className="start">
        <Paragraph className="startDate">
          {convertTimeStampToDate(time)}
        </Paragraph>
        <Paragraph className="startDate">
          <span style={{ color: "rgba(86, 86, 86, 1)" }}>
            {convertTimeStampToTime(time)}
          </span>
        </Paragraph>
      </div>
      <div className="finish">
        <Paragraph className=" finishDate">
          {convertTimeStampToDate(eta)}
        </Paragraph>
        <Paragraph className=" finishDate">
          <span style={{ color: "rgba(0, 102, 68, 1)" }}>
            {convertTimeStampToTime(eta)}
          </span>
        </Paragraph>
      </div>

      <Paragraph className="ID index">{id}</Paragraph>
      <Paragraph className="user userName">{`${driver?.firstName} ${driver?.lastName}`}</Paragraph>
      <div className="serviceType">
        {generateIcon()}
        <Paragraph className="serviceTypeText">
          {serviceType?.name} - {mobility}
        </Paragraph>
      </div>
      <div className="status">
        <StatusPill
          style={{ minWidth: "unset", display: "inline-block" }}
          status={status}
        />
      </div>
      <div className="messages">
        {newMessagesCount > 0 && <li className="msgs">New Message</li>}
      </div>
      <div className="actions actionsContainer">
        <Button
          onClick={e => {
            e.stopPropagation();
            dispatch(openWorkersForm(id, workers));
          }}
          className="actionButton"
          buttonDesignType={
            hasWorkers ? buttonDesignType.SECONDARY : buttonDesignType.PRIMARY
          }
        >
          {hasWorkers ? "Edit Workers" : "Assign Workers"}
        </Button>
      </div>
    </ServiceItemStyles>
  );
};

export default ServiceItem;
