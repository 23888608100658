import styled from "styled-components";

export const ServiceItemStyles = styled.div`
  display: flex;
  align-items: center;

  .startDate {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgba(86, 86, 86, 0.6);
  }

  .finishDate {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgba(0, 102, 68, 0.6);
  }

  .index {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .userName {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .serviceTypeText {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .msgs {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ff4747;
  }

  .actionsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .actionButton {
      padding: 8px 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      width: 100%;
    }
  }
`;
