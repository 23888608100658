import styled from "styled-components";

export const SpecialDaysStyles = styled.div`
  .formContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputsContainer {
      width: calc(100% - 150px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inputContainer {
        width: 48%;
        .input {
          margin-top: 2px;
        }
      }
    }

    .button {
      width: 113px;
      margin-bottom: 7px;
    }
  }

  .specialDaysList {
    margin-top: 3px;
  }
`;
