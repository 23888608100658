export const changePasswordValidation = ({
  oldPassword,
  password,
  passwordConfirmation
}) => {
  const errors = {};
  if (!oldPassword) {
    errors.oldPassword = "* Required";
  }

  if (!password) {
    errors.password = "* Required";
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = "* Required";
  } else if (passwordConfirmation !== password) {
    errors.passwordConfirmation = "* No Match";
  }
  return errors;
};
