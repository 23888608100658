/**
 * Checks is value empty, null or undefined
 * If value is array, checks is length of value greater of zero
 * @param value - The value it checks
 * @returns Is value empty
 */
export const isEmpty = value => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "null" ||
    value === "undefined"
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};

export const hasProperties = object => {
  if (isEmpty(Object.values(object))) {
    return false;
  }
  return true;
};

/**
 * Updates object with updatedValues
 * @param oldObject - object
 * @param updatedValues - any
 * @returns object with updated values
 */
export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues
  };
};
