export class ManagerFormModel {
  constructor(data = {}) {
    this.email = data?.email || "";
    this.firstName = data?.firstName || "";
    this.lastName = data?.lastName || "";
    this.contactNumber = data?.contactNumber || "";
    this.permissions = data?.permissions || [];
    this.branches = data?.branches || [];
  }
}
