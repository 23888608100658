import ReactTooltip from "react-tooltip";
// styles
import { IconTextTooltipStyles } from "./iconTextTooltip.styles";

const IconTextTooltip = ({
  Icon,
  text,
  isTooltipVisible,
  isTextVisible,
  tooltipContent,
  tooltipUpperContent,
  style,
  id
}) => {
  return (
    <IconTextTooltipStyles style={{ ...style }}>
      <button
        className="iconContainer"
        style={{ cursor: isTooltipVisible && "pointer" }}
        data-tip
        data-for={id}
      >
        <Icon />
      </button>
      {isTextVisible && <p className="text">{text}</p>}
      {isTooltipVisible && (
        <ReactTooltip
          className="tooltip"
          id={id}
          aria-haspopup="true"
          role="example"
        >
          {tooltipUpperContent && (
            <p className="tooltipText">{tooltipUpperContent}</p>
          )}
          <p className="tooltipText">{tooltipContent}</p>
        </ReactTooltip>
      )}
    </IconTextTooltipStyles>
  );
};

export default IconTextTooltip;
