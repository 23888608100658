import { memo } from "react";

// Components
import { Label, Paragraph } from "../../typography";

// Builders
import { serviceTypeCardBuilder } from "../../../builders/serviceTypeCard.builder";

// Styles
import { ServiceTypeFormCardStyles } from "./serviceTypeFormCard.styles";

const ServiceTypeFormCard = ({
  id,
  serviceType,
  handleCapactyChange,
  className,
  value,
  handleCheckBoxChange
}) => {
  const isActive = value?.mobility;
  const { Icon, name } = serviceTypeCardBuilder(serviceType.typeKey);
  return (
    <ServiceTypeFormCardStyles
      className={className}
      isActive={isActive}
      onClick={handleCheckBoxChange}
    >
      <div className="iconAndCheckboxContainer">
        {Icon}
        <div className="checkboxesContainer">
          <Paragraph className="serviceTypeName">{name}</Paragraph>
        </div>
      </div>
      <div>
        <Label style={{ color: isActive ? "#172B4D" : "#a4a5aa80" }}>
          Capacity
        </Label>
        <div style={{ marginTop: 2 }}>
          <input
            disabled={!isActive}
            pattern="[0-9]+"
            onClick={e => e.stopPropagation()}
            onChange={e => handleCapactyChange(e, serviceType.id)}
            value={value ? value.capacity : ""}
            className="capacityInput"
          />
        </div>
      </div>
    </ServiceTypeFormCardStyles>
  );
};

export default memo(ServiceTypeFormCard);
