import { useState } from "react";

// Components
import { Body, Header } from "../../../../UI";

// Containers
import WorkingDays from "../../../../../containers/forms/generalBranchSettingsForms/Schedule/WorkingDays/WorkingDays";
import SpecialDays from "../../../../../containers/forms/generalBranchSettingsForms/Schedule/SpecialDays/SpecialDays";

// Constants
import * as scheduleTabs from "../../../../../constants/schdule.constants";

// Styles
import { ManageScheduleStyles } from "./manageSchedule.styles";

const ManageSchedule = ({ branchId }) => {
  const [activeTab, setActiveTab] = useState(scheduleTabs.WORKING_DAYS);
  return (
    <ManageScheduleStyles>
      <Header className="header">
        <div
          onClick={() => setActiveTab(scheduleTabs.WORKING_DAYS)}
          className={`scheduleTabs ${
            activeTab === scheduleTabs.WORKING_DAYS && "activeScheduleTab"
          }`}
        >
          Working Days
        </div>
        <div
          onClick={() => setActiveTab(scheduleTabs.SPECIAL_DAYS)}
          className={`scheduleTabs ${
            activeTab === scheduleTabs.SPECIAL_DAYS && "activeScheduleTab"
          }`}
        >
          Special Days
        </div>
      </Header>
      <Body className="body">
        {activeTab === scheduleTabs.WORKING_DAYS && (
          <WorkingDays branchId={branchId} />
        )}
        {activeTab === scheduleTabs.SPECIAL_DAYS && (
          <SpecialDays branchId={branchId} />
        )}
      </Body>
    </ManageScheduleStyles>
  );
};

export default ManageSchedule;
