import { apiService } from "../api.service";

// Requests
import {
  BranchGeneralSettingsRequest,
  BranchServiceTypesRequest,
  WorkDaysRequest,
  SpecialDayRequest,
  ServiceMenuRequest,
  RoleRequest,
  StaffMemberRequest
} from "../../models/api/requests";

// Responses
import {
  BranchResponse,
  BranchServiceTypesResponse,
  WorkDaysResponse,
  SpecialDaysResponse,
  SpecialDayResponse,
  ServiceMenuResponse,
  RolesResponse,
  StaffMemebersResponse,
  MessageResponse
} from "../../models/api/responses";

/**
 * Fetch branch general settings Request
 * @param headerBranchId - id
 * @return - BranchResponse || ErrorResponse
 */
export const fetchBranchGeneralSettings = async headerBranchId => {
  const response = await apiService.get(
    "/branches/settings?with_images=true",
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new BranchResponse(response);
};

/**
 * Update branch general settings Request
 * @return - BranchResponse || ErrorResponse
 */
export const updateBranchGeneralSettingsRequest = async (
  branchGeneralSettingsPayload,
  initialValues,
  headerBranchId
) => {
  const payload = new BranchGeneralSettingsRequest(
    branchGeneralSettingsPayload
  );
  payload.removeSameValues(initialValues);
  const response = await apiService.patch(
    `/branches/settings`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new BranchResponse(response);
};

/**
 * Branch Activation Request
 * @return - BranchResponse || ErrorResponse
 */
export const branchActivationRequest = async (
  branchActivationPayload,
  headerBranchId
) => {
  const payload = { is_active: branchActivationPayload.isActive };
  const response = await apiService.post(
    `/branches/activate`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return { isActive: response.data.is_active };
};

/**
 * Fetch branch service types and capacity Request
 * @param headerBranchId - id
 * @return - BranchServiceTypesResponse || ErrorResponse
 */
export const fetchBranchServiceTypes = async headerBranchId => {
  const response = await apiService.get(
    "/branches/service-types",
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new BranchServiceTypesResponse(response);
};

/**
 * Update branch service types and capacity Request
 * @param headerBranchId - id
 * @return - BranchServiceTypesResponse || ErrorResponse
 */
export const updateBranchServiceTypes = async (formValues, headerBranchId) => {
  const payload = new BranchServiceTypesRequest(formValues);
  const response = await apiService.post(
    "/branches/service-types",
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new BranchServiceTypesResponse(response);
};

/**
 * Fetch branch work days Request
 * @param headerBranchId - id
 * @return - WorkDaysResponse || ErrorResponse
 */
export const fetchWorkDays = async headerBranchId => {
  const response = await apiService.get(
    "/branches/schedule/work-days",
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new WorkDaysResponse(response);
};

/**
 * Update branch work days Request
 * @param headerBranchId - id
 * @return - WorkDaysResponse || ErrorResponse
 */
export const updateWorkDays = async (formValues, headerBranchId) => {
  const payload = new WorkDaysRequest(formValues);
  const response = await apiService.post(
    "/branches/schedule/work-days",
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new WorkDaysResponse(response);
};

/**
 * Fetch branch special days Request
 * @param headerBranchId - id
 * @return - SpecialDaysResponse || ErrorResponse
 */
export const fetchSpecialDays = async headerBranchId => {
  const response = await apiService.get(
    "/branches/schedule/special-days",
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new SpecialDaysResponse(response);
};

/**
 * Create branch special day Request
 * @param headerBranchId - id
 * @return - SpecialDayResponse || ErrorResponse
 */
export const createSpecialDayRequest = async (
  specialDayFormValues,
  headerBranchId
) => {
  const payload = new SpecialDayRequest(specialDayFormValues);
  const response = await apiService.post(
    "/branches/schedule/special-days",
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new SpecialDayResponse(response);
};

/**
 * Delete branch special day Request
 * @param headerBranchId - id
 * @return - MessageResponse || ErrorResponse
 */
export const deleteSpecialDayRequest = async (specialDayId, headerBranchId) => {
  const response = await apiService.delete(
    `/branches/schedule/special-days/${specialDayId}`,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Fetch service-menu Request
 * @param headerBranchId - id
 * @param queryParam - string
 * @return - ServiceMenuResponse || ErrorResponse
 */
export const fetchServiceMenuRequest = async (headerBranchId, queryParam) => {
  let query = "";
  if (queryParam) {
    query = `?service_type=${queryParam}`;
  }
  const response = await apiService.get(
    `/branches/service-menu${query}`,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new ServiceMenuResponse(response);
};

/**
 * Create service  Request
 * @param headerBranchId - id
 * @param serviceMenuFormValues - string
 * @return - ServiceMenuResponse || ErrorResponse
 */
export const createServiceMenuRequest = async (
  headerBranchId,
  serviceMenuFormValues
) => {
  const payload = new ServiceMenuRequest(serviceMenuFormValues);
  const response = await apiService.post(
    `/branches/service-menu`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new ServiceMenuResponse(response);
};

/**
 * Fetch service-menu Request
 * @param headerBranchId - id
 * @param serviceMenuFormValues - string
 * @return - ServiceMenuResponse || ErrorResponse
 */
export const updateServiceMenuRequest = async (
  headerBranchId,
  serviceMenuFormValues,
  serviceMenuInitial,
  serviceId
) => {
  const payload = new ServiceMenuRequest(serviceMenuFormValues);
  payload.removeSameValues(serviceMenuInitial);
  const response = await apiService.patch(
    `/branches/service-menu/${serviceId}`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new ServiceMenuResponse(response);
};

/**
 * Delete service-menu Request
 * @param serviceId - id
 * @return - ServiceMenuResponse || ErrorResponse
 */
export const deleteServiceMenuRequest = async (headerBranchId, serviceId) => {
  const response = await apiService.delete(
    `/branches/service-menu/${serviceId}`,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new ServiceMenuResponse(response);
};

/**
 * Fetch roles Request
 * @param headerBranchId - id
 * @return - RolesResponse || ErrorResponse
 */
export const fetchRolesRequest = async headerBranchId => {
  const response = await apiService.get(`/roles`, headerBranchId);
  if (response.hasError) {
    return response;
  }
  return new RolesResponse(response);
};

/**
 * Create role Request
 * @param headerBranchId - id
 * @return - RolesResponse || ErrorResponse
 */
export const createRoleRequest = async (headerBranchId, roleFormValues) => {
  const payload = new RoleRequest(roleFormValues);
  const response = await apiService.post(`/roles`, payload, headerBranchId);
  if (response.hasError) {
    return response;
  }
  return new RolesResponse(response);
};

/**
 * Update role Request
 * @param headerBranchId - id
 * @return - RolesResponse || ErrorResponse
 */
export const updateRoleRequest = async (
  headerBranchId,
  roleFormValues,
  roleId
) => {
  const payload = new RoleRequest(roleFormValues);
  const response = await apiService.put(
    `/roles/${roleId}`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new RolesResponse(response);
};

/**
 * Delete role Request
 * @param roleId - id
 * @return - RolesResponse || ErrorResponse
 */
export const deleteRoleRequest = async (headerBranchId, roleId) => {
  const response = await apiService.delete(`/roles/${roleId}`, headerBranchId);
  if (response.hasError) {
    return response;
  }
  return new RolesResponse(response);
};

/**
 * Fetch staff memebers Request
 * @param headerBranchId - id
 * @return - StaffMemebersResponse || ErrorResponse
 */
export const fetchStaffMembersRequest = async headerBranchId => {
  const response = await apiService.get(`branches/staff`, headerBranchId);
  if (response.hasError) {
    return response;
  }
  return new StaffMemebersResponse(response);
};

/**
 * Create staff member Request
 * @param headerBranchId - id
 * @return - StaffMemebersResponse || ErrorResponse
 */
export const createStaffMemberRequest = async (
  headerBranchId,
  staffMemberValues
) => {
  const payload = new StaffMemberRequest(staffMemberValues);
  const response = await apiService.post(
    `branches/staff`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new StaffMemebersResponse(response);
};

/**
 * Update staff member Request
 * @param headerBranchId - id
 * @param serviceMenuFormValues - string
 * @return - ServiceMenuResponse || ErrorResponse
 */
export const updateStaffMemberRequest = async (
  headerBranchId,
  staffMemberValues,
  initialValues,
  staffMemberId
) => {
  const payload = new StaffMemberRequest(staffMemberValues);
  payload.removeSameValues(initialValues);
  const response = await apiService.patch(
    `branches/staff/${staffMemberId}`,
    payload,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new StaffMemebersResponse(response);
};

/**
 * Delete staff member Request
 * @param staffMemberId - id
 * @return - StaffMemebersResponse || ErrorResponse
 */
export const deleteStaffMemberRequest = async (
  headerBranchId,
  staffMemberId
) => {
  const response = await apiService.delete(
    `branches/staff/${staffMemberId}`,
    headerBranchId
  );
  if (response.hasError) {
    return response;
  }
  return new StaffMemebersResponse(response);
};
