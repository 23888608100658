import { useCallback } from "react";
import useServiceRequestHandler from "./useServiceRequestHandler";
import useSocket from "./useSocket";

export default function useNotifications() {
  const { handleServiceRequest } = useServiceRequestHandler();
  const handleNewNotification = useCallback(
    ({ data, type }) => {
      const notificationHandlers = {
        service_requests: handleServiceRequest
      };

      const handler = notificationHandlers[type];
      if (handler) {
        handler(data);
      } else {
        console.warn(`Unhandled notification type: ${type}`);
      }
    },
    [handleServiceRequest]
  );

  useSocket(handleNewNotification);
}
