// components
import { Button } from "../../UI";
import { Paragraph } from "../../typography";
// icons
import { Trash } from "../../../SVGS";
// builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";
// styles
import styled from "styled-components";

const StaffManagementItemStyles = styled.div`
  padding: 8.5px 24px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e2ea;
  margin-top: -1px;
`;

const StaffManagementItem = ({
  editAction,
  deleteAction,
  firstName,
  lastName,
  roles,
  contactNumber,
  email
}) => {
  return (
    <StaffManagementItemStyles>
      <Paragraph className="name">{`${firstName} ${lastName}`}</Paragraph>
      <Paragraph className="role">
        {roles.length > 0 ? roles[0].name : ""}
      </Paragraph>
      <Paragraph className="contact">{contactNumber}</Paragraph>
      <Paragraph className="email">{email}</Paragraph>
      <div className="actions">
        <Button onClick={editAction}>Edit</Button>
        <Button
          onClick={deleteAction}
          className="deleteButton"
          buttonDesignType={buttonDesignType.DANGER}
        >
          <Trash />
        </Button>
      </div>
    </StaffManagementItemStyles>
  );
};

export default StaffManagementItem;
