// Domain Models
import { Driver, Truck, Trailer, Worker, Location, Review } from ".";

// Utils
import { isEmpty } from "../../../utils";
import { convertTimeStampToTime } from "../../../utils/timeStampToDate";

export class EmployeeScheduleService {
  constructor(service = {}) {
    this.id = service.id;
    this.serviceTypeId = service.service_type_id || service.serviceTypeId;
    this.mobility = service.mobility;
    this.truckId = service.truck_id || service.truckId;
    this.trailerId = service.trailer_id || service.trailerId;
    this.status = service.status;
    this.time =
      typeof service.time !== "string"
        ? convertTimeStampToTime(service.time)
        : service.time;
    this.timeDate = new Date(service.time * 1000).getDate();
    this.eta =
      typeof service.time !== "string"
        ? convertTimeStampToTime(service.eta)
        : service.eta;
    this.etaDate = new Date(service.eta * 1000).getDate();
    this.phoneNumber = service.phone_number || service.phoneNumber;
    this.specialRequest = service.special_request || service.specialRequest;
    this.driverId = service.driver_id || service.driverId;
    this.creatorId = service.creator_id || service.creatorId;
    this.fleetId = service.fleet_id || service.fleetId;
    this.branchId = service.branch_id || service.branchId;
    this.businessId = service.business_id || service.businessId;
    this.declineReason = service.decline_reason || service.declineReason;
    this.createdAt = service.created_at || service.createdAt;
    this.updatedAt = service.updated_at || service.updatedAt;
    this.driver = !isEmpty(service.driver)
      ? new Driver(service.driver)
      : undefined;
    this.serviceTypeKey = service.service_type_key || service.serviceTypeKey;
    this.truck = !isEmpty(service.truck) ? new Truck(service.truck) : undefined;
    this.trailer = !isEmpty(service.trailer)
      ? new Trailer(service.trailer)
      : undefined;
    this.workers = !isEmpty(service.workers)
      ? service.workers.map(worker => new Worker(worker))
      : [];
    this.location = !isEmpty(service.location)
      ? new Location(service.location)
      : undefined;
    this.review = !isEmpty(service.review)
      ? new Review(service.review)
      : undefined;
    this.startIndex = service.startIndex || undefined;
    this.finishIndex = service.finishIndex || undefined;
    this.length = service.length || 0;
    this.hasMore = service.hasMore || null;
    this.isActive = service.isActive || false;
  }
}
