/**
 * Returns value from localStorage under key name
 * @param key - string
 * @returns any value that is stored under key name
 */
export const getItem = key => {
  const storageValue = localStorage.getItem(key);
  if (storageValue) {
    return JSON.parse(storageValue);
  }
};

/**
 * Sets value to localStorage under key name
 * @param key - string
 * @param value - any
 */
export const setItem = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Removes value from localStorage based on key
 * @param key - string
 */
export const removeItem = key => {
  return localStorage.removeItem(key);
};
