// SVGS
import { CircledBell, AddPresetIcon, Close } from "../../../SVGS";
// styled
import { NotificationPresetItemStyles } from "./notificationPresetItem.styles";

const NotificationPresetItem = ({
  isAddNewPreset = false,
  newPresetText,
  addNewPreset,
  removePreset,
  preset
}) => {
  return (
    <NotificationPresetItemStyles isAddNewPreset={isAddNewPreset}>
      <div className="bellContainer">
        {isAddNewPreset ? <AddPresetIcon /> : <CircledBell />}
      </div>
      {isAddNewPreset ? (
        <p className="newPresetText" onClick={addNewPreset}>
          Add "
          <span
            className="presetText"
            style={{ color: !newPresetText && "#b4b4b4" }}
          >
            {newPresetText ? newPresetText : "Some text"}
          </span>
          " as new preset.
        </p>
      ) : (
        <>
          <p className="presetText">{preset}</p>
          <Close onClick={removePreset} />
        </>
      )}
    </NotificationPresetItemStyles>
  );
};

export default NotificationPresetItem;
