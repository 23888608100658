import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

// Components
import Message from "./Message";
import Notification from "./Notification";

// Calls
import { fetchChatMessagesRequest } from "../../api/calls/chat.calls";

// Styles
import styled from "styled-components";

const ChatBoxStyles = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
`;

const ChatBox = ({ serviceId }) => {
  const { user } = useSelector(state => state.app);
  const { newMessage } = useSelector(state => state.chatReducer);
  const [chatBoxState, setChatBoxState] = useState({
    messages: [],
    hasMore: true,
    lastMessageId: undefined
  });

  const { messages, lastMessageId } = chatBoxState;

  const scrollToBottom = () => {
    const chatBox = document.getElementById("scrollableDiv");
    chatBox.scrollTop = chatBox.scrollHeight;
  };

  const getInitialMessages = useCallback(async () => {
    const response = await fetchChatMessagesRequest(serviceId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }

    setChatBoxState(prevState => ({
      ...prevState,
      messages: response.messages,
      hasMore: response.pagination.hasMore,
      lastMessageId: response.messages[response.messages.length - 1]?.id
    }));
    scrollToBottom();
  }, [serviceId]);

  useEffect(() => {
    getInitialMessages();
  }, [getInitialMessages]);

  const getAdditionalMessages = async () => {
    const response = await fetchChatMessagesRequest(serviceId, lastMessageId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }

    setChatBoxState(prevState => ({
      ...prevState,
      messages: [...response.messages, ...prevState.messages],
      hasMore: response.pagination.hasMore,
      lastMessageId: response.messages[response.messages.length - 1]?.id
    }));
  };

  useEffect(() => {
    if (newMessage) {
      setChatBoxState(prevState => ({
        ...prevState,
        messages: [...prevState.messages, newMessage]
      }));
    }
  }, [newMessage]);

  const modifiedMessages = (allMessages = messages) => {
    let groupedMessages = [];
    const dates = allMessages
      .sort((message1, message2) => message1.createdAt - message2.createdAt)
      .map(message => message.date);
    const uniqueDates = [...new Set(dates)];
    groupedMessages = uniqueDates.map(date => {
      const groupedMessages = [];
      allMessages.forEach(message => {
        if (message.date === date) {
          groupedMessages.push(message);
        }
      });
      const sortedMessages = groupedMessages.sort(
        (message1, message2) => message1.createdAt - message2.createdAt
      );
      return { date: date, messages: sortedMessages };
    });

    return groupedMessages;
  };

  return (
    <ChatBoxStyles id="scrollableDiv">
      <InfiniteScroll
        dataLength={messages.length}
        hasMore={true}
        // height={800}
        next={getAdditionalMessages}
        scrollThreshold={0.5}
        inverse={true}
        scrollableTarget="scrollableDiv"
      >
        {modifiedMessages().map((group, i) => (
          <div key={i}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0"
              }}
            >
              <hr style={{ width: "100%" }} />
              <p>{group.date}</p>
              <hr style={{ width: "100%" }} />
            </div>
            {group.messages.map(message => {
              switch (message.type) {
                case "text":
                  return (
                    <Message
                      key={message.id}
                      isMyMessage={message.sender.id === user.businessId}
                      text={message.body}
                      time={message.time}
                    />
                  );
                case "system":
                  return <Notification key={message.id} text={message.body} />;

                default:
                  return null;
              }
            })}
          </div>
        ))}
      </InfiniteScroll>
    </ChatBoxStyles>
  );
};

export default ChatBox;
