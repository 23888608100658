import * as analyticsConstants from "../../../../../constants/analytics.constants";

export const branches = [
  {
    label: "SIRUS CYBERNATICS CO.",
    value: 1
  },
  {
    label: "TruckShop.CO",
    value: 2
  }
];

export const timeFrames = [
  {
    label: "Any",
    value: analyticsConstants.ANY
  },
  {
    label: "Before selected date",
    value: analyticsConstants.BEFORE_SELECTED_DATE
  },
  {
    label: "After selected date",
    value: analyticsConstants.AFTER_SELECTED_DATE
  },
  {
    label: "Selected date range",
    value: analyticsConstants.SELECTED_DATE_RANGE
  }
];
