// styles
import { CheckboxButtonsStyles } from "./checkboxButtons.styles";

const CheckboxButtons = ({
  className,
  style,
  yesText,
  noText,
  value,
  name,
  handleChange
}) => {
  return (
    <CheckboxButtonsStyles className={className} style={{ ...style }}>
      <button
        onClick={() => handleChange(name, true)}
        type="button"
        className={`checkboxButton ${value && "active"}`}
      >
        {yesText}
      </button>
      <button
        type="button"
        onClick={() => handleChange(name, false)}
        className={`checkboxButton ${!value && "active"}`}
      >
        {noText}
      </button>
    </CheckboxButtonsStyles>
  );
};

export default CheckboxButtons;
