export const serviceRequestsTableHeadBuilder = [
  {
    className: "ID",
    title: "ID"
  },
  {
    className: "user",
    title: "User"
  },
  {
    className: "serviceType",
    title: "Service Type"
  },
  {
    className: "bookedFor",
    title: "Booked For"
  },
  {
    className: "messages",
    title: ""
  },
  {
    className: "actions",
    title: ""
  }
];
