// Action types
import * as actionTypes from "../actionTypes/service-requests.types";

// Utils
import { updateObject } from "../../utils/global.utils";

// Constants
import * as modalTypes from "../../constants/serviceRequests.constants";
import { removeItem, setItem } from "../../utils";
import { OPENED_SERVICE_REQUEST_DETAILS_BRANCH } from "../../constants/localStorage/localStorage.constants";
import { Service } from "../../models/app/domain";

const initialState = {
  requests: [],
  selectedRequest: undefined,
  requestId: undefined,
  modalType: undefined,
  tiketDetailsModalType: undefined
};

export const serviceRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_REQUESTS_SUCCESS:
      return setServiceRequests(state, action);
    case actionTypes.ADD_NEW_SERVICE_REQUEST:
      return addNewServiceRequest(state, action);
    case actionTypes.APPROVE_SERVICE_REQUESTS_SUCCESS:
      return approveServiceRequest(state, action);
    case actionTypes.OPEN_DECLINE_REASON:
      return openDeclineReason(state, action);
    case actionTypes.OPEN_TICKET_DETAILS_SUCCESS:
      return openTicketDetails(state, action);
    case actionTypes.CLOSE_SERVICE_REQUESTS_MODAL:
      return closeModal(state, action);
    case actionTypes.DECLINE_SERVICE_REQUESTS_SUCCESS:
      return declineServiceRequestSuccess(state, action);
    case actionTypes.UPDATE_SERVICE_REQUEST_SUCCESS:
      return updateServiceRequest(state, action);
    case actionTypes.SET_TIKET_DETAILS_MODAL:
      return setTiketDetailsModalType(state, action);
    case actionTypes.SET_NEW_MESSAGE_IN_SERVICE_REQUEST:
      return setNewMessageInServiceRequest(state, action);
    case actionTypes.SERVICES_REQUESTS_SEE_NEW_MESSAGES:
      return seeNewMessages(state, action);
    default:
      return state;
  }
};

const setServiceRequests = (state, { requests }) => {
  return updateObject(state, {
    requests
  });
};

const addNewServiceRequest = (state, { serviceRequest }) => {
  return updateObject(state, {
    requests: [new Service(serviceRequest), ...state.requests]
  });
};

const approveServiceRequest = (state, { serviceId }) => {
  return updateObject(state, {
    requests: state.requests.filter(request => request.id !== serviceId),
    modalType: undefined
  });
};

const closeModal = (state, action) => {
  removeItem(OPENED_SERVICE_REQUEST_DETAILS_BRANCH);
  return updateObject(state, {
    selectedRequest: undefined,
    modalType: undefined,
    requestId: undefined
  });
};

const openTicketDetails = (state, { serviceRequest }) => {
  setItem(OPENED_SERVICE_REQUEST_DETAILS_BRANCH, serviceRequest.branchId);
  return updateObject(state, {
    selectedRequest: serviceRequest,
    modalType: modalTypes.TIKET_DETAILS
  });
};

const openDeclineReason = (state, { serviceRequestId, branchId }) => {
  return updateObject(state, {
    requestId: serviceRequestId,
    modalType: modalTypes.DECLINE,
    branchId
  });
};

const updateServiceRequest = (state, { serviceRequest }) => {
  return updateObject(state, {
    selectedRequest: serviceRequest,
    requests: state.requests.map(request =>
      request.id === serviceRequest.id ? serviceRequest : request
    ),
    tiketDetailsModalType: undefined
  });
};

const declineServiceRequestSuccess = (state, { serviceId }) => {
  return updateObject(state, {
    requests: state.requests.filter(request => request.id !== serviceId),
    requestId: undefined,
    modalType: undefined,
    selectedRequest: undefined
  });
};

const setTiketDetailsModalType = (state, { tiketDetailsModalType }) => {
  return updateObject(state, {
    tiketDetailsModalType
  });
};

const setNewMessageInServiceRequest = (state, { serviceId }) => {
  return updateObject(state, {
    requests: state.requests.map(request =>
      request.id === serviceId
        ? {
            ...request,
            newMessagesCount: request.newMessagesCount + 1
          }
        : request
    ),
    selectedRequest: state.selectedRequest && {
      ...state.selectedRequest,
      newMessagesCount: state.selectedRequest.newMessagesCount + 1
    }
  });
};

const seeNewMessages = (state, { serviceId }) => {
  return updateObject(state, {
    requests: state.requests.map(request =>
      request.id === serviceId
        ? { ...request, newMessagesCount: 0, isSeen: true }
        : request
    ),
    selectedRequest: state.selectedRequest && {
      ...state.selectedRequest,
      newMessagesCount: 0,
      isSeen: true
    }
  });
};
