const MapMarker = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="32"
      fill="none"
      viewBox="0 0 22 32"
    >
      <path
        fill="#EE3A36"
        stroke="#B0221F"
        d="M11 30.671l-.094-.108a72.57 72.57 0 01-4.992-6.534c-1.365-2.025-2.723-4.294-3.739-6.561C1.157 15.195.5 12.961.5 11 .5 5.195 5.195.5 11 .5S21.5 5.195 21.5 11c0 1.961-.657 4.195-1.675 6.468-1.015 2.267-2.374 4.536-3.74 6.56A72.573 72.573 0 0111 30.671zM6.571 11A4.43 4.43 0 0011 15.429 4.43 4.43 0 0015.429 11 4.43 4.43 0 0011 6.571 4.43 4.43 0 006.571 11z"
      ></path>
    </svg>
  );
};

export default MapMarker;
