// components
import { useState } from "react";
import NotificationPresetItem from "../../components/lists/chat/NotificationPreset.item";
import { setItem, getItem, removeItem } from "../../utils";

// constants
import { NOTIFICATION_PRESETS } from "../../constants/localStorage/localStorage.constants";

// utils
import { isEmpty } from "../../utils";

// styled
import { NotificationPresetStyles } from "./notificationPresets.styles";
import { toast } from "react-toastify";

const NotificationPreset = ({ newPresetText, setNewPresetText }) => {
  const [notificationsPresets, setNotificationPresets] = useState(
    getItem(NOTIFICATION_PRESETS)
  );

  const addNewPreset = () => {
    if (notificationsPresets && notificationsPresets.length >= 10) {
      toast.error("You can have maximum 10 presets");
      return;
    }

    if (isEmpty(newPresetText)) {
      toast.error("Preset cannot be empty.");
      return;
    }
    setNotificationPresets(
      notificationsPresets
        ? [newPresetText, ...notificationsPresets]
        : [newPresetText]
    );
    setItem(
      NOTIFICATION_PRESETS,
      notificationsPresets
        ? [newPresetText, ...notificationsPresets]
        : [newPresetText]
    );
    setNewPresetText("");
  };

  const removePreset = index => {
    const updatePresets = notificationsPresets.filter(
      (preset, i) => i !== index
    );

    if (isEmpty(updatePresets)) {
      setNotificationPresets(undefined);
      removeItem(NOTIFICATION_PRESETS);
      return;
    }
    setNotificationPresets(updatePresets);
    setItem(NOTIFICATION_PRESETS, updatePresets);
  };
  return (
    <NotificationPresetStyles>
      <h5 className="notTitle">preset notifications</h5>
      {notificationsPresets ? (
        <div className="presetsContainer">
          {notificationsPresets.map((preset, i) => (
            <NotificationPresetItem
              preset={preset}
              key={i}
              removePreset={() => removePreset(i)}
            />
          ))}
        </div>
      ) : (
        <div className="presetsContainer">
          <div className="presetPlaceholder">
            <h3 className="title">
              Add new notification preset you can have maximum 10
            </h3>
          </div>
        </div>
      )}

      <NotificationPresetItem
        isAddNewPreset
        newPresetText={newPresetText}
        addNewPreset={addNewPreset}
      />
    </NotificationPresetStyles>
  );
};

export default NotificationPreset;
