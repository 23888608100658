import { combineReducers } from "redux";
import { usersReducer } from "../reducers/users.reducer";
import { appReducer } from "../reducers/app.reducer";
import { businessProfileReducer } from "../reducers/business.reducer";
import { managersReducer } from "../reducers/managers.reducer";
import { serviceRequestsReducer } from "../reducers/service-requests.reducer";
import { servicesReducer } from "../reducers/services.reducer";
import { chatReducer } from "../reducers/chat.reducer";
import { serviceCommentsReducer } from "../reducers/services-comments.reducer";

const rootReducer = combineReducers({
  app: appReducer,
  businessProfile: businessProfileReducer,
  managersPage: managersReducer,
  serviceRequestsPage: serviceRequestsReducer,
  usersReducers: usersReducer,
  servicesReducer: servicesReducer,
  chatReducer: chatReducer,
  serviceCommentsReducer: serviceCommentsReducer
});

export default rootReducer;
