import { useSelector } from "react-redux";

// components
import { Label } from "../../../../typography";
import { CheckboxButton, Select, DatePicker } from "../../../../UI";

// Constants
import * as analyticsContants from "../../../../../constants/analytics.constants";

// Filter options
import { timeFrames } from "./filtersOptions";

// Utils
import { isEmpty } from "../../../../../utils";

// Styles
import { FiltersStyles } from "./filters.styles";

const Filters = ({ filters, setAnalyticsState }) => {
  const { branches } = useSelector(state => state.app);
  const branchesOptions = branches.map(branch => ({
    value: branch.id,
    label: branch.name
  }));
  const handleDateChange = (name, date) => {
    switch (name) {
      case "from":
        setAnalyticsState(prevValue => ({
          ...prevValue,
          filters: {
            ...prevValue.filters,
            dateFrom: date
          }
        }));
        break;
      case "to":
        setAnalyticsState(prevValue => ({
          ...prevValue,
          filters: {
            ...prevValue.filters,
            dateTo: date
          }
        }));
        break;

      default:
        break;
    }
  };

  return (
    <FiltersStyles>
      <div className="inputsContainer">
        <Label>Select a Sample From</Label>
        <div className="checkboxesContainer">
          <CheckboxButton
            className="checkboxButton"
            handleChange={() =>
              setAnalyticsState(prevValue => ({
                ...prevValue,
                filters: {
                  ...prevValue.filters,
                  branchFilter: analyticsContants.ALL_BRANCHES,
                  branchId: undefined,
                  timeFrame: "",
                  dateFrom: null,
                  dateTo: null
                }
              }))
            }
            value={filters.branchFilter === analyticsContants.ALL_BRANCHES}
            text="All Branches"
          />
          <CheckboxButton
            className="checkboxButton"
            handleChange={() =>
              setAnalyticsState(prevValue => ({
                ...prevValue,
                filters: {
                  ...prevValue.filters,
                  branchFilter: analyticsContants.SELECTED_BRANCH
                }
              }))
            }
            value={filters.branchFilter === analyticsContants.SELECTED_BRANCH}
            text="Selected Branch"
          />
        </div>
      </div>
      <div className="inputsContainer">
        <Label>Select a Branch</Label>
        <div className="select">
          <Select
            value={filters.branchId}
            options={branchesOptions}
            labelKey="label"
            valueKey="value"
            onChange={option =>
              setAnalyticsState(prevValue => ({
                ...prevValue,
                filters: {
                  ...prevValue.filters,
                  branchName: option[0]?.label,
                  branchId: option[0]?.value
                }
              }))
            }
            disabled={filters.branchFilter === analyticsContants.ALL_BRANCHES}
            shouldHaveError={false}
          />
        </div>
      </div>
      <div className="inputsContainer">
        <Label>Select a Time Frame</Label>
        <div className="select">
          <Select
            value={filters.timeFrame}
            labelKey="label"
            valueKey="value"
            onChange={option => {
              setAnalyticsState(prevValue => ({
                ...prevValue,
                filters: {
                  ...prevValue.filters,
                  timeFrame: option[0]?.value,
                  dateFrom: null,
                  dateTo: null
                }
              }));
            }}
            options={timeFrames}
            shouldHaveError={false}
            disabled={
              filters.branchFilter === analyticsContants.ALL_BRANCHES ||
              isEmpty(filters.branchId)
            }
          />
        </div>
      </div>

      <div className="dateContainer inputsContainer">
        {(filters.timeFrame === analyticsContants.SELECTED_DATE_RANGE ||
          filters.timeFrame === analyticsContants.AFTER_SELECTED_DATE) && (
          <div>
            <Label>From</Label>
            <div className="select">
              <DatePicker
                value={filters.dateFrom}
                name="from"
                className="input"
                placeholder="Select Date"
                onChange={handleDateChange}
              />
            </div>
          </div>
        )}
        {(filters.timeFrame === analyticsContants.SELECTED_DATE_RANGE ||
          filters.timeFrame === analyticsContants.BEFORE_SELECTED_DATE) && (
          <div>
            <Label>To</Label>
            <div className="select">
              <DatePicker
                value={filters.dateTo}
                name="to"
                className="input"
                placeholder="Select Date"
                onChange={handleDateChange}
              />
            </div>
          </div>
        )}
      </div>
    </FiltersStyles>
  );
};

export default Filters;
