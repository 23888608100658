import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Card, Header, Body, CentralModal, SideModal } from "../components/UI";
import { Heading2, Paragraph } from "../components/typography";
import Row from "../components/lists/Row";
import ServiceRequestItem from "../components/lists/serviceRequests/ServiceRequest.item";
import TiketDetails from "../components/TiketDetails/TiketDetails";

// Containers
import DeclineForm from "../containers/forms/serviceRequests/DeclineForm/Decline.form";
import Chat from "../containers/chat/Chat";

// Builders
import { serviceRequestsTableHeadBuilder } from "../builders/serviceRequests.builder";

// Constants
import * as modalTypes from "../constants/serviceRequests.constants";

// Actions
import {
  getServiceRequests,
  openDeclineReason,
  openTicketDetails,
  closeModals,
  setNewMessageToServiceRequest,
  seeNewMessages
} from "../redux/actions/service-requests.actions";
import { setNewChatMessage } from "../redux/actions/chat.actions";

// Services
import { disableScroll, enableScroll } from "../utils";

// Styles
import { ServiceRequestsStyles } from "../pagesStyles/serviceRequests.styles";
import useSupportRole from "../hooks/useSupportRole";

// const newRequests = [1, 2, 3];

const ServiceRequests = () => {
  const dispatch = useDispatch();
  const { activeBranch } = useSelector(state => state.app);
  const { requests, selectedRequest, modalType, tiketDetailsModalType } =
    useSelector(state => state.serviceRequestsPage);
  const { newMessage } = useSelector(state => state.chatReducer);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { isSupportRole } = useSupportRole();

  useEffect(() => {
    dispatch(getServiceRequests(isSupportRole));
  }, [activeBranch, isSupportRole, dispatch]);

  useEffect(() => {
    if (newMessage && !isChatOpen) {
      dispatch(
        setNewMessageToServiceRequest(parseInt(newMessage.serviceId, 0))
      );
      dispatch(setNewChatMessage(undefined));
    }
  }, [newMessage, isChatOpen, dispatch]);

  const toggleChat = serviceId => {
    dispatch(setNewChatMessage(undefined));
    dispatch(seeNewMessages(serviceId));
    setIsChatOpen(prevValue => !prevValue);
  };

  return (
    <ServiceRequestsStyles>
      <PageHeader title="Service Requests" />
      <Card className="card">
        <Header>
          <Heading2>SERVICE REQUESTS</Heading2>
        </Header>
        <Body className="tableHead">
          {serviceRequestsTableHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      {/* <div className="newRequestsContainer">
        <p className="newRequestsLabel">New Requests</p>
        <Card>
          <Body className="list">
            {newRequests.map(req => (
              <Row key={req} style={{ cursor: "pointer" }}>
                <ServiceRequestItem
                  onClick={() => {
                    disableScroll();
                    setModalType(modalTypes.TIKET_DETAILS);
                  }}
                  declineAction={e => {
                    e.stopPropagation();
                    disableScroll();
                    setModalType(modalTypes.DECLINE);
                  }}
                />
              </Row>
            ))}
          </Body>
        </Card>
      </div> */}

      <Card className="requests">
        <Body className="list">
          {requests.map(req => (
            <Row key={req.id} style={{ cursor: "pointer" }}>
              <ServiceRequestItem
                request={req}
                onClick={() => {
                  disableScroll();
                  dispatch(openTicketDetails(req.id, req.branchId));
                }}
                declineAction={e => {
                  e.stopPropagation();
                  disableScroll();
                  dispatch(openDeclineReason(req.id, req.branchId));
                }}
              />
            </Row>
          ))}
        </Body>
      </Card>

      {modalType === modalTypes.DECLINE && (
        <CentralModal
          className="modalCard"
          closeModal={() => {
            enableScroll();
            dispatch(closeModals());
          }}
        >
          <Card>
            <DeclineForm
              closeModal={() => {
                enableScroll();
                dispatch(closeModals());
              }}
            />
          </Card>
        </CentralModal>
      )}

      {modalType === modalTypes.TIKET_DETAILS && (
        <SideModal
          closeModal={() => {
            enableScroll();
            dispatch(closeModals());
            setIsChatOpen(false);
          }}
        >
          <TiketDetails
            serviceDetails={selectedRequest}
            toggleChat={() => toggleChat(selectedRequest.id)}
            closeModal={() => {
              enableScroll();
              dispatch(closeModals());
              setIsChatOpen(false);
            }}
            tiketDetailsModalType={tiketDetailsModalType}
          />
          {isChatOpen && <Chat service={selectedRequest} />}
        </SideModal>
      )}
    </ServiceRequestsStyles>
  );
};

export default ServiceRequests;
