import styled from "styled-components";

export const AnalyticsStyles = styled.section`
  min-height: calc(100vh - 42px);

  .headerCard {
    margin-top: 24px;
  }

  .filters {
    padding: 14px 24px 24px 24px;
  }

  .analyticsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    min-height: 500px;
    height: calc(100vh - 325px);
  }

  .analyticsEmpty {
    margin: auto;
  }
`;
