import styled from "styled-components";

export const ForgotPasswordFormStyles = styled.div`
  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    .submitButton {
      padding: 10px 17.5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #fafafb;
      border-radius: 6px;
    }
  }
`;
