import {
  TruckIcon,
  RoadIcon,
  TireIcon,
  TowingIcon,
  TrailerIcon
} from "../SVGS";
import * as serviceTypes from "../constants/serviceTypes.constants";

export const serviceTypeCardBuilder = type => {
  switch (type) {
    case serviceTypes.TRUCK_SHOP:
      return {
        Icon: <TruckIcon />,
        name: "Truck Shop"
      };
    case serviceTypes.ROAD_ASSISTANCE:
      return {
        Icon: <RoadIcon />,
        name: "Road Assistance"
      };
    case serviceTypes.TOWING:
      return {
        Icon: <TowingIcon />,
        name: "Towing"
      };
    case serviceTypes.TIRE_REPAIR:
      return {
        Icon: <TireIcon />,
        name: "Tire Repair"
      };
    case serviceTypes.TRAILER_REPAIR:
      return {
        Icon: <TrailerIcon />,
        name: "Trailer Repair"
      };
    case serviceTypes.TIRE_SHOP:
      return {
        Icon: <TireIcon />,
        name: "Tire Shop"
      };

    default:
      return {
        Icon: null,
        name: ""
      };
  }
};
