// Domain Model
import { File } from ".";

export class Business {
  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.taxId = data.tax_id;
    this.websiteUrl = data.website_url;
    this.verifiedBy = data.verified_by;
    this.verifiedAt = data.verified_at;
    this.declinedAt = data.declined_at;
    this.paymentInformation = data.payment_information;
    this.proofDocument = new File(data.proof_document);
    this.verificationStatus = data.verification_status;
  }
}
