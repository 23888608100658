import { memo } from "react";

const Close = ({ onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
      style={{ cursor: "pointer" }}
      className={className}
    >
      <path
        fill="#B5B5BE"
        d="M13.41 12l4.3-4.29a1.004 1.004 0 00-1.42-1.42L12 10.59l-4.29-4.3a1.004 1.004 0 10-1.42 1.42l4.3 4.29-4.3 4.29a.999.999 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1.001 1.001 0 001.639-.325 1 1 0 00-.22-1.095L13.41 12z"
      ></path>
    </svg>
  );
};

export default memo(Close);
