import styled from "styled-components";

export const DragAndDropUploadStyles = styled.div`
  .dragAndDropContainer {
    height: 124px;
    background: #fafbfc;
    border: 1px dashed #7fcafa;
    border-radius: 4px;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .text {
      color: #979797;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #979797;
    }

    .browseButton {
      position: absolute;
      bottom: 12px;
      right: 12px;
      background: #fafbfc;
      border: 1px solid #dfe1e6;
      border-radius: 6px;
      color: #172b4d;
      padding: 10px 12px;
    }
  }

  .imagesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;

    .imageCard {
      width: 220px;
      height: 136px;
      overflow: hidden;
      position: relative;
      background: gray;
      margin: 0 8px 8px 0;

      .prioIcon {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .deleteButton {
        position: absolute;
        top: 8px;
        right: 8px;

        &:hover path {
          fill: #ffffff;
        }
      }
    }
  }
`;
