import { forwardRef } from "react";
import DatePicker from "react-datepicker";

// UI
import { Button } from "..";
import { Label, Paragraph } from "../../typography";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Styles
import styled from "styled-components";

const EtaBookedForInputStyles = styled.div`
  .cardContainer {
    margin-bottom: 12px;

    .cardItem {
      border: 1px solid #dfe1e6;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 8px;
      margin-top: 2px;

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #181818;
      }

      .boldText {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #181818;
      }

      .estimateButton {
        padding: 8px 16px;
        border-radius: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .react-datepicker {
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
    /* max-height: 265px; */
    display: flex;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    border: 0.38rem solid transparent;
    height: 10px;
    width: 10px;
    top: 9px;
  }

  .react-datepicker__navigation--next {
    border-left-color: #171725;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #171725;
  }

  .react-datepicker__header {
    background: #fff;
  }

  .react-datepicker__month-container {
    float: unset;
  }

  .react-datepicker__time-container {
    float: unset;
  }

  .gPdmTZ .react-datepicker__time-container .react-datepicker__time {
    height: unset;
    overflow: unset;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: normal !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #497bb0;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal !important;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #497bb0;
    color: white;
    font-weight: normal;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    height: calc(195px + (1.7rem / 2)) !important;
    max-height: 265px;
  }

  .react-datepicker__header--time {
    height: 56.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    height: 100%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc !important;
  }

  .react-datepicker-wrapper {
    display: flex;
  }

  .react-datepicker__input-container {
    width: 100%;
  }
`;

const EtaBookedForInput = () => {
  const CustomInput = forwardRef(({ value, onClick, placeholderText }, ref) => (
    <div className="cardContainer">
      <Label>Booked For</Label>
      <div className="cardItem">
        <div>
          <Paragraph className="text">123:30PM</Paragraph>
          <Paragraph className="text" style={{ color: "#18181899" }}>
            07/07/2021
          </Paragraph>
        </div>
        {/* {!status && ( */}
        <div>
          <Button
            className="estimateButton"
            onClick={onClick}
            buttonDesignType={buttonDesignType.SECONDARY}
          >
            Change time
          </Button>
        </div>
        {/* )} */}
      </div>
    </div>
  ));

  return (
    <EtaBookedForInputStyles>
      <DatePicker
        className="timePicker"
        // selected={value}
        onChange={date => {}}
        showTimeSelect
        timeIntervals={30}
        customInput={<CustomInput placeholderText="Select date" />}
      />
    </EtaBookedForInputStyles>
  );
};

export default EtaBookedForInput;
