import styled from "styled-components";

export const BranchFormStyles = styled.div`
  .body {
    border-bottom: 1px solid #f1f1f5;
  }

  .input {
    margin-top: 2px;
  }

  .inputsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .md {
    width: 49%;
  }

  .serviceTypesLabelContainer {
    display: flex;
    align-items: center;

    .errorMessageServiceType {
      margin-left: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: normal;
      color: #ca2c2c;
    }
  }

  .serviceTypesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
  }

  .dragAndDropText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #8d8d94;
    margin: 2px 0 9px 0;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
