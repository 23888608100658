// styles
import { CheckboxButtonStyles } from "./checkboxButton.styles";

const CheckboxButton = ({ style, className, text, value, handleChange }) => {
  return (
    <CheckboxButtonStyles className={className}>
      <button
        style={{ ...style }}
        type="button"
        onClick={handleChange}
        className={`checkboxButton ${value && "active"}`}
      >
        {text}
      </button>
    </CheckboxButtonStyles>
  );
};

export default CheckboxButton;
