import styled from "styled-components";

export const GraphDataItemStyles = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-left: ${({ type }) => {
    switch (type) {
      case "BG":
        return "0";
      case "SM":
        return "12px";
      default:
        return "12px";
    }
  }};
  cursor: pointer;

  .dot {
    min-width: ${({ type }) => {
      switch (type) {
        case "BG":
          return "8px";
        case "SM":
          return "6px";
        default:
          return "6px";
      }
    }};
    min-height: ${({ type }) => {
      switch (type) {
        case "BG":
          return "8px";
        case "SM":
          return "6px";
        default:
          return "6px";
      }
    }};
    border-radius: ${({ type }) => {
      switch (type) {
        case "BG":
          return "4px";
        case "SM":
          return "3px";
        default:
          return "3px";
      }
    }};
    margin-right: 12px;
  }

  .text {
    white-space: nowrap;
    font-style: normal;
    font-weight: ${({ type }) => {
      switch (type) {
        case "BG":
          return "500";
        case "SM":
          return "normal";
        default:
          return "normal";
      }
    }};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .hr {
    width: 100%;
    border: none;
    border-top: 1px solid #f1f1f5;
  }
`;
