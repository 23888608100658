import { memo } from "react";
import { useSelector } from "react-redux";

// Components
import { SubscriptionPlanPrice } from "../../../../UI";
import { Label, Paragraph } from "../../../../typography";

// Icons
import { SubscriptionInfoIcon } from "../../../../../SVGS";

// Utils
import {
  convertTimeStampToDate,
  daysLeftTillEndOfSubscription
} from "../../../../../utils/timeStampToDate";

// Styles
import { SubscriptionPlanStyles } from "./subscriptionPlan.styles";

const SubscriptionPlan = ({
  activeSubscription,
  openSubscribeInformation,
  isActiveSubscription
}) => {
  const { branches } = useSelector(state => state.app);

  return (
    <SubscriptionPlanStyles>
      <div className="infoIconContainer" onClick={openSubscribeInformation}>
        <SubscriptionInfoIcon
          color={isActiveSubscription ? "#7FCAFA" : "silver"}
        />
      </div>
      <div className="subscriptionPlanInfo">
        <div className="subscriptionInfoDetailsContainer">
          <div className="infoRow">
            <h3 id="planName" className="planName">
              {activeSubscription.item.subscriptionPlan.name}
            </h3>
            <div className="secondColumn">
              <Label className="label">Valid For</Label>
              <Paragraph className="paragraph">
                {daysLeftTillEndOfSubscription(
                  activeSubscription.currentPeriodEnd
                )}{" "}
                days
              </Paragraph>
            </div>
            <div>
              <Label className="label">Start Date</Label>
              <Paragraph className="paragraph date">
                {convertTimeStampToDate(activeSubscription.currentPeriodStart)}
              </Paragraph>
            </div>
          </div>
          <div className="infoRow">
            <SubscriptionPlanPrice
              className="planName"
              price={Math.round(
                activeSubscription.item.subscriptionPrice.unitAmount / 100
              )}
            />
            <div className="secondColumn">
              <Label className="label">No. Of Branches</Label>
              <Paragraph className="paragraph">
                {branches.length}/
                {
                  activeSubscription.item.subscriptionPlan
                    .allowedNumberOfBranches
                }
              </Paragraph>
            </div>
            <div>
              <Label className="label">Expiration Date</Label>
              <Paragraph className="paragraph date">
                {convertTimeStampToDate(activeSubscription.currentPeriodEnd)}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </SubscriptionPlanStyles>
  );
};

export default memo(SubscriptionPlan);
