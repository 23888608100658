import styled from "styled-components";

export const BookedForFormStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .formBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 0 20px;

    .react-datepicker {
      font-family: Inter;
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation {
      border: 0.38rem solid transparent;
      height: 10px;
      width: 10px;
      top: 9px;
    }

    .react-datepicker__navigation--next {
      border-left-color: #171725;
    }

    .react-datepicker__navigation--previous {
      border-right-color: #171725;
    }

    .react-datepicker__header {
      background: #fff;
    }

    .react-datepicker__month-container {
      float: unset;
    }

    .react-datepicker__time-container {
      float: unset;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-family: Inter;
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
      font-weight: normal !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: #497bb0;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Inter;
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: #497bb0;
      color: white;
      font-weight: normal;
    }

    /* .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      height: calc(195px + (1.7rem / 2)) !important;
      max-height: 265px;
    } */

    /* .react-datepicker__header--time {
      height: 56.5px;
      display: flex;
      align-items: center;
      justify-content: center;
    } */

    /* .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box {
      height: 100%;
    } */

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--disabled {
      color: #ccc !important;
    }

    .react-datepicker-wrapper {
      display: flex;
    }

    .react-datepicker__input-container {
      width: 100%;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.8rem;
    }

    .inputContainer {
      width: 100%;
      margin-top: 20px;
    }
  }

  .footer {
    padding: 20px;
  }
`;
