import React from "react";
import styled from "styled-components";

// import Avatar from "../Avatar/Avatar";
// import { SearchIcon } from "../../../SVGS";

const DropDownRendererStyles = styled.div`
  width: 100%;
  max-height: 352px;
  overflow: hidden;

  .searchContainer {
    position: relative;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;

    .iconContainer {
      position: absolute;
      z-index: 2;
      left: 12px;
    }

    .searchInput {
      position: relative;
      width: 100%;
      border: none;
      background: #fafbfc;
      border: 1px solid #dfe1e6;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 8px 9px;
      height: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #292359;
      outline: none;
    }

    .searchInput::placeholder {
      color: #44444f99;
    }
  }

  .line {
    margin-top: 8px;
    border: 1px solid #dfe1e6;
  }

  .optionsContainer {
    max-height: 150px;
    overflow: auto;
    .option {
      padding: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #f6f8f9;
      }

      .value {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #44444f99;
      }
    }
  }
`;

const DropDownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search, "i");
  const filteredOptions = props.options.filter(item =>
    regexp.test(item[props.searchBy] || item[props.labelKey] || item.label1)
  );
  return (
    <DropDownRendererStyles>
      {props.searchable && (
        <div className="searchContainer">
          {/* <div className="iconContainer">
            <SearchIcon />
          </div> */}
          <input
            value={state.search}
            onChange={methods.setSearch}
            type="text"
            className="searchInput"
            placeholder="Search"
          />
        </div>
      )}
      {filteredOptions.length > 0 && props.searchable && (
        <hr className="line" />
      )}
      <div className="optionsContainer">
        {filteredOptions.map((option, i) => (
          <div
            className="option"
            key={option[props.valueKey]}
            onClick={() => methods.addItem(option)}
          >
            {/* {props.withImage && (
                <Avatar
                  size={props.size}
                  src={props.src}
                  isCircle={props.isCircle}
                  alt="option"
                  style={{ marginRight: props.isCircle ? 20 : 12 }}
                />
              )} */}
            <div>
              <p className={`value`}>{option[props.labelKey]}</p>
              <p className={`value`} style={{ opacity: 0.4 }}>
                {option.label1 && option.label1}
              </p>
            </div>
          </div>
        ))}
      </div>
    </DropDownRendererStyles>
  );
};

export default DropDownRenderer;
