import { StatusPillStyles } from "./statusPill.styles";

const StatusPill = ({ style, status, className }) => {
  return (
    <StatusPillStyles
      style={{ ...style }}
      status={status}
      className={className}
    >
      {status}
    </StatusPillStyles>
  );
};

export default StatusPill;
