import styled from "styled-components";

export const SubscriptionPlanInformationStyles = styled.div`
  .planInformationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .infoRow {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .planName {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #171725;
      margin-right: 92px;
      width: 130px;
      white-space: nowrap;
    }

    .secondColumn {
      width: 100px;
      margin-right: 63px;
    }

    .label {
      line-height: 16px;
      margin-bottom: 2px;
    }

    .paragraph {
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0.1px;
    }

    .date {
      letter-spacing: 0.1px;
    }
  }

  .featuresContainer {
    margin-top: 12px;
  }

  .featureRow {
    padding: 0 0 4px 0;
    border-bottom: 1px solid #f1f1f5;
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    .featureName {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-left: 5px;
    }
  }

  .featureRow:last-child {
    margin-bottom: 0;
  }
`;
