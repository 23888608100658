// Form Models
import { BranchServiceTypeFormModel } from "./Branch-service-type-form.model";

// Utils
import { isEmpty } from "../../../utils";

export class BranchFormModel {
  constructor(branch = {}) {
    this.name = branch.name || "";
    this.address = branch?.address || "";
    this.state = branch?.state || "";
    this.postalCode = branch?.postalCode || "";
    this.city = branch?.city || "";
    this.longitude = branch?.longitude || 0;
    this.latitude = branch?.latitude || 0;
    this.contactNumber = branch?.contactNumber || "";
    this.managers = branch?.managers || [];
    this.images = branch?.images || [];
    this.branchServiceTypes = !isEmpty(branch?.branchServiceTypes)
      ? branch?.branchServiceTypes.map(
          serviceType => new BranchServiceTypeFormModel(serviceType)
        )
      : [];
  }
}
