import styled from "styled-components";

export const ManageBranchesStyles = styled.section`
  min-height: calc(100vh - 42px);

  .headerCard {
    margin-top: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .branchesList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .filters {
    display: flex;
    align-items: center;

    .searchContainer {
      position: relative;
      display: flex;
      align-items: center;
      width: 250px;
      height: 36px;
      margin-top: 2px;

      .icon {
        position: absolute;
        top: 10px;
        left: 14px;
        z-index: 1;
      }

      .para {
        position: absolute;
        top: 10px;
        left: 44px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1px;
        color: #202650;
        z-index: 1;
      }

      .searchInput {
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #f2f6f9;
        border-radius: 6px;
        padding: 14px 10px 14px 40px;
        height: 100%;
        width: 100%;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1px;
        color: #202650;
      }
    }
  }
`;
