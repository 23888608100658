const ServiceRequestsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FBFCFF"
        d="M15.95 7.11c-.026-.224-.288-.393-.515-.393-.733 0-1.383-.43-1.656-1.096a1.782 1.782 0 01.447-1.978.445.445 0 00.049-.604 7.918 7.918 0 00-1.268-1.28.446.446 0 00-.608.05c-.476.527-1.333.724-1.995.448A1.777 1.777 0 019.324.52.444.444 0 008.93.052a7.984 7.984 0 00-1.8-.004.445.445 0 00-.396.457A1.78 1.78 0 015.64 2.212c-.654.267-1.505.073-1.98-.45a.446.446 0 00-.605-.051c-.479.376-.915.807-1.294 1.28a.445.445 0 00.048.607c.557.504.736 1.306.447 1.996-.276.658-.959 1.082-1.74 1.082a.435.435 0 00-.463.392 8.02 8.02 0 00-.003 1.82c.025.225.295.393.525.393a1.755 1.755 0 011.646 1.096c.28.682.1 1.477-.448 1.979a.444.444 0 00-.048.602c.371.474.798.904 1.265 1.28.184.148.45.128.61-.048.478-.529 1.334-.725 1.994-.448.69.289 1.125.987 1.082 1.736a.444.444 0 00.393.467 7.923 7.923 0 001.8.005.445.445 0 00.395-.458 1.778 1.778 0 011.095-1.706c.658-.269 1.505-.072 1.981.45.159.173.42.194.604.051a7.96 7.96 0 001.294-1.28.444.444 0 00-.047-.607 1.772 1.772 0 01-.449-1.996A1.79 1.79 0 0115.38 9.32l.099.002c.23.019.44-.158.468-.392.07-.601.071-1.213.004-1.82zM8 12.612a4.612 4.612 0 113.96-2.247l-2.02-2.02a2.82 2.82 0 00.223-1.104c0-.755-.294-1.465-.828-1.999a2.809 2.809 0 00-1.999-.828c-.252 0-.503.033-.745.1a.32.32 0 00-.222.223.313.313 0 00.089.305s.997 1.005 1.33 1.338c.035.035.035.119.03.149l-.003.022c-.033.367-.098.807-.152.976l-.021.02-.022.023c-.171.055-.618.12-.991.154v-.002l-.017.004-.016.001c-.04 0-.099-.011-.153-.065-.347-.347-1.305-1.298-1.305-1.298a.326.326 0 00-.233-.104.317.317 0 00-.298.24 2.837 2.837 0 00.73 2.74 2.81 2.81 0 002 .829c.385 0 .759-.078 1.103-.224l2.042 2.042A4.589 4.589 0 018 12.61z"
      ></path>
    </svg>
  );
};

export default ServiceRequestsIcon;
