const CircledGeneralSettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M19.718 12.634l-.923-.2a5.015 5.015 0 00-.297-.717l.511-.794a.358.358 0 00-.048-.447l-1.437-1.437a.359.359 0 00-.447-.048l-.794.51a5.018 5.018 0 00-.716-.296l-.2-.923A.359.359 0 0015.015 8h-2.032a.359.359 0 00-.35.282l-.2.923c-.246.08-.486.18-.717.296l-.794-.51a.359.359 0 00-.447.048l-1.437 1.437a.359.359 0 00-.048.447l.51.794c-.117.231-.216.47-.296.716l-.923.2a.359.359 0 00-.282.351v2.032c0 .169.118.314.282.35l.923.2c.08.246.18.486.296.717l-.51.794a.359.359 0 00.048.447l1.437 1.437c.119.12.305.14.447.048l.794-.51c.231.117.47.216.716.296l.2.923a.358.358 0 00.351.282h2.032a.358.358 0 00.35-.282l.2-.923c.246-.08.486-.18.717-.297l.794.511a.359.359 0 00.447-.048l1.437-1.437c.12-.119.14-.305.048-.447l-.51-.794c.117-.231.216-.47.296-.716l.923-.2a.359.359 0 00.282-.351v-2.032a.359.359 0 00-.282-.35zM16.15 14A2.154 2.154 0 0114 16.151 2.154 2.154 0 0111.849 14c0-1.186.965-2.151 2.151-2.151s2.151.965 2.151 2.151z"
      ></path>
    </svg>
  );
};

export default CircledGeneralSettingsIcon;
