import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { TextField, Button } from "../../../../components/UI";
import { Label } from "../../../../components/typography";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Form Models
import { ChangePasswordFormModel } from "../../../../models/app/forms";

// Services
import { changePasswordValidation } from "../../../../services/validations/changePassword.validation";

// Calls
import { changePasswordRequest } from "../../../../api/calls/app.call";

// Styles
import { ChangePasswordFormStyles } from "./changePasswordForm.styles";

const ChangePasswordForm = () => {
  const changePasswordHandler = async (values, { resetForm }) => {
    const response = await changePasswordRequest(values);
    if (response.hasError) {
      toast.error(response.error.message);
    } else {
      toast.success(response.message);
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: new ChangePasswordFormModel(),
    onSubmit: changePasswordHandler,
    validate: changePasswordValidation
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <ChangePasswordFormStyles>
      <form onSubmit={handleSubmit}>
        <div className="inputContainer">
          <Label>Current Password</Label>
          <TextField
            type="password"
            name="oldPassword"
            value={values.oldPassword}
            touched={touched.oldPassword}
            error={errors.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="bottomInputs">
          <div className="inputContainer">
            <Label>New Password</Label>
            <TextField
              type="password"
              name="password"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="inputContainer">
            <Label>Confirm New Password</Label>
            <TextField
              type="password"
              name="passwordConfirmation"
              value={values.passwordConfirmation}
              touched={touched.passwordConfirmation}
              error={errors.passwordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="buttonsContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
      </form>
    </ChangePasswordFormStyles>
  );
};

export default ChangePasswordForm;
