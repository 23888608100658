const DarkPin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="13"
      fill="none"
      viewBox="0 0 10 13"
    >
      <path
        fill="#202650"
        d="M4.531 12.266c.211.328.703.328.914 0C8.867 7.344 9.5 6.828 9.5 5 9.5 2.516 7.484.5 5 .5A4.486 4.486 0 00.5 5c0 1.828.61 2.344 4.031 7.266z"
      ></path>
    </svg>
  );
};

export default DarkPin;
