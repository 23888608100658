// styles
import { CardStyles } from "./card.styles";

const Card = ({ children, style, className, rounded }) => (
  <CardStyles rounded={rounded} style={{ ...style }} className={className}>
    {children}
  </CardStyles>
);

export default Card;
