const CircledProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <g fill="#fff" clipPath="url(#clip0)">
        <path d="M15.585 10.396a.532.532 0 01-.533-.533v-.798h-2.129v.798a.532.532 0 01-1.065 0v-.798c0-.588.478-1.065 1.065-1.065h2.13c.586 0 1.064.477 1.064 1.065v.798a.533.533 0 01-.532.533zM14.366 15.123a1.167 1.167 0 01-.788-.01L7.6 13.12v4.062c0 .809.655 1.464 1.464 1.464h9.848c.81 0 1.464-.655 1.464-1.464V13.12l-6.01 2.002z"></path>
        <path d="M20.376 11.061v1.22l-6.26 2.086a.357.357 0 01-.128.021.357.357 0 01-.128-.021L7.6 12.28v-1.219c0-.81.655-1.464 1.464-1.464h9.848c.81 0 1.464.655 1.464 1.464z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H12.776V10.647H0z"
            transform="translate(7.6 8)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircledProfileIcon;
