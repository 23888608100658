export const timeSlots = [
  "00:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM"
];

export const employeesData = [
  {
    id: 1,
    name: "Aleksa Ivakic",
    inProgress: [
      {
        start: "07:00 AM",
        finish: "10:30 AM",
        id: 1,
        isActive: true
      },
      {
        start: "08:00 AM",
        finish: "09:30 AM",
        id: 2,
        isActive: false
      },
      {
        start: "09:00 AM",
        finish: "10:00 AM",
        id: 3,
        isActive: false
      },
      {
        start: "11:30 AM",
        finish: "12:30 PM",
        id: 4,
        isActive: false
      },
      {
        start: "11:30 AM",
        finish: "01:30 PM",
        id: 433332,
        isActive: false
      },
      {
        start: "12:00 PM",
        finish: "02:00 PM",
        id: 5,
        isActive: false
      },
      {
        start: "13:00 PM",
        finish: "03:00 PM",
        id: 6,
        isActive: false
      },
      {
        start: "12:30 PM",
        finish: "02:30 PM",
        id: 7,
        isActive: false
      }
    ],
    paused: [
      {
        start: "09:00 AM",
        finish: "09:00 AM",
        id: 4444455,
        isActive: true
      },
      {
        start: "09:00 AM",
        finish: "10:00 AM",
        id: 4324452,
        isActive: false
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 444477,
        isActive: false
      }
    ],
    pending: [
      {
        start: "08:00 AM",
        finish: "10:30 AM",
        id: 333333,
        isActive: true
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 333444,
        isActive: false
      }
    ]
  },
  {
    id: 2,
    name: "Vladan Jovanovic",
    inProgress: [
      {
        start: "08:00 AM",
        finish: "09:30 AM",
        id: 4444444,
        isActive: true
      },
      {
        start: "09:00 AM",
        finish: "09:30 AM",
        id: 4444445,
        isActive: false
      }
    ],
    paused: [
      {
        start: "09:00 AM",
        finish: "11:30 AM",
        id: 4444455,
        isActive: true
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 444477,
        isActive: false
      }
    ],
    pending: [
      {
        start: "08:00 AM",
        finish: "10:30 AM",
        id: 333333,
        isActive: true
      },
      {
        start: "08:00 AM",
        finish: "11:30 AM",
        id: 333233,
        isActive: false
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 333444,
        isActive: false
      }
    ]
  },
  {
    id: 3,
    name: "Petar Sijan",
    inProgress: [
      {
        start: "08:00 AM",
        finish: "09:30 AM",
        id: 4444444,
        isActive: false
      },
      {
        start: "09:00 AM",
        finish: "12:00 PM",
        id: 4444445,
        isActive: true
      }
    ],
    paused: null,
    pending: [
      {
        start: "08:00 AM",
        finish: "10:30 AM",
        id: 333333,
        isActive: false
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 333444,
        isActive: false
      }
    ]
  },
  {
    id: 4,
    name: "Milos Danilovic",
    inProgress: [
      {
        start: "08:00 AM",
        finish: "09:30 AM",
        id: 4444444,
        isActive: false
      },
      {
        start: "09:00 AM",
        finish: "12:00 PM",
        id: 4444445,
        isActive: true
      }
    ],
    paused: null,
    pending: [
      {
        start: "08:00 AM",
        finish: "10:30 AM",
        id: 333333,
        isActive: false
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 333444,
        isActive: false
      }
    ]
  },
  {
    id: 5,
    name: "Nemanja Markovic",
    inProgress: [
      {
        start: "08:00 AM",
        finish: "09:30 AM",
        id: 4444444,
        isActive: false
      },
      {
        start: "09:00 AM",
        finish: "12:00 PM",
        id: 4444445,
        isActive: true
      }
    ],
    paused: null,
    pending: [
      {
        start: "08:00 AM",
        finish: "10:30 AM",
        id: 333333,
        isActive: false
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 333444,
        isActive: false
      }
    ]
  },
  {
    id: 6,
    name: "Petar Markovic",
    inProgress: [
      {
        start: "08:00 AM",
        finish: "09:30 AM",
        id: 4444444,
        isActive: false
      },
      {
        start: "09:00 AM",
        finish: "12:00 PM",
        id: 4444445,
        isActive: true
      }
    ],
    paused: null,
    pending: [
      {
        start: "08:00 AM",
        finish: "10:30 AM",
        id: 333333,
        isActive: false
      },
      {
        start: "04:00 PM",
        finish: "07:30 PM",
        id: 333444,
        isActive: false
      }
    ]
  }
];
