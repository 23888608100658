import styled from "styled-components";

export const GeneralBranchSettingsStyles = styled.section`
  min-height: calc(100vh - 42px);

  .contentContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 24px;

    .tabs {
      width: 32.6%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }

    .cardContainer {
      width: 63.8%;
    }
  }
`;
