// styles
import { SubscriptionPlaceHolderStyles } from "./subscriptionPlaceholder.styles";

const SubscriptionPlaceholder = ({ placeholder }) => {
  return (
    <SubscriptionPlaceHolderStyles>
      <p className="placeholderText">{placeholder}</p>
    </SubscriptionPlaceHolderStyles>
  );
};

export default SubscriptionPlaceholder;
