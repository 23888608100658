// Request Models
import { BranchServiceTypeRequest } from ".";

export class BranchServiceTypesRequest {
  constructor(branchServiceTypesFormValues = {}) {
    this.branch_service_types =
      branchServiceTypesFormValues.branchServiceTypes.map(
        type => new BranchServiceTypeRequest(type)
      );
  }
}
