import styled from "styled-components";

export const ServiceMenuStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    .addServiceMenu {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #fafafb;
    }
  }

  .filters {
    padding: 14px 24px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);

    .tab {
      padding: 2px 6px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #9496a3;
      outline: none;
      border: 1px solid transparent;
      background: transparent;
      border-radius: 3px;
      margin-right: 24px;
      cursor: pointer;
    }

    .activeTab {
      background: rgba(116, 189, 233, 0.1);
      border: 1px solid #74bde9;
      color: #202650;
    }
  }

  .body {
    padding: 0;
  }

  .modalCard {
    width: 620px !important;
    max-height: 90% !important;
  }
`;
