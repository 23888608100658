import styled from "styled-components";

export const RegisterStepTwoFormStyles = styled.div`
  .alignedInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputContainer {
      width: 186px;
    }
  }

  .label {
    margin-bottom: 2px;
  }

  .backButton {
    position: absolute;
    padding: 9px 14px;
    top: -19px;
    display: flex;
    align-items: center;

    .arrowContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      transform: rotate(-90deg);
      padding-top: 2.5px;
    }
  }

  .tabs {
    margin: 36px 0 12px 0;
    display: flex;
    align-items: center;

    .tab {
      font-style: normal;
      font-weight: normal;
      color: #979797;
    }
  }

  .form {
    margin-top: 31px;

    .stateDropdown {
      background: #fafbfc;
      border: 1px solid #dfe1e6;
    }

    .submitButton {
      width: 100%;
    }

    .link {
      cursor: pointer;
      color: #74bde9;
      font-weight: normal;
    }

    .bottomText {
      font-weight: normal;
      color: #9fadc3;
      font-size: 12px;
      text-align: center;
      margin: 18px 0 0 0;
    }
  }
`;
