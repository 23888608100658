import styled from "styled-components";

export const RegisterStyles = styled.section`
  display: flex;
  height: 100vh;
  background: #ffffff;

  .formContainer {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 56px 0 56px 0;
    overflow-y: auto;

    .container {
      width: 390px;
      position: relative;
    }
  }

  .sliderContainer {
    width: 50%;
    height: 100%;
  }
`;
