// Domain Models
import { Permission } from ".";
import { File } from ".";
import { Branch } from ".";

// Utils
import { isEmpty } from "../../../utils";

export class Manager {
  constructor(manager = {}) {
    this.id = manager.id;
    this.email = manager.email;
    this.firstName = manager.first_name;
    this.lastName = manager.last_name;
    this.contactNumber = manager.contact_number;
    this.permissions = !isEmpty(manager.permissions)
      ? manager.permissions.map(permission => new Permission(permission))
      : [];
    this.branches = !isEmpty(manager.branches)
      ? manager.branches.map(branch => new Branch(branch))
      : [];
    this.profileImage = new File(manager.profile_image);
    this.fullName = this.generateFullName();
  }

  generateFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  generateManagerForm() {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      contactNumber: this.contactNumber,
      permissions: this.permissions.map(permission => permission.key),
      branches: this.branches
    };
  }
}
