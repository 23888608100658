import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Action types
import * as types from "../actionTypes/manager.types";

// Actions
import {
  addNewManagerSuccess,
  getManagersSuccess,
  updateManagerSuccess,
  deleteManagerSuccess
} from "../actions/managers.actions";

// Calls
import {
  createManagerRequest,
  fetchManagersRequest,
  updateManagerRequest,
  deleteManagersRequest
} from "../../api/calls/manager.call";

// Create new manager logic
function* createManager(action) {
  const response = yield call(createManagerRequest, action.managerFormValues);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.ADD_MANAGER_FAILED
    });
  }
  yield put(addNewManagerSuccess(response.managers));
}

function* createManagerWatcher() {
  yield takeLatest(types.ADD_MANAGER_REQUEST, createManager);
}

// Update manager logic
function* updateManager(action) {
  const response = yield call(
    updateManagerRequest,
    action.managerFormValues,
    action.initialValues,
    action.id
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.UPDATE_MANAGER_FAILED
    });
  }
  yield put(updateManagerSuccess(response.managers));
}

function* updateManagerWatcher() {
  yield takeLatest(types.UPDATE_MANAGER_REQUEST, updateManager);
}

// Delete manager logic
function* deleteManager(action) {
  const response = yield call(deleteManagersRequest, action.id);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.DELETE_MANAGER_FAILED
    });
  }
  yield put(deleteManagerSuccess(response.managers));
}

function* deleteManagerWatcher() {
  yield takeLatest(types.DELETE_MANAGER_REQUEST, deleteManager);
}

// Fetch managers logic
function* fetchManagers() {
  const response = yield call(fetchManagersRequest);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_MANAGERS_FAILED
    });
  }
  yield put(getManagersSuccess(response.managers));
}

function* fetchManagersWatcher() {
  yield takeLatest(types.FETCH_MANAGERS_REQUEST, fetchManagers);
}

export default function* managerSaga() {
  yield all([
    createManagerWatcher(),
    fetchManagersWatcher(),
    updateManagerWatcher(),
    deleteManagerWatcher()
  ]);
}
