import styled from "styled-components";

export const GraphStyles = styled.div`
  width: 51.6%;
  margin-right: 24px;
  height: 100%;

  .graphCard {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .graph {
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grqphLabel {
    position: absolute;
    transform: rotate(90deg);
    width: 134px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #202650;
  }

  .graphCell {
    cursor: pointer;
  }

  .custom-tooltip {
    transform: rotate(90deg) !important;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 3px;

    .tooltipLabel {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
`;
