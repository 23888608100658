import { useState } from "react";

// components
import {
  Header,
  Body,
  Select,
  Footer,
  Button
} from "../../../../components/UI";
import { Label, Heading2, Paragraph } from "../../../../components/typography";
// lists
import Row from "../../../../components/lists/Row";
// builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";
import { modalBuilder } from "../../../../builders/generalSettingsModal.builder";
// constants
import * as modalTypes from "../../../../constants/generalSettingsModal.constants";
// SVGS
import { Close } from "../../../../SVGS";
// styles
import { GeneralSettingsListModalStyles } from "./generalSettingsListModal.styles";

const GeneralSettingsListModal = ({
  modalType,
  trucks,
  trailers,
  trailerTypes,
  paymentTypes,
  truckOptions,
  trailerOptions,
  trailerTypeOptions,
  paymentOptions,
  saveModalChanges,
  closeModal
}) => {
  const selectedValues = (values, options) => {
    const selectedIds = values.map(am => am.id ?? am.key);
    return options.filter(option =>
      selectedIds.includes(option.id ?? option.key)
    );
  };

  const setValuesBasedOnModalType = () => {
    if (modalType === modalTypes.SUPPORTED_TRUCKS)
      return {
        value: trucks,
        options: truckOptions
      };
    if (modalType === modalTypes.SUPPORTED_TRAILERS)
      return {
        value: trailers,
        options: trailerOptions
      };
    if (modalType === modalTypes.SUPPORTED_TRAILER_TYPES)
      return {
        value: trailerTypes,
        options: trailerTypeOptions
      };
    if (modalType === modalTypes.SUPPORTED_PAYMENT_TYPES) {
      return {
        value: paymentTypes,
        options: paymentOptions
      };
    }
    return { value: [], options: [] };
  };

  const setSelectLabelKey = () => {
    if (modalType === modalTypes.SUPPORTED_TRUCKS) return "manufacturer";
    if (modalType === modalTypes.SUPPORTED_TRAILERS) return "manufacturer";
    if (modalType === modalTypes.SUPPORTED_TRAILER_TYPES) return "name";
    if (modalType === modalTypes.SUPPORTED_PAYMENT_TYPES) return "name";
  };

  const setSelectValueKey = () => {
    if (
      modalType === modalTypes.SUPPORTED_TRUCKS ||
      modalType === modalTypes.SUPPORTED_TRAILERS ||
      modalType === modalTypes.SUPPORTED_TRAILER_TYPES
    )
      return "id";
    if (modalType === modalTypes.SUPPORTED_PAYMENT_TYPES) return "key";
  };

  const [modalValue, setModalValue] = useState(setValuesBasedOnModalType());

  const { title, inputLabel, inputPlaceholder, listLabel } =
    modalBuilder[modalType];

  const selectFilteredOptions = () => {
    const selectedIds = modalValue.value.map(am => am.id ?? am.key);
    return modalValue.options.filter(
      option => !selectedIds.includes(option.id ?? option.key)
    );
  };

  const removeOption = id => {
    setModalValue(prevValues => ({
      ...prevValues,
      value: prevValues.value.filter(option =>
        option.id ? option.id !== id : option.key !== id
      )
    }));
  };

  return (
    <GeneralSettingsListModalStyles>
      <Header className="header">
        <Heading2 className="title">{title}</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <div>
          <Label>{inputLabel}</Label>
          <Select
            searchable
            value={null}
            options={selectFilteredOptions()}
            onChange={option =>
              setModalValue(prevValues => ({
                ...prevValues,
                value: [...prevValues.value, option[0]]
              }))
            }
            labelKey={setSelectLabelKey()}
            className="input unselectable"
            placeholder={inputPlaceholder}
            alwayClearInput
            valueKey={setSelectValueKey()}
          />
        </div>
        <div>
          <Label>{listLabel}</Label>
          <div className="list">
            {selectedValues(modalValue.value, modalValue.options).map(
              option => (
                <Row
                  style={{ padding: "12px 24px" }}
                  key={option.id ?? option.key}
                >
                  <div className="item">
                    <Paragraph className="workerName">
                      {option[setSelectLabelKey()]}
                    </Paragraph>
                    <Close
                      onClick={() => removeOption(option.id ?? option.key)}
                    />
                  </div>
                </Row>
              )
            )}
          </div>
        </div>
      </Body>
      <Footer className="footer">
        <Button
          className="submitButton"
          type="button"
          onClick={() => saveModalChanges(modalValue.value)}
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Save Changes
        </Button>
      </Footer>
    </GeneralSettingsListModalStyles>
  );
};

export default GeneralSettingsListModal;
