import { useEffect, useState } from "react";
import { getItem } from "../../../utils";
import { TOKEN } from "../../../constants/localStorage/localStorage.constants";
import { useSelector } from "react-redux";
import { initializeSocket } from "../utils";

export default function useSocket(notificationHandler) {
  const { auth } = useSelector(state => state.app);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (auth.token) {
      const token = getItem(TOKEN);
      if (!token) {
        console.warn("No token found in local storage.");
        return;
      }
      const newSocket = initializeSocket(token);
      if (newSocket) {
        setSocket(newSocket);
      }

      return () => {
        newSocket?.disconnect();
      };
    }
  }, [auth.token]);

  useEffect(() => {
    if (socket) {
      socket.on("new_notification", notificationHandler);

      return () => {
        socket.off("new_notification", notificationHandler);
      };
    }
  }, [socket, notificationHandler]);
}
