import { apiService } from "../api.service";

// Constants
import * as systemComponentsConsts from "../../constants/system-components/system-components.constants";

// Responses
import { SystemComponentsResponse } from "../../models/api/responses";

const systemComponentsQueryGeneretaor = ({
  serviceTypes,
  truckManufacturers,
  trailerManufacturers,
  trailerTypes,
  paymentTypes
}) => {
  let query = `?`;
  if (serviceTypes === systemComponentsConsts.SERVICE_TYPES) {
    query = query + `service_types=true`;
  }
  if (truckManufacturers === systemComponentsConsts.TRUCK_MANUFACTURERS) {
    query =
      query +
      `${
        serviceTypes ? "&truck_manufacturers=true" : "truck_manufacturers=true"
      }`;
  }

  if (trailerManufacturers === systemComponentsConsts.TRAILER_MANUFACTURERS) {
    query =
      query +
      `${
        serviceTypes || truckManufacturers
          ? "&trailer_manufacturers=true"
          : "trailer_manufacturers=true"
      }`;
  }

  if (trailerTypes === systemComponentsConsts.TRAILER_TYPES) {
    query =
      query +
      `${
        serviceTypes || truckManufacturers || trailerManufacturers
          ? "&trailer_types=true"
          : "trailer_types=true"
      }`;
  }

  if (paymentTypes === systemComponentsConsts.PAYMENT_TYPES) {
    query =
      query +
      `${
        serviceTypes ||
        truckManufacturers ||
        trailerManufacturers ||
        trailerTypes
          ? "&payment_types=true"
          : "payment_types=true"
      }`;
  }

  return query === "?" ? "" : query;
};

/**
 * System Components request
 * @param serviceTypes - string
 * @param truckManufacturers - string
 * @param trailerManufacturers - string
 * @param trailerTypes - string
 * @return - SystemComponentsResponse || ErrorResponse
 */
export const fetchSystemComponents = async ({
  serviceTypes,
  truckManufacturers,
  trailerManufacturers,
  trailerTypes,
  paymentTypes
}) => {
  const response = await apiService.get(
    `/system/components${systemComponentsQueryGeneretaor({
      serviceTypes,
      truckManufacturers,
      trailerManufacturers,
      trailerTypes,
      paymentTypes
    })}`
  );
  if (response.hasError) {
    return response;
  }
  return new SystemComponentsResponse(response);
};
