import { useEffect } from "react";

const useClickOutside = (ref, callback, parent) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);

    if (parent) {
      parent.addEventListener("click", handleClick);
    }
    return () => {
      if (parent) {
        parent.removeEventListener("click", handleClick);
      }
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useClickOutside;
