// Domain Models
import { BranchServiceType } from "../../app/domain";

export class BranchServiceTypesResponse {
  constructor(response = {}) {
    this.branchServiceTypes = response.data.branch_service_types.map(
      type => new BranchServiceType(type)
    );
  }
}
