const CheckBoxIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="8"
      fill="none"
      viewBox="0 0 9 8"
    >
      <path
        fill="#fff"
        d="M8.848 1.363l-5.62 5.62a.48.48 0 01-.7 0l-2.38-2.35a.5.5 0 010-.71l.53-.53a.48.48 0 01.7 0l1.5 1.49 4.74-4.74a.5.5 0 01.7 0l.53.53a.5.5 0 010 .69z"
      ></path>
    </svg>
  );
};

export default CheckBoxIcon;
