import { all } from "redux-saga/effects";
import appSaga from "../sagas/app.saga";
import bussinessSaga from "../sagas/bussiness.saga";
import managerSaga from "../sagas/manager.saga";
import serviceRequestsSaga from "../sagas/service-requests.saga";
import servicesSaga from "../sagas/services.saga";
import serviceCommentsSaga from "../sagas/service-comments.saga";

function* rootSaga() {
  yield all([
    appSaga(),
    bussinessSaga(),
    managerSaga(),
    serviceRequestsSaga(),
    servicesSaga(),
    serviceCommentsSaga()
  ]);
}

export default rootSaga;
