import styled from "styled-components";

export const SliderStyles = styled.div`
  width: 100%;
  height: 100vh;

  .slider {
    width: 100%;
    height: 100%;
  }

  .carousel-slider {
    height: 100%;
  }

  .slider-wrapper {
    height: 100%;
  }

  .imageContainer {
    height: 100%;
    width: 100%;

    .image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`;
