// components
import { Header, Body } from "../../../../UI";
import { Heading2 } from "../../../../typography";
// containers
import ServiceTypeAndCapacityForm from "../../../../../containers/forms/generalBranchSettingsForms/ServiceTypeAndCapacity/ServiceTypeAndCapacity.form";
// styles
import styled from "styled-components";

const ServiceTypeAndCapacityStyles = styled.div``;

const ServiceTypeAndCapacity = ({ branchId }) => {
  return (
    <ServiceTypeAndCapacityStyles>
      <Header>
        <Heading2>SERVICE TYPE AND CAPACITY</Heading2>
      </Header>
      <Body>
        <ServiceTypeAndCapacityForm branchId={branchId} />
      </Body>
    </ServiceTypeAndCapacityStyles>
  );
};

export default ServiceTypeAndCapacity;
