import styled from "styled-components";

export const RegisterStepOneFormStyles = styled.div`
  .tabs {
    margin: 36px 0 12px 0;
    display: flex;
    align-items: center;

    .tab {
      font-style: normal;
      font-weight: normal;
      color: #979797;
    }
  }

  .form {
    margin-top: 31px;

    .firstAndLastNameContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inputContainer {
        width: 186px;
      }
    }

    .label {
      margin-bottom: 2px;
    }

    .nextButton {
      width: 100%;
    }

    .link {
      cursor: pointer;
      color: #74bde9;
      font-weight: normal;
    }

    .bottomText {
      font-weight: normal;
      color: #9fadc3;
      font-size: 12px;
      text-align: center;
      margin: 18px 0 0 0;
    }
  }
`;
