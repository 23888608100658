// Domain Models
import { WorkerSchedule, WorkDay } from "../../app/domain";

export class WorkersScheduleResponse {
  constructor(response = {}) {
    this.employees = response.data.workers.map(
      workerSchedule => new WorkerSchedule(workerSchedule)
    );
    this.workDay = response.data.work_day
      ? new WorkDay(response.data.work_day)
      : null;
  }
}
