import axios from "axios";
import { toast } from "react-toastify";

// Constants
import { BASE_API_URL } from "../constants/api/api.constants";
import * as localStoageConsts from "../constants/localStorage/localStorage.constants";

// Responses
import { ErrorResponse } from "../models/api/responses";

// Actions
import { logout } from "../redux/actions/app.actions";

// Utils
import * as localStorageUtils from "../utils";

class ApiService {
  constructor() {
    let service = axios.create({
      baseURL: BASE_API_URL
    });
    service.interceptors.request.use(
      this.handleReqInterceptorSuccess,
      this.handleReqInterceptorError
    );
    service.interceptors.response.use(
      this.handleResInterceptorSuccess,
      this.handleResInterceptorError
    );
    this.service = service;
    this.isIssuingRefreshToken = false;
  }

  setReduxStore(store) {
    this.store = store;
  }

  handleReqInterceptorSuccess(request) {
    if (request.baseURL === BASE_API_URL && !request.headers.Authorization) {
      const token = localStorageUtils.getItem(localStoageConsts.TOKEN);

      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      if (request.headerBranchId) {
        request.headers["x-branch"] = `${request.headerBranchId}`;
      }

      if (
        localStorageUtils.isEmpty(request.headerBranchId) &&
        localStorageUtils.getItem(localStoageConsts.ACTIVE_BRANCH)
      ) {
        request.headers["x-branch"] = `${localStorageUtils.getItem(
          localStoageConsts.ACTIVE_BRANCH
        )}`;
      }

      if (
        localStorageUtils.getItem(
          localStoageConsts.OPENED_SERVICE_REQUEST_DETAILS_BRANCH
        )
      ) {
        request.headers["x-branch"] = `${localStorageUtils.getItem(
          localStoageConsts.OPENED_SERVICE_REQUEST_DETAILS_BRANCH
        )}`;
      }

      if (
        localStorageUtils.getItem(
          localStoageConsts.OPENED_SERVICE_DETAILS_BRANCH
        )
      ) {
        request.headers["x-branch"] = `${localStorageUtils.getItem(
          localStoageConsts.OPENED_SERVICE_DETAILS_BRANCH
        )}`;
      }
    }
    return request;
  }

  handleReqInterceptorError = error => {
    console.log("request interceptor error", error.response);
    return Promise.reject(error);
  };

  handleResInterceptorSuccess(response) {
    return response;
  }

  handleResInterceptorError = async error => {
    const originalRequest = error.config;
    if (
      [401].includes(error.response.status) &&
      originalRequest.url !== `/auth/refresh` &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      if (!this.isIssuingRefreshToken) {
        await this.refreshAccessToken(originalRequest);
        return this.service(originalRequest);
      } else {
        await this.waitForRefreshToken();
        return this.service(originalRequest);
      }
    }
    return Promise.reject(error);
  };

  // Function for pausing code until refresh token is resolved in another api instance
  async waitForRefreshToken() {
    if (!this.isIssuingRefreshToken) return;
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.waitForRefreshToken();
  }

  async refreshAccessToken(request) {
    this.isIssuingRefreshToken = true;

    const refreshToken = localStorageUtils.getItem(
      localStoageConsts.REFRESH_TOKEN
    );

    const payload = {
      refresh_token: refreshToken
    };

    try {
      const response = await axios.post(
        `${BASE_API_URL}/auth/refresh`,
        payload
      );

      const newToken = response.data.auth.access_token;
      const newRefreshToken = response.data.auth.refresh_token;

      if (newToken && newRefreshToken) {
        request.headers.Authorization = `Bearer ${newToken}`;
        localStorageUtils.setItem(localStoageConsts.TOKEN, newToken);
        localStorageUtils.setItem(
          localStoageConsts.REFRESH_TOKEN,
          newRefreshToken
        );
      }
    } catch (error) {
      toast.error("Unauthorized!");
      this.store.dispatch(logout());
    }
    this.isIssuingRefreshToken = false;
  }

  async handleApiCall(apiCall) {
    try {
      const response = await apiCall();
      return response;
    } catch (error) {
      return new ErrorResponse(error.response);
    }
  }

  async get(path, headerBranchId) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "GET",
        url: path,
        headerBranchId: headerBranchId
      })
    );
  }

  async getBlob(path, headerBranchId) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "GET",
        url: path,
        headerBranchId: headerBranchId,
        responseType: "blob"
      })
    );
  }

  async post(path, payload, headerBranchId) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "POST",
        url: path,
        data: payload,
        headerBranchId: headerBranchId
      })
    );
  }

  async put(path, payload, headerBranchId) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "PUT",
        url: path,
        data: payload,
        headerBranchId: headerBranchId
      })
    );
  }

  async patch(path, payload, headerBranchId) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "PATCH",
        url: path,
        data: payload,
        headerBranchId: headerBranchId
      })
    );
  }

  async delete(path, headerBranchId) {
    return this.handleApiCall(() =>
      this.service.request({
        method: "DELETE",
        url: path,
        headerBranchId: headerBranchId
      })
    );
  }
}

export const apiService = new ApiService();
