const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#202650"
        d="M3.517 6.992a12.624 12.624 0 005.491 5.491l1.834-1.833a.829.829 0 01.85-.2 9.506 9.506 0 002.975.475c.458 0 .833.375.833.833v2.909a.836.836 0 01-.833.833C6.842 15.5.5 9.158.5 1.333.5.875.875.5 1.333.5H4.25c.458 0 .833.375.833.833 0 1.042.167 2.042.475 2.975a.836.836 0 01-.208.85L3.517 6.992z"
      ></path>
    </svg>
  );
};

export default PhoneIcon;
