import { apiService } from "../api.service";

// Responses
import { AnalythicsDataResponse } from "../../models/api/responses/analythics-data.response";

/**
 * Fetch service  Request
 * @param branchId - Branch id.
 * @param dateFrom - Date from.
 * @param dateTo - Date to.
 * @return - ServiceResponse || ErrorResponse
 */
export const fetchAnalythicsRequest = async (branchId, dateFrom, dateTo) => {
  let query = "";

  query = analythicsFilter(query, branchId, dateFrom, dateTo);

  const response = await apiService.get(`/businesses/analytics${query}`);
  if (response.hasError) {
    return response;
  }
  return new AnalythicsDataResponse(response);
};

/**
 * Exports the analytics.
 * @param branchId - Branch id.
 * @param dateFrom - Date from.
 * @param dateTo - Date to.
 * @return - ServiceResponse || ErrorResponse
 */
export const exportAnalythicsPDF = async (branchId, dateFrom, dateTo) => {
  let query = "";

  query = analythicsFilter(query, branchId, dateFrom, dateTo);

  const response = await apiService.getBlob(
    `/businesses/analytics/export${query}`
  );
  if (response.hasError) {
    return response;
  }
  return response;
};

/**
 * Filters query.
 * @param branchId - Branch id.
 * @param dateFrom - Date from.
 * @param dateTo - Date to.
 * @return - Updated query.
 */
const analythicsFilter = (query, branchId, dateFrom, dateTo) => {
  if (branchId) {
    query = query + `?branch_id=${branchId}`;
  }
  if (dateFrom) {
    query = query + `&date_from=${dateFrom}`;
  }
  if (dateTo) {
    query = query + `&date_to=${dateTo}`;
  }

  return query;
};

// export const fetchRating = async (branchId, dateFrom, dateTo) => {
//   let query = "";
//   if (branchId) {
//     query = query + `?branch_id=${branchId}`;
//   }
//   if (dateFrom) {
//     query = query + `&date_from=${dateFrom}`;
//   }
//   if (dateTo) {
//     query = query + `&date_to=${dateTo}`;
//   }
//   const response = await apiService.get(`/businesses/analytics${query}`);
//   if (response.hasError) {
//     return response;
//   }
//   return new AnalythicsDataResponse(response);
// };
