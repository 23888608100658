import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { Header, Body } from "../../../../../components/UI";
import { Heading2 } from "../../../../../components/typography";
import RoleForm from "../RoleForm/RoleForm";

// Icons
import { Close } from "../../../../../SVGS";

// Form Models
import { RoleFormModel } from "../../../../../models/app/forms";

// Calls
import { updateRoleRequest } from "../../../../../api/calls/general-branch-settings.call";

// Services
import { roleValidation } from "../../../../../services/validations/role.validation";

// Styles
import { EditRoleStyles } from "./editRole.styles";

const EditRole = ({
  closeModal,
  chosenRole,
  branchId,
  permissions,
  setRolesAfterCreateAndUpdateRole
}) => {
  const initialValues = new RoleFormModel(chosenRole.generateRoleForm());
  const updateRoleHandler = async values => {
    const response = await updateRoleRequest(branchId, values, chosenRole.id);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Role successfully updated.");
    setRolesAfterCreateAndUpdateRole(response.roles);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: updateRoleHandler,
    validate: roleValidation
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange
  } = formik;

  return (
    <EditRoleStyles>
      <Header className="formHeader">
        <Heading2>EDIT ROLE</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <RoleForm
            permissions={permissions}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            buttonText="Save Changes"
          />
        </form>
      </Body>
    </EditRoleStyles>
  );
};

export default EditRole;
