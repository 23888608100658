// Domain Models
import { Manufacturer, Trailer } from ".";

// Utils
import { isEmpty } from "../../../utils";

export class Truck {
  constructor(truck = {}) {
    this.id = truck.id;
    this.manufacturer = new Manufacturer(truck.manufacturer);
    this.model = truck.model;
    this.productionYear = truck.production_year;
    this.last4Vin = truck.last_4_vin;
    this.isActive = truck.is_active;
    this.createdAt = truck.created_at;
    this.updatedAt = truck.updated_at;
    this.trailers = !isEmpty(truck.trailers)
      ? truck.trailers.map(trailer => new Trailer(trailer))
      : [];
  }
}
