const CircledServiceTypeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M10.24 12.299h9.408c.194 0 .352-.23.352-.512v-1.03c0-.282-.158-.51-.352-.51H10.24c-.194 0-.351.228-.351.51v1.03c0 .283.157.512.351.512zM8.352 15.38h9.409c.194 0 .351-.23.351-.512v-1.03c0-.282-.157-.51-.351-.51H8.35c-.194 0-.351.228-.351.51v1.03c0 .283.157.512.352.512zM10.239 18.448l9.409.012c.194 0 .352-.23.352-.512V16.92c0-.282-.158-.511-.352-.511H10.24c-.194 0-.351.229-.351.511v1.018c0 .282.157.51.35.511z"
      ></path>
    </svg>
  );
};

export default CircledServiceTypeIcon;
