const WorkerArrow = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
      className={className}
      onClick={onClick}
    >
      <path
        fill="#393939"
        d="M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z"
      ></path>
    </svg>
  );
};

export default WorkerArrow;
