import { Verified } from "../../../../../SVGS";

// Utils
import { daysLeftTillEndOfSubscription } from "../../../../../utils/timeStampToDate";

// styles
import { PlanStyles } from "./plan.styles";

const Plan = ({ subscriptionPlanName, subscriptionPlanEndPeriod }) => {
  return (
    <PlanStyles>
      <div className="container">
        <div style={{ display: "block" }}>
          <Verified fill="#ffffff" />
        </div>
        <div style={{ marginLeft: "12px" }}>
          <h2 className="title">{subscriptionPlanName}</h2>
          <p className="smallText">Valid For</p>
          <p className="text">
            {daysLeftTillEndOfSubscription(
              new Date(subscriptionPlanEndPeriod).getTime() / 1000 // The function works with a timestamp in seconds
            )}{" "}
            days
          </p>
        </div>
      </div>
    </PlanStyles>
  );
};

export default Plan;
