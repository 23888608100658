import styled from "styled-components";

export const ServiceTypeFormCardStyles = styled.div`
  background: ${({ isActive }) => (isActive ? "#F2F9FD" : "#fafbfc")};
  border: 1px solid ${({ isActive }) => (isActive ? "#74BDE9" : "#dfe1e6")};
  border-radius: 3px;
  width: 336px;
  margin: 0 24px 24px 0;
  padding: 15px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .iconAndCheckboxContainer {
    display: flex;
    align-items: center;

    .checkboxesContainer {
      margin-left: 24px;
    }
  }

  .serviceTypeName {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #172b4d;
  }

  .capacityInput {
    width: 68px;
    background: #ffffff;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    padding: 8px 9px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #44444f99;
    outline: none;
  }
`;
