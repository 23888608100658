const TowingIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
      style={{ ...style }}
    >
      <path
        fill="#445570"
        d="M7.565 7.071c.432 0 .783.352.783.786v7.857h14.87c.431 0 .782.352.782.786v.786c0 1.3-1.053 2.357-2.348 2.357 0 1.3-1.053 2.357-2.348 2.357a2.355 2.355 0 01-2.348-2.357H14.87c0 1.3-1.054 2.357-2.348 2.357a2.355 2.355 0 01-2.348-2.357H7.826c0 1.3-1.053 2.357-2.348 2.357a2.355 2.355 0 01-2.348-2.357h-.782A2.355 2.355 0 010 17.286V16.5h2.348a.784.784 0 00.782-.786.784.784 0 00-.782-.786H0V11c0-2.166 1.755-3.929 3.913-3.929h3.652zM5.478 18.857a.785.785 0 000 1.572.785.785 0 000-1.572zm7.044 0a.785.785 0 000 1.572.785.785 0 000-1.572zm6.782 0a.785.785 0 000 1.572.785.785 0 000-1.572zM21.13 0a2.355 2.355 0 012.348 2.357c0 1.18-.738 1.717-1.565 2.548V7.5c0 .277.124.53.31.627a2.353 2.353 0 011.255 2.087c0 1.3-1.053 2.357-2.348 2.357a2.355 2.355 0 01-2.348-2.357c0-.434.351-.785.783-.785.432 0 .783.351.783.785 0 .434.35.786.782.786a.785.785 0 00.364-1.482c-.707-.374-1.146-1.147-1.146-2.018V6.476l-7.637 7.667H9.913v-3.857C19.815.344 19.841.01 20.981 0h.15zM5.217 8.643H3.913A2.355 2.355 0 001.565 11v2.357h3.652V8.643zM21.13 1.57a.785.785 0 000 1.572.785.785 0 000-1.572z"
      ></path>
    </svg>
  );
};

export default TowingIcon;
