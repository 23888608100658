// Domain Models
import { Auth, User, Branch, MenuItem, DashboardData } from "../../app/domain";

// Utils
import { isEmpty } from "../../../utils";

export class AuthenticationResponse {
  constructor(response = {}) {
    this.auth = new Auth(response.data.auth);
    this.user = new User(response.data.user);
    this.menuItems = !isEmpty(response.data.menu_items)
      ? response.data.menu_items.map(item => new MenuItem(item))
      : [];
    this.branches = !isEmpty(response.data.branches)
      ? response.data.branches.map(branch => new Branch(branch))
      : [];
    this.dashboardData = new DashboardData(response.data.dashboard_data);
  }
}
