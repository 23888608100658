import { toast } from "react-toastify";

// Action types
import * as actionTypes from "../actionTypes/service-requests.types";

export const getServiceRequests = isSupportRole => {
  return {
    type: actionTypes.FETCH_SERVICE_REQUESTS_REQUEST,
    isSupportRole
  };
};

export const getServiceRequestsSuccess = requests => {
  return {
    type: actionTypes.FETCH_SERVICE_REQUESTS_SUCCESS,
    requests
  };
};

export const addNewServiceRequest = serviceRequest => {
  return {
    type: actionTypes.ADD_NEW_SERVICE_REQUEST,
    serviceRequest
  };
};

export const approveServiceRequests = (serviceId, branchId) => {
  return {
    type: actionTypes.APPROVE_SERVICE_REQUESTS_REQUEST,
    serviceId,
    branchId
  };
};

export const approveServiceRequestsSuccess = (serviceId, messageResponse) => {
  toast.success(messageResponse);
  return {
    type: actionTypes.APPROVE_SERVICE_REQUESTS_SUCCESS,
    serviceId
  };
};

export const openDeclineReason = (serviceRequestId, branchId) => {
  return {
    type: actionTypes.OPEN_DECLINE_REASON,
    serviceRequestId,
    branchId
  };
};

export const openTicketDetails = (serviceId, branchId) => {
  return {
    type: actionTypes.OPEN_TICKET_DETAILS,
    serviceId,
    branchId
  };
};

export const openTicketDetailsSuccess = serviceRequest => {
  return {
    type: actionTypes.OPEN_TICKET_DETAILS_SUCCESS,
    serviceRequest
  };
};

export const closeModals = () => {
  return {
    type: actionTypes.CLOSE_SERVICE_REQUESTS_MODAL
  };
};

export const declineServiceRequests = (
  serviceId,
  declineReasonFormValues,
  branchId
) => {
  return {
    type: actionTypes.DECLINE_SERVICE_REQUESTS_REQUEST,
    serviceId,
    declineReasonFormValues,
    branchId
  };
};

// Covers messages, eta & booked for
// Payload comes from UI exact as it should be for request
export const updateServiceRequest = (serviceId, payload) => {
  return {
    type: actionTypes.UPDATE_SERVICE_REQUEST_REQUEST,
    serviceId,
    payload
  };
};

export const updateServiceRequestSuccess = serviceRequest => {
  toast.success("Service request successfully updated.");
  return {
    type: actionTypes.UPDATE_SERVICE_REQUEST_SUCCESS,
    serviceRequest
  };
};

export const declineServiceRequestsSuccess = (serviceId, messageResponse) => {
  toast.success(messageResponse);
  return {
    type: actionTypes.DECLINE_SERVICE_REQUESTS_SUCCESS,
    serviceId
  };
};

export const setTiketDetailsModalType = tiketDetailsModalType => {
  return {
    type: actionTypes.SET_TIKET_DETAILS_MODAL,
    tiketDetailsModalType
  };
};

export const setNewMessageToServiceRequest = serviceIdFromNewMessage => {
  return {
    type: actionTypes.SET_NEW_MESSAGE_IN_SERVICE_REQUEST,
    serviceId: serviceIdFromNewMessage
  };
};

export const seeNewMessages = serviceId => {
  return {
    type: actionTypes.SERVICES_REQUESTS_SEE_NEW_MESSAGES,
    serviceId
  };
};
