// Domain Models
import { User } from ".";

// Utils
import { isEmpty } from "../../../utils";

export class ServiceComment {
  constructor(serviceComment = {}) {
    this.id = serviceComment.id;
    this.comment = serviceComment.comment;
    this.createdAt = serviceComment.created_at;
    this.user = !isEmpty(serviceComment.user)
      ? new User(serviceComment.user)
      : undefined;
  }
}
