import styled from "styled-components";

export const MessageStyles = styled.div`
  display: flex;
  justify-content: ${({ isMyMessage }) =>
    isMyMessage ? "flex-end" : "flex-start"};

  .messageContainer {
    display: flex;
    padding: 10px;
    background: ${({ isMyMessage }) => (isMyMessage ? "#74bde9" : "#FFFFFF")};
    border: 1px solid
      ${({ isMyMessage }) => (isMyMessage ? "#55a4d1" : "#DFE1E6")};
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .time {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-right: ${({ isMyMessage }) => (isMyMessage ? "10px" : "0px")};
    order: ${({ isMyMessage }) => (isMyMessage ? "1" : "2")};
    color: ${({ isMyMessage }) => (isMyMessage ? "#fffc" : "#172b4d66")};
    min-width: 55px;
  }

  .message {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    margin-right: ${({ isMyMessage }) => (isMyMessage ? "0px" : "10px")};
    order: ${({ isMyMessage }) => (isMyMessage ? "2" : "1")};
    color: ${({ isMyMessage }) => (isMyMessage ? "#fff" : "#262626")};
  }
`;
