import { apiService } from "../api.service";

// Requests
import { BranchRequest } from "../../models/api/requests";

// Responses
import {
  BranchResponse,
  BranchesResponse,
  MessageResponse
} from "../../models/api/responses";
import { isEmpty } from "../../utils";

/**
 * Create branch Request
 * @param branchFormValues - BranchFormModel
 * @return - BranchResponse || ErrorResponse
 */
export const createBranchRequest = async branchFormValues => {
  const payload = new BranchRequest(branchFormValues);
  const response = await apiService.post("/branches", payload);
  if (response.hasError) {
    return response;
  }
  return new BranchResponse(response);
};

/**
 * Fetch branches Request
 * @return - BranchesResponse || ErrorResponse
 */
export const fetchBranchesRequest = async (lastBranch, searchQuery) => {
  const limit = 10;
  let query = `?with_relations=true&pagination[per_page]=${limit}`;
  if (!isEmpty(lastBranch)) {
    query = query + `&pagination[from]=${lastBranch}`;
  }

  if (searchQuery) {
    query = query + `&search=${searchQuery}`;
  }

  const response = await apiService.get(`/branches${query}`);
  if (response.hasError) {
    return response;
  }
  return new BranchesResponse(response);
};

/**
 * Fetch branch Request
 * @return - BranchResponse || ErrorResponse
 */
export const fetchBranchRequest = async id => {
  const response = await apiService.get(`/branches/${id}`);
  if (response.hasError) {
    return response;
  }
  return new BranchResponse(response);
};

/**
 * Update branch Request
 * @return - BranchResponse || ErrorResponse
 */
export const updateBranchRequest = async (
  branchFormValues,
  initialValues,
  id
) => {
  const payload = new BranchRequest(branchFormValues);
  payload.removeSameValues(initialValues);
  const response = await apiService.patch(`/branches/${id}`, payload);
  if (response.hasError) {
    return response;
  }
  return new BranchResponse(response);
};

/**
 * Delte branch Request
 * @param id - string | number
 * @return - MessageResponse || ErrorResponse
 */
export const removeBranchRequest = async id => {
  const response = await apiService.delete(`/branches/${id}`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse({
    data: { message: "Branch successfully deleted!" }
  });
};
