import { DropDownArrow } from "../../../../../SVGS";

import { VerifyBusinessStyles } from "./verify-business.styles";

const VerifyBusiness = () => {
  return (
    <VerifyBusinessStyles>
      <div>
        <h2 className="title">Verify Your Business</h2>
        <p className="text">Follow this link to verify your business</p>
      </div>
      <div className="arrowContainer">
        <DropDownArrow />
      </div>
    </VerifyBusinessStyles>
  );
};

export default VerifyBusiness;
