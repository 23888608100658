import {
  Dashboard,
  BranchSettingsIcon,
  ManagerIcon,
  BusinessProfileIcon,
  ServiceRequestsIcon,
  ServicesIcon,
  EmployeeScheduleIcon,
  AnalyticsIcon
} from "../SVGS";

// Constants
import * as menuItemsKeys from "../constants/menu-items/menu-items.constants";

// Utils
import { isEmpty } from "../utils";

const navigation = [
  {
    key: menuItemsKeys.DASHBOARD,
    path: "/",
    name: "Dashboard",
    Icon: Dashboard
  },
  {
    key: menuItemsKeys.MANAGE_BRANCHES,
    path: "/branches",
    name: "Manage Branches",
    Icon: BranchSettingsIcon
  },
  {
    key: menuItemsKeys.MANAGERS,
    path: "/managers",
    name: "Managers",
    Icon: ManagerIcon
  },
  {
    key: menuItemsKeys.BUSINESS_PROFILE,
    path: "/business-profile",
    name: "Business Profile",
    Icon: BusinessProfileIcon
  },
  {
    key: menuItemsKeys.SERVICE_REQUESTS,
    path: "/service-requests",
    name: "Service Requests",
    Icon: ServiceRequestsIcon
  },
  {
    key: menuItemsKeys.SERVICES,
    path: "/services",
    name: "Services",
    Icon: ServicesIcon
  },
  {
    key: menuItemsKeys.SCHEDULE,
    path: "/employee-schedule",
    name: "Employee Schedule",
    Icon: EmployeeScheduleIcon
  },
  {
    key: menuItemsKeys.ANALYTICS,
    path: "/analytics",
    name: "Analytics",
    Icon: AnalyticsIcon
  }
];

export const generateMeniLinks = menuItems => {
  if (!isEmpty(menuItems)) {
    const menuItemsKeys = menuItems.map(item => item.key);
    return navigation.filter(link => menuItemsKeys.includes(link.key));
  }
  return [];
};
