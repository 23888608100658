// Domain Models
import { Manufacturer, TrailerType } from ".";

export class Trailer {
  constructor(trailer = {}) {
    this.id = trailer.id;
    this.manufacturer = new Manufacturer(trailer.manufacturer);
    this.type = new TrailerType(trailer.type);
    this.productionYear = trailer.production_year;
    this.last4Vin = trailer.last_4_vin;
    this.isActive = trailer.is_active;
    this.createdAt = trailer.created_at;
    this.updatedAt = trailer.updated_at;
  }
}
