import { memo } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { TextField, Button } from "../../../components/UI";
import { Label } from "../../../components/typography";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Form Models
import { ForgotPasswrodFormModel } from "../../../models/app/forms";

// Services
import { forgotPasswordValidate } from "../../../services/validations/forgotPassword.validations";

// Calls
import { forgotPasswordRequest } from "../../../api/calls/app.call";

// Styles
import { ForgotPasswordFormStyles } from "./forgotPassword.styles";

const ForgotPasswordForm = ({ closeModal }) => {
  const handleForgotPassword = async values => {
    const response = await forgotPasswordRequest(values);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success(response.message);
    closeModal();
  };

  const formik = useFormik({
    initialValues: new ForgotPasswrodFormModel(),
    onSubmit: handleForgotPassword,
    validate: forgotPasswordValidate
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;
  return (
    <ForgotPasswordFormStyles>
      <form onSubmit={handleSubmit}>
        <div>
          <Label style={{ marginBottom: 2 }}>Email</Label>
          <TextField
            placeholder="Your email"
            name="email"
            type="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="buttonContainer">
          <Button
            className="submitButton"
            type="submit"
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            SEND EMAIL
          </Button>
        </div>
      </form>
    </ForgotPasswordFormStyles>
  );
};

export default memo(ForgotPasswordForm);
