import styled from "styled-components";

export const PlanCardStyles = styled.div`
  background: ${({ selected }) => (selected ? "#F2F9FD" : "#ffffff")};
  border: ${({ selected }) =>
    selected ? "1px solid #74BDE9" : " 1px solid #e2e2ea"};
  border-radius: 3px;
  width: 24%;
  padding: 24px;

  .planTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #171725;
    margin-bottom: 8px;
  }

  .planDescription {
    margin: 15px 0 24px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #696974;
  }

  .featureContainer {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .featureName {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-left: 5px;
    }
  }

  .choosePlanButton {
    width: 100%;
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #172b4d;
    padding: 10px;
  }

  .choosePlanButton:hover {
    background: #202650;
    border: 1px solid #202650;
    color: #ffffff;
  }

  .cancelSubscription {
    background: #b92500 !important;
    border: 1px solid #b92500 !important;
    color: #ffffff;
    padding: 10px;
  }

  .chosenPlanText {
    width: 100%;
    height: 37px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #172b4d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
