import { Link } from "react-router-dom";
// styles
import { NavLinkStyles } from "./navLink.styles";

const NavLink = ({ isActive, path, Icon, name }) => {
  return (
    <Link style={{ textDecoration: "none" }} to={path}>
      <NavLinkStyles isActive={isActive}>
        <Icon />
        <p className="linkName">{name}</p>
      </NavLinkStyles>
    </Link>
  );
};

export default NavLink;
