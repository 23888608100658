export const TOKEN = "TRUCKFIX_ERP_TOKEN";
export const REFRESH_TOKEN = "TRUCKFIX_ERP_REFRESH_TOKEN";
export const TOKEN_EXPIRATION_DATE = "TRUCKFIX_ERP_TOKEN_EXPIRATION_DATE";
export const MENU_ITEMS = "TRUCKFIX_ERP_MENU_ITEMS";
export const ACTIVE_BRANCH = "TRUCKFIX_ERP_ACTIVE_BRANCH";
export const NOTIFICATION_PRESETS = "TRUCKFIX_NOTIFICATION_PRESETS";
export const OPENED_SERVICE_DETAILS_BRANCH =
  "TRUCKFIX_ERP_OPENED_SERVICE_DETAILS_BRANCH";
export const OPENED_SERVICE_REQUEST_DETAILS_BRANCH =
  "OPENED_SERVICE_REQUEST_DETAILS_BRANCH";
