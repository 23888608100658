const TruckIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="30"
      fill="none"
      viewBox="0 0 24 30"
      style={{ ...style }}
    >
      <path
        fill="#172B4D"
        d="M22.855 24.52v-4.625c0-.994-.758-1.8-1.694-1.8h-.442V1.205a.458.458 0 00-.19-.375L19.454.075c-.282-.197-.657.017-.657.376v5.424l-.32-.919c-.431-1.235-1.54-2.054-2.78-2.054H8.296c-1.247 0-2.36.829-2.786 2.074l-.307.899V.45c0-.359-.375-.573-.657-.376L3.471.83a.458.458 0 00-.19.376v16.889h-.443c-.935 0-1.693.806-1.693 1.8v4.626H.423c-.233 0-.423.201-.423.45v2.487c0 .248.19.45.423.45H2.25V29.1c0 .497.38.9.847.9H5.34c.467 0 .847-.403.847-.9v-1.192h11.625V29.1c0 .497.379.9.846.9h2.244c.468 0 .847-.403.847-.9v-1.192h1.827c.233 0 .423-.202.423-.45V24.97c0-.249-.19-.45-.423-.45h-.722zM5.765 8.382h12.47v4.434H5.766V8.38zm-.291 14.88H4.028c-.442 0-.8-.38-.8-.85v-.021c0-.47.358-.85.8-.85h1.446c.442 0 .8.38.8.85v.021c0 .47-.358.85-.8.85zm9.404.251H9.122c-.468 0-.847-.403-.847-.9v-5.701c0-.497.379-.9.847-.9h5.756c.468 0 .847.403.847.9v5.701c0 .497-.38.9-.847.9zm5.094-.25h-1.446c-.442 0-.8-.381-.8-.851v-.021c0-.47.358-.85.8-.85h1.446c.442 0 .8.38.8.85v.021c0 .47-.358.85-.8.85zm-5.223-3.958H9.238c-.232 0-.42-.2-.42-.445v-.01c0-.245.188-.445.42-.445h5.512c.232 0 .42.2.42.445v.01c0 .246-.188.445-.42.445zm0-1.8H9.238c-.232 0-.42-.2-.42-.445v-.01c0-.245.188-.445.42-.445h5.512c.232 0 .42.2.42.446v.009c0 .246-.188.445-.42.445zm0 5.4H9.238c-.232 0-.42-.2-.42-.446v-.009c0-.246.188-.445.42-.445h5.512c.232 0 .42.2.42.445v.01c0 .245-.188.445-.42.445zm0-1.8H9.238c-.232 0-.42-.2-.42-.446v-.009c0-.246.188-.445.42-.445h5.512c.232 0 .42.2.42.445v.01c0 .245-.188.445-.42.445z"
      ></path>
    </svg>
  );
};

export default TruckIcon;
