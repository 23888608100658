export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICES_FAILED = "FETCH_SERVICES_FAILED";
export const OPEN_SERVICE_DETAILS = "OPEN_SERVICE_DETAILS";
export const OPEN_SERVICE_DETAILS_SUCCESS = "OPEN_SERVICE_DETAILS_SUCCESS";
export const OPEN_SERVICE_DETAILS_FAILED = "OPEN_SERVICE_DETAILS_FAILED";
export const CLOSE_MODAL = "SERVICES_CLOSE_MODAL";
export const SERVICES_CLOSE_CENTRAL_MODAL = "SERVICES_CLOSE_CENTRAL_MODAL";
export const CANCEL_SERVICE_REQUEST = "CANCEL_SERVICE_REQUEST";
export const CANCEL_SERVICE_SUCCESS = "CANCEL_SERVICE_SUCCESS";
export const CANCEL_SERVICE_FAILED = "CANCEL_SERVICE_FAILED";
export const START_SERVICE_REQUEST = "START_SERVICE_REQUEST";
export const START_SERVICE_SUCCESS = "START_SERVICE_SUCCESS";
export const START_SERVICE_FAILED = "START_SERVICE_FAILED";
export const PAUSE_SERVICE_REQUEST = "PAUSE_SERVICE_REQUEST";
export const PAUSE_SERVICE_SUCCESS = "PAUSE_SERVICE_SUCCESS";
export const PAUSE_SERVICE_FAILED = "PAUSE_SERVICE_FAILED";
export const RESUME_SERVICE_REQUEST = "RESUME_SERVICE_REQUEST";
export const RESUME_SERVICE_SUCCESS = "RESUME_SERVICE_SUCCESS";
export const RESUME_SERVICE_FAILED = "RESUME_SERVICE_FAILED";
export const FINISH_SERVICE_REQUEST = "FINISH_SERVICE_REQUEST";
export const FINISH_SERVICE_SUCCESS = "FINISH_SERVICE_SUCCESS";
export const FINISH_SERVICE_FAILED = "FINISH_SERVICE_FAILED";
export const OPEN_WORKERS_FORM = "OPEN_WORKERS_FORM";
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILED = "UPDATE_SERVICE_FAILED";
export const SERVICES_SET_TIKET_DETAILS_MODAL =
  "SERVICES_SET_TIKET_DETAILS_MODAL";
export const SERVICES_CLEAR_SHOULD_REFETCH = "SERVICES_CLEAR_SHOULD_REFETCH";
export const FETCH_MORE_SERVICES_REQUEST = "FETCH_MORE_SERVICES_REQUEST";
export const FETCH_MORE_SERVICES_SUCCESS = "FETCH_MORE_SERVICES_SUCCESS";
export const FETCH_MORE_SERVICES_FAILED = "FETCH_MORE_SERVICES_FAILED";
export const SERVICES_SET_NEW_MESSAGES = "SERVICES_SET_NEW_MESSAGES";
export const SERVICES_SEE_NEW_MESSAGES = "SERVICES_SEE_NEW_MESSAGES";

// SCHEDULE
export const FETCH_EMPLOYEES_SCHEDULE_REQUESTS =
  "FETCH_EMPLOYEES_SCHEDULE_REQUESTS";
export const FETCH_EMPLOYEES_SCHEDULE_SUCCESS =
  "FETCH_EMPLOYEES_SCHEDULE_SUCCESS";
export const FETCH_EMPLOYEES_SCHEDULE_FAILED =
  "FETCH_EMPLOYEES_SCHEDULE_FAILED";
export const RESET_SCHEDULE_ON_PAGE_LEAVE = "RESET_SCHEDULE_ON_PAGE_LEAVE";
