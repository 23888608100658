import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "../actionTypes/bussiness.types";
import { toast } from "react-toastify";

// Calls
import {
  fetchBusinessRequest,
  updateBusinessProfileRequest
} from "../../api/calls/business.call";
import { fetchActiveSubscriptionRequest } from "../../api/calls/subscription.call";

// Actions
import {
  fetchBusinessDataSuccess,
  updateBusinessProfileSuccess,
  updateBusinessProfileFailed,
  fetchActiveSubscriptionSuccess
} from "../actions/business.actions";

// Fetch business profile logic

function* getBussinessData() {
  const response = yield call(fetchBusinessRequest);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_BUSINESS_FAILED
    });
  }
  yield put(fetchBusinessDataSuccess(response));
}

function* getBussinessDataWatcher() {
  yield takeLatest(types.FETCH_BUSINESS_REQUEST, getBussinessData);
}

// Update business profile logic

function* updateBusinessProfile(action) {
  const response = yield call(
    updateBusinessProfileRequest,
    action.businessProfileFormValues,
    action.businessProfileInitialValues
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put(updateBusinessProfileFailed(response));
  }
  yield put(updateBusinessProfileSuccess(response));
}

function* updateBusinessProfileWatcher() {
  yield takeLatest(
    types.UPDATE_BUSINESS_PROFILE_REQUEST,
    updateBusinessProfile
  );
}

// Get Active Subscription  logic

function* getActiveSubscription() {
  const response = yield call(fetchActiveSubscriptionRequest);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_ACTIVE_SUBSCRIPTION_FAILED
    });
  }
  yield put(fetchActiveSubscriptionSuccess(response.activeSubscription));
}

function* getActiveSubscriptionWatcher() {
  yield takeLatest(
    types.FETCH_ACTIVE_SUBSCRIPTION_REQUEST,
    getActiveSubscription
  );
}

export default function* bussinessSaga() {
  yield all([
    getBussinessDataWatcher(),
    updateBusinessProfileWatcher(),
    getActiveSubscriptionWatcher()
  ]);
}
