import styled from "styled-components";
import * as color from "../../constants/color.constants";

const ParagraphStyles = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  word-break: break-all;
  color: ${color.PRIMARY_COLOR};
`;

const Paragraph = ({ children, onClick, className, style }) => (
  <ParagraphStyles className={className} onClick={onClick} style={{ ...style }}>
    {children}
  </ParagraphStyles>
);

export default Paragraph;
