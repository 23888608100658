import styled from "styled-components";

export const MyProfileFormStyles = styled.div`
  .topInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputContainer {
    width: 48%;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
