import { useState, useRef } from "react";
// icons
import { WorkerArrow } from "../../../SVGS";
// styles
import { WorkerItemStyles } from "./workerItem.styles";

const WorkerItem = ({ firstName, lastName, contactNumber, email }) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const element = useRef(null);

  const collapseSection = chosenElement => {
    const sectionHeight = chosenElement.scrollHeight;
    const elementTransition = chosenElement.style.transition;
    chosenElement.style.transition = "";
    requestAnimationFrame(() => {
      chosenElement.style.height = `${sectionHeight}px`;
      chosenElement.style.transition = elementTransition;
      requestAnimationFrame(() => {
        chosenElement.style.height = `${0}px`;
      });
    });
  };

  const expandSection = chosenElement => {
    const sectionHeight = chosenElement.scrollHeight;
    chosenElement.style.height = `${sectionHeight}px`;
  };

  return (
    <WorkerItemStyles>
      <div
        className="nameContainer"
        onClick={() => {
          setIsDescriptionVisible(prevState => !prevState);
          if (isDescriptionVisible) {
            collapseSection(element.current);
          } else {
            expandSection(element.current);
          }
        }}
      >
        <h5 className="name">{`${firstName} ${lastName}`}</h5>
        <WorkerArrow
          className={`arrow  ${isDescriptionVisible && "arrowUp"}`}
        />
      </div>
      <div ref={element} className="description">
        <p className="data data1">{email}</p>
        <p className="data">{contactNumber}</p>
      </div>
    </WorkerItemStyles>
  );
};

export default WorkerItem;
