// Domain Models
import { Price, SubscriptionPlan } from ".";

export class SubscriptionItem {
  constructor(item = {}) {
    this.id = item.id;
    this.quantity = item.quantity;
    this.subscriptionPlan = new SubscriptionPlan(item.subscription_plan);
    this.subscriptionPrice = new Price(item.subscription_price);
  }
}
