export const IN_PROGRESS = "in-progress";
export const PENDING = "pending";
export const PAUSED = "paused";
export const CANCELED = "canceled";
export const FINISHED = "finished";
export const ACCEPTED = "accepted";
export const ACTIVE = "active";
export const DECLINED = "declined";
export const DELETED = "deleted";
export const FLEET_CANCELED = "fleet-canceled";
export const EXTERNAL = "external";
