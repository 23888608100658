import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

// Components
import {
  Header,
  Body,
  Footer,
  Button,
  TimePicker
} from "../../../components/UI";
import { Heading2, Label } from "../../../components/typography";

// Icons
import { Close } from "../../../SVGS";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Utils
import { getUtcDateObject } from "../../../utils/timeStampToDate";

// Styles
import { BookedForFormStyles } from "./bookedFor.styles";

const BookedForForm = ({ initialDateValue, closeModal, updateBookigDate }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (initialDateValue) {
      setValue(getUtcDateObject(initialDateValue));
    }
  }, [initialDateValue]);

  return (
    <BookedForFormStyles>
      <Header className="header">
        <Heading2 style={{ textTransform: "uppercase" }}>
          Change booking date and time
        </Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body className="formBody">
        <DatePicker
          wrapperClassName="customDatepicker"
          selected={value}
          onChange={date => setValue(date)}
          inline
        />
        <div className="inputContainer">
          <Label>Time</Label>
          <TimePicker
            value={value}
            dateFormat="hh : mm aa"
            handleChange={date => setValue(date)}
          />
        </div>
      </Body>
      <Footer className="footer">
        <Button
          onClick={() => updateBookigDate(value)}
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Save Changes
        </Button>
      </Footer>
    </BookedForFormStyles>
  );
};

export default BookedForForm;
