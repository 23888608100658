import styled from "styled-components";

export const GeneralSettingsFormStyles = styled.div`
  .inputsFlexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inputContainer {
      width: 48%;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .button {
        background: #fafbfc;
        border: 1px solid #dfe1e6;
        border-radius: 6px;
        width: fit-content;
        margin-top: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1px;
        color: #172b4d;
        padding: 10px;
      }

      .inputMargin {
        margin-top: 2px;
      }

      .checkboxesContainer {
        margin-top: 2px;
        padding-top: 10px;
      }
    }
  }

  .isActive {
    border-bottom: 1px solid #f1f1f5;
  }

  .inputSeparator {
    padding-top: 20px;
  }

  .dragAndDropText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8d8d94;
    margin: 2px 0 8px 0;
    word-break: normal;
  }

  .submitContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .modalCard {
    width: 440px !important;
    max-height: 90% !important;

    .card {
      overflow: unset;
    }
  }

  .priceRangeContainer {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .priceInput {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 9px 8px;
    width: 100%;
    outline: none;
    text-align: left;
    padding-left: 15px;
  }

  .priceInput:focus {
    border: 1px solid #2f76ff;
  }

  .priceInput::placeholder {
    opacity: 0.5;
  }

  .priceSpan {
    position: relative;
  }

  .priceSpan::before {
    content: "$";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .separator {
    margin: 0 10px;
  }
`;
