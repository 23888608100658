export class RegisterAccountForm {
  constructor(data = {}) {
    this.email = data?.email || "";
    this.firstName = data?.firstName || "";
    this.lastName = data?.lastName || "";
    this.password = data?.password || "";
    this.passwordConfirmation = data?.passwordConfirmation || "";
  }
}

export class BussinesForm {
  constructor(data = {}) {
    this.name = data?.name || "";
    this.proofDocumentationUrl =
      data?.proofDocument?.url || data?.proofDocumentationUrl || "";
    this.websiteUrl = data?.websiteUrl || "";
    this.taxId = data?.taxId || "";
    this.address = data?.address || "";
    this.state = data?.state || "";
    this.postalCode = data?.postalCode || "";
    this.city = data?.city || "";
    this.longitude = data?.longitude || 0;
    this.latitude = data?.latitude || 0;
  }
}
