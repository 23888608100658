import styled from "styled-components";

export const ServiceTypeAndCapacityFormStyles = styled.div`
  .serviceTypesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .cards {
      min-width: 336px;
      width: 48%;
      margin: 0 0 24px 0;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
