import styled from "styled-components";

export const VerifyBusinessStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #172b4d;
    margin-top: 8px;
  }

  .arrowContainer {
    transform: rotate(-270deg);
  }
`;
