import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Paragraph } from "../../../../typography";
import { SideModal, Card, CentralModal } from "../../../../UI";
import ServiceTimeFrame from "./ServiceTimeFrame/ServiceTimeFrame";
import TiketDetails from "../../../../TiketDetails/TiketDetails";

// Containers
import Chat from "../../../../../containers/chat/Chat";

// Constants
// import * as serviceTypes from "../../../../../constants/services.constants";
import * as statuses from "../../../../../constants/status.constants";
import * as modalTypes from "../../../../../constants/serviceRequests.constants";

// Handlers
import {
  delcareRowHeight,
  firstTableScroll,
  secondTableScroll,
  expandEmployees
} from "./schedule.handlers";

// Domain Models
import {
  WorkerSchedule,
  EmployeeScheduleService
} from "../../../../../models/app/domain";

// Actions
import {
  openTicketDetails,
  closeModal,
  closeCentralModal,
  setNewMessageToService,
  seeNewMessages
} from "../../../../../redux/actions/services.actions";
import { setNewChatMessage } from "../../../../../redux/actions/chat.actions";

// Containers
import WorkersForm from "../../../../../containers/forms/workers/Workers.form";

// Services
import { enableScroll, disableScroll } from "../../../../../utils";

// dummyData
import { timeSlots } from "../../../../../dummyData/employeeSchedule.data";

// Styles
import { ScheduleStyles } from "./schedule.styles";

const Schedule = ({ dateQuery, isChatOpen, setIsChatOpen }) => {
  const dispatch = useDispatch();
  const {
    selectedService,
    tiketDetailsModalType,
    employees,
    centralModalType,
    workersFormData,
    workDay
  } = useSelector(state => state.servicesReducer);
  const { newMessage } = useSelector(state => state.chatReducer);
  const [modefiedEmployees, setModefiedEmployees] = useState([]);

  const opensAt = workDay?.opensAt;
  const closesAt = workDay?.closesAt;

  const modefiedTimeSlots = timeSlots;

  useEffect(() => {
    setModefiedEmployees(
      expandEmployees(
        employees,
        modefiedTimeSlots,
        dateQuery,
        opensAt,
        closesAt
      )
    );
  }, [employees, dateQuery, modefiedTimeSlots, opensAt, closesAt]);

  const setActiveService = (employeeId, serviceId, serviceStatus) => {
    setModefiedEmployees(
      modefiedEmployees.map(employee =>
        employeeId === employee.id
          ? new WorkerSchedule({
              ...employee,
              [serviceStatus]: employee[serviceStatus].map(service =>
                service.id === serviceId
                  ? new EmployeeScheduleService({
                      ...service,
                      hasMore: service.hasMore
                        ? service.hasMore.map(ser =>
                            ser.id === serviceId
                              ? new EmployeeScheduleService({
                                  ...ser,
                                  isActive: true
                                })
                              : new EmployeeScheduleService({
                                  ...ser,
                                  isActive: false
                                })
                          )
                        : null,
                      isActive: true
                    })
                  : new EmployeeScheduleService({
                      ...service,
                      hasMore: service.hasMore
                        ? service.hasMore.map(ser =>
                            ser.id === serviceId
                              ? new EmployeeScheduleService({
                                  ...ser,
                                  isActive: false
                                })
                              : ser
                          )
                        : null,
                      isActive: false
                    })
              )
            })
          : employee
      )
    );
  };

  useEffect(() => {
    if (newMessage && !isChatOpen) {
      dispatch(setNewMessageToService(parseInt(newMessage.serviceId, 0)));
      dispatch(setNewChatMessage(undefined));
    }
  }, [newMessage, dispatch, isChatOpen]);

  const toggleChat = serviceId => {
    dispatch(setNewChatMessage(undefined));
    dispatch(seeNewMessages(serviceId));
    setIsChatOpen(prevValue => !prevValue);
  };

  return (
    <ScheduleStyles timesSlotsLength={modefiedTimeSlots.length}>
      {opensAt === undefined &&
      closesAt === undefined &&
      modefiedEmployees.length ? (
        <div className="palceholder">
          <p className="text">We are not working on this date</p>
        </div>
      ) : modefiedEmployees.length ? (
        <>
          <div className="firstTable">
            <div className="timeSlot" style={{ height: 20 }}>
              {""}
            </div>
            <div
              id="firstTable"
              className="firstTableBody"
              onScroll={firstTableScroll}
            >
              {modefiedEmployees.map((employee, i) => (
                <div
                  className={`nameCol ${
                    i === 0 && "firstColumn"
                  } ${delcareRowHeight(employee)}`}
                  key={employee.id}
                >
                  <Paragraph className="name">
                    {employee.firstName} {employee.lastName}
                  </Paragraph>
                </div>
              ))}
            </div>
          </div>
          <div className="table">
            <div className="tableHead">
              <div className="firstCol">
                <div className="timeSlot" style={{ height: 20 }}>
                  {""}
                </div>
              </div>
              {modefiedTimeSlots.map(timeslot => (
                <div className="col" key={timeslot}>
                  <Paragraph className="tableHeadText">{timeslot}</Paragraph>
                </div>
              ))}
            </div>
            <div
              id="secondTableContent"
              onScroll={secondTableScroll}
              className="listContent"
            >
              {modefiedEmployees.map((employee, i) => (
                <div
                  className={`tableBody ${i === 0 && "firstColumn"} `}
                  key={employee.id}
                >
                  {modefiedTimeSlots.map(timeslot => (
                    <div
                      className={`col column ${delcareRowHeight(employee)}`}
                      key={timeslot}
                    >
                      <Paragraph style={{ width: 104 }}>{""}</Paragraph>
                    </div>
                  ))}
                  <div className="scheduleContent">
                    {employee.inProgressServices && (
                      <div className="inProgressContainer">
                        {employee.inProgressServices.map(service => {
                          return (
                            <ServiceTimeFrame
                              {...service}
                              openTicketAction={() => {
                                disableScroll();
                                dispatch(openTicketDetails(service.id));
                              }}
                              employee={employee}
                              key={service.id}
                              type={statuses.IN_PROGRESS}
                              typeOfService="inProgressServices"
                              setActiveService={setActiveService}
                            />
                          );
                        })}
                      </div>
                    )}
                    {employee.pausedServices && (
                      <div className="pausedContainer">
                        {employee.pausedServices.map(service => {
                          return (
                            <ServiceTimeFrame
                              {...service}
                              openTicketAction={() => {
                                disableScroll();
                                dispatch(openTicketDetails(service.id));
                              }}
                              employee={employee}
                              key={service.id}
                              type={statuses.PAUSED}
                              typeOfService="pausedServices"
                              setActiveService={setActiveService}
                            />
                          );
                        })}
                      </div>
                    )}
                    {employee.acceptedServices && (
                      <div className="pendingContainer">
                        {employee.acceptedServices.map(service => {
                          return (
                            <ServiceTimeFrame
                              {...service}
                              openTicketAction={() => {
                                disableScroll();
                                dispatch(openTicketDetails(service.id));
                              }}
                              employee={employee}
                              key={service.id}
                              type={statuses.PENDING}
                              typeOfService="acceptedServices"
                              setActiveService={setActiveService}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="palceholder">
          <p className="text">Select Employee to see their schedule</p>
        </div>
      )}

      {selectedService && (
        <SideModal
          closeModal={() => {
            enableScroll();
            dispatch(closeModal());
            setIsChatOpen(false);
          }}
        >
          <TiketDetails
            tiketDetailsModalType={tiketDetailsModalType}
            serviceDetails={selectedService}
            toggleChat={() => toggleChat(selectedService.id)}
            closeModal={() => {
              enableScroll();
              dispatch(closeModal());
              setIsChatOpen(false);
            }}
            shouldShowWokers
          />
          {isChatOpen && <Chat service={selectedService} />}
        </SideModal>
      )}

      {centralModalType === modalTypes.WORKERS && (
        <CentralModal
          className="modalCard"
          closeModal={() => {
            enableScroll();
            dispatch(closeCentralModal());
          }}
        >
          <Card>
            <WorkersForm
              closeModal={() => {
                enableScroll();
                dispatch(closeCentralModal());
              }}
              workersFormData={workersFormData}
            />
          </Card>
        </CentralModal>
      )}
    </ScheduleStyles>
  );
};

export default Schedule;
