import { useFormik } from "formik";
import { toast } from "react-toastify";
// Components
import { Heading2 } from "../../../../../components/typography";
import { Body, Header } from "../../../../../components/UI";
import ServiceForm from "../ServiceForm/ServiceForm";

// Icons
import { Close } from "../../../../../SVGS";

// Form Models
import { ServiceMenuFormModel } from "../../../../../models/app/forms";

// Calls
import { createServiceMenuRequest } from "../../../../../api/calls/general-branch-settings.call";

// Services
import { serviceValidation } from "../../../../../services/validations/service.validation";

// Styles
import { AddServiceStyles } from "./addService.styles";

const AddService = ({
  closeModal,
  branchId,
  setServiceMenuStateAfterCreateOrEdit
}) => {
  const createServiceHandler = async values => {
    const response = await createServiceMenuRequest(branchId, values);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Service successfully created.");
    setServiceMenuStateAfterCreateOrEdit(response.serviceMenu);
  };

  const formik = useFormik({
    initialValues: new ServiceMenuFormModel(),
    onSubmit: createServiceHandler,
    validate: serviceValidation
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange
  } = formik;

  return (
    <AddServiceStyles>
      <Header className="formHeader">
        <Heading2>ADD SERVICE</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <ServiceForm
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            buttonText="Add Service"
          />
        </form>
      </Body>
    </AddServiceStyles>
  );
};

export default AddService;
