import { CircledProfileIcon, CircledSettingsIcon } from "../SVGS";
import * as tabsValues from "../constants/accountSettingsTabs.constants";

export const tabsBuilder = [
  {
    tabName: "My Profile",
    icon: <CircledProfileIcon />,
    value: tabsValues.MY_PROFILE
  },
  {
    tabName: "Change Password",
    icon: <CircledSettingsIcon />,
    value: tabsValues.CHANGE_PASSOWRD
  }
];
