const ServicesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <g fill="#F7F8FC" clipPath="url(#clip0_483:5442)">
        <path d="M15.3 1.7h-.85V.85c0-.51-.34-.85-.85-.85s-.85.34-.85.85v.85h-8.5V.85C4.25.34 3.91 0 3.4 0s-.85.34-.85.85v.85H.85c-.425 0-.85.34-.85.85v11.9c0 .51.425.85.85.85h5.185a6.814 6.814 0 01-.935-3.4c0-3.74 3.06-6.8 6.8-6.8 1.615 0 3.06.595 4.25 1.53V2.55c0-.425-.425-.85-.85-.85z"></path>
        <path d="M11.9 6.8c-2.805 0-5.1 2.295-5.1 5.1 0 2.805 2.295 5.1 5.1 5.1 2.805 0 5.1-2.295 5.1-5.1 0-2.805-2.295-5.1-5.1-5.1zm1.7 5.95h-1.7c-.51 0-.85-.34-.85-.85V9.35c0-.51.34-.85.85-.85s.85.34.85.85v1.7h.85c.51 0 .85.34.85.85s-.34.85-.85.85z"></path>
      </g>
      <defs>
        <clipPath id="clip0_483:5442">
          <path fill="#fff" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ServicesIcon;
