import { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { Button, ServiceTypeFormCard } from "../../../../components/UI";

// Form Models
import { BranchServiceTypeFormModel } from "../../../../models/app/forms";

// Calls
import {
  fetchBranchServiceTypes,
  updateBranchServiceTypes
} from "../../../../api/calls/general-branch-settings.call";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Validations
import { branchServiceTypeValidation } from "../../../../services/validations/service-type-and-capacity.validation";

// Styles
import { ServiceTypeAndCapacityFormStyles } from "./serviceTypeAndCapacityForm.styles";

const ServiceTypeAndCapacityForm = ({ branchId }) => {
  const { systemComponents } = useSelector(state => state.app);
  const [initialValues, setInitialValue] = useState([]);

  const getBranchServiceTypes = useCallback(async () => {
    const response = await fetchBranchServiceTypes(branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setInitialValue(
      response.branchServiceTypes.map(
        branchServiceType => new BranchServiceTypeFormModel(branchServiceType)
      )
    );
  }, [branchId]);

  useEffect(() => {
    getBranchServiceTypes();
  }, [getBranchServiceTypes]);

  const updateServiceTypesHandler = async values => {
    const response = await updateBranchServiceTypes(
      { branchServiceTypes: values },
      branchId
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Branch service types successfully updated.");
    setInitialValue(
      response.branchServiceTypes.map(
        branchServiceType => new BranchServiceTypeFormModel(branchServiceType)
      )
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: updateServiceTypesHandler,
    validate: branchServiceTypeValidation
  });

  const { values, errors, handleSubmit, setValues } = formik;

  /**
   * Set mobility of branch service type and add branch service type if doesnt exists
   * Note: Inside branchServiceType IF block don't change order of conditions it will break UI behaviour
   * @param serviceType - The value it checks
   * @param mobility - 'shop' || 'mobile'
   * @returns void
   */
  const handleCheckBoxChange = (serviceType, mobility) => {
    let branchServiceType = values.find(
      branchServiceType => branchServiceType.id === serviceType.id
    );
    if (branchServiceType) {
      // Must be first condition
      if (branchServiceType.mobility === "all") {
        if (mobility === "shop") {
          setValues(prevValues =>
            prevValues.map(type =>
              type.id === branchServiceType.id
                ? new BranchServiceTypeFormModel({
                    ...branchServiceType,
                    mobility: "mobile"
                  })
                : type
            )
          );
          return;
        }

        setValues(prevValues =>
          prevValues.map(type =>
            type.id === branchServiceType.id
              ? new BranchServiceTypeFormModel({
                  ...branchServiceType,
                  mobility: "shop"
                })
              : type
          )
        );

        return;
      }

      // Must be second condition
      if (mobility !== branchServiceType.mobility) {
        setValues(prevValues =>
          prevValues.map(type =>
            type.id === branchServiceType.id
              ? new BranchServiceTypeFormModel({
                  ...branchServiceType,
                  mobility: "all"
                })
              : type
          )
        );
        return;
      }

      setValues(prevValues =>
        prevValues.filter(type => type.id !== branchServiceType.id)
      );
      return;
    }

    branchServiceType = new BranchServiceTypeFormModel({
      id: serviceType.id,
      capacity: 1,
      mobility: mobility
    });

    setValues(prevValues => [...prevValues, branchServiceType]);
  };

  /**
   * Set capacity of branch service type
   * @param e - input element
   * @param id
   * @returns void
   */
  const handleCapactyChange = useCallback(
    (e, id) => {
      const handleNumbersChangeOnly = previousValue => {
        let inputValue = e.target.validity.valid
          ? parseInt(e.target.value, 0)
          : previousValue;
        if (isNaN(inputValue)) {
          inputValue = "";
        }

        return inputValue;
      };

      setValues(prevValues =>
        prevValues.map(serviceType =>
          serviceType.id === id
            ? new BranchServiceTypeFormModel({
                ...serviceType,
                capacity: handleNumbersChangeOnly(serviceType.capacity)
              })
            : serviceType
        )
      );
    },
    [setValues]
  );

  return (
    <ServiceTypeAndCapacityFormStyles>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (errors.values) {
            toast.error(errors.values);
            return;
          }
          handleSubmit();
        }}
        className="form"
      >
        <div className="serviceTypesContainer">
          {systemComponents?.serviceTypes?.map(serviceType => (
            <ServiceTypeFormCard
              key={serviceType.id}
              serviceType={serviceType}
              value={values.find(type => type.id === serviceType.id)}
              handleCapactyChange={handleCapactyChange}
            />
          ))}
        </div>
        <div className="buttonsContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
      </form>
    </ServiceTypeAndCapacityFormStyles>
  );
};

export default ServiceTypeAndCapacityForm;
