import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import swal from "sweetalert";

// Components
import Row from "../../../../../components/lists/Row";
import SpecialDayItem from "../../../../../components/lists/generalBranchSettings/SpecialDay.item";
import { Label } from "../../../../../components/typography";
import {
  Header,
  TextField,
  Button,
  DatePicker,
  Body
} from "../../../../../components/UI";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";

// Utils
import { isEmpty } from "../../../../../utils";

// Form Models
import { SpecialDayFormModel } from "../../../../../models/app/forms";

// Calls
import {
  fetchSpecialDays,
  createSpecialDayRequest,
  deleteSpecialDayRequest
} from "../../../../../api/calls/general-branch-settings.call";

// Services
import { specialDayValidation } from "../../../../../services/validations/specialDays.validation";

// Styles
import { SpecialDaysStyles } from "./specialDays.styles";

const SpecialDays = ({ branchId }) => {
  const [specialDays, setSpecialDays] = useState([]);

  const getSpecailDays = useCallback(async () => {
    const response = await fetchSpecialDays(branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setSpecialDays(response.specialDays);
  }, [branchId]);

  useEffect(() => {
    getSpecailDays();
  }, [getSpecailDays]);

  const createSpecialDay = async (values, { resetForm }) => {
    const response = await createSpecialDayRequest(values, branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Special day successfully added to branch.");
    resetForm();
    setSpecialDays([...specialDays, response.specialDay]);
  };

  const formik = useFormik({
    initialValues: new SpecialDayFormModel(),
    onSubmit: createSpecialDay,
    validate: specialDayValidation
  });

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } =
    formik;

  const datepickeOnChangeHandler = (name, date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = `${date.getFullYear()}`;

    const formatedDay = day < 10 ? `0${day}` : `${day}`;
    const formatedMonth = month < 10 ? `0${month}` : `${month}`;

    setFieldValue(name, `${formatedMonth}/${formatedDay}/${year}`);
  };

  const convertStringToDateObject = dateString => {
    if (isEmpty(dateString)) {
      return dateString;
    }
    const splitedDateString = dateString.split("/");
    const month = splitedDateString[0];
    const day = splitedDateString[1];
    const year = splitedDateString[2];
    return new Date(
      parseInt(year, 0),
      parseInt(month - 1, 0),
      parseInt(day, 0)
    );
  };

  const removeSpecialDay = async id => {
    const result = await swal({
      title: "Delete Special day?",
      text: "Are you sure you want to delete this special day?",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }
    const response = await deleteSpecialDayRequest(id, branchId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success(response.message);
    setSpecialDays(specialDays.filter(day => day.id !== id));
  };

  return (
    <SpecialDaysStyles>
      <Header>
        <form
          autoComplete="new-password"
          onSubmit={handleSubmit}
          className="formContainer"
        >
          <div className="inputsContainer">
            <div className="inputContainer">
              <Label>Special Day Name</Label>
              <TextField
                value={values.name}
                touched={touched.name}
                error={errors.name}
                // onBlur={handleBlur}
                onChange={handleChange}
                name="name"
                className="input"
                placeholder="Name"
              />
            </div>
            <div className="inputContainer">
              <Label>Date</Label>
              <DatePicker
                value={convertStringToDateObject(values.date)}
                touched={touched.date}
                error={errors.date}
                name="date"
                className="input"
                dateFormat="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                onChange={datepickeOnChangeHandler}
              />
            </div>
          </div>
          <Button
            className="button"
            type="submit"
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            Add date
          </Button>
        </form>
      </Header>
      {!isEmpty(specialDays) && (
        <Body>
          <Label>Added Special Days</Label>
          <div className="specialDaysList">
            {specialDays.map(({ id, name, date }) => (
              <Row key={id}>
                <SpecialDayItem
                  dayName={name}
                  date={date}
                  removeAction={() => removeSpecialDay(id)}
                />
              </Row>
            ))}
          </div>
        </Body>
      )}
    </SpecialDaysStyles>
  );
};

export default SpecialDays;
