import { useSelector } from "react-redux";

export default function useSupportRole() {
  const { user, menuItems } = useSelector(state => state.app);
  const supportRole = user?.roles.find(role => role.name === "Support");
  const isSupportRole = !!supportRole;

  const itemsToShow = [
    "dashboard",
    "service-requests",
    "services",
    "manage-branches"
  ];
  const supportRoleMenuItems = menuItems?.filter(item =>
    itemsToShow.includes(item.key)
  );

  return { isSupportRole, supportRole, supportRoleMenuItems };
}
