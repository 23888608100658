export class BranchGeneralSettingsRequest {
  constructor(branchGeneralSettingsFormValues = {}) {
    this.images = branchGeneralSettingsFormValues.images;
    this.has_parking = branchGeneralSettingsFormValues.hasParking;
    this.description = branchGeneralSettingsFormValues.description;
    this.speciality = branchGeneralSettingsFormValues.speciality;
    this.hourly_price_from = branchGeneralSettingsFormValues.hourlyPriceFrom;
    this.hourly_price_to = branchGeneralSettingsFormValues.hourlyPriceTo;
    this.truck_manufacturers = branchGeneralSettingsFormValues.supportedTrucks;
    this.trailer_manufacturers =
      branchGeneralSettingsFormValues.supportedTrailers;
    this.trailer_types = branchGeneralSettingsFormValues.supportedTrailerTypes;
    this.payment_types = branchGeneralSettingsFormValues.supportedPaymentTypes;
    this.load_images = true;
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (
        key !== "images" &&
        key !== "has_parking" &&
        key !== "load_images" &&
        initValuesArr.includes(value)
      ) {
        delete this[key];
      }
    });
  }
}
