import { Carousel } from "react-responsive-carousel";
// styles
import { SliderStyles } from "./slider.styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Slider = ({ images }) => {
  return (
    <SliderStyles>
      <Carousel
        autoPlay
        className="slider"
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        infiniteLoop
        interval={2500}
      >
        {images.map((image, i) => (
          <div className="imageContainer" key={i}>
            <img src={image} alt={`slide${i}`} className="image" />
          </div>
        ))}
      </Carousel>
    </SliderStyles>
  );
};

export default Slider;
