// Domain Models
import {
  ServiceType,
  Manufacturer,
  TrailerType,
  PaymentType
} from "../../app/domain";

export class SystemComponentsResponse {
  constructor(response = {}) {
    this.trailerTypes = response.data.trailer_types.map(
      type => new TrailerType(type)
    );
    this.truckManufacturers = response.data.truck_manufacturers.map(
      manufacturer => new Manufacturer(manufacturer)
    );
    this.trailerManufacturers = response.data.trailer_manufacturers.map(
      manufacturer => new Manufacturer(manufacturer)
    );
    this.serviceTypes = response.data.service_types.map(
      service => new ServiceType(service)
    );
    this.paymentTypes = response.data.payment_types.map(
      paymentType => new PaymentType(paymentType)
    );
  }
}
