// Pages
import {
  Login,
  Register,
  ResetPassword,
  PrivacyPolicy,
  BusinessProfile,
  SubscriptionPlans,
  AccountSettings,
  ManageBranches,
  AddBranch,
  EditBranch,
  GeneralBranchSettings,
  Managers,
  ServiceRequests,
  Services,
  EmployeeSchedule,
  Analytics,
  Dashboard
} from "../pages";

// Constants
import * as menuItemsKeys from "../constants/menu-items/menu-items.constants";

// Utils
import { isEmpty } from "../utils";

const privateRoutes = [
  {
    key: menuItemsKeys.DASHBOARD,
    path: "/",
    component: Dashboard,
    exact: true,
    fullLayout: false
  },
  {
    key: menuItemsKeys.BUSINESS_PROFILE,
    path: "/business-profile",
    component: BusinessProfile,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.BUSINESS_PROFILE,
    path: "/subscription-plans",
    component: SubscriptionPlans,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.BUSINESS_PROFILE,
    path: "/account-settings",
    component: AccountSettings,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.MANAGE_BRANCHES,
    path: "/branches",
    component: ManageBranches,
    exact: true,
    fullLayout: false
  },
  {
    key: menuItemsKeys.MANAGE_BRANCHES,
    path: "/create-branch",
    component: AddBranch,
    exact: true,
    fullLayout: false
  },
  {
    key: menuItemsKeys.MANAGE_BRANCHES,
    path: "/branches/:id",
    component: EditBranch,
    exact: true,
    fullLayout: false
  },
  {
    key: menuItemsKeys.MANAGE_BRANCHES,
    path: "/branch-settings/:id",
    component: GeneralBranchSettings,
    exact: true,
    fullLayout: false
  },
  {
    key: menuItemsKeys.MANAGERS,
    path: "/managers",
    component: Managers,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.SERVICE_REQUESTS,
    path: "/service-requests",
    component: ServiceRequests,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.SERVICES,
    path: "/services",
    component: Services,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.SCHEDULE,
    path: "/employee-schedule",
    component: EmployeeSchedule,
    exact: false,
    fullLayout: false
  },
  {
    key: menuItemsKeys.ANALYTICS,
    path: "/analytics",
    component: Analytics,
    exact: false,
    fullLayout: false
  }
];

const publicRoutes = [
  {
    path: "/",
    component: Login,
    exact: true,
    fullLayout: true
  },
  {
    path: "/register",
    component: Register,
    exact: false,
    fullLayout: true
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    exact: false,
    fullLayout: true
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: false,
    fullLayout: true
  }
];

export const generateRoutes = (token, menuItems) => {
  if (!isEmpty(token) && !isEmpty(menuItems)) {
    const menuItemsKeys = menuItems.map(item => item.key);
    return privateRoutes.filter(route => menuItemsKeys.includes(route.key));
  }
  return publicRoutes;
};
