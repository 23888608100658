const Rating = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#202650"
        d="M2.8 13.574c.146.108.324.095.565-.082L6.95 10.87l3.587 2.622c.24.177.418.19.564.082.146-.108.197-.273.102-.571l-1.39-4.215 3.605-2.602c.248-.178.311-.35.254-.515-.05-.171-.203-.273-.514-.266l-4.437.006L7.364 1.19C7.269.898 7.129.784 6.95.784s-.31.114-.406.406L5.18 5.41.743 5.405C.438 5.398.286 5.5.23 5.67c-.05.165.013.337.254.515l3.605 2.602-1.383 4.215c-.096.298-.051.463.095.571z"
      ></path>
    </svg>
  );
};

export default Rating;
