import { apiService } from "../api.service";

// Requests
import { SubscribeRequest } from "../../models/api/requests";

// Responses
import {
  RedirectUrlResponse,
  SubscriptionPlansResponse,
  ActiveSubscriptionResponse,
  MessageResponse
} from "../../models/api/responses";

/**
 * Billing Request
 * @return - RedirectUrlResponse || ErrorResponse
 */
export const billingRequest = async () => {
  const response = await apiService.post(`/payment/sessions/portal`);
  if (response.hasError) {
    return response;
  }
  return new RedirectUrlResponse(response);
};

/**
 * Set up Billing Request
 * @return - RedirectUrlResponse || ErrorResponse
 */
export const setUpBillingRequest = async () => {
  const response = await apiService.post(`/payment/sessions/checkout/setup`);
  if (response.hasError) {
    return response;
  }
  return new RedirectUrlResponse(response);
};

/**
 * Fetch Subscription plans Request
 * @return - SubscriptionPlansResponse || ErrorResponse
 */
export const fetchSubscriptionPlansRequest = async () => {
  const response = await apiService.get(`/payment/store/subscription-plans`);
  if (response.hasError) {
    return response;
  }
  return new SubscriptionPlansResponse(response);
};

/**
 * Fetch Active Subscription Request
 * @return - ActiveSubscriptionResponse || ErrorResponse
 */
export const fetchActiveSubscriptionRequest = async () => {
  const response = await apiService.get(`/businesses/subscription`);
  if (response.hasError) {
    return response;
  }
  return new ActiveSubscriptionResponse(response);
};

/**
 * Subscribe to plan Request
 * @return - RedirectUrlResponse || ErrorResponse
 */
export const subscribeToPlanRequest = async subscriptionPlan => {
  const payload = new SubscribeRequest(subscriptionPlan);
  const response = await apiService.post(`/payment/subscriptions`, payload);
  if (response.hasError) {
    return response;
  }
  return new RedirectUrlResponse(response);
};

/**
 * Cancel subscription Request
 * @return - MessageResponse || ErrorResponse
 */
export const cancelSubscribeRequest = async () => {
  const response = await apiService.delete(`/payment/subscriptions`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Resume subscription Request
 * @return - MessageResponse || ErrorResponse
 */
export const resumeSubscribeRequest = async () => {
  const response = await apiService.post(`/payment/subscriptions/resume`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Change subscription plan Request
 * @return - MessageResponse || ErrorResponse
 */
export const changeSubscriptionPlanRequest = async subscriptionPlan => {
  const payload = new SubscribeRequest(subscriptionPlan);
  const response = await apiService.put(`/payment/subscriptions`, payload);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};
