// Domain Models
import { ServiceMenu } from "../../app/domain";

export class ServiceMenuResponse {
  constructor(response = {}) {
    this.serviceMenu = response.data.service_menu.map(
      menu => new ServiceMenu(menu)
    );
  }
}
