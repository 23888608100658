// Domain Models
import { SubscriptionPlan } from "../../app/domain";

export class SubscriptionPlansResponse {
  constructor(response = {}) {
    this.subscriptionPlans = response.data.subscription_plans.map(
      plan => new SubscriptionPlan(plan)
    );
  }
}
