import { memo } from "react";
// styles
import { TabStyles } from "./tab.styles";

const Tab = ({ icon, tabName, value, isActive, onClick }) => {
  return (
    <TabStyles onClick={() => onClick(value)} isActive={isActive}>
      {icon}
      <p className="tabName">{tabName}</p>
    </TabStyles>
  );
};

export default memo(Tab);
