import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { MoonLoader } from "react-spinners";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Button, Card, Header } from "../components/UI";
import { Heading2, Label } from "../components/typography";

// Page specific components
import BranchCard from "../components/PageSpecificComponents/pagesComponents/manageBranches/branchCard/BranchCard";

// Builders
import * as buttonDesignType from "../builders/buttonDesign.types";

// Actions
import { deleteBranch } from "../redux/actions/app.actions";

// Calls
import {
  fetchBranchesRequest,
  removeBranchRequest
} from "../api/calls/branch.call";

// Styles
import { ManageBranchesStyles } from "../pagesStyles/manageBranches.styles";
import { SearchIcon } from "../SVGS";
import useDebounce from "../hooks/useDebounce";

const ManageBranches = () => {
  const [branches, setBranches] = useState([]);
  const [branchesPagination, setBranchesPagination] = useState({});
  const { setSearchQuery, debouncedSearchQuery } = useDebounce();
  const [loading, setLoading] = useState(false);
  const { activeBranch } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const history = useHistory();
  const lastBranch = branches[branches.length - 1];

  const getBranches = async (lastBranchId, searchQuery = "") => {
    if (searchQuery && !lastBranchId) {
      setLoading(true);
    }
    const response = await fetchBranchesRequest(lastBranchId, searchQuery);
    setLoading(false);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }

    if (lastBranchId) {
      setBranches(prev => [
        ...prev,
        ...(Array.isArray(response.branches)
          ? response.branches
          : [response.branches])
      ]);
    } else {
      setBranches(
        Array.isArray(response.branches)
          ? response.branches
          : [response.branches]
      );
    }

    setBranchesPagination(response.pagination);
  };

  useEffect(() => {
    setBranches([]);
    setBranchesPagination({});
    getBranches(undefined, debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const deleteBranchHandler = async id => {
    const result = await swal({
      title: "Delete Branch?",
      text: "Are you sure you want to delete this branch? This action cannot be undone.",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }
    const response = await removeBranchRequest(id);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setBranches(pervValues => pervValues.filter(branch => branch.id !== id));
    dispatch(deleteBranch(id));
    toast.success(response.message);
  };

  const override = css`
    display: block;
    border-color: #d2e6f2;
    box-sizing: content-box;
  `;

  return (
    <ManageBranchesStyles>
      <PageHeader title="Manage Branches" />
      <Card className="headerCard">
        <Header className="header">
          <Heading2>YOUR BRANCHES</Heading2>
          <Button
            onClick={() => history.push("/create-branch")}
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            + Add New Branch
          </Button>
        </Header>
        <Header className="filters">
          <div style={{ marginRight: 24 }}>
            <Label>Search by name</Label>
            <div className="searchContainer">
              <SearchIcon className="icon" />
              <input
                className="searchInput"
                // value={idQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </Header>
      </Card>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <MoonLoader size={22} color="#74BDE9" css={override} />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={branches.length}
          next={() => getBranches(lastBranch?.id, debouncedSearchQuery)}
          hasMore={branchesPagination.has_more}
        >
          <div className="branchesList">
            {branches?.map(branch => {
              const branchId = branch.id;
              return (
                <BranchCard
                  {...branch}
                  isActive={branchId === activeBranch?.id}
                  key={branchId}
                  deleteBranch={() => deleteBranchHandler(branchId)}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      )}
    </ManageBranchesStyles>
  );
};

export default ManageBranches;
