import * as modalTypes from "../constants/generalSettingsModal.constants";

export const modalBuilder = {
  [modalTypes.SUPPORTED_TRUCKS]: {
    title: "Supported Trucks",
    inputLabel: "Add New Supported Truck",
    inputPlaceholder: "Select a truck",
    listLabel: "Supported Trucks"
  },
  [modalTypes.SUPPORTED_TRAILERS]: {
    title: "Supported Trailers",
    inputLabel: "Add New Supported Trailer",
    inputPlaceholder: "Select a trailer",
    listLabel: "Supported Trailers"
  },
  [modalTypes.SUPPORTED_TRAILER_TYPES]: {
    title: "Supported Trailer Types",
    inputLabel: "Add New Supported Trailer Type",
    inputPlaceholder: "Select a trailer type",
    listLabel: "Supported Trailer Types"
  },
  [modalTypes.SUPPORTED_PAYMENT_TYPES]: {
    title: "Payment Options",
    inputLabel: "Add New Payment Option",
    inputPlaceholder: "Select a payment option",
    listLabel: "Payment Options"
  }
};
