import { useSelector } from "react-redux";
import { useState, useRef, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

// Components
import { DatePicker } from "../../../../UI";
import { Label } from "../../../../typography";

// SVGS
import { SearchIcon, Close } from "../../../../../SVGS";

// Calls
import { fetchWorkersRequest } from "../../../../../api/calls/services.call";

// Hooks
import useDraggableHorizontalScroll from "../../../../../hooks/useDraggableHorizontalScroll";
import useClickOutside from "../../../../../hooks/useClickOutside";

// Styles
import { FiltersStyles } from "./filters.styles";

const Filters = ({
  dateQuery,
  setDateQuery,
  chosenEmployees,
  setChosenEmployees
}) => {
  const { activeBranch } = useSelector(state => state.app);
  const [isSearchVisibile, setIsSearchVisible] = useState(false);
  const [search, setSearch] = useState("");
  const employeesRef = useRef(null);
  const searchRef = useRef(null);
  const [options, setOptions] = useState([]);

  const getWorkers = useCallback(async () => {
    const response = await fetchWorkersRequest(search);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setOptions(response.workers);
  }, [search]);

  useEffect(() => {
    if (search) {
      getWorkers();
    }
  }, [search, getWorkers]);

  useEffect(() => {
    setSearch("");
    setOptions([]);
  }, [activeBranch]);

  const filteredEmployees = () => {
    const chosenEmployeesIds = chosenEmployees.map(employee => employee.id);
    const filteredOptionsIds = options
      .filter(
        employee =>
          employee.fullName
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) &&
          !chosenEmployeesIds.includes(employee.id)
      )
      .map(employee => employee.id);
    return options.filter(employee => filteredOptionsIds.includes(employee.id));
  };

  const handleDateChange = (name, date) => {
    setDateQuery(date);
  };

  useDraggableHorizontalScroll(employeesRef);

  useClickOutside(searchRef, () => setIsSearchVisible(false));

  return (
    <FiltersStyles>
      <div>
        <Label>Date</Label>
        <div>
          <DatePicker
            name="date"
            style={{ marginTop: 2, width: 171 }}
            shouldHaveError={false}
            value={dateQuery}
            dateFormat="MM/dd/yyyy"
            onChange={handleDateChange}
          />
        </div>
      </div>
      <div
        className={`employeeSearchInput ${
          chosenEmployees.length > 0 && "employeeSearchInputFullWidth"
        }`}
      >
        <Label>Search by ID</Label>
        <div className={`employeeSearch ${isSearchVisibile && "focusedField"}`}>
          <div ref={searchRef} className="searchContainer">
            <div className="searchInputContainer">
              <SearchIcon className="icon" />
              <input
                value={search}
                onChange={e => setSearch(e.target.value)}
                onFocus={() => {
                  setIsSearchVisible(true);
                }}
                className="searchInput"
                placeholder="Employee name"
              />
            </div>
            {search && isSearchVisibile && filteredEmployees().length > 0 && (
              <div className="searchResultsContainer">
                {filteredEmployees().map(employee => (
                  <button
                    onClick={() =>
                      setChosenEmployees([...chosenEmployees, employee])
                    }
                    className="searchResult"
                    key={employee.id}
                  >
                    {employee.fullName}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div ref={employeesRef} className="chosenEmployeesContainer">
            {chosenEmployees.map(employee => (
              <div className="employeePill" key={employee.id}>
                <p className="employeeName">{employee.fullName}</p>
                <Close
                  className="removeEmployeeIcon"
                  onClick={() =>
                    setChosenEmployees(
                      chosenEmployees.filter(
                        chosenEmployee => chosenEmployee.id !== employee.id
                      )
                    )
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </FiltersStyles>
  );
};

export default Filters;
