const SearchIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className={className}
    >
      <path
        fill="#92929D"
        fillRule="evenodd"
        d="M2.902 13.698A7.633 7.633 0 1114.4 12.89l3.012 3.012-1.178 1.179-2.976-2.976a7.634 7.634 0 01-10.355-.407zM12.52 4.08a5.967 5.967 0 10-8.438 8.438 5.967 5.967 0 008.438-8.438z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default SearchIcon;
