// Utils
import { isEmpty } from "../../../utils";

export class AnalythicsData {
  constructor(data = {}) {
    this.layer = data.layer || 1;
    this.backendValue = data.value;
    this.name = data.title;
    this.secondLayerName = !isEmpty(data.secondLayerName)
      ? data.secondLayerName
      : data.layer === 2
      ? this.name
      : undefined;
    this.value = 1000;
    this.color = data.decoration.color;
    this.children = !isEmpty(data.children)
      ? data.children.map(
          child =>
            new AnalythicsData({
              ...child,
              color: child.decoration.color,
              layer: this.layer + 1,
              secondLayerName: this.secondLayerName
            })
        )
      : null;
  }

  setColor(title, layer = 1, secondLayerName, children) {
    // if (isEmpty(children)) {
    //   return "transparent";
    // }
    if (layer === 1) {
      if (title.toLowerCase() === "requests") {
        return "#202650";
      }
      if (title.toLowerCase() === "employees") {
        return "#66CCFF";
      }
    }
    if (layer === 2) {
      if (title.toLowerCase() === "accepted") {
        return "#76FF84";
      }
      if (title.toLowerCase() === "declined") {
        return "#FF4747";
      }
      return "#66ccffbf";
    }

    if (layer > 2) {
      if (secondLayerName?.toLowerCase() === "accepted") {
        return "#76ff8480";
      }
      if (secondLayerName?.toLowerCase() === "declined") {
        return "#ff474780";
      }
      return "#66ccff80";
    }
  }
}
