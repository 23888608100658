import styled from "styled-components";

export const CheckboxButtonsStyles = styled.div`
  display: flex;
  align-items: center;

  .checkboxButton {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #172b4d;
    padding: 4px 10px;
    min-width: 45px;
    height: 28px;
    margin-right: 12px;
    cursor: pointer;
  }

  .active {
    background: rgba(116, 189, 233, 0.1);
    border: 1px solid #74bde9;
  }
`;
