import { toast } from "react-toastify";

// Action types
import * as actionTypes from "../actionTypes/services.types";

export const getServices = ({
  dateQuery,
  groupQuery,
  idQuery,
  limit = 10,
  lastService,
  isSupportRole
}) => {
  return {
    type: actionTypes.FETCH_SERVICES_REQUEST,
    dateQuery,
    groupQuery,
    idQuery,
    limit,
    lastService,
    isSupportRole
  };
};

export const getServicesSuccess = (services, pagination) => {
  return {
    type: actionTypes.FETCH_SERVICES_SUCCESS,
    services,
    pagination
  };
};

export const getMoreServices = ({
  dateQuery,
  groupQuery,
  idQuery,
  limit = 10,
  lastService
}) => {
  return {
    type: actionTypes.FETCH_MORE_SERVICES_REQUEST,
    dateQuery,
    groupQuery,
    idQuery,
    limit,
    lastService
  };
};

export const getMoreServicesSuccess = (services, pagination) => {
  return {
    type: actionTypes.FETCH_MORE_SERVICES_SUCCESS,
    services,
    pagination
  };
};

export const openTicketDetails = (serviceId, branchId) => {
  return {
    type: actionTypes.OPEN_SERVICE_DETAILS,
    serviceId,
    branchId
  };
};

export const openTicketDetailsSuccess = service => {
  return {
    type: actionTypes.OPEN_SERVICE_DETAILS_SUCCESS,
    service
  };
};

export const cancelService = serviceId => {
  return {
    type: actionTypes.CANCEL_SERVICE_REQUEST,
    serviceId
  };
};

export const cancelServiceSuccess = message => {
  toast.success(message);
  return {
    type: actionTypes.CANCEL_SERVICE_SUCCESS
  };
};

export const startService = (serviceId, branchId) => {
  return {
    type: actionTypes.START_SERVICE_REQUEST,
    serviceId,
    branchId
  };
};

export const startServiceSuccess = message => {
  toast.success(message);
  return {
    type: actionTypes.START_SERVICE_SUCCESS
  };
};

export const pauseService = serviceId => {
  return {
    type: actionTypes.PAUSE_SERVICE_REQUEST,
    serviceId
  };
};

export const pauseServiceSuccess = message => {
  toast.success(message);
  return {
    type: actionTypes.PAUSE_SERVICE_SUCCESS
  };
};

export const resumeService = serviceId => {
  return {
    type: actionTypes.RESUME_SERVICE_REQUEST,
    serviceId
  };
};

export const resumeServiceSuccess = message => {
  toast.success(message);
  return {
    type: actionTypes.RESUME_SERVICE_SUCCESS
  };
};

export const finishService = serviceId => {
  return {
    type: actionTypes.FINISH_SERVICE_REQUEST,
    serviceId
  };
};

export const finishServiceSuccess = message => {
  toast.success(message);
  return {
    type: actionTypes.FINISH_SERVICE_SUCCESS
  };
};

export const openWorkersForm = (serviceId, workers) => {
  return {
    type: actionTypes.OPEN_WORKERS_FORM,
    serviceId,
    workers
  };
};

export const updateService = (formValues, serviceId) => {
  return {
    type: actionTypes.UPDATE_SERVICE_REQUEST,
    formValues,
    serviceId
  };
};

export const updateServiceSuccess = service => {
  toast.success("Service updated successfully.");
  return {
    type: actionTypes.UPDATE_SERVICE_SUCCESS,
    service
  };
};

export const setNewMessageToService = serviceIdFromNewMessage => {
  return {
    type: actionTypes.SERVICES_SET_NEW_MESSAGES,
    serviceId: serviceIdFromNewMessage
  };
};

export const seeNewMessages = serviceId => {
  return {
    type: actionTypes.SERVICES_SEE_NEW_MESSAGES,
    serviceId
  };
};

export const clearShouldRefresh = () => {
  return {
    type: actionTypes.SERVICES_CLEAR_SHOULD_REFETCH
  };
};

export const setServicesTiketDetailsModalType = tiketDetailsModalType => {
  return {
    type: actionTypes.SERVICES_SET_TIKET_DETAILS_MODAL,
    tiketDetailsModalType
  };
};

export const closeCentralModal = () => {
  return {
    type: actionTypes.SERVICES_CLOSE_CENTRAL_MODAL
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL
  };
};

// SCHEDULE

export const getSchedule = (dateQuery, chosenFilter, chosenEmployees) => {
  return {
    type: actionTypes.FETCH_EMPLOYEES_SCHEDULE_REQUESTS,
    dateQuery,
    chosenFilter,
    chosenEmployees
  };
};

export const getScheduleSuccess = (employees, workDay) => {
  return {
    type: actionTypes.FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
    employees,
    workDay
  };
};

export const resetScheduleOnPageLeave = () => {
  return {
    type: actionTypes.RESET_SCHEDULE_ON_PAGE_LEAVE
  };
};
