import styled from "styled-components";

export const NotificationPresetItemStyles = styled.div`
  background: #ffffff;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .bellContainer {
    width: ${({ isAddNewPreset }) => (isAddNewPreset ? "17px" : "36px")};
    height: ${({ isAddNewPreset }) => (isAddNewPreset ? "17px" : "36px")};
    margin-right: 12px;
  }

  .presetText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #181d3c;
    word-break: break-all;
    width: 100%;
  }

  .newPresetText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #172b4d;
    word-break: break-all;
  }
`;
