// styles
import { SubscriptionPlanPriceStyles } from "./subscriptionPlanPrice.styles";

const SubscriptionPlanPrice = ({ price, className, designType }) => {
  return (
    <SubscriptionPlanPriceStyles designType={designType} className={className}>
      <p className="dollar">$</p>
      <p className="price">{price}</p>
      <p className="time">/month</p>
    </SubscriptionPlanPriceStyles>
  );
};

export default SubscriptionPlanPrice;
