import { apiService } from "../../api/api.service";

import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// Reducers
import rootReducer from "./root.reducer";

// Sagas
import rootSaga from "./root.saga";

const sagaMiddleware = createSagaMiddleware();

const withDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  withDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

apiService.setReduxStore(store);

export default store;
