import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Action types
import * as types from "../actionTypes/service-comments.types";

// Actions
import {
  getServiceCommentsSuccess,
  deleteCommentSuccess,
  createServiceCommentSuccess
} from "../actions/service-comments.actions";

// Calls
import {
  fetchServiceCommentsRequest,
  deleteCommentRequest,
  createServiceCommentRequest
} from "../../api/calls/service-comments.call";

// Fetch service comments logic
function* fetchServiceCommentsCall(action) {
  const response = yield call(fetchServiceCommentsRequest, action.serviceId);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_SERVICE_COMMENTS_FAILED
    });
  }
  yield put(getServiceCommentsSuccess(response.comments));
}

function* fetchServiceCommentsCallWatcher() {
  yield takeLatest(
    types.FETCH_SERVICE_COMMENTS_REQUEST,
    fetchServiceCommentsCall
  );
}

function* createServiceComment(action) {
  const response = yield call(
    createServiceCommentRequest,
    action.serviceId,
    action.serviceCommentFormValues
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.CREATE_SERVICE_COMMENT_FAILED
    });
  }
  yield put(createServiceCommentSuccess(response.comment));
}

function* createServiceCommentWatcher() {
  yield takeLatest(types.CREATE_SERVICE_COMMENT_REQUEST, createServiceComment);
}

function* deleteComment(action) {
  const response = yield call(deleteCommentRequest, action.id);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.DELETE_SERVICE_COMMENT_FAILED
    });
  }
  yield put(deleteCommentSuccess(response.message, action.id));
}

function* deleteCommentWatcher() {
  yield takeLatest(types.DELETE_SERVICE_COMMENT_REQUEST, deleteComment);
}

export default function* servicesSaga() {
  yield all([
    fetchServiceCommentsCallWatcher(),
    deleteCommentWatcher(),
    createServiceCommentWatcher()
  ]);
}
