import { useCallback } from "react";
// components
import Row from "../../../../../components/lists/Row";
import {
  TextField,
  Textarea,
  Checkbox,
  Button
} from "../../../../../components/UI";
import { Label, Paragraph } from "../../../../../components/typography";
// builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";
// styles
import { RoleFormStyles } from "./roleForm.styles";

const RoleForm = ({
  permissions,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  buttonText = "Edit Role"
}) => {
  const handleCheckBoxChange = useCallback(
    id => {
      if (values.permissions.includes(id)) {
        setFieldValue(
          "permissions",
          values.permissions.filter(permission => permission !== id)
        );
        return;
      }

      setFieldValue("permissions", [...values.permissions, id]);
    },
    [values.permissions, setFieldValue]
  );

  return (
    <RoleFormStyles>
      <div>
        <Label>Role Name</Label>
        <TextField
          name="name"
          value={values.name}
          touched={touched.name}
          error={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
          className="input"
          placeholder="Enter role name"
        />
      </div>
      <div>
        <Label>Role Description</Label>
        <Textarea
          name="description"
          value={values.description}
          touched={touched.description}
          error={errors.description}
          onChange={handleChange}
          onBlur={handleBlur}
          className="input"
          placeholder="Enter a short description about this role"
        />
      </div>
      <div>
        <Label>Role Permissions</Label>
        <div className="permissionsList">
          {permissions.map(({ id, description, name }, i) => (
            <Row className="row" key={i}>
              <Checkbox
                value={values.permissions.includes(id)}
                handleChange={() => handleCheckBoxChange(id)}
              />
              <div className="textContainer">
                <Paragraph className="permissionName">{name}</Paragraph>
                <Paragraph className="permissionDesc">{description}</Paragraph>
              </div>
            </Row>
          ))}
        </div>
      </div>
      <div className="buttonContainer">
        <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
          {buttonText}
        </Button>
      </div>
    </RoleFormStyles>
  );
};

export default RoleForm;
