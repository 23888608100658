import { toast } from "react-toastify";

// Action types
import * as actionTypes from "../actionTypes/service-comments.types";

export const getServiceComments = ({ serviceId }) => {
  return {
    type: actionTypes.FETCH_SERVICE_COMMENTS_REQUEST,
    serviceId
  };
};

export const getServiceCommentsSuccess = comments => {
  return {
    type: actionTypes.FETCH_SERVICE_COMMENTS_SUCCESS,
    comments
  };
};

export const deleteComment = id => {
  return {
    type: actionTypes.DELETE_SERVICE_COMMENT_REQUEST,
    id
  };
};

export const deleteCommentSuccess = (message, id) => {
  toast.success(message);
  return {
    type: actionTypes.DELETE_SERVICE_COMMENT_SUCCESS,
    id
  };
};

export const createServiceComment = (serviceId, serviceCommentFormValues) => {
  return {
    type: actionTypes.CREATE_SERVICE_COMMENT_REQUEST,
    serviceId,
    serviceCommentFormValues
  };
};

export const createServiceCommentSuccess = comment => {
  toast.success("Comment successfully created!");
  return {
    type: actionTypes.CREATE_SERVICE_COMMENT_SUCCESS,
    comment
  };
};
