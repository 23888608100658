import styled from "styled-components";

export const SubscriptionPlaceHolderStyles = styled.div`
  padding: 5px 0 42px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .placeholderText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #bcbcbc;
  }
`;
