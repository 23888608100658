import styled from "styled-components";

export const ChangePasswordFormStyles = styled.div`
  .inputContainer {
    width: 48%;
  }

  .bottomInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
