// Domain Models
import { SubscriptionFeature } from ".";

export class SubscriptionPlanFeature {
  constructor(subscriptionPlanFeature = {}) {
    this.supported = subscriptionPlanFeature.supported;
    this.subscriptionFeature = new SubscriptionFeature(
      subscriptionPlanFeature.subscription_feature
    );
  }
}
