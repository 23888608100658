// Components
import { Label, Paragraph, Span } from "../../../../typography";
import { Pill } from "../../../../UI";

// Icons
import { BluePin, FileIcon } from "../../../../../SVGS";

// Utils
import { isEmpty } from "../../../../../utils";

// Styles
import { BusinessProfileDetailsStyles } from "./businessProfileDetails.styles";

const BusinessProfileDetails = ({ business, location }) => {
  return (
    <BusinessProfileDetailsStyles>
      {business && location && (
        <>
          <div className="column">
            <div className="cell">
              <Label className="label">Business Name</Label>
              <Paragraph className="paragraph">{business.name}</Paragraph>
            </div>
            <div className="cell" style={{ marginTop: 27 }}>
              <Label className="label">State</Label>
              <Paragraph className="paragraph">{location.state}</Paragraph>
            </div>
          </div>
          <div className="column">
            <div className="cell">
              <Label className="label">HQ Address</Label>
              <div className="flexDiv">
                <BluePin />
                <Span className="span">{location.address}</Span>
              </div>
            </div>
            <div className="cell" style={{ marginTop: 27 }}>
              <Label className="label">ZIP/Postal Code</Label>
              <Paragraph className="paragraph">{location.postalCode}</Paragraph>
            </div>
          </div>
          <div className="column">
            <div className="cell">
              <div className="bussinessProofLabelContainer">
                <Label className="label">Business Proof</Label>
                {isEmpty(business.proofDocument.url) && (
                  <Label className="errorLabel">(No files uploaded)</Label>
                )}
              </div>

              <div className="flexDiv">
                {business.proofDocument.url && (
                  <FileIcon style={{ height: 15, marginRight: 9 }} />
                )}
                <Span
                  className="link"
                  style={{
                    color: business.proofDocument.url ? "#74bde9" : "black",
                    cursor: business.proofDocument.url && "pointer"
                  }}
                  onClick={() => {
                    business.proofDocument.url &&
                      window.open(business.proofDocument.url, "_blank");
                  }}
                >
                  {business.proofDocument.url
                    ? business.proofDocument.url
                    : "/"}
                </Span>
              </div>
            </div>
            <div className="cell" style={{ marginTop: 27 }}>
              <Label className="label">Website</Label>
              <Paragraph
                className="link"
                onClick={() => {
                  business.websiteUrl &&
                    window.open(business.websiteUrl, "_blank");
                }}
                style={{
                  color: business.websiteUrl ? "#74bde9" : "black",
                  cursor: business.websiteUrl && "pointer"
                }}
              >
                {business.websiteUrl ? business.websiteUrl : "/"}
              </Paragraph>
            </div>
          </div>
          <div className="column">
            <div className="cell">
              <Label className="label">Tax Identification Number</Label>
              <Paragraph className="paragraph">{business.taxId}</Paragraph>
            </div>
          </div>
          <div className="column">
            <div className="lastColumnContent cell">
              <div className="statusContainer">
                <Label className="label">Status</Label>
                <Pill
                  rounded
                  status={business.verificationStatus}
                  text={business.verificationStatus}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </BusinessProfileDetailsStyles>
  );
};

export default BusinessProfileDetails;
