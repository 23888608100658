const Dashboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FBFCFF"
        fillRule="evenodd"
        d="M1 6h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V7a1 1 0 011-1zm6-6h2a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1V1a1 1 0 011-1zm0 6h2a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1zm6-6h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V1a1 1 0 011-1zm0 6h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V7a1 1 0 011-1zM1 12h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1v-2a1 1 0 011-1zm6 0h2a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1v-2a1 1 0 011-1zm6 0h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Dashboard;
