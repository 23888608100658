const Dots = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="3"
      fill="none"
      viewBox="0 0 15 3"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <path
        fill="#9FADC3"
        d="M1.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM13.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      ></path>
    </svg>
  );
};

export default Dots;
