import { useDispatch } from "react-redux";

// Components
import { Paragraph } from "../../typography";
import { Button } from "../../UI";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Utils
import {
  convertTimeStampToDate,
  convertTimeStampToTime
} from "../../../utils/timeStampToDate";

// Actions
import { approveServiceRequests } from "../../../redux/actions/service-requests.actions";

// Styles
import { ServiceRequestItemStyles } from "./serviceRequestItem.styles";
import { useSelector } from "react-redux";

const ServiceRequestItem = ({ onClick, declineAction, request }) => {
  const dispatch = useDispatch();
  const { systemComponents } = useSelector(state => state.app);
  const serviceType = systemComponents.serviceTypes.find(
    type => type.typeKey === request.serviceTypeKey
  );
  return (
    <ServiceRequestItemStyles onClick={onClick}>
      <Paragraph className="ID Id">{request.id}</Paragraph>
      <Paragraph className="user userText">{`${request?.driver?.firstName} ${request?.driver?.lastName}`}</Paragraph>
      <Paragraph className="serviceType serviceTypeText">
        {serviceType?.name} - {request.mobility}
      </Paragraph>
      <Paragraph className="bookedFor date">
        {convertTimeStampToDate(request.time)}{" "}
        <span style={{ color: "#171725" }}>
          {convertTimeStampToTime(request.time)}
        </span>
      </Paragraph>
      <div className="messages">
        {request.newMessagesCount > 0 && <li className="msgs">New Message</li>}
      </div>

      <div className="actions">
        <Button
          className="actionButtons"
          style={{ marginRight: 12 }}
          buttonDesignType={buttonDesignType.PRIMARY}
          onClick={e => {
            e.stopPropagation();
            dispatch(approveServiceRequests(request.id, request.branchId));
          }}
        >
          Approve
        </Button>
        <Button
          onClick={declineAction}
          className="actionButtons"
          buttonDesignType={buttonDesignType.SECONDARY}
        >
          Decline
        </Button>
      </div>
    </ServiceRequestItemStyles>
  );
};

export default ServiceRequestItem;
