const Clock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        fill="#97A0AF"
        d="M7 .969A6.78 6.78 0 00.219 7.75 6.78 6.78 0 007 14.531a6.78 6.78 0 006.781-6.781A6.78 6.78 0 007 .969zm0 12.25A5.45 5.45 0 011.531 7.75 5.467 5.467 0 017 2.281c3.008 0 5.469 2.461 5.469 5.469A5.467 5.467 0 017 13.219zm1.668-2.844c.164.11.355.082.465-.082l.52-.684c.109-.164.081-.355-.083-.464l-1.804-1.34V3.922a.353.353 0 00-.329-.328h-.875a.332.332 0 00-.328.328v4.512c0 .082.028.191.11.246l2.324 1.695z"
      ></path>
    </svg>
  );
};

export default Clock;
