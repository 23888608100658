import { LocationRequest } from "./location.request";

export class UpdateBussinessProfileRequest {
  constructor(data = {}) {
    this.name = data?.name;
    this.proof_document_url = data?.proofDocumentationUrl;
    this.website_url = data?.websiteUrl;
    this.tax_id = data?.taxId;
    this.location = new LocationRequest({
      state: data?.state,
      city: data?.city,
      postalCode: data?.postalCode,
      address: data?.address,
      longitude: data?.longitude,
      latitude: data?.latitude
    });
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);

    Object.entries(this).forEach(([key, value]) => {
      if (key !== "location" && initValuesArr.includes(value)) {
        delete this[key];
      }
    });
  }
}
