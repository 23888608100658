const CircledServiceMenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M18.221 12.224L15.144 15.3l1.404 1.404 3.077-3.077-1.404-1.404zM14.678 15.769l-.125.124a.33.33 0 00-.08.13l-.467 1.403a.33.33 0 00.419.418l1.41-.473a.332.332 0 00.128-.08l.119-.118-1.404-1.404zM21.005 10.815c-.37-.37-1.034-.37-1.404 0l-.911.941 1.404 1.404.911-.941a.994.994 0 000-1.404z"
      ></path>
      <path
        fill="#fff"
        d="M11.655 14h3.855l3.116-3.122V9.66a.988.988 0 00-.987-.992h-.359V8.33a.33.33 0 10-.662 0v.336h-.662V8.33a.33.33 0 10-.661 0v.336h-.662V8.33a.33.33 0 10-.662 0v.336h-.662V8.33a.33.33 0 10-.662 0v.336h-.662V8.33a.33.33 0 10-.661 0v.336h-.331a.992.992 0 00-.993.99v8.646c0 .547.445.992.992.992h6.647c.548 0 .987-.44.987-.988v-2.75L16.43 17.76a1 1 0 01-.383.238l-1.413.474a.99.99 0 01-1.122-.361.975.975 0 01-.154-.801h-1.703a.33.33 0 110-.662h1.912l.22-.662h-2.132a.33.33 0 110-.662h2.531l.662-.662h-3.193a.33.33 0 110-.662zm0-2.647h2.426a.33.33 0 110 .662h-2.426a.33.33 0 110-.662zm0 1.324h3.798a.33.33 0 110 .662h-3.798a.33.33 0 110-.662z"
      ></path>
    </svg>
  );
};

export default CircledServiceMenuIcon;
