import styled from "styled-components";

export const GraphDataStyles = styled.div`
  width: 46.3%;
  height: 100%;

  .dataContainerCard {
    width: 100%;
    height: 100%;
  }

  .dataHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    .left {
      display: flex;
      align-items: center;

      .linksContainer {
        display: flex;
        align-items: center;
        margin-left: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1px;
      }
    }

    .dotsContainer {
      position: relative;
      display: flex;
      align-items: center;

      .popup {
        position: absolute;
        right: 0;
        background: #74bde9;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
          0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        padding: 12px;
        width: 154px;
        top: 20px;
        right: 5px;

        .pointer {
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 10px 10px;
          border-color: transparent transparent #74bde9 transparent;
          top: -10px;
          right: 0;
        }

        .exportText {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          margin-top: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .dataBody {
    height: calc(100% - 130px);
  }

  .dataFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ratingContainer {
      display: flex;
      align-items: center;

      .rating {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 1px;
        margin-left: 12px;
        color: #202650;
      }
    }
  }
`;
