import validator from "validator";

export const staffValidate = ({
  email,
  firstName,
  lastName,
  role,
  contactNumber
}) => {
  const errors = {};
  if (!email) {
    errors.email = "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  if (!firstName) {
    errors.firstName = "* Required";
  }

  if (!lastName) {
    errors.lastName = "* Required";
  }

  if (!role) {
    errors.role = "* Required";
  }

  if (!contactNumber) {
    errors.contactNumber = "* Required";
  } else if (contactNumber.replace(/ /g, "").length < 12) {
    errors.contactNumber = "* Invalid format";
  }

  return errors;
};
