const DropDownArrow = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="5"
      fill="none"
      viewBox="0 0 9 5"
      className={className}
      onClick={onClick}
    >
      <path
        fill="#292359"
        d="M.848 5c-.493 0-.739-.602-.383-.957l3.5-3.5a.561.561 0 01.793 0l3.5 3.5c.355.355.11.957-.383.957H.848z"
      ></path>
    </svg>
  );
};

export default DropDownArrow;
