import styled from "styled-components";

const HeaderStyles = styled.header`
  padding: 24px;
  border-bottom: 1px solid #f1f1f5;
`;

const Header = ({ children, style, className }) => (
  <HeaderStyles style={{ ...style }} className={className}>
    {children}
  </HeaderStyles>
);

export default Header;
