import styled from "styled-components";

export const RolesAndPermissionsStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    .addRole {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #fafafb;
    }
  }

  .listSubtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202650;
  }

  .body {
    padding: 0;
  }

  .modalCard {
    width: 620px !important;
    max-height: 90% !important;
  }
`;
