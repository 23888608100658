export const editBusinesProfileValidation = ({
  name,
  address,
  state,
  city,
  postalCode,
  taxId,
  proofDocumentationUrl,
  websiteUrl
}) => {
  const errors = {};

  if (!name) {
    errors.name = "* Required";
  }

  if (!address) {
    errors.address = "* Required";
  }

  if (!state) {
    errors.state = "* Required";
  }

  if (!city) {
    errors.city = "* Required";
  }

  if (!postalCode) {
    errors.postalCode = "* Required";
  }

  if (!taxId) {
    errors.taxId = "* Required";
  }

  return errors;
};
