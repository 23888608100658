const ChatPlaceholderIcon = ({ bgFill = "#9191C3", size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill={bgFill}
          d="M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25z"
        ></path>
        <path
          fill="#fff"
          d="M21.66 21.006A12.466 12.466 0 0112.5 25c-3.848 0-7.29-1.739-9.583-4.474.202-.142.417-.27.644-.381A19.56 19.56 0 0112 18c2.95.075 5.84.81 8.439 2.145.46.226.87.518 1.221.86zM12.5 5a5.5 5.5 0 110 11 5.5 5.5 0 010-11z"
        ></path>
      </g>
    </svg>
  );
};

export default ChatPlaceholderIcon;
