// styles
import styled from "styled-components";

const RowStyles = styled.div`
  background: #ffffff;
  border: 1px solid #e2e2ea;
  padding: 17px 24px;
  margin-top: -1px;
  width: 100%;
`;

const Row = ({ onClick, children, style, className }) => {
  return (
    <RowStyles onClick={onClick} style={{ ...style }} className={className}>
      {children}
    </RowStyles>
  );
};

export default Row;
