import styled from "styled-components";

export const AssignedManagerItemStyles = styled.div`
  display: flex;
  align-items: center;

  .managerName {
    width: 20%;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .email {
    width: 30%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #44444f;
    text-align: center;
  }

  .permissionsContainer {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .closeContainer {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
