import { useSelector } from "react-redux";

// Components
import { Body, Header } from "../../../UI";
import { Label } from "../../../typography";
import Row from "../../../lists/Row";
import BranchesItem from "../../../lists/Managers/Branches.item";

// Icons
import { Close } from "../../../../SVGS";

// Utils
import { isEmpty } from "../../../../utils";

// Styles
import { ViewBranchesStyles } from "./viewBranches.styles";

const ViewBranches = ({ closeModal }) => {
  const { chosenManager } = useSelector(state => state.managersPage);

  return (
    <ViewBranchesStyles>
      <Header className="viewBranchesHeader">
        <div className="dataContainer">
          <div style={{ width: 120, marginRight: 20 }}>
            <p className="manager">Manager</p>
            <p className="managerName">{`${chosenManager.firstName} ${chosenManager.lastName}`}</p>
          </div>
          <div style={{ width: 320, marginRight: 20 }}>
            <p className="managerEmail">{chosenManager.email}</p>
            <p className="managerPhoneContact">{chosenManager.contactNumber}</p>
          </div>
        </div>
        <div className="close">
          <Close onClick={closeModal} />
        </div>
      </Header>
      <Body>
        {isEmpty(chosenManager.branches) ? (
          <p>No assigned branches for this manager</p>
        ) : (
          <>
            <Label>List Of Branches</Label>
            <div className="branchesList">
              {chosenManager.branches.map(({ id, name, address }) => (
                <Row key={id}>
                  <BranchesItem
                    branchName={name}
                    address={address}
                    hasRemoveAction={false}
                  />
                </Row>
              ))}
            </div>
          </>
        )}
      </Body>
    </ViewBranchesStyles>
  );
};

export default ViewBranches;
