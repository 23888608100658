import styled from "styled-components";
import * as color from "../../constants/color.constants";

const Heading2Styles = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  /* word-break: break-all; */
  letter-spacing: 1px;
  color: ${color.PRIMARY_COLOR};
`;

const Heading2 = ({ children, className, style }) => (
  <Heading2Styles className={className} style={{ ...style }}>
    {children}
  </Heading2Styles>
);

export default Heading2;
