const Bell = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <rect
        width="34"
        height="34"
        fill={isActive ? "#F3A957" : "transparent"}
        rx="3"
      ></rect>
      <path
        fill={isActive ? "#fff" : "#8692B1"}
        d="M25.748 21.002l-2.303-2.304a1.7 1.7 0 01-.494-1.198v-3.3a6.113 6.113 0 00-5.764-6.197 5.951 5.951 0 00-6.138 5.951V17.5a1.7 1.7 0 01-.502 1.198l-2.296 2.304a.85.85 0 00.596 1.454h16.306a.85.85 0 00.596-1.454z"
      ></path>
      <path
        fill={isActive ? "#fff" : "#8692B1"}
        d="M13.718 24.157a3.4 3.4 0 006.564 0h-6.564z"
        opacity="0.6"
      ></path>
    </svg>
  );
};

export default Bell;
