// Form Models
import { WorkDayFormModel } from "../models/app/forms";

export const workDaysBuilder = [
  new WorkDayFormModel({
    dayOfTheWeek: "monday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  }),
  new WorkDayFormModel({
    dayOfTheWeek: "tuesday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  }),
  new WorkDayFormModel({
    dayOfTheWeek: "wednesday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  }),
  new WorkDayFormModel({
    dayOfTheWeek: "thursday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  }),
  new WorkDayFormModel({
    dayOfTheWeek: "friday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  }),
  new WorkDayFormModel({
    dayOfTheWeek: "saturday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  }),
  new WorkDayFormModel({
    dayOfTheWeek: "sunday",
    opensAt: "08:00 AM",
    closesAt: "08:00 AM"
  })
];
