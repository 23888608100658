import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { Heading2, Label, Paragraph } from "../../../components/typography";
import { Body, Button, Footer, Header, Select } from "../../../components/UI";
import Row from "../../../components/lists/Row";

// Builder
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Calls
import { fetchWorkersRequest } from "../../../api/calls/services.call";

// Actions
import { updateService } from "../../../redux/actions/services.actions";

// Icons
import { Close } from "../../../SVGS";

// Styles
import { WorkersFormStyles } from "./workersForm.styles";

const WorkersForm = ({ closeModal, workersFormData }) => {
  const { serviceId } = useSelector(state => state.servicesReducer);
  const dispatch = useDispatch();
  const [allWorkers, setAllWorkers] = useState([]);
  const workerOptions = allWorkers.map(worker => ({
    value: worker.id,
    label: `${worker.firstName} ${worker.lastName}`
  }));

  const getWorkers = async () => {
    const response = await fetchWorkersRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setAllWorkers(response.workers);
  };

  useEffect(() => {
    getWorkers();
  }, []);

  const updateWorkersHandler = values => {
    dispatch(updateService(values, serviceId));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      workers: workersFormData
    },
    onSubmit: updateWorkersHandler
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const workersFilteredOptions = () => {
    return workerOptions.filter(
      option => !values.workers.includes(option.value)
    );
  };

  return (
    <WorkersFormStyles>
      <form onSubmit={handleSubmit}>
        <Header className="workersHeader">
          <Heading2>MANAGE WORKERS</Heading2>
          <Close onClick={closeModal} />
        </Header>
        <Body>
          <div>
            <Label>Assign new workers</Label>
            <Select
              searchable
              value={null}
              options={workersFilteredOptions()}
              valueKey="value"
              labelKey="label"
              onChange={option =>
                setFieldValue("workers", [...values.workers, option[0].value])
              }
              className="input"
              placeholder="Assign a worker"
              alwayClearInput
            />
          </div>
          {values.workers.length > 0 && (
            <div>
              <Label>Assigned workers</Label>
              <div className="workersList">
                {workerOptions
                  .filter(option => values.workers.includes(option.value))
                  .map(({ value, label }) => (
                    <Row style={{ padding: "12px 24px" }} key={value}>
                      <div className="workersItem">
                        <Paragraph className="workerName">{label}</Paragraph>
                        <Close
                          onClick={() =>
                            setFieldValue(
                              "workers",
                              values.workers.filter(id => id !== value)
                            )
                          }
                        />
                      </div>
                    </Row>
                  ))}
              </div>
            </div>
          )}
        </Body>
        <Footer className="footer">
          <Button
            className="submitButton"
            type="submit"
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            Save Changes
          </Button>
        </Footer>
      </form>
    </WorkersFormStyles>
  );
};

export default WorkersForm;
