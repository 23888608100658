import { useSelector } from "react-redux";

import { NotificationsCounter } from "../../UI";

// Icons
import {
  DropDownArrow,
  TruckIcon,
  RoadIcon,
  TowingIcon,
  TireIcon,
  TrailerIcon
} from "../../../SVGS";

// Constants
import * as serviceTypes from "../../../constants/serviceTypes.constants";

import styled from "styled-components";

const DashboardServiceItemStyles = styled.div`
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ bgColor }) => bgColor};
  margin-bottom: 8px;
  cursor: pointer;
  border: ${({ isNotification, bgColor }) =>
    isNotification ? `1px solid #D9D9D9` : `1px solid ${bgColor}`};

  &:last-child {
    margin-bottom: 0;
  }

  .serviceType {
    display: flex;
    align-items: center;
    height: 20px;

    .serviceTypeName {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #202650;
      margin-left: 4px;
    }
  }

  .ID {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202650;
    margin-top: 8px;
  }

  .arrowContainer {
    transform: rotate(-270deg);
  }
`;

const DashboardServiceItem = ({
  id,
  serviceTypeKey = serviceTypes.TRUCK_SHOP,
  newMessagesCount,
  status,
  onClick,
  isNotification = false
}) => {
  const { systemComponents } = useSelector(state => state.app);
  const serviceType = systemComponents.serviceTypes.find(
    type => type.typeKey === serviceTypeKey
  );
  const generateIcon = () => {
    switch (serviceTypeKey) {
      case serviceTypes.TRUCK_SHOP:
        return <TruckIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.ROAD_ASSISTANCE:
        return <RoadIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.TOWING:
        return <TowingIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.TIRE_REPAIR:
        return <TireIcon style={{ width: 16, marginRight: 8 }} />;
      case serviceTypes.TRAILER_REPAIR:
        return <TrailerIcon style={{ width: 16, marginRight: 8 }} />;

      default:
        return null;
    }
  };

  const bgColor = () => {
    if (isNotification) {
      return "transparent";
    }
    switch (status) {
      case "pending":
        return "#EEF7FC";
      case "paused":
        return "#FFF0B3";
      case "in-progress":
        return "#E3FCEF";
      default:
        return "#EEF7FC";
    }
  };
  return (
    <DashboardServiceItemStyles
      onClick={onClick}
      isNotification={isNotification}
      bgColor={bgColor()}
    >
      <div>
        <div className="serviceType">
          {generateIcon()}
          <p className="serviceTypeName">{serviceType?.name}</p>
        </div>
        <p className="ID">ID: {id}</p>
      </div>

      {isNotification ? (
        <NotificationsCounter
          className="notificationCounter"
          counter={newMessagesCount}
        />
      ) : (
        <div className="arrowContainer">
          <DropDownArrow />
        </div>
      )}
    </DashboardServiceItemStyles>
  );
};

export default DashboardServiceItem;
