// Domain Model
import { ServiceType } from ".";

export class ServiceMenu {
  constructor(serviceMenu = {}) {
    this.id = serviceMenu.id;
    this.title = serviceMenu.title;
    this.price = serviceMenu.price;
    this.available = serviceMenu.available;
    this.description = serviceMenu.description;
    this.serviceType = new ServiceType(serviceMenu.service_type);
  }

  generateServiceForm() {
    return {
      title: this.title,
      price: this.price,
      available: this.available,
      description: this.description,
      serviceTypeKey: this.serviceType.typeKey
    };
  }
}
