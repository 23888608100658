// Domain Models
import { File } from ".";
import { Role } from ".";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class User {
  constructor(user = {}) {
    this.id = user.id;
    this.email = user.email;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.contactNumber = user.contact_number;
    this.businessId = user.business_id;
    this.profileImage = new File(user.profile_image);
    this.roles = !isEmpty(user.roles)
      ? user.roles.map(role => new Role(role))
      : [];
  }

  generateProfileForm() {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      contactNumber: this.contactNumber
    };
  }
}
