const AnalyticsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        fill="#FBFCFF"
        d="M14.862 7.613H9.566a.42.42 0 00-.292.721l3.73 3.73a.42.42 0 00.657-.052 6.697 6.697 0 001.612-3.903.42.42 0 00-.411-.495zM7.966 0a.42.42 0 00-.419.42v5.886a.42.42 0 00.419.422h5.888c.231 0 .42-.189.42-.422A6.74 6.74 0 007.966 0z"
      ></path>
      <path
        fill="#FBFCFF"
        d="M11.202 11.722L6.859 7.379a.418.418 0 01-.118-.293L6.74.952a.418.418 0 00-.42-.419 6.741 6.741 0 104.853 11.803.416.416 0 00.029-.614z"
      ></path>
    </svg>
  );
};

export default AnalyticsIcon;
