import styled from "styled-components";

export const BusinessProfileStyles = styled.section`
  width: 100%;
  min-height: 100vh;

  .card {
    margin-top: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 24px;

      .buttonsContainer {
        display: flex;
        align-items: center;
      }
    }
  }

  .subscribedCard {
    border-bottom: 1px solid
      ${({ isActiveSubscription }) =>
        isActiveSubscription ? "#7fcafa" : "silver"} !important;
  }

  .subscribedHeader {
    border-bottom: 1px solid
      ${({ isActiveSubscription }) =>
        isActiveSubscription ? "#7fcafa" : "silver"} !important;
  }

  .subscribedBody {
    border-left: 12px solid
      ${({ isActiveSubscription }) =>
        isActiveSubscription ? "#7fcafa" : "silver"} !important;
    border-right: 1px solid
      ${({ isActiveSubscription }) =>
        isActiveSubscription ? "#7fcafa" : "silver"} !important;
    padding: 0;
  }

  .planInformationCard {
    width: 700px;
  }

  .editBusinesProfileCard {
    width: 852px;
  }
`;
