import { useFormik } from "formik";
// components
import { TextField, Button } from "../../../components/UI";
import { Label } from "../../../components/typography";
// builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";
// services
import { resetPasswordValidate } from "../../../services/validations/resetPassword.validations";
// styles
import { ResetPasswordFormStyles } from "./resetPassword.styles";

const ResetPasswordForm = () => {
  const handleResetPassword = async values => {
    console.log(values);
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: ""
    },
    onSubmit: handleResetPassword,
    validate: resetPasswordValidate
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <ResetPasswordFormStyles>
      <form onSubmit={handleSubmit}>
        <div>
          <Label style={{ marginBottom: 2 }}>New Password</Label>
          <TextField
            name="newPassword"
            type="password"
            value={values.newPassword}
            touched={touched.newPassword}
            error={errors.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <Label style={{ marginBottom: 2 }}>Confirm New Password</Label>
          <TextField
            name="confirmNewPassword"
            type="password"
            value={values.confirmNewPassword}
            touched={touched.confirmNewPassword}
            error={errors.confirmNewPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="buttonContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
      </form>
    </ResetPasswordFormStyles>
  );
};

export default ResetPasswordForm;
