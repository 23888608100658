// styles
import styled from "styled-components";

const BranchItemStyles = styled.div`
  padding: 20px;
  ${({ isActive }) =>
    isActive
      ? `
    background: rgba(127, 202, 250, 0.1);
    border: 1px solid #7FCAFA;
  `
      : `
  background: none;
    border: 1px solid transparent;
  `}
  cursor: pointer;

  &:hover {
    background: rgba(127, 202, 250, 0.1);
    border: 1px solid #7fcafa;
  }

  .branchName {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: ${({ isActive }) => (isActive ? "#7FCAFA" : "#000000")};
  }

  .branchDetails {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1px;
    color: #9fadc3;
  }
`;

const BranchItem = ({ name, location, isActive, onClick }) => {
  return (
    <BranchItemStyles onClick={onClick} isActive={isActive}>
      <h4 className="branchName">{name}</h4>
      <p className="branchDetails">{location.address}</p>
    </BranchItemStyles>
  );
};

export default BranchItem;
