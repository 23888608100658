const PrioritymageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="31"
      fill="none"
      viewBox="0 0 24 31"
    >
      <path
        fill="#202650"
        d="M20.571 0H3.43A3.41 3.41 0 00.017 3.402L0 30.621l12-5.104 12 5.104V3.402C24 1.531 22.457 0 20.571 0z"
      ></path>
      <path
        fill="#fff"
        d="M9.931 19.862v-4.965h3.31v4.965h4.139v-6.62h2.482l-8.276-7.449-8.275 7.449h2.482v6.62h4.138z"
      ></path>
    </svg>
  );
};

export default PrioritymageIcon;
