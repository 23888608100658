import styled from "styled-components";

export const LoginFormStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .form {
    width: 390px;
  }

  .passwordLabelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  .link {
    cursor: pointer;
    color: #74bde9;
    font-weight: normal;
  }

  .signUp {
    font-weight: normal;
    color: #9fadc3;
    font-size: 12px;
    text-align: center;
    margin: 18px 0 0 0;
  }
`;
