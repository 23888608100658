import { memo } from "react";
// components
import { Card, Header, Body } from "../../../../UI";
import { Heading2 } from "../../../../typography";
import EditBusinesProfileForm from "../../../../../containers/forms/businessProfile/EditBusinesProfile.form";
// icons
import { Close } from "../../../../../SVGS";
// styles
import styled from "styled-components";

const EditBusinesProfileStyles = styled.div`
  .editBusinesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const EditBusinesProfile = ({ closeModal }) => {
  return (
    <EditBusinesProfileStyles>
      <Card>
        <Header className="editBusinesHeader">
          <Heading2>EDIT BUSINESS PROFILE</Heading2>
          <Close onClick={closeModal} />
        </Header>
        <Body>
          <EditBusinesProfileForm />
        </Body>
      </Card>
    </EditBusinesProfileStyles>
  );
};

export default memo(EditBusinesProfile);
