import styled from "styled-components";

export const CheckboxButtonStyles = styled.div`
  .checkboxButton {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    padding: 4px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #44444f99;
    cursor: pointer;
  }

  .active {
    background: rgba(116, 189, 233, 0.1);
    border: 1px solid #74bde9;
    color: #172b4d;
  }
`;
