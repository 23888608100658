import styled from "styled-components";

export const CheckboxStyles = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .checkboxMask {
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkboxMask:hover {
    background: #f5faff;
    border: 1px solid #5d97ba;
  }

  .checked {
    background: #74bde9 !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .checkboxInput {
    width: 16px;
    height: 16px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #445570;
    margin-left: 12px;
  }
`;
