import { useState } from "react";
// components
import ChatInput from "./ChatInput";
import ChatBox from "./ChatBox";
import NotificationPresets from "./NotificationPresets";

// constants
import * as statusTypes from "../../constants/status.constants";
// styles
import { ChatStyles } from "./chat.styles";

const Chat = ({ service }) => {
  const [isPresetOpen, setIsPresetsOpen] = useState(false);
  const [newPresetText, setNewPresetText] = useState("");
  const isInputVisible =
    service.status === statusTypes.IN_PROGRESS ||
    service.status === statusTypes.PAUSED ||
    service.status === statusTypes.ACCEPTED ||
    service.status === statusTypes.PENDING;
  return (
    <ChatStyles isInputVisible={isInputVisible}>
      <div className="chatBox">
        <ChatBox serviceId={service.id} />
        {isPresetOpen && (
          <div className="notificationPresetContainer">
            <NotificationPresets
              newPresetText={newPresetText}
              setNewPresetText={setNewPresetText}
            />
          </div>
        )}
      </div>
      {isInputVisible && (
        <div className="inputContainer">
          <ChatInput
            serviceId={service.id}
            setNewPresetText={setNewPresetText}
            isPresetOpen={isPresetOpen}
            setIsPresetsOpen={setIsPresetsOpen}
            newPresetText={newPresetText}
          />
        </div>
      )}
    </ChatStyles>
  );
};

export default Chat;
