// Action types
import * as actionTypes from "../actionTypes/app.types";

// Utils
import { getItem } from "../../utils";
import { updateObject, isEmpty } from "../../utils/global.utils";
import { setItem, removeItem } from "../../utils/localStorage.utils";

// Constants
import {
  TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXPIRATION_DATE,
  MENU_ITEMS,
  ACTIVE_BRANCH,
  OPENED_SERVICE_DETAILS_BRANCH,
  OPENED_SERVICE_REQUEST_DETAILS_BRANCH
} from "../../constants/localStorage/localStorage.constants";

// Domain Models
import { Auth } from "../../models/app/domain";

const initialState = {
  auth: new Auth(),
  user: undefined,
  menuItems: getItem(MENU_ITEMS) || [],
  branches: [],
  dashboardData: undefined,
  activeBranch: undefined,
  socket: undefined,
  systemComponents: {
    serviceTypes: [],
    truckManufacturers: [],
    trailerManufacturers: [],
    trailerTypes: [],
    paymentTypes: []
  }
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_SUCCESS:
      return setUserAfterAuthentication(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return setUserAfterAuthentication(state, action);
    case actionTypes.FETCH_AUTHENTICATED_USER_SUCCESS:
      return setAuthenticatedUserData(state, action);
    case actionTypes.LOGOUT:
      return logout(state, action);
    case actionTypes.FETCH_SYSTEM_COMPONENTS_SUCCESS:
      return setSystemComponents(state, action);
    case actionTypes.FETCH_BRANCHES_SUCCESS:
      return setBranches(state, action);
    case actionTypes.CREATE_BRANCH_SUCCESS:
      return addBranch(state, action);
    case actionTypes.UPDATE_BRANCH_SUCCESS:
      return updateBranch(state, action);
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateUserProfile(state, action);
    case actionTypes.SET_ACTIVE_BRANCH:
      return setActiveBranch(state, action);
    case actionTypes.DELETE_BRANCH:
      return deleteBranch(state, action);
    case actionTypes.CONNECT_TO_SOCKET_SUCCESS:
      return connectToSocket(state, action);
    default:
      return state;
  }
};

// AUTH REDUCER HANDLERS
const setUserAfterAuthentication = (state, { authenticationResponseData }) => {
  const firstBranchInList = authenticationResponseData.branches[0];
  setItem(TOKEN, authenticationResponseData.auth.token);
  setItem(REFRESH_TOKEN, authenticationResponseData.auth.refreshToken);
  setItem(TOKEN_EXPIRATION_DATE, authenticationResponseData.auth.expiresAt);
  setItem(MENU_ITEMS, authenticationResponseData.menuItems);
  firstBranchInList && setItem(ACTIVE_BRANCH, firstBranchInList.id);
  return updateObject(state, {
    auth: authenticationResponseData.auth,
    user: authenticationResponseData.user,
    menuItems: authenticationResponseData.menuItems,
    branches: authenticationResponseData.branches,
    activeBranch: firstBranchInList,
    dashboardData: authenticationResponseData.dashboardData
  });
};

const setAuthenticatedUserData = (state, { userResponseData }) => {
  const activeBranchId = getItem(ACTIVE_BRANCH);
  const activeBranch = userResponseData.branches.find(
    branch => branch.id === activeBranchId
  );
  setItem(MENU_ITEMS, userResponseData.menuItems);
  removeItem(OPENED_SERVICE_DETAILS_BRANCH);
  removeItem(OPENED_SERVICE_REQUEST_DETAILS_BRANCH);
  return updateObject(state, {
    user: userResponseData.user,
    menuItems: userResponseData.menuItems,
    branches: userResponseData.branches,
    activeBranch: isEmpty(activeBranch)
      ? userResponseData.branches[0]
      : activeBranch,
    dashboardData: userResponseData.dashboardData
  });
};

const logout = (state, action) => {
  removeItem(TOKEN);
  removeItem(REFRESH_TOKEN);
  removeItem(TOKEN_EXPIRATION_DATE);
  removeItem(MENU_ITEMS);
  removeItem(ACTIVE_BRANCH);
  return updateObject(state, {
    auth: new Auth(),
    user: undefined,
    menuItems: [],
    branches: [],
    activeBranch: undefined,
    dashboardData: undefined
  });
};

// BRANCHES REDUCER HANDLERS
const addBranch = (state, { branch }) => {
  return updateObject(state, {
    branches: [...state.branches, branch]
  });
};

const updateBranch = (state, { branch }) => {
  return updateObject(state, {
    branches: state.branches.map(b => (b.id === branch.id ? branch : b)),
    activeBranch:
      state.activeBranch.id === branch.id ? branch : state.activeBranch
  });
};

const setBranches = (state, { branches }) => {
  return updateObject(state, {
    branches
  });
};

const setActiveBranch = (state, { branch }) => {
  setItem(ACTIVE_BRANCH, branch.id);
  return updateObject(state, {
    activeBranch: branch
  });
};

const deleteBranch = (state, { id }) => {
  if (state.activeBranch.id === id) {
    removeItem(ACTIVE_BRANCH);
  }
  return updateObject(state, {
    branches: state.branches.filter(branch => branch.id !== id),
    activeBranch: state.activeBranch.id === id ? undefined : state.activeBranch
  });
};

// USER REDUCER HANDLERS
const updateUserProfile = (state, { userResponse }) => {
  return updateObject(state, {
    user: userResponse.user
  });
};

// SYSTEM COMPONENTS REDUCER HANDLERS
const setSystemComponents = (
  state,
  {
    systemComponentsResponse: {
      serviceTypes,
      trailerManufacturers,
      truckManufacturers,
      trailerTypes,
      paymentTypes
    }
  }
) => {
  return updateObject(state, {
    systemComponents: {
      serviceTypes: !isEmpty(serviceTypes)
        ? serviceTypes
        : state.systemComponents.serviceTypes,
      truckManufacturers: !isEmpty(truckManufacturers)
        ? truckManufacturers
        : state.systemComponents.truckManufacturers,
      trailerManufacturers: !isEmpty(trailerManufacturers)
        ? trailerManufacturers
        : state.systemComponents.trailerManufacturers,
      trailerTypes: !isEmpty(trailerTypes)
        ? trailerTypes
        : state.systemComponents.trailerTypes,
      paymentTypes: !isEmpty(paymentTypes)
        ? paymentTypes
        : state.systemComponents.paymentTypes
    }
  });
};

// SOCKET ACTIONS
const connectToSocket = (state, { socket }) => {
  return updateObject(state, {
    socket
  });
};
