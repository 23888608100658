import { forwardRef } from "react";
import DatePicker from "react-datepicker";
// icons
import { Calendar, DropDownArrow } from "../../../SVGS";
// package styling
import "react-datepicker/dist/react-datepicker.css";
// custom styling
import { DatePickerStyles } from "./datePicker.styles";

const CustomDatePicekr = ({
  value,
  onChange,
  touched,
  name,
  placeholder = "Select date",
  dateFormat = "MMM, d, yyyy",
  error,
  shouldHaveError = true,
  className,
  style,
  disabled
}) => {
  const CustomInput = forwardRef(
    ({ value, onClick, placeholderText, disabled }, ref) => (
      <div
        className={`input ${value && "selected"} ${
          touched && error && "error"
        } ${disabled && "disabledInput"}`}
        onClick={onClick}
        // ref={ref}
      >
        <div className="valueContainer">
          <div className="iconContainer">
            <Calendar fill={value ? "#172B4D" : "#97A0AF"} />
          </div>
          {value ? (
            <p className="value">{value}</p>
          ) : (
            <p className="placeholder">{placeholderText}</p>
          )}
        </div>
        <div className="arrowContainer">
          <DropDownArrow />
        </div>
      </div>
    )
  );

  return (
    <DatePickerStyles className={className} style={{ ...style }}>
      <DatePicker
        className="datePicker"
        selected={value}
        onChange={date => onChange(name, date)}
        customInput={
          <CustomInput disabled={disabled} placeholderText={placeholder} />
        }
        dateFormat={dateFormat}
        disabled={disabled}
      />
      {shouldHaveError && (
        <p className="errorMessage">{touched && error && error}</p>
      )}
    </DatePickerStyles>
  );
};

export default CustomDatePicekr;
