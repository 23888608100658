import styled from "styled-components";
import * as color from "../../constants/color.constants";

const SmallTextStyles = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

const SmallText = ({ children, className, style }) => (
  <SmallTextStyles className={className} style={{ ...style }}>
    {children}
  </SmallTextStyles>
);

export default SmallText;
