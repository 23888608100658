const RoadIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      fill="none"
      viewBox="0 0 24 22"
      style={{ ...style }}
    >
      <path
        fill="#445570"
        d="M11.742 0l.12.002a3.035 3.035 0 012.915 3.033c0 1.644-2.921 5.174-3.032 5.306l-.003.004-.003-.004c-.11-.132-3.031-3.661-3.031-5.306A3.035 3.035 0 0111.623.002l.12-.002zm0 1.517l-.081.003a1.138 1.138 0 000 2.27l.081.003.081-.003a1.138 1.138 0 000-2.27l-.08-.003zM7.966 9.862L0 22h2.81L9.164 9.862H7.966zM14.317 9.862L20.67 22h2.81L15.516 9.862h-1.198zM11.36 10.24h.76v-.378h1.456L19.93 22h-7.81v-1.897h-.76V22H3.55L9.904 9.862h1.457v.379zm.76 6.829h-.76v1.896h.76V17.07zm0-2.656h-.76v1.518h.76v-1.518zm0-1.896h-.76v1.138h.76v-1.138zm0-1.518h-.76v.76h.76v-.76z"
      ></path>
    </svg>
  );
};

export default RoadIcon;
