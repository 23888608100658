import styled from "styled-components";

export const SubscriptionPlanStyles = styled.div`
  position: relative;
  padding: 24px 24px 24px 56px;

  .infoIconContainer {
    position: absolute;
    left: 8px;
    top: 8px;
    cursor: pointer;
  }

  .subscriptionPlanInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subscriptionInfoDetailsContainer {
      .infoRow {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .planName {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.1px;
          color: #171725;
          margin-right: 92px;
          width: 130px;
          white-space: nowrap;
        }

        .secondColumn {
          width: 100px;
          margin-right: 63px;
        }

        .label {
          line-height: 16px;
          margin-bottom: 2px;
        }

        .paragraph {
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0.1px;
        }

        .date {
          letter-spacing: 0.1px;
        }
      }

      .infoRow:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
