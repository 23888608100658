import styled from "styled-components";

export const FiltersStyles = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .inputsContainer {
    margin-top: 10px;
  }

  .checkboxesContainer {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-right: 24px;

    .checkboxButton {
      height: 36px;
      margin-right: 12px;
    }

    .checkboxButton:last-child {
      margin-right: 0;
    }
  }

  .select {
    height: 36px;
    margin-top: 5px;
    width: 230px;
    margin-right: 24px;
  }

  .dateContainer {
    display: flex;
    align-items: center;
  }
`;
