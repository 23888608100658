import { apiService } from "../api.service";

// Requests
import { FileUploadRequest } from "../../models/api/requests";

// Responses
import { FileUploadResponse } from "../../models/api/responses";

/**
 * Upload file to backed and return url
 * @param file - File
 * @return - FileUploadResponse || ErrorResponse
 */
export const fileUpload = async file => {
  const fileUploadRequest = new FileUploadRequest(file);
  const { payload } = fileUploadRequest;
  const response = await apiService.post("/file-management/upload", payload);
  if (response.hasError) {
    return response;
  }
  return new FileUploadResponse(response);
};
