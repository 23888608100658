import styled from "styled-components";

export const TextFieldStyles = styled.div`
  width: 100%;

  .input {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #00000099;
    padding: 9px 8px;
    width: 100%;
    outline: none;
  }

  .input:focus {
    border: 1px solid #2f76ff;
  }

  .input[type="password"]::-webkit-contacts-auto-fill-button,
  .input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }

  .error {
    border: 1px solid #ca2c2c !important;
  }

  .input::placeholder {
    opacity: 0.5;
  }

  .errorMessage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ca2c2c;
    margin-top: 4px;
    height: 24px;
  }
`;
