// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Header, Card } from "../components/UI";
import { Heading2 } from "../components/typography";

// Containers
import AddBranchFrom from "../containers/forms/manageBranches/addBranch/AddBranch.form";

// Styles
import { AddBranchStyles } from "../pagesStyles/addBranch.styles";

const AddBranch = () => {
  return (
    <AddBranchStyles>
      <PageHeader title="Add New Branch" />
      <Card className="card">
        <Header>
          <Heading2>ADD NEW BRANCH</Heading2>
        </Header>
        <AddBranchFrom />
      </Card>
    </AddBranchStyles>
  );
};

export default AddBranch;
