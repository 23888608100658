import { isEmpty } from "../../../utils";

export class LocationRequest {
  constructor(data = {}) {
    this.postal_code = data?.postalCode;
    this.address = data?.address;
    this.state = data?.state;
    this.city = data?.city;
    this.longitude = data?.longitude;
    this.latitude = data?.latitude;
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (initValuesArr.includes(value) || isEmpty(value)) {
        delete this[key];
      }
    });
  }
}
