// Domain Models
import { SubscriptionItem } from ".";

export class Subscription {
  constructor(subscription = {}) {
    this.id = subscription.id;
    this.businessId = subscription.business_id;
    this.status = subscription.status;
    this.currentPeriodStart = subscription.current_period_start;
    this.currentPeriodEnd = subscription.current_period_end;
    this.canceledAt = subscription.canceled_at;
    this.cancelAt = subscription.cancel_at;
    this.updatedAt = subscription.updated_at;
    this.item = new SubscriptionItem(subscription.item);
  }
}
