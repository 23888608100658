import { useState, useRef } from "react";
// components
import GrapghDataItem from "../../../../lists/analytics/GraphData.item";
import { Heading2 } from "../../../../typography";
import { Card, Header, Body, Footer, Button } from "../../../../UI";
// icons
import { Dots, Rating } from "../../../../../SVGS";
// hooks
import useClickOutside from "../../../../../hooks/useClickOutside";
// styles
import { GraphDataStyles } from "./graphData.styles";

const GraphData = ({
  chosenGraphs,
  graphsData,
  setGraphsData,
  choseRandomGraph,
  backOneGraph,
  exportAnalyticsPDF
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const exportRef = useRef(null);

  useClickOutside(exportRef, () => setIsPopUpOpen(false));

  return (
    <GraphDataStyles>
      <Card className="dataContainerCard">
        <Header className="dataHeader">
          <div className="left">
            <Button onClick={backOneGraph}>Back</Button>
            <div className="linksContainer">
              {chosenGraphs.map((graph, i) => (
                <div key={i} onClick={() => choseRandomGraph(graph, i)}>
                  {i !== 0 && i !== chosenGraphs.length && "/"}
                  {graph.ringOne[0].name}
                </div>
              ))}
            </div>
          </div>

          <div
            ref={exportRef}
            className="dotsContainer"
            onClick={() => setIsPopUpOpen(prevState => !prevState)}
          >
            <Dots />
            {isPopUpOpen && (
              <div className="popup" onClick={e => e.stopPropagation()}>
                <div className="pointer" />
                <p
                  className="exportText"
                  style={{ margin: 0 }}
                  onClick={() => exportAnalyticsPDF()}
                >
                  Export
                </p>
                <p className="exportText">Export CSV</p>
              </div>
            )}
          </div>
        </Header>
        <Body className="dataBody">
          {graphsData?.ringOne?.map((ringOneData, i) => (
            <div key={i}>
              <GrapghDataItem
                onClick={() => setGraphsData(ringOneData)}
                color={ringOneData.color}
                name={ringOneData.name}
                type="BG"
                number={ringOneData.backendValue}
              />
              {ringOneData?.children?.map((dataItem, j) => (
                <GrapghDataItem
                  onClick={() => setGraphsData(dataItem)}
                  key={j}
                  color={dataItem.color}
                  name={dataItem.name}
                  type="SM"
                  number={dataItem.backendValue}
                />
              ))}
            </div>
          ))}
        </Body>
        <Footer className="dataFooter">
          <Heading2>AVARAGE RATING : </Heading2>
          <div className="ratingContainer">
            <Rating />
            <p className="rating">4 / 5</p>
          </div>
        </Footer>
      </Card>
    </GraphDataStyles>
  );
};

export default GraphData;
