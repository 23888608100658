import styled from "styled-components";

export const NotificationPresetStyles = styled.div`
  position: absolute;
  bottom: 0;
  min-height: 345px;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.12);
  padding: 24px 24px 11px 24px;
  width: 100%;
  animation: displayPresets 0.4s ease-in-out;

  .notTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #202650;
    margin-bottom: 12px;
  }

  .presetsContainer {
    height: 210px;
    overflow-y: auto;
    margin-bottom: 12px;

    .presetPlaceholder {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        width: 70%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: #bcbcbc;
      }
    }
  }

  @keyframes displayPresets {
    from {
      transform: translateY(345px);
    }
    to {
      transform: translateY(0px);
    }
  }
`;
