import styled from "styled-components";

export const FileUplaodStyles = styled.div`
  .inputContainer {
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;

    .addButton {
      font-weight: 600;
      padding: 8px 12.5px;

      &:hover {
        background: #202650;
        color: #fff;
        border: 1px solid #202650;
      }
    }

    .valueContainer {
      padding: 5px 6px;
      display: flex;
      align-items: center;
      width: ${({ value }) => value && "calc(100% - 40px)"};

      .value {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #00000099;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: ${({ value }) => (value ? "1" : "0.5")};
        width: 100%;
      }

      .valueTextColor {
        color: #74bde9;
      }
    }
  }

  .error {
    border: 1px solid #ca2c2c !important;
  }

  .errorMessage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ca2c2c;
    margin-top: 4px;
    height: 24px;
  }

  .delete:hover path {
    fill: #fff;
  }
`;
