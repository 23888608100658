// Utils
import { isEmpty } from "../../../utils";

export class StaffMemberRequest {
  constructor(staffMember = {}) {
    this.email = staffMember.email;
    this.first_name = staffMember.firstName;
    this.last_name = staffMember.lastName;
    this.contact_number = staffMember.contactNumber;
    this.role = staffMember.role;
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (initValuesArr.includes(value) || isEmpty(value)) {
        delete this[key];
      }
    });
  }
}
