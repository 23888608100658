export class Location {
  constructor(location = {}) {
    this.postalCode = location?.postal_code;
    this.address = location?.address;
    this.state = location?.state;
    this.city = location?.city;
    this.longitude = location?.longitude;
    this.latitude = location?.latitude;
  }
}
