import styled from "styled-components";

export const WorkingDaysStyles = styled.div`
  .inputsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 12px 24px;
    border-bottom: 1px solid #dfe1e6;

    .timePickersContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 150px);
      min-height: 86px;
    }

    .inputContainer {
      width: 48%;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  }
`;
