import { DashboardItemResponse } from "./dashboard-item.response";

export class DashboardResponse {
  constructor(response = {}) {
    this.services = new DashboardItemResponse(response.data.services);
    this.requests = new DashboardItemResponse(response.data.requests);
    this.notifications = new DashboardItemResponse(
      response.data?.notifications
    );
  }
}
