import { useFormik } from "formik";
import { useDispatch } from "react-redux";

// Components
import { Heading2 } from "../../../../components/typography";
import { Body, Header } from "../../../../components/UI";
import ManagerForm from "../ManagerForm/Manager.form";

// Icons
import { Close } from "../../../../SVGS";

// Form Models
import { ManagerFormModel } from "../../../../models/app/forms";

// Services
import { managerValidation } from "../../../../services/validations/manager.validation";

// Actions
import { addNewManager } from "../../../../redux/actions/managers.actions";

// Styles
import { AddManagerStyles } from "./addManager.styles";

const AddManager = ({ closeModal }) => {
  const dispatch = useDispatch();
  const createManagerHandler = values => {
    dispatch(addNewManager(values));
  };

  const formik = useFormik({
    initialValues: new ManagerFormModel(),
    onSubmit: createManagerHandler,
    validate: managerValidation
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddManagerStyles>
      <Header className="modalHeader">
        <Heading2>ADD NEW MANAGER</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <ManagerForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            buttonText="Add Manager"
          />
        </form>
      </Body>
    </AddManagerStyles>
  );
};

export default AddManager;
