export const OPEN_MODAL = "MANAGERS_OPEN_MODAL";
export const CLOSE_MODAL = "MANAGERS_CLOSE_MODAL";
export const ADD_MANAGER_REQUEST = "ADD_MANAGER_REQUEST";
export const ADD_MANAGER_SUCCESS = "ADD_MANAGER_SUCCESS";
export const ADD_MANAGER_FAILED = "ADD_MANAGER_FAILED";
export const FETCH_MANAGERS_REQUEST = "FETCH_MANAGERS_REQUEST";
export const FETCH_MANAGERS_SUCCESS = "FETCH_MANAGERS_SUCCESS";
export const FETCH_MANAGERS_FAILED = "FETCH_MANAGERS_FAILED";
export const UPDATE_MANAGER_REQUEST = "UPDATE_MANAGER_REQUEST";
export const UPDATE_MANAGER_SUCCESS = "UPDATE_MANAGER_SUCCESS";
export const UPDATE_MANAGER_FAILED = "UPDATE_MANAGER_FAILED";
export const DELETE_MANAGER_REQUEST = "DELETE_MANAGER_REQUEST";
export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
export const DELETE_MANAGER_FAILED = "DELETE_MANAGER_FAILED";
export const EDIT_MANAGER = "EDIT_MANAGER";
export const VIEW_MANAGER_BRANCHES = "VIEW_MANAGER_BRANCHES";
