import React from "react";

const MessageSendIcon = ({ hasInputValue }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        fill={hasInputValue ? "#202650" : "#DFE1E6"}
        d="M.01 18L21 9 .01 0 0 7l15 2-15 2 .01 7z"
      ></path>
    </svg>
  );
};

export default MessageSendIcon;
