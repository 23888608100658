// Domain Models
import { Permission } from "../../app/domain";

export class PermissionsResponse {
  constructor(response = {}) {
    this.permissions = response.data.permissions.map(
      permission => new Permission(permission)
    );
  }
}
