import React from "react";

const Verified = ({ fill = "#74BDE9" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M12 24c6.565 0 12-5.447 12-12 0-6.565-5.447-12-12.012-12C5.435 0 0 5.435 0 12c0 6.553 5.447 12 12 12zm0-2a9.952 9.952 0 01-9.988-10c0-5.553 4.423-10 9.976-10A9.98 9.98 0 0122 12a9.954 9.954 0 01-10 10zm-1.294-4.412c.388 0 .718-.188.953-.553l5.376-8.459c.13-.235.283-.494.283-.752 0-.53-.47-.871-.965-.871-.294 0-.588.188-.812.53l-4.882 7.835-2.318-3c-.282-.377-.541-.47-.87-.47a.912.912 0 00-.906.928c0 .26.106.506.27.73l2.87 3.53c.295.387.613.552 1 .552z"
      ></path>
    </svg>
  );
};

export default Verified;
