import {
  EmployeeScheduleService,
  WorkerSchedule
} from "../../../../../models/app/domain";

// css handler
export const delcareRowHeight = employee => {
  if (
    employee.acceptedServices !== null &&
    employee.pausedServices !== null &&
    employee.inProgressServices !== null
  ) {
    return "threeRows";
  }

  if (
    (employee.acceptedServices === null &&
      employee.pausedServices !== null &&
      employee.inProgressServices !== null) ||
    (employee.acceptedServices !== null &&
      employee.pausedServices === null &&
      employee.inProgressServices !== null) ||
    (employee.acceptedServices !== null &&
      employee.pausedServices !== null &&
      employee.inProgressServices === null)
  ) {
    return "twoRows";
  }

  if (
    (employee.acceptedServices !== null &&
      employee.pausedServices == null &&
      employee.inProgressServices === null) ||
    (employee.acceptedServices === null &&
      employee.pausedServices !== null &&
      employee.inProgressServices === null) ||
    (employee.acceptedServices == null &&
      employee.pausedServices == null &&
      employee.inProgressServices !== null)
  ) {
    return "oneRow";
  }
};

// scrolling handlers
export const firstTableScroll = () => {
  const rightScrollPos = document.getElementById("firstTable").scrollTop;

  document.getElementById("secondTableContent").scrollTop = rightScrollPos;
};

export const secondTableScroll = () => {
  const rightScrollPos =
    document.getElementById("secondTableContent").scrollTop;

  document.getElementById("firstTable").scrollTop = rightScrollPos;
};

// expanding data handlers
const expandService = (services, timeSlots, dateQuery, opensAt, closesAt) => {
  if (services && timeSlots) {
    return services.map((service, i) => {
      const hasMore = services.filter(serv => serv.time === service.time);
      let startIndex = 0;
      let finishIndex = 0;
      const opens = opensAt;
      const closes = closesAt;

      if (service.timeDate === dateQuery.getDate()) {
        startIndex = timeSlots.findIndex(timeSlot => timeSlot === service.time);
      }

      if (service.timeDate < dateQuery.getDate()) {
        if (opens) {
          startIndex = timeSlots.findIndex(timeSlot => timeSlot === opens);
        } else {
          startIndex = timeSlots.findIndex(
            timeSlot => timeSlot === service.time
          );
        }
      }

      if (service.etaDate > dateQuery.getDate()) {
        if (closes) {
          finishIndex = timeSlots.findIndex(timeSlot => timeSlot === closes);
        } else {
          finishIndex = 47;
        }
      }

      if (service.etaDate === dateQuery.getDate()) {
        finishIndex = timeSlots.findIndex(timeSlot => timeSlot === service.eta);
      }

      const length = timeSlots.slice(startIndex, finishIndex + 1).length;

      return new EmployeeScheduleService({
        ...service,
        startIndex: startIndex,
        finishIndex: finishIndex,
        length,
        hasMore: hasMore.length > 1 ? hasMore : null,
        isActive: i === 0 ? true : false
      });
    });
  }

  return null;
};

export const expandEmployees = (
  employees,
  timeSlots,
  dateQuery,
  opensAt,
  closesAt
) => {
  return employees.map(employee => {
    return new WorkerSchedule({
      ...employee,
      inProgressServices: expandService(
        employee.inProgressServices,
        timeSlots,
        dateQuery,
        opensAt,
        closesAt
      ),
      acceptedServices: expandService(
        employee.acceptedServices,
        timeSlots,
        dateQuery,
        opensAt,
        closesAt
      ),
      pausedServices: expandService(
        employee.pausedServices,
        timeSlots,
        dateQuery,
        opensAt,
        closesAt
      )
    });
  });
};
