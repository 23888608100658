import {
  CircledGeneralSettingsIcon,
  CircledServiceTypeIcon,
  CircledCalendarIcon,
  CircledServiceMenuIcon,
  CircledManagementStaffIcon,
  CircledKeyIcon
} from "../SVGS";
import * as tabsValues from "../constants/generalBranchSettingsTabs.constants";
import * as serviceTypes from "../constants/serviceTypes.constants";

export const tabsBuilder = [
  {
    tabName: "General Settings",
    icon: <CircledGeneralSettingsIcon />,
    value: tabsValues.GENERAL_SETTINGS
  },
  {
    tabName: "Service Type and Capacity",
    icon: <CircledServiceTypeIcon />,
    value: tabsValues.SERVICE_TYPE_AND_CAPACITY
  },
  {
    tabName: "Manage Schedule",
    icon: <CircledCalendarIcon />,
    value: tabsValues.MANAGE_SCHEDULE
  },
  {
    tabName: "Service Menu",
    icon: <CircledServiceMenuIcon />,
    value: tabsValues.SERVICE_MENU
  },
  {
    tabName: "Staff Management",
    icon: <CircledManagementStaffIcon />,
    value: tabsValues.STAFF_MANAGEMENT
  },
  {
    tabName: "Roles & Permission",
    icon: <CircledKeyIcon />,
    value: tabsValues.ROLES_AND_PERMISSIONS
  }
];

export const StaffManagementTableHeadBuilder = [
  {
    className: "name",
    title: "Name"
  },
  {
    className: "role",
    title: "Role"
  },
  {
    className: "contact",
    title: "Contact Number"
  },
  {
    className: "email",
    title: "Email"
  },
  {
    className: "actions",
    title: ""
  }
];

export const serviceMenuFiltersBuilder = [
  {
    value: undefined,
    title: "All Types"
  },
  {
    value: serviceTypes.TRUCK_SHOP,
    title: "Truck Shop"
  },
  {
    value: serviceTypes.ROAD_ASSISTANCE,
    title: "Road Assistance"
  },
  {
    value: serviceTypes.TOWING,
    title: "Towing"
  },
  {
    value: serviceTypes.TIRE_REPAIR,
    title: "Tyre Repair"
  },
  {
    value: serviceTypes.TRAILER_REPAIR,
    title: "Trailer Repair"
  }
];
