import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
// components
import { Card } from "../../../../UI";
// styles
import { GraphStyles } from "./graph.styles";

const Graph = ({ label, graphsData, graphCellClick }) => {
  const { ringOne, ringTwo, ringThree } = graphsData;

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="tooltipLabel">{`${payload[0].name}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <GraphStyles>
      <Card className="graphCard">
        <ResponsiveContainer className="graph" width="100%" height="100%">
          <>
            <p className="grqphLabel">{label}</p>
            <PieChart width={500} height={500}>
              <Pie
                data={ringOne}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={90}
                outerRadius={130}
                fill="#8884d8"
              >
                {ringOne?.map((entry, index) => (
                  <Cell
                    key={`ringOne-${index}`}
                    onClick={() => {
                      graphCellClick(entry);
                    }}
                    fill={entry.color}
                    className="graphCell"
                  />
                ))}
              </Pie>
              <Pie
                data={ringTwo}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={130}
                outerRadius={160}
                fill="#82ca9d"
              >
                {ringTwo?.map((entry, index) => (
                  <Cell
                    key={`ringTwo-${index}`}
                    onClick={() => {
                      graphCellClick(entry);
                    }}
                    fill={entry.color}
                    className="graphCell"
                  />
                ))}
              </Pie>
              <Pie
                data={ringThree}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={160}
                outerRadius={190}
                fill="#82ca9d"
              >
                {ringThree?.map((entry, index) => (
                  <Cell
                    key={`ringThree-${index}`}
                    onClick={() => {
                      graphCellClick(entry);
                    }}
                    fill={entry.color}
                    className="graphCell"
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </>
        </ResponsiveContainer>
      </Card>
    </GraphStyles>
  );
};

export default Graph;
