const BusinessProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      fill="none"
      viewBox="0 0 17 15"
    >
      <path
        fill="#202650"
        d="M4.25 8.234a.83.83 0 100-1.66.83.83 0 000 1.66zM4.25 9.23c-.604 0-1.096.492-1.096 1.096v.83h2.192v-.83c0-.604-.492-1.096-1.096-1.096z"
      ></path>
      <path
        fill="#202650"
        d="M13.049 8.898a4.953 4.953 0 01-4.775-6.242h-6.78C.67 2.656 0 3.326 0 4.15v8.633c0 .824.67 1.494 1.494 1.494H13.88c.824 0 1.494-.67 1.494-1.494V8.317a4.916 4.916 0 01-2.324.581zm-4.815.133h1.86a.498.498 0 110 .996h-1.86a.498.498 0 110-.996zm-1.892 2.623a.498.498 0 01-.498.498H2.656a.498.498 0 01-.498-.498v-1.328c0-.663.311-1.255.794-1.639a1.82 1.82 0 01-.528-1.283c0-1.007.82-1.826 1.826-1.826 1.007 0 1.826.82 1.826 1.826 0 .5-.202.953-.528 1.284.483.383.794.975.794 1.638v1.328zm4.549.498H8.234a.498.498 0 110-.996h2.657a.498.498 0 110 .996z"
      ></path>
      <path
        fill="#202650"
        d="M13.049 0a3.956 3.956 0 00-3.951 3.951 3.956 3.956 0 003.95 3.951A3.956 3.956 0 0017 3.952 3.956 3.956 0 0013.049 0zm1.18 3.473L12.9 5.067a.498.498 0 01-.828-.096l-.531-1.063a.498.498 0 01.89-.445l.192.381.84-1.009a.498.498 0 11.765.638z"
      ></path>
    </svg>
  );
};

export default BusinessProfileIcon;
