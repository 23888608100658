const BranchSettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#202650"
        d="M8 4.703A2.346 2.346 0 015.656 2.36 2.346 2.346 0 018 .016a2.347 2.347 0 012.344 2.343A2.347 2.347 0 018 4.703zM.313 13.406a2.343 2.343 0 113.202.858 2.343 2.343 0 01-3.202-.858zM12.485 14.264a2.343 2.343 0 112.342-4.059 2.343 2.343 0 01-2.342 4.059zM1.916 8.612L.98 8.561a7.057 7.057 0 013.17-5.492l.514.784a6.12 6.12 0 00-2.748 4.759zM14.083 8.612a6.12 6.12 0 00-2.747-4.759l.514-.784a7.057 7.057 0 013.17 5.492l-.937.05zM8.002 15.984a6.963 6.963 0 01-3.17-.753l.423-.836a6.132 6.132 0 005.493 0l.423.836c-.988.5-2.054.753-3.17.753z"
      ></path>
    </svg>
  );
};

export default BranchSettingsIcon;
