import styled from "styled-components";

export const HeaderActionsStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;

  .topButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

  .finishButton {
    width: 100% !important;
  }

  .button {
    padding: 8.5px 0;
    width: 167px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    border-radius: 6px;
  }

  .danger {
    background: #fafbfc;
    border: 1px solid #b92500;
    color: #b92500;
  }

  .danger:hover {
    background: #e42e2d;
    color: #ffffff;
    border: 1px solid #e42e2d;
  }

  .history {
    width: 100%;
  }
`;
