import styled from "styled-components";

export const BusinessProfileDetailsStyles = styled.div`
  display: flex;

  .column {
    width: 20%;

    margin-right: 10px;

    .cell {
      height: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: normal;
      margin-bottom: 2px !important;
    }

    .flexDiv {
      display: flex;
      align-items: center;
      height: 16px;
    }

    .bussinessProofLabelContainer {
      display: flex;
      align-items: center;

      .errorLabel {
        margin-left: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: normal;
        color: #ca2c2c;
        margin-bottom: 2px;
      }
    }

    .paragraph {
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 16px;
    }

    .span {
      margin-left: 9px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #44444f;
      line-height: 16px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      width: 100% !important;
    }

    .link {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #74bde9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .column:last-child {
    margin-right: 0;
  }

  .statusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lastColumnContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;
