import { useCallback } from "react";
import { useSelector } from "react-redux";

// Components
import { Label } from "../../../../../components/typography";
import {
  TextField,
  Select,
  Textarea,
  CheckboxButtons,
  Button
} from "../../../../../components/UI";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";

// Styles
import { ServiceFormStyles } from "./serviceForm.styles";

const ServiceForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  buttonText
}) => {
  const { systemComponents } = useSelector(state => state.app);
  const serviceTypeOptions = systemComponents.serviceTypes.map(serviceType => ({
    value: serviceType.typeKey,
    label: serviceType.name
  }));

  const handlePriceChange = useCallback(
    (e, fieldName) => {
      let inputValue = e.target.validity.valid
        ? parseInt(e.target.value, 0)
        : values.servicePrice;
      if (isNaN(inputValue)) {
        inputValue = "";
      }
      setFieldValue(fieldName, inputValue);
    },
    [values.servicePrice, setFieldValue]
  );

  return (
    <ServiceFormStyles>
      <div className="inputsContainer">
        <div className="inputContainer">
          <Label>Service Type</Label>
          <Select
            handleTouched={() => setFieldTouched("serviceTypeKey", true)}
            value={values.serviceTypeKey}
            touched={touched.serviceTypeKey}
            error={errors.serviceTypeKey}
            onChange={option => {
              setFieldValue("serviceTypeKey", option[0].value);
            }}
            valueKey="value"
            labelKey="label"
            options={serviceTypeOptions}
            placeholder="Select a service type"
          />
        </div>
        <div className="inputContainer">
          <Label>Service Title</Label>
          <TextField
            value={values.title}
            name="title"
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.title}
            error={errors.title}
            placeholder="Enter a service title"
          />
        </div>
      </div>
      <div className="inputsContainer">
        <div className="inputContainer">
          <Label>Service Price</Label>
          <TextField
            value={values.price}
            onBlur={handleBlur}
            name="price"
            touched={touched.price}
            error={errors.price}
            onChange={e => handlePriceChange(e, "price")}
            placeholder="Enter a price"
          />
        </div>
        <div className="inputContainer">
          <Label>Select Availability</Label>
          <div className="checkboxesContainer">
            <CheckboxButtons
              name="available"
              value={values.available}
              yesText="Available"
              noText="Unavailable"
              handleChange={setFieldValue}
            />
          </div>
        </div>
      </div>
      <div>
        <Label>Service Description</Label>
        <Textarea
          value={values.description}
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          touched={touched.description}
          error={errors.description}
          placeholder="Enter a short description about this service"
        />
      </div>
      <div className="buttonsContainer">
        <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
          {buttonText}
        </Button>
      </div>
    </ServiceFormStyles>
  );
};

export default ServiceForm;
