// styles
import { TextareaStyles } from "./textarea.styles";

const Textarea = ({
  value,
  name,
  onChange,
  onBlur,
  placeholder,
  id,
  error,
  touched,
  className
}) => {
  return (
    <TextareaStyles>
      <textarea
        autoComplete="off"
        id={id}
        name={name}
        className={`textarea ${className && className} ${
          touched && error && "error"
        }`}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      <p className="errorMessage">{touched && error && error}</p>
    </TextareaStyles>
  );
};

export default Textarea;
