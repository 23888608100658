export const FETCH_SERVICE_REQUESTS_REQUEST = "FETCH_SERVICE_REQUESTS_REQUEST";
export const FETCH_SERVICE_REQUESTS_SUCCESS = "FETCH_SERVICE_REQUESTS_SUCCESS";
export const FETCH_SERVICE_REQUESTS_FAILED = "FETCH_SERVICE_REQUESTS_FAILED";
export const APPROVE_SERVICE_REQUESTS_REQUEST =
  "APPROVE_SERVICE_REQUESTS_REQUEST";
export const APPROVE_SERVICE_REQUESTS_SUCCESS =
  "APPROVE_SERVICE_REQUESTS_SUCCESS";
export const APPROVE_SERVICE_REQUESTS_FAILED =
  "APPROVE_SERVICE_REQUESTS_FAILED";
export const OPEN_DECLINE_REASON = "OPEN_DECLINE_REASON";
export const OPEN_TICKET_DETAILS = "OPEN_TICKET_DETAILS";
export const OPEN_TICKET_DETAILS_SUCCESS = "OPEN_TICKET_DETAILS_SUCCESS";
export const OPEN_TICKET_DETAILS_FAILED = "OPEN_TICKET_DETAILS_FAILED";
export const CLOSE_SERVICE_REQUESTS_MODAL = "CLOSE_SERVICE_REQUESTS_MODAL";
export const DECLINE_SERVICE_REQUESTS_REQUEST =
  "DECLINE_SERVICE_REQUESTS_REQUEST";
export const DECLINE_SERVICE_REQUESTS_SUCCESS =
  "DECLINE_SERVICE_REQUESTS_SUCCESS";
export const DECLINE_SERVICE_REQUESTS_FAILED =
  "DECLINE_SERVICE_REQUESTS_FAILED";
export const UPDATE_SERVICE_REQUEST_REQUEST = "UPDATE_SERVICE_REQUEST_REQUEST";
export const UPDATE_SERVICE_REQUEST_SUCCESS = "UPDATE_SERVICE_REQUEST_SUCCESS";
export const UPDATE_SERVICE_REQUEST_FAILED = "UPDATE_SERVICE_REQUEST_FAILED";
export const SET_TIKET_DETAILS_MODAL = "SET_TIKET_DETAILS_MODAL";
// CHAT
export const SET_NEW_MESSAGE_IN_SERVICE_REQUEST =
  "SET_NEW_MESSAGE_IN_SERVICE_REQUEST";
export const SERVICES_REQUESTS_SEE_NEW_MESSAGES =
  "SERVICES_REQUESTS_SEE_NEW_MESSAGES";
