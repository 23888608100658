import styled from "styled-components";

export const NotificationStyles = styled.div`
  background: #fff4e8;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  .bellContainer {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }

  .notfication {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #181d3c;
  }
`;
