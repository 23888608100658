const EmployeeScheduleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <g fill="#F7F8FC" clipPath="url(#clip0_483:5482)">
        <path d="M15.3 1.7h-.85V.85c0-.51-.34-.85-.85-.85s-.85.34-.85.85v.85h-8.5V.85C4.25.34 3.91 0 3.4 0s-.85.34-.85.85v.85H.85c-.425 0-.85.34-.85.85v11.9c0 .51.425.85.85.85h5.185a6.814 6.814 0 01-.935-3.4c0-3.74 3.06-6.8 6.8-6.8 1.615 0 3.06.595 4.25 1.53V2.55c0-.425-.425-.85-.85-.85z"></path>
        <path d="M11.781 6.562a5.22 5.22 0 100 10.439 5.22 5.22 0 000-10.44zm0 1.56a1.727 1.727 0 110 3.453 1.727 1.727 0 010-3.453zm-.001 7.514a3.83 3.83 0 01-2.495-.92.736.736 0 01-.258-.56c0-.966.783-1.74 1.75-1.74h2.009c.967 0 1.747.774 1.747 1.74 0 .215-.095.42-.258.56a3.829 3.829 0 01-2.495.92z"></path>
      </g>
      <defs>
        <clipPath id="clip0_483:5482">
          <path fill="#fff" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmployeeScheduleIcon;
