import styled from "styled-components";

export const SubscriptionPlansStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;
  }

  .plansContainer {
    display: flex;
    justify-content: space-between;
  }
`;
