export const FETCH_BUSINESS_REQUEST = "FETCH_BUSINESS_REQUEST";
export const FETCH_BUSINESS_SUCCESS = "FETCH_BUSINESS_SUCCESS";
export const FETCH_BUSINESS_FAILED = "FETCH_BUSINESS_FAILED";
export const UPDATE_BUSINESS_PROFILE_REQUEST =
  "UPDATE_BUSINESS_PROFILE_REQUEST";
export const UPDATE_BUSINESS_PROFILE_SUCCESS =
  "UPDATE_BUSINESS_PROFILE_SUCCESS";
export const UPDATE_BUSINESS_PROFILE_FAILED = "UPDATE_BUSINESS_PROFILE_FAILED";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const FETCH_ACTIVE_SUBSCRIPTION_REQUEST =
  "FETCH_ACTIVE_SUBSCRIPTION_REQUEST";
export const FETCH_ACTIVE_SUBSCRIPTION_SUCCESS =
  "FETCH_ACTIVE_SUBSCRIPTION_SUCCESS";
export const FETCH_ACTIVE_SUBSCRIPTION_FAILED =
  "FETCH_ACTIVE_SUBSCRIPTION_FAILED";
