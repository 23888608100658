import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import MoonLoader from "react-spinners/MoonLoader";

// Components
import Button from "../Button/Button";
import { Label } from "../../typography";

// Styles
import { DragAndDropUploadStyles } from "./dragAndDropUpload.styles";

// Icons
import { Trash, PrioritymageIcon } from "../../../SVGS";

// Calls
import { fileUpload } from "../../../api/calls/file-upload.call";

// Builders
import * as buttonType from "../../../builders/buttonDesign.types";
import { readUrlFromFile } from "../../../utils";

const DragAndDropUpload = ({
  images,
  name,
  priorityIconVisible,
  handleChange
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onDrop = useCallback(
    async acceptedFiles => {
      // Do something with the files
      setIsLoading(true);
      const imagesResponse = await Promise.all(
        acceptedFiles.map(file => fileUpload(file))
      );
      if (imagesResponse.hasError) {
        setIsLoading(false);
        return;
      }
      const urls = imagesResponse.map(image => image.url);
      setIsLoading(false);
      handleChange(name, [...images, ...urls]);
    },
    [images, name, handleChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true
  });

  const removeImage = index => {
    handleChange(
      name,
      images.filter((image, i) => i !== index)
    );
  };

  return (
    <DragAndDropUploadStyles>
      <div className="dragAndDropContainer" {...getRootProps()}>
        {isLoading ? (
          <MoonLoader color={"#202650"} loading={isLoading} size={15} />
        ) : (
          <>
            <p className="text" style={{ fontSize: 36, marginBottom: 10 }}>
              +
            </p>
            <p className="text">Drag & drop images</p>
            <input {...getInputProps()} name={name} />
            <Button className="browseButton">Browse...</Button>
          </>
        )}
      </div>
      {images.length > 0 && (
        <div style={{ marginTop: 8 }}>
          <Label>Uploaded Images</Label>
          <div className="imagesContainer">
            {images.map((image, i) => {
              const imageId = `image${i}`;
              return (
                <div className="imageCard" key={i}>
                  {i === 0 && (
                    <div className="prioIcon">
                      <PrioritymageIcon />
                    </div>
                  )}
                  <img
                    src={
                      image instanceof File
                        ? readUrlFromFile(image, imageId)
                        : image
                    }
                    className="image"
                    alt={image.filename}
                    id={imageId}
                  />
                  <Button
                    className="deleteButton"
                    buttonDesignType={buttonType.DANGER}
                    onClick={() => removeImage(i)}
                  >
                    <Trash />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </DragAndDropUploadStyles>
  );
};

export default DragAndDropUpload;
