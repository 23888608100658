import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";

// Components
import { PageWrapper } from "./components/PageSpecificComponents";
import SideMenu from "./components/SideMenu/SideMenu";

// Pages
import { NotFound } from "./pages";

// Configs
import { generateRoutes } from "./config/router.config";

// Constants
import * as systemComponentsConsts from "./constants/system-components/system-components.constants";

// Actions
import {
  fetchAuthenticatedUserData,
  fetchSystemComponents,
  connectToSocket
} from "./redux/actions/app.actions";
import { setNewChatMessage } from "./redux/actions/chat.actions";

// Models
import { ChatMessage } from "./models/app/domain";

// Utils
import { getItem, isEmpty } from "./utils";

// Constants
import { TOKEN } from "./constants/localStorage/localStorage.constants";

import history from "./history";

// Styles
import "react-toastify/dist/ReactToastify.css";
import useNotifications from "./features/socketNotifications/hooks/useNotification";

const AppRoute = ({ component: Component, fullLayout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            {!fullLayout && <SideMenu />}
            {!fullLayout ? (
              <PageWrapper>
                <Component {...props} />
              </PageWrapper>
            ) : (
              <Component {...props} />
            )}
          </>
        );
      }}
    />
  );
};

const App = () => {
  const { user, auth, socket, menuItems } = useSelector(state => state.app);
  const dispatch = useDispatch();
  useNotifications();

  useEffect(() => {
    if (getItem(TOKEN) && isEmpty(user)) {
      dispatch(fetchAuthenticatedUserData());
    }

    // setting scroll position in document and using it with scrollDisabled and scrollEnabled services in services/utils
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });
  }, [user, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(
        fetchSystemComponents({
          serviceTypes: systemComponentsConsts.SERVICE_TYPES,
          truckManufacturers: systemComponentsConsts.TRUCK_MANUFACTURERS,
          trailerManufacturers: systemComponentsConsts.TRAILER_MANUFACTURERS,
          trailerTypes: systemComponentsConsts.TRAILER_TYPES,
          paymentTypes: systemComponentsConsts.PAYMENT_TYPES
        })
      );
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (auth.token) {
      dispatch(connectToSocket());
    }
  }, [auth.token, dispatch]);

  useEffect(() => {
    socket?.on("message-received", data => {
      console.log(data);
      dispatch(setNewChatMessage(new ChatMessage(data.message)));
    });

    socket?.on("exception", data => {
      console.log(data);
    });
  }, [socket, dispatch]);

  return (
    <Router history={history}>
      <Switch>
        {generateRoutes(auth?.token, menuItems).map(
          ({ exact, path, component, fullLayout }) => (
            <AppRoute
              exact={exact}
              path={path}
              component={component}
              key={path}
              fullLayout={fullLayout}
            />
          )
        )}
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
    </Router>
  );
};

export default App;
