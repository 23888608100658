import styled from "styled-components";

export const ResetPasswordStyles = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;

  .card {
    width: 400px;
  }
`;
