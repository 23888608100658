import { Verified, DropDownArrow } from "../../../../../SVGS";
import history from "../../../../../history";
// styles
import { ChoosePlanStyles } from "./chose-plan.styles";

const ChoosePlan = () => {
  return (
    <ChoosePlanStyles onClick={() => history.push("business-profile")}>
      <div className="container">
        <div style={{ display: "block" }}>
          <Verified />
        </div>
        <div style={{ marginLeft: "12px" }}>
          <h2 className="title">Choose a Plan</h2>
          <p className="text">
            Your next step is to choose a subscription plan
          </p>
        </div>
      </div>
      <div className="arrowContainer">
        <DropDownArrow />
      </div>
    </ChoosePlanStyles>
  );
};

export default ChoosePlan;
