const TireIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="33"
      fill="none"
      viewBox="0 0 24 33"
      style={{ ...style }}
    >
      <path
        fill="#172B4D"
        d="M16.93 0c-.832.933-1.53 2.37-2.089 4.125h-3.26l-1.648-1.08-.666.981 1.8 1.179a.609.609 0 00.333.099h3.098a33.58 33.58 0 00-.631 2.946h-2.885l-1.65-1.08-.665.981 1.8 1.179a.609.609 0 00.333.099h2.882a47.997 47.997 0 00-.32 2.946h-2.98l-1.65-1.08-.665.981 1.8 1.179a.609.609 0 00.333.099h3.083A53.16 53.16 0 0013.2 16.5h-2.818l-1.65-1.08-.665.981 1.8 1.179a.609.609 0 00.333.099h3.015c.022.985.07 1.972.147 2.946h-2.98l-1.65-1.08-.665.981 1.8 1.179a.609.609 0 00.333.099h3.269c.103 1.009.236 1.996.398 2.946h-2.885l-1.65-1.08-.665.981 1.8 1.179a.61.61 0 00.333.099h3.285c.214 1.05.467 2.04.756 2.946h-2.66l-1.648-1.08-.666.981 1.8 1.179a.609.609 0 00.333.099h3.257c.478 1.22 1.036 2.232 1.673 2.946H6.6c-.916 0-2.137-1.065-3.28-2.946H6a.608.608 0 00.333-.1l1.8-1.178-.666-.98-1.649 1.079h-3.15a22.165 22.165 0 01-1.208-2.946H4.8a.609.609 0 00.333-.1l1.8-1.178-.666-.98-1.649 1.079H1.2v.343c-.3-1.019-.559-2.118-.758-3.29H3.6a.609.609 0 00.333-.098l1.8-1.179-.666-.98-1.649 1.079H.266a31.796 31.796 0 01-.242-2.946H3a.609.609 0 00.333-.1l1.8-1.178-.666-.98L2.818 16.5H0c0-1.016.05-1.998.136-2.946H3a.609.609 0 00.333-.1l1.8-1.178-.666-.98-1.649 1.079H.266c.134-1.033.315-2.017.533-2.946H4.2a.61.61 0 00.333-.1l1.8-1.178-.666-.98L4.018 8.25H1.101c.3-1.072.648-2.057 1.025-2.946H5.4a.609.609 0 00.333-.1l1.8-1.178-.666-.98-1.649 1.079h-2.55C3.979 1.512 5.508 0 6.6 0h10.33zm2.27 0C21.47 0 24 6.776 24 16.5S21.47 33 19.2 33s-4.8-6.776-4.8-16.5S16.93 0 19.2 0zm0 7.66c-2.061 0-3 4.583-3 8.84s.939 8.84 3 8.84 3-4.583 3-8.84-.939-8.84-3-8.84zm0 1.18c.994 0 1.8 3.43 1.8 7.66s-.806 7.66-1.8 7.66-1.8-3.43-1.8-7.66.806-7.66 1.8-7.66z"
      ></path>
    </svg>
  );
};

export default TireIcon;
