export class ServiceType {
  constructor(serviceType = {}) {
    this.id = serviceType.id;
    this.name = serviceType.name;
    this.typeKey = serviceType.key;
    this.type = serviceType.type;
    this.mobility = serviceType.mobility;
    this.shouldShowSelection = serviceType.should_show_selection;
  }
}
