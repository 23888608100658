export class Driver {
  constructor(driver = {}) {
    this.id = driver.id;
    this.firstName = driver.first_name;
    this.lastName = driver.last_name;
    this.email = driver.email;
    this.imageUrl = driver.image_url;
    this.phoneNumber = driver.phone_number;
  }
}
