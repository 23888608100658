import { DropDownArrow } from "../../../../../SVGS";

// history
import history from "../../../../../history";

// styles
import { ListHeaderStyles } from "./list-header.styles";

const ListHeader = ({ title, number, numberColor, link }) => {
  return (
    <ListHeaderStyles>
      <div>
        <p className="number" style={{ color: numberColor }}>
          {number}
        </p>
        <p className="title">{title}</p>
      </div>
      <div className="viewAllContainer" onClick={() => history.push(link)}>
        <p className="viewAll">View all</p>
        <div className="arrowContainer">
          <DropDownArrow />
        </div>
      </div>
    </ListHeaderStyles>
  );
};

export default ListHeader;
