import styled from "styled-components";

const SideModalStyles = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  background: #00000040;
  z-index: 1000;
  top: 0;
  left: 0;

  .modalContainer {
    width: 410px;
    height: 100%;

    animation: displayModal 0.4s ease-in-out;
  }

  @keyframes displayModal {
    from {
      transform: translateX(100px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const SideModal = ({ children, closeModal }) => (
  <SideModalStyles onClick={closeModal}>
    <div className="modalContainer" onClick={e => e.stopPropagation()}>
      {children}
    </div>
  </SideModalStyles>
);

export default SideModal;
