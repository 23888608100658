import { useSelector } from "react-redux";

// Components
import Row from "../../../../components/lists/Row";
import { Label } from "../../../../components/typography";
import {
  TextField,
  Checkbox,
  Select,
  Button,
  NumberFormatterInput
} from "../../../../components/UI";
import BranchesItem from "../../../../components/lists/Managers/Branches.item";

// Constants
import * as permissionsEnums from "../../../../constants/permissions.constants";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Utils
import { isEmpty } from "../../../../utils";

// Styles
import { ManagerFormStyles } from "./managerForm.styles";

const ManagerForm = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  buttonText
}) => {
  const { branches } = useSelector(state => state.app);

  const branchesFilteredOptions = () => {
    const assignedBranchesIds = values.branches.map(am => am.id);
    return branches.filter(option => !assignedBranchesIds.includes(option.id));
  };

  const removeBranch = id => {
    setFieldValue(
      "branches",
      values.branches.filter(branch => branch.id !== id)
    );
  };

  return (
    <ManagerFormStyles>
      <div className="flexContainer">
        <div className="inputContainer">
          <Label>First Name</Label>
          <TextField
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            name="firstName"
            className="input"
            placeholder="Enter managers first name"
          />
        </div>
        <div className="inputContainer">
          <Label>Last Name</Label>
          <TextField
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            name="lastName"
            className="input"
            placeholder="Enter managers last name"
          />
        </div>
      </div>
      <div className="flexContainer">
        <div className="inputContainer">
          <Label>Email Address</Label>
          <TextField
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
            className="input"
            placeholder="Enter managers email address"
          />
        </div>
        <div className="inputContainer">
          <Label>Contact Number</Label>
          <NumberFormatterInput
            value={values.contactNumber}
            error={errors.contactNumber}
            touched={touched.contactNumber}
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            className="input"
            name="contactNumber"
            placeholder="Enter managers contact number"
            format="###-###-####"
          />
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        <Label>Permisions</Label>
        <div className="checkboxContainer">
          <Checkbox
            value={values.permissions.includes(
              permissionsEnums.BUSINESS_PROFILE
            )}
            handleChange={() =>
              setFieldValue(
                "permissions",
                values.permissions.includes(permissionsEnums.BUSINESS_PROFILE)
                  ? values.permissions.filter(
                      permission =>
                        permission !== permissionsEnums.BUSINESS_PROFILE
                    )
                  : [...values.permissions, permissionsEnums.BUSINESS_PROFILE]
              )
            }
            style={{ marginRight: 24 }}
            label="Access to Business Profile"
          />
          <Checkbox
            value={values.permissions.includes(
              permissionsEnums.BRANCH_AND_MANAGERS_MANAGMENT
            )}
            handleChange={() =>
              setFieldValue(
                "permissions",
                values.permissions.includes(
                  permissionsEnums.BRANCH_AND_MANAGERS_MANAGMENT
                )
                  ? values.permissions.filter(
                      permission =>
                        permission !==
                        permissionsEnums.BRANCH_AND_MANAGERS_MANAGMENT
                    )
                  : [
                      ...values.permissions,
                      permissionsEnums.BRANCH_AND_MANAGERS_MANAGMENT
                    ]
              )
            }
            label="Access to Branches & Managers"
          />
        </div>
      </div>
      {!isEmpty(branches) && (
        <div>
          <Label>Add Branches</Label>
          <Select
            value={null}
            options={branchesFilteredOptions()}
            onChange={option =>
              setFieldValue("branches", [...values.branches, option[0]])
            }
            dropdownPosition="auto"
            alwayClearInput
            searchable
            className="input"
            placeholder="Select a branch"
          />
          {values.branches.length > 0 && (
            <div className="selectedBranches">
              <Label>Selected Branches</Label>
              <div className="branchesList">
                {values.branches.map(({ id, name, location }) => (
                  <Row key={id}>
                    <BranchesItem
                      branchName={name}
                      address={location.address}
                      removeBranch={() => removeBranch(id)}
                    />
                  </Row>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="buttonContainer">
        <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
          {buttonText}
        </Button>
      </div>
    </ManagerFormStyles>
  );
};

export default ManagerForm;
