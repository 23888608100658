export const serviceValidation = ({
  serviceTypeKey,
  title,
  price,
  description
}) => {
  const errors = {};

  if (!serviceTypeKey) {
    errors.serviceTypeKey = "* Required";
  }

  if (!title) {
    errors.title = "* Required";
  }

  if (!price) {
    errors.price = "* Required";
  }

  if (!description) {
    errors.description = "* Required";
  }

  return errors;
};
