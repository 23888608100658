// components
import { Paragraph } from "../../typography";
import { Button, ExpandingDescription } from "../../UI";
// builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";
// icons
import { Trash } from "../../../SVGS";
// styles
import styled from "styled-components";

const ServiceMenuItemStyles = styled.div`
  border-top: 1px solid #f1f1f5;
  padding: 12px 24px 0 24px;

  &:first-child {
    border-top: none;
  }

  .topContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .serviceTypeName {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #74bde9;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      .buttons {
        height: 41px;
      }

      .buttons:hover path {
        fill: #ffffff;
      }
    }
  }

  .shopTitle {
    margin: 10px 0 15px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .priceAvailability {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #44444f;
    }

    .price {
      margin-right: 24px;
    }
  }
`;

const ServiceMenuItem = ({
  serviceType,
  title,
  price,
  available,
  description,
  deleteAction,
  editAction
}) => {
  return (
    <ServiceMenuItemStyles>
      <div className="topContent">
        <Paragraph className="serviceTypeName">{serviceType.name}</Paragraph>
        <div className="buttonsContainer">
          <Button onClick={editAction} className="buttons">
            Edit Service
          </Button>
          <Button
            onClick={deleteAction}
            buttonDesignType={buttonDesignType.DANGER}
            className="buttons"
            style={{ marginLeft: 8 }}
          >
            <Trash />
          </Button>
        </div>
      </div>
      <h3 className="shopTitle">{title}</h3>
      <div className="priceAvailability">
        <Paragraph className="price text">$ {price}</Paragraph>
        <Paragraph className="text">
          {available ? "Available" : "Unavailable"}
        </Paragraph>
      </div>
      <ExpandingDescription title="description" text={description} />
    </ServiceMenuItemStyles>
  );
};

export default ServiceMenuItem;
