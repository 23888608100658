import styled from "styled-components";

export const WorkerItemStyles = styled.div`
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  padding: 4px 8px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  .nameContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #172b4d;
    }

    .arrow {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      transform: rotate(0deg);
    }

    .arrowUp {
      transform: rotate(180deg);
    }
  }

  .description {
    height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    .data {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #748094;
    }

    .data1 {
      margin-top: 8px;
    }
  }
`;
