const CircledCalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M11.548 11.03a.376.376 0 01-.376-.375v-2.28a.376.376 0 11.751 0v2.28a.376.376 0 01-.375.376zM16.451 11.03a.376.376 0 01-.375-.375v-2.28a.376.376 0 11.751 0v2.28a.376.376 0 01-.376.376zM8.569 13.213v5.185c0 .884.719 1.603 1.603 1.603h7.655c.884 0 1.603-.72 1.603-1.603v-5.185H8.57z"
      ></path>
      <path
        fill="#fff"
        d="M19.43 12.462v-1.72c0-.883-.719-1.602-1.603-1.602h-.249v1.515c0 .622-.505 1.127-1.127 1.127a1.129 1.129 0 01-1.127-1.127V9.14h-2.649v1.515c0 .622-.505 1.127-1.127 1.127a1.129 1.129 0 01-1.127-1.127V9.14h-.25c-.883 0-1.602.719-1.602 1.603v1.719H19.43z"
      ></path>
    </svg>
  );
};

export default CircledCalendarIcon;
