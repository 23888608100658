// components
import {
  TextField,
  Button,
  Select,
  NumberFormatterInput
} from "../../../../../components/UI";
import { Label } from "../../../../../components/typography";
// builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";
// styles
import { StaffFormStyles } from "./staffForm.styles";

const StaffForm = ({
  roles,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  buttonText
}) => {
  const roleOptions = roles.map(role => ({
    value: role.id,
    label: role.name
  }));

  return (
    <StaffFormStyles>
      <div className="inputsContainer">
        <div className="inputContainer">
          <Label>First Name</Label>
          <TextField
            name="firstName"
            value={values.firstName}
            touched={touched.firstName}
            error={errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            className="input"
            placeholder="Enter a first name"
          />
        </div>
        <div className="inputContainer">
          <Label>Last Name</Label>
          <TextField
            name="lastName"
            value={values.lastName}
            touched={touched.lastName}
            error={errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            className="input"
            placeholder="Enter a last name"
          />
        </div>
      </div>
      <div className="inputsContainer">
        <div className="inputContainer">
          <Label>Email</Label>
          <TextField
            name="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            className="input"
            placeholder="Enter staff email address"
          />
        </div>
        <div className="inputContainer">
          <Label>Phone Number</Label>
          <NumberFormatterInput
            value={values.contactNumber}
            error={errors.contactNumber}
            touched={touched.contactNumber}
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            className="input"
            name="contactNumber"
            placeholder="Enter Phone Number"
            format="###-###-####"
          />
        </div>
      </div>
      <div className="inputContainer">
        <Label>Role</Label>
        <Select
          handleTouched={() => setFieldTouched("role", true)}
          value={values.role}
          valueKey="value"
          labelKey="label"
          touched={touched.role}
          error={errors.role}
          onChange={option => {
            setFieldValue("role", option[0].value);
          }}
          options={roleOptions}
          className="input"
          placeholder="Select a role"
        />
      </div>
      <div className="buttonContainer">
        <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
          {buttonText}
        </Button>
      </div>
    </StaffFormStyles>
  );
};

export default StaffForm;
