export class BranchServiceTypeRequest {
  constructor(branchServiceType = {}) {
    this.service_type_id = branchServiceType.id;
    this.mobility = branchServiceType.mobility;
    this.capacity = branchServiceType.capacity;
  }

  removeSameValues(initValues) {
    if (initValues) {
      const initalBranchServiceTypeValues = Object.values(initValues);
      Object.entries(this).forEach(([key, value]) => {
        if (initalBranchServiceTypeValues.includes(value)) {
          delete this[key];
        }
      });
    }
  }
}
