const CircledKeyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M18.983 8.99a3.358 3.358 0 00-2.39-.99c-.904 0-1.753.352-2.391.99a3.344 3.344 0 00-.969 2.007 3.4 3.4 0 00.423 2.06l-5.627 5.626 1.261 1.26.66-.658.715.715 1.178-1.179-.715-.715.883-.883.715.715 1.18-1.178-.716-.716 1.727-1.727a3.4 3.4 0 002.059.422 3.345 3.345 0 002.007-.968c.638-.639.99-1.488.99-2.39 0-.903-.352-1.752-.99-2.39zm-.846 2.532a1.189 1.189 0 01-.843.349 1.194 1.194 0 01-.843-2.036 1.194 1.194 0 011.686 0 1.194 1.194 0 010 1.687z"
      ></path>
    </svg>
  );
};

export default CircledKeyIcon;
