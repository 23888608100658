import styled from "styled-components";

export const NotFoundPageStyles = styled.section`
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 406px;
    padding: 40px;

    .topContent {
      height: 100px;
      width: 100%;
      background: rgba(32, 37, 83, 0.1);
      border-radius: 100px;
      display: flex;
      align-items: center;

      .notFoundTitleContainer {
        margin-left: 30px;

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 70px;
          line-height: 70px;
          letter-spacing: 0.1px;
          color: #202553;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 2.6px;
          color: #202553;
          margin-left: 5px;
        }
      }
    }

    .notFoundText {
      text-align: center;
      margin: 30px 0;
      padding: 0 30px;
    }
  }
`;
