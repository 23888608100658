// constants
import * as filtersTypes from "../constants/services.constants";

export const filters = [
  {
    title: "All",
    value: filtersTypes.FILTERS_ALL
  },
  {
    title: "Unassigned",
    value: filtersTypes.FILTERS_UNASIGNED
  },
  {
    title: "Accepted",
    value: filtersTypes.FILTERS_ACCEPTED
  },
  {
    title: "In Progress",
    value: filtersTypes.FILTERS_IN_PROGRESS
  },
  {
    title: "Paused",
    value: filtersTypes.FILTERS_PAUSED
  },
  {
    title: "Canceled",
    value: filtersTypes.FILTERS_CANCELED
  },
  {
    title: "Finished",
    value: filtersTypes.FILTERS_FINISHED
  }
];

export const tableHeadBuilder = [
  {
    title: "Start",
    className: "start"
  },
  {
    title: "Finish",
    className: "finish"
  },
  {
    title: "ID",
    className: "ID"
  },
  {
    title: "User",
    className: "user"
  },
  {
    title: "Service Type",
    className: "serviceType"
  },
  {
    title: "Status",
    className: "status"
  },
  {
    title: "",
    className: "messages"
  },
  {
    title: "Workers",
    className: "actions"
  }
];
