// Utils
import { isEmpty } from "../../../utils";

export class ServiceRequest {
  constructor(serviceFormValues = {}) {
    this.workers = serviceFormValues.workers;
    this.eta = serviceFormValues.eta;
  }

  removeUnusedValues() {
    Object.entries(this).forEach(([key, value]) => {
      if (key !== "workers" && isEmpty(value)) {
        delete this[key];
      }
    });
  }
}
