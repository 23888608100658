import styled from "styled-components";

export const RoleFormStyles = styled.div`
  .input {
    margin-top: 2px;
  }

  .permissionsList {
    margin-top: 2px;
    max-height: 274px;
    overflow-y: auto;
    padding: 1px 0;

    .row {
      padding: 12px 24px;
      display: flex;
      align-items: center;

      .textContainer {
        margin-left: 12px;
        .permissionName {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.1px;
          color: #171725;
        }

        .permissionDesc {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #44444f;
          opacity: 0.6;
          margin-top: 6px;
        }
      }
    }
  }

  .buttonContainer {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
  }
`;
