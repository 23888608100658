// Domain Models
import { Location } from ".";
import { File } from ".";
import { Manager } from ".";
import { BranchServiceType } from ".";
import { Manufacturer } from ".";
import { TrailerType } from ".";
import { WorkDay } from ".";
import { SpecialDay } from ".";

// Form Models
import { SpecialDayFormModel, WorkDayFormModel } from "../forms";

// Utils
import { isEmpty } from "../../../utils";
import { PaymentType } from "./Payment-type.model";

export class Branch {
  constructor(branch = {}) {
    this.id = branch.id;
    this.name = branch.name;
    this.contactNumber = branch.contact_number;
    this.hasParking = branch.has_parking;
    this.isActive = branch.is_active;
    this.description = branch.description;
    this.speciality = branch.speciality;
    this.hourlyPriceFrom = branch.hourly_price_from;
    this.hourlyPriceTo = branch.hourly_price_to;
    this.location = new Location(branch.location);
    this.images = !isEmpty(branch.images)
      ? branch.images.map(image => new File(image))
      : [];
    this.managers = !isEmpty(branch.managers)
      ? branch.managers.map(manager => new Manager(manager))
      : [];
    this.branchServiceTypes = !isEmpty(branch.branch_service_types)
      ? branch.branch_service_types.map(
          serviceType => new BranchServiceType(serviceType)
        )
      : [];
    this.truckManufacturers = !isEmpty(branch.truck_manufacturers)
      ? branch.truck_manufacturers.map(
          manufacturer => new Manufacturer(manufacturer)
        )
      : [];
    this.trailerManufacturers = !isEmpty(branch.trailer_manufacturers)
      ? branch.trailer_manufacturers.map(
          manufacturer => new Manufacturer(manufacturer)
        )
      : [];
    this.trailerTypes = !isEmpty(branch.trailer_types)
      ? branch.trailer_types.map(type => new TrailerType(type))
      : [];
    this.paymentTypes = !isEmpty(branch.payment_types)
      ? branch.payment_types.map(paymentType => new PaymentType(paymentType))
      : [];
    this.workDays = !isEmpty(branch.work_days)
      ? branch.work_days.map(day => new WorkDay(day))
      : [];
    this.specialDays = !isEmpty(branch.special_days)
      ? branch.special_days.map(day => new SpecialDay(day))
      : [];
  }

  generateBranchForm() {
    return {
      name: this.name,
      contactNumber: this.contactNumber,
      address: this.location.address,
      city: this.location.city,
      state: this.location.state,
      postalCode: this.location.postalCode,
      longitude: this.location.longitude,
      latitude: this.location.latitude,
      managers: this.managers.map(manager => manager.id),
      images: this.images.map(image => image.url),
      branchServiceTypes: this.branchServiceTypes
    };
  }

  generateBranchGeneralSettingsForm() {
    return {
      truckManufacturers: this.truckManufacturers,
      trailerManufacturers: this.trailerManufacturers,
      trailerTypes: this.trailerTypes,
      paymentTypes: this.paymentTypes,
      hasParking: this.hasParking,
      isActive: this.isActive,
      images: this.images.map(image => image.url),
      description: this.description,
      speciality: this.speciality,
      hourlyPriceFrom: this.hourlyPriceFrom,
      hourlyPriceTo: this.hourlyPriceTo
    };
  }

  generateBranchScheduleForm() {
    return {
      workDays: this.workDays.map(day => new WorkDayFormModel(day)),
      specialDays: this.specialDays.map(day => new SpecialDayFormModel(day))
    };
  }
}
