import { useSelector } from "react-redux";

// Components
import {
  Card,
  Header,
  Body,
  Footer,
  SubscriptionPlanPrice
} from "../../../../UI";
import { Heading2, Label, Paragraph } from "../../../../typography";

// Icons
import { Close } from "../../../../../SVGS";
import Accepted from "../../../../../images/accepted.png";
import Declined from "../../../../../images/declined.png";

// Utils
import {
  convertTimeStampToDate,
  daysLeftTillEndOfSubscription
} from "../../../../../utils/timeStampToDate";

// Styles
import { SubscriptionPlanInformationStyles } from "./subscriptionPlanInformation.styles";

const SubscriptionPlanInformation = ({ activeSubscription, closeModal }) => {
  const { branches } = useSelector(state => state.app);
  return (
    <SubscriptionPlanInformationStyles>
      <Card>
        <Header className="planInformationHeader">
          <Heading2>PLAN INFORMATION</Heading2>
          <Close onClick={closeModal} />
        </Header>
        <Body>
          <div className="infoRow">
            <h3 id="planName" className="planName">
              {activeSubscription.item.subscriptionPlan.name}
            </h3>
            <div className="secondColumn">
              <Label className="label">Valid For</Label>
              <Paragraph className="paragraph">
                {daysLeftTillEndOfSubscription(
                  activeSubscription.currentPeriodEnd
                )}{" "}
                days
              </Paragraph>
            </div>
            <div>
              <Label className="label">No. Of Branches</Label>
              <Paragraph className="paragraph">
                {branches.length}/
                {
                  activeSubscription.item.subscriptionPlan
                    .allowedNumberOfBranches
                }
              </Paragraph>
            </div>
          </div>
          <div className="infoRow">
            <SubscriptionPlanPrice
              designType="md"
              className="planName"
              price={Math.round(
                activeSubscription.item.subscriptionPrice.unitAmount / 100
              )}
            />
            <div className="secondColumn">
              <Label className="label">Start Date</Label>
              <Paragraph className="paragraph date">
                {convertTimeStampToDate(activeSubscription.currentPeriodStart)}
              </Paragraph>
            </div>
            <div>
              <Label className="label">Expiration Date</Label>
              <Paragraph className="paragraph date">
                {convertTimeStampToDate(activeSubscription.currentPeriodEnd)}
              </Paragraph>
            </div>
          </div>
        </Body>
        <Footer>
          <Label>Featuers</Label>
          <div className="featuresContainer">
            {activeSubscription.item.subscriptionPlan.subscriptionPlanFeatures.map(
              ({ subscriptionFeature, supported }, i) => (
                <div className="featureRow" key={i}>
                  <img
                    src={supported ? Accepted : Declined}
                    alt={supported ? "accepted" : "declined"}
                  />
                  <Paragraph
                    className="featureName"
                    style={{ color: supported ? "#006644" : "#B92500" }}
                  >
                    {subscriptionFeature.title}
                  </Paragraph>
                </div>
              )
            )}
          </div>
        </Footer>
      </Card>
    </SubscriptionPlanInformationStyles>
  );
};

export default SubscriptionPlanInformation;
