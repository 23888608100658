import validator from "validator";

export const registerStepOneValidation = ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation
}) => {
  const errors = {};
  if (!firstName) {
    errors.firstName = "* Required";
  }

  if (!lastName) {
    errors.lastName = "* Required";
  }

  if (!email) {
    errors.email = "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  if (!password) {
    errors.password = "* Required";
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = "* Required";
  } else if (passwordConfirmation !== password) {
    errors.passwordConfirmation = "* No Match";
  }
  return errors;
};

export const registerStepTwoValidation = ({
  name,
  address,
  state,
  city,
  postalCode,
  taxId,
  proofDocumentationUrl,
  websiteUrl
}) => {
  const errors = {};

  if (!name) {
    errors.name = "* Required";
  }

  if (!address) {
    errors.address = "* Required";
  }

  if (!state) {
    errors.state = "* Required";
  }

  if (!city) {
    errors.city = "* Required";
  }

  if (!postalCode) {
    errors.postalCode = "* Required";
  }

  if (!taxId) {
    errors.taxId = "* Required";
  } else if (taxId.replace(/ /g, "").length < 10) {
    errors.taxId = "* Invalid format";
  }

  return errors;
};
