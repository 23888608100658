// styles
import { PillStyles } from "./pill.styles";

// Constants
import * as businessProfileConsts from "../../../constants/business-profile/business-profile.constants";

const Pill = ({ className, rounded = false, status = "success" }) => {
  return (
    <PillStyles className={className} rounded={rounded} status={status}>
      {status === businessProfileConsts.BUSINESS_STATUS_PENDING && "Pending"}
      {status === businessProfileConsts.BUSINESS_STATUS_VERIFIED && "Verified"}
      {status === businessProfileConsts.BUSINESS_STATUS_NOT_VERIFIED &&
        "Not Verified"}
    </PillStyles>
  );
};

export default Pill;
