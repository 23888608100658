import { useCallback, useEffect } from "react";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Body, Button, Card, Header, CentralModal } from "../components/UI";
import { Heading2, Paragraph } from "../components/typography";
import ManagerItem from "../components/lists/Managers/Manager.item";
import ViewBranches from "../components/PageSpecificComponents/pagesComponents/managers/ViewBranches";
import Row from "../components/lists/Row";

// Containers
import AddManager from "../containers/forms/managers/AddManager/AddManager";
import EditManager from "../containers/forms/managers/EditManager/EditManager";

// Builders
import * as buttonDesignType from "../builders/buttonDesign.types";
import { managersTableHeadBuilder } from "../builders/managers.builder";

// Constants
import * as modalTypes from "../constants/managers.constants";

// Actions
import {
  openModal,
  deleteManager,
  closeModal,
  getManagers,
  editManager,
  viewManagerBranches
} from "../redux/actions/managers.actions";

// Styles
import { ManagersStyles } from "../pagesStyles/managers.styles";

const Managers = () => {
  const dispatch = useDispatch();
  const { managers, chosenManager, modalType } = useSelector(
    state => state.managersPage
  );

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  const removeManager = async id => {
    const result = await swal({
      title: "Delete Manager?",
      text: "Are you sure you want to delete this user? This action cannot be undone.",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }
    dispatch(deleteManager(id));
  };

  const editManagerHandler = useCallback(
    (manager, modalType) => dispatch(editManager(manager, modalType)),
    [dispatch]
  );

  const vewManagerBranchesHandler = useCallback(
    (manager, modalType) => dispatch(viewManagerBranches(manager, modalType)),
    [dispatch]
  );

  const openModalHandler = useCallback(
    modalType => dispatch(openModal(modalType)),
    [dispatch]
  );

  const closeModalHandler = useCallback(
    () => dispatch(closeModal()),
    [dispatch]
  );

  return (
    <ManagersStyles modalType={modalType}>
      <PageHeader title="Managers" />
      <Card className="card">
        <Header className="header">
          <Heading2>ALL MANAGERS</Heading2>
          <Button
            onClick={() => openModalHandler(modalTypes.ADD_MANAGER)}
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            + Add New Manager
          </Button>
        </Header>
        <Body className="tableHead">
          {managersTableHeadBuilder.map(({ title, className }, i) => (
            <Paragraph className={`tableHeadTitle ${className}`} key={i}>
              {title}
            </Paragraph>
          ))}
        </Body>
      </Card>

      <Card className="list">
        {managers.map(item => (
          <Row key={item.id}>
            <ManagerItem
              item={item}
              showBranchesAction={() =>
                vewManagerBranchesHandler(item, modalTypes.VIEW_BRANCHES)
              }
              editManager={() =>
                editManagerHandler(item, modalTypes.EDIT_MANAGER)
              }
              deleteManager={() => removeManager(item.id)}
            />
          </Row>
        ))}
      </Card>

      {modalType && (
        <CentralModal className="modalCard" closeModal={closeModalHandler}>
          <Card className="card">
            {modalType === modalTypes.VIEW_BRANCHES && (
              <ViewBranches closeModal={closeModalHandler} />
            )}
            {modalType === modalTypes.ADD_MANAGER && (
              <AddManager closeModal={closeModalHandler} />
            )}
            {modalType === modalTypes.EDIT_MANAGER && chosenManager && (
              <EditManager closeModal={closeModalHandler} />
            )}
          </Card>
        </CentralModal>
      )}
    </ManagersStyles>
  );
};

export default Managers;
