import styled from "styled-components";

export const EmployeeScheduleStyles = styled.section`
  min-height: calc(100vh - 42px);

  .card {
    margin-top: 24px;
    position: relative;
    z-index: 6;

    .statusFilters {
      display: flex;
      align-items: center;
      padding: 12px 24px;

      .tab {
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #9fadc3;
        outline: none;
        border: none;
        background: transparent;
        margin-right: 44px;
        cursor: pointer;
      }

      .activeTab {
        color: #171725;
      }
    }
  }

  .estimationCard {
    height: unset;
  }
`;
