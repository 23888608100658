import { useRef } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// Components
import { TextField, Button } from "../../../../components/UI";
import { Label } from "../../../../components/typography";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Form Models
import { ProfileForm } from "../../../../models/app/forms";

// Services
import { myProfileValidation } from "../../../../services/validations/myProfile.validation";

// Actions
import { updateProfile } from "../../../../redux/actions/app.actions";

// Utils
import { isEmpty } from "../../../../utils";

// Styles
import { MyProfileFormStyles } from "./myProfileForm.styles";
import { useEffect } from "react";

const MyProfileForm = () => {
  const user = useSelector(state => state.app.user);
  const dispatch = useDispatch();
  const initialProfileData = useRef(
    !isEmpty(user) && user.generateProfileForm()
  );

  const updateMyProfileHandler = values => {
    dispatch(updateProfile(values, initialProfileData.current));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: new ProfileForm(initialProfileData.current),
    onSubmit: updateMyProfileHandler,
    validate: myProfileValidation
  });

  useEffect(() => {
    initialProfileData.current = !isEmpty(user) && user.generateProfileForm();
  }, [user]);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <MyProfileFormStyles>
      <form onSubmit={handleSubmit}>
        <div className="topInputs">
          <div className="inputContainer">
            <Label>First Name</Label>
            <TextField
              name="firstName"
              value={values.firstName}
              touched={touched.firstName}
              error={errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="inputContainer">
            <Label>Last Name</Label>
            <TextField
              name="lastName"
              value={values.lastName}
              touched={touched.lastName}
              error={errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <Label>Email</Label>
          <TextField
            name="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label>Phone Number</Label>
          <TextField
            name="contactNumber"
            value={values.contactNumber}
            touched={touched.contactNumber}
            error={errors.contactNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </div>
        <div className="buttonsContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
      </form>
    </MyProfileFormStyles>
  );
};

export default MyProfileForm;
