// Domain Models
import { Role, File } from ".";

export class StaffMemeber {
  constructor(member = {}) {
    this.id = member.id;
    this.email = member.email;
    this.firstName = member.first_name;
    this.lastName = member.last_name;
    this.contactNumber = member.contact_number;
    this.roles = member.roles.map(role => new Role(role));
    this.profileImage = new File(member.profile_image);
  }

  generateStaffMemeberForm() {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      contactNumber: this.contactNumber,
      role: this.roles[0].id
    };
  }
}
