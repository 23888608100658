import { isEmpty } from "../../utils";

export const branchValidation = ({
  name,
  address,
  state,
  city,
  postalCode,
  branchServiceTypes,
  contactNumber
}) => {
  const errors = {};
  if (!name) {
    errors.name = "* Required";
  }

  if (!address) {
    errors.address = "* Required";
  }

  if (!state) {
    errors.state = "* Required";
  }

  if (!city) {
    errors.city = "* Required";
  }

  if (!postalCode) {
    errors.postalCode = "* Required";
  }

  if (!contactNumber) {
    errors.contactNumber = "* Required";
  } else if (contactNumber.replace(/ /g, "").length < 12) {
    errors.contactNumber = "* Invalid format";
  }

  if (isEmpty(branchServiceTypes)) {
    errors.branchServiceTypes = "* You need to have at least one service type";
  }
  branchServiceTypes.forEach(type => {
    if (isEmpty(type.capacity)) {
      errors.branchServiceTypes = "* Capacity must be filled";
    }

    if (type.capacity > 9999) {
      errors.branchServiceTypes = "* Capacity must be less then 9999";
    }
  });

  return errors;
};
