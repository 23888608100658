import React from "react";
import styled from "styled-components";

import { DropDownArrow } from "../../../SVGS";
// import Avatar from "../Avatar/Avatar";

const ContentRendererStyles = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .contentContainer {
    display: flex;
    align-items: center;
    margin: 0 !important;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #0a131e;
  }

  .placeholder {
    color: #0a131e80 !important;
  }

  .arrow {
    background: red;
    width: 10px;
    height: 10px;
  }

  .arrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-180deg);
  }
`;

const contentRenderer = ({ props, state }) => {
  return (
    <ContentRendererStyles>
      <div className="contentContainer" style={{ height: props.size }}>
        {props.withImage && props.Icon && props.Icon}
        <div style={{ marginLeft: props.Icon ? 10 : 0 }}>
          <p
            className={`value ${
              !!state.values.length === false && "placeholder"
            }`}
          >
            {!props.alwayClearInput && state.values.length > 0
              ? state.values[0].label
              : props.placeholder}
          </p>
          <p
            className={`value ${
              !!state.values.length === false && "placeholder"
            }`}
            style={{ opacity: 0.4 }}
          >
            {state.values.length > 0 && state.values[0].label1}
          </p>
        </div>
      </div>
      <div className="arrowContainer">
        <DropDownArrow />
      </div>
    </ContentRendererStyles>
  );
};

export default contentRenderer;
