import NumberFormat from "react-number-format";

// Styles
import { NumberFormatterInputStyles } from "./numberFormatterInput.styles";

const NumberFormatterInput = ({
  value,
  error,
  touched,
  type = "text",
  placeholder,
  onChange,
  onBlur,
  format = "##-#######",
  name
}) => {
  return (
    <NumberFormatterInputStyles>
      <NumberFormat
        type={type}
        name={name}
        onBlur={() => onBlur(name, true)}
        onValueChange={values => {
          const { formattedValue } = values;
          onChange(name, formattedValue);
        }}
        value={value}
        placeholder={placeholder}
        className={`input ${touched && error && "error"}`}
        format={format}
        // isNumericString
      />
      <p className="errorMessage">{touched && error && error}</p>
    </NumberFormatterInputStyles>
  );
};

export default NumberFormatterInput;
