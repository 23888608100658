import { useCallback, useState } from "react";
// components
import { PageHeader } from "../components/PageSpecificComponents";
import { Tab, Card, Header, Body } from "../components/UI";
import { Heading2 } from "../components/typography";
// containers
import ChangePasswordForm from "../containers/forms/accountSettings/changePasswordForm/ChangePassword.form";
import MyProfileForm from "../containers/forms/accountSettings/myProfileForm/MyProfile.form";
// builders
import { tabsBuilder } from "../builders/accountSettingsTabs.builder";
// constants
import * as tabsValues from "../constants/accountSettingsTabs.constants";
// styles
import { AccountSettingsStyles } from "../pagesStyles/accountSettings.styles";

const AccountSettings = () => {
  const [chosenTab, setChosenTab] = useState(tabsValues.MY_PROFILE);

  const setTab = useCallback(value => setChosenTab(value), []);

  return (
    <AccountSettingsStyles>
      <PageHeader title="Account Settings" />
      <div className="contentContainer">
        <div className="tabs">
          {tabsBuilder.map((tab, i) => (
            <Tab
              {...tab}
              isActive={tab.value === chosenTab}
              onClick={setTab}
              key={i}
            />
          ))}
        </div>
        <div className="cardContainer">
          <Card>
            <Header>
              <Heading2>
                {chosenTab === tabsValues.MY_PROFILE && "MY PROFILE"}
                {chosenTab === tabsValues.CHANGE_PASSOWRD && "CHANGE PASSWORD"}
              </Heading2>
            </Header>
            <Body>
              {chosenTab === tabsValues.CHANGE_PASSOWRD && (
                <ChangePasswordForm />
              )}
              {chosenTab === tabsValues.MY_PROFILE && <MyProfileForm />}
            </Body>
          </Card>
        </div>
      </div>
    </AccountSettingsStyles>
  );
};

export default AccountSettings;
