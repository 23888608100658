import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

// Action types
import * as types from "../actionTypes/services.types";

// Actions
import {
  getServicesSuccess,
  getMoreServicesSuccess,
  openTicketDetailsSuccess,
  cancelServiceSuccess,
  startServiceSuccess,
  pauseServiceSuccess,
  resumeServiceSuccess,
  finishServiceSuccess,
  updateServiceSuccess,
  getScheduleSuccess
} from "../actions/services.actions";

// Calls
import {
  fetchServicesRequest,
  fetchServiceDetailsRequest,
  cancelServiceRequest,
  startServiceRequest,
  pauseServiceRequest,
  resumeServiceRequest,
  finishServiceRequest,
  updateServiceRequest,
  fetchWorkersScheduleRequest
} from "../../api/calls/services.call";

// Fetch services logic
function* fetchServicesCall(action) {
  const response = yield call(
    fetchServicesRequest,
    action.dateQuery,
    action.groupQuery,
    action.idQuery,
    action.limit,
    action.lastService,
    action.isSupportRole
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_SERVICES_FAILED
    });
  }
  yield put(getServicesSuccess(response.services, response.pagination));
}

function* fetchServicesCallWatcher() {
  yield takeLatest(types.FETCH_SERVICES_REQUEST, fetchServicesCall);
}

// Fetch more services logic
function* fetchMoreServicesCall(action) {
  const response = yield call(
    fetchServicesRequest,
    action.dateQuery,
    action.groupQuery,
    action.idQuery,
    action.limit,
    action.lastService
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_MORE_SERVICES_FAILED
    });
  }
  yield put(getMoreServicesSuccess(response.services, response.pagination));
}

function* fetchMoreServicesCallWatcher() {
  yield takeLatest(types.FETCH_MORE_SERVICES_REQUEST, fetchMoreServicesCall);
}

// Open service tiket details logic
function* openServiceDetailsCall(action) {
  const response = yield call(
    fetchServiceDetailsRequest,
    action.serviceId,
    action.branchId
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.OPEN_SERVICE_DETAILS_FAILED
    });
  }
  yield put(openTicketDetailsSuccess(response.service));
}

function* openServiceDetailsCallWatcher() {
  yield takeLatest(types.OPEN_SERVICE_DETAILS, openServiceDetailsCall);
}

// Cancel service logic
function* cancelServiceCall(action) {
  const response = yield call(cancelServiceRequest, action.serviceId);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.CANCEL_SERVICE_FAILED
    });
  }
  yield put(cancelServiceSuccess(response.message));
}

function* cancelServiceCallWatcher() {
  yield takeLatest(types.CANCEL_SERVICE_REQUEST, cancelServiceCall);
}

// Start service logic
function* startServiceCall(action) {
  const response = yield call(
    startServiceRequest,
    action.serviceId,
    action.branchId
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.START_SERVICE_FAILED
    });
  }
  yield put(startServiceSuccess(response.message));
}

function* startServiceCallWatcher() {
  yield takeLatest(types.START_SERVICE_REQUEST, startServiceCall);
}

// Pause service logic
function* pauseServiceCall(action) {
  const response = yield call(pauseServiceRequest, action.serviceId);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.PAUSE_SERVICE_FAILED
    });
  }
  yield put(pauseServiceSuccess(response.message));
}

function* pauseServiceCallWatcher() {
  yield takeLatest(types.PAUSE_SERVICE_REQUEST, pauseServiceCall);
}

// Resume service logic
function* resumeServiceCall(action) {
  const response = yield call(resumeServiceRequest, action.serviceId);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.RESUME_SERVICE_FAILED
    });
  }
  yield put(resumeServiceSuccess(response.message));
}

function* resumeServiceCallWatcher() {
  yield takeLatest(types.RESUME_SERVICE_REQUEST, resumeServiceCall);
}

// Finish service logic
function* finishServiceCall(action) {
  const response = yield call(finishServiceRequest, action.serviceId);
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FINISH_SERVICE_FAILED
    });
  }
  yield put(finishServiceSuccess(response.message));
}

function* finishServiceCallWatcher() {
  yield takeLatest(types.FINISH_SERVICE_REQUEST, finishServiceCall);
}

// Update service logic
function* updateServiceCall(action) {
  const response = yield call(
    updateServiceRequest,
    action.formValues,
    action.serviceId
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.UPDATE_SERVICE_FAILED
    });
  }
  yield put(updateServiceSuccess(response.service));
}

function* updateServiceCallWatcher() {
  yield takeLatest(types.UPDATE_SERVICE_REQUEST, updateServiceCall);
}

// SCHEDULE
function* getEmployeesCall(action) {
  const response = yield call(
    fetchWorkersScheduleRequest,
    action.dateQuery,
    action.chosenFilter,
    action.chosenEmployees
  );
  if (response.hasError) {
    toast.error(response.error.message);
    return yield put({
      type: types.FETCH_EMPLOYEES_SCHEDULE_FAILED
    });
  }

  yield put(getScheduleSuccess(response.employees, response.workDay));
}

function* getEmployeesCallWatcher() {
  yield takeLatest(types.FETCH_EMPLOYEES_SCHEDULE_REQUESTS, getEmployeesCall);
}

export default function* servicesSaga() {
  yield all([
    fetchServicesCallWatcher(),
    fetchMoreServicesCallWatcher(),
    openServiceDetailsCallWatcher(),
    cancelServiceCallWatcher(),
    startServiceCallWatcher(),
    pauseServiceCallWatcher(),
    resumeServiceCallWatcher(),
    finishServiceCallWatcher(),
    updateServiceCallWatcher(),
    // schedule saga
    getEmployeesCallWatcher()
  ]);
}
