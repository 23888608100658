export const managersTableHeadBuilder = [
  {
    className: "firstName",
    title: "First Name"
  },
  {
    className: "lastName",
    title: "Last Name"
  },
  {
    className: "permissions",
    title: "Permissions"
  },
  {
    className: "branches",
    title: "Branches"
  },
  {
    className: "phone",
    title: "Phone"
  },
  {
    className: "email",
    title: "Email"
  },
  {
    className: "actions",
    title: ""
  }
];
