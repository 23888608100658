import { apiService } from "../api.service";

// Requests
import { ServiceCommentRequest } from "../../models/api/requests";

// Responses
import {
  ServiceCommentsResponse,
  MessageResponse,
  ServiceCommentResponse
} from "../../models/api/responses";
/**
 * Fetch service comments Request
 * @return - ServiceCommentsResponse || ErrorResponse
 */
export const fetchServiceCommentsRequest = async serviceId => {
  const response = await apiService.get(`/services/${serviceId}/comments`);
  if (response.hasError) {
    return response;
  }
  return new ServiceCommentsResponse(response);
};

/**
 * Create service comment Request
 * @param serviceCommentFormValues - ServiceCommentFormModel
 * @return - ServiceCommentResponse || ErrorResponse
 */
export const createServiceCommentRequest = async (
  serviceId,
  serviceCommentFormValues
) => {
  const payload = new ServiceCommentRequest(serviceCommentFormValues);
  const response = await apiService.post(
    `/services/${serviceId}/comments`,
    payload
  );
  if (response.hasError) {
    return response;
  }
  return new ServiceCommentResponse(response);
};

/**
 * Delete comment Request
 * @param id - string
 * @return - SuccessResponse || ErrorResponse
 */
export const deleteCommentRequest = async id => {
  const response = await apiService.delete(`/services/comments/${id}`);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse({
    data: { message: "Comment successfully deleted!" }
  });
};
