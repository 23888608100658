const Calendar = ({ fill = "#97A0AF" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
      style={{ margin: "0 0 2px 0" }}
    >
      <path
        fill={fill}
        d="M11.813 2.5H10.5V1.078a.353.353 0 00-.328-.328H9.078a.332.332 0 00-.328.328V2.5h-3.5V1.078A.353.353 0 004.922.75H3.828a.332.332 0 00-.328.328V2.5H2.187C1.45 2.5.875 3.102.875 3.813v9.624c0 .739.574 1.313 1.313 1.313h9.624c.711 0 1.313-.574 1.313-1.313V3.813A1.33 1.33 0 0011.812 2.5zm-.165 10.938H2.352c-.11 0-.164-.055-.164-.165V5.125h9.624v8.148c0 .11-.082.165-.164.165z"
      ></path>
    </svg>
  );
};

export default Calendar;
