import { useCallback, useState } from "react";
import { useParams } from "react-router";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Tab, Card } from "../components/UI";

// Page components
import {
  GeneralSettings,
  ServiceTypeAndCapacity,
  ManageSchedule,
  ServiceMenu,
  StaffManagement,
  RolesAndPermissions
} from "../components/PageSpecificComponents/pagesComponents/generalBranchSettings";

// Builders
import { tabsBuilder } from "../builders/generalBranchSettings.builder";

// Constants
import * as tabsValues from "../constants/generalBranchSettingsTabs.constants";

// Styles
import { GeneralBranchSettingsStyles } from "../pagesStyles/general-branch-settings.styles";

const GeneralBranchSettings = () => {
  const [chosenTab, setChosenTab] = useState(tabsValues.GENERAL_SETTINGS);
  const { id } = useParams();

  const setTab = useCallback(value => setChosenTab(value), []);

  return (
    <GeneralBranchSettingsStyles>
      <PageHeader title="General Branch Settings" />
      <div className="contentContainer">
        <div className="tabs">
          {tabsBuilder.map((tab, i) => (
            <Tab
              {...tab}
              isActive={tab.value === chosenTab}
              onClick={setTab}
              key={i}
            />
          ))}
        </div>
        <div className="cardContainer">
          <Card>
            {chosenTab === tabsValues.GENERAL_SETTINGS && (
              <GeneralSettings branchId={id} />
            )}
            {chosenTab === tabsValues.SERVICE_TYPE_AND_CAPACITY && (
              <ServiceTypeAndCapacity branchId={id} />
            )}
            {chosenTab === tabsValues.MANAGE_SCHEDULE && (
              <ManageSchedule branchId={id} />
            )}
            {chosenTab === tabsValues.SERVICE_MENU && (
              <ServiceMenu branchId={id} />
            )}
            {chosenTab === tabsValues.STAFF_MANAGEMENT && (
              <StaffManagement branchId={id} />
            )}
            {chosenTab === tabsValues.ROLES_AND_PERMISSIONS && (
              <RolesAndPermissions branchId={id} />
            )}
          </Card>
        </div>
      </div>
    </GeneralBranchSettingsStyles>
  );
};

export default GeneralBranchSettings;
