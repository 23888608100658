export const convertTimeStampToDate = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = `${date.getFullYear()}`;

    const formatedDay = day < 10 ? `0${day}` : `${day}`;
    const formatedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${formatedMonth}.${formatedDay}.${year}`;
  }

  return undefined;
};

export const convertTimeStampToDateWithSlash = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = `${date.getFullYear()}`;

    const formatedDay = day < 10 ? `0${day}` : `${day}`;
    const formatedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${formatedMonth}/${formatedDay}/${year}`;
  }

  return undefined;
};

export const daysLeftTillEndOfSubscription = endTimeStamp => {
  //Timestamp in seconds
  if (endTimeStamp) {
    const startDate = new Date();
    const endDate = new Date(endTimeStamp * 1000);

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  return undefined;
};

export const convertTimeStampToTime = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp * 1000);
    const hours = date.getUTCHours();

    const minutes = date.getMinutes();
    const formatedHours =
      hours === 12 ? 12 : hours % 12 < 10 ? `0${hours % 12}` : `${hours % 12}`;
    const formatedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const aa = hours < 12 ? "AM" : "PM";
    const formatedValue = `${formatedHours}:${formatedMinutes} ${aa}`;

    return formatedValue;
  }

  return undefined;
};

export const convertTimeStampToRequestFormat = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp * 1000);
    const hours = date.getHours();

    const minutes = date.getMinutes();
    const formatedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formatedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const formatedValue = `${formatedHours}:${formatedMinutes}`;

    return formatedValue;
  }

  return undefined;
};

export const convertTimeStampToRequestDate = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = `${date.getFullYear()}`;

    const formatedDay = day < 10 ? `0${day}` : `${day}`;
    const formatedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${year}-${formatedMonth}-${formatedDay}`;
  }

  return undefined;
};

export const getUtcDateObject = timeStamp => {
  if (timeStamp) {
    const date = new Date(timeStamp * 1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getMinutes();
    return new Date(year, month, day, hours, minutes);
  }

  return undefined;
};
