const CircledSettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M19.761 10.133a.354.354 0 00-.58-.12l-1.828 1.828-.983-.212-.21-.982 1.827-1.828a.355.355 0 00-.12-.58 3.379 3.379 0 00-4.503 4.05l-4.878 4.879c-.313.313-.486.73-.486 1.173 0 .443.173.86.486 1.173.313.313.73.486 1.173.486.443 0 .86-.173 1.173-.486l4.879-4.878a3.388 3.388 0 003.298-.866 3.379 3.379 0 00.752-3.637z"
      ></path>
    </svg>
  );
};

export default CircledSettingsIcon;
