import validator from "validator";

export const forgotPasswordValidate = ({ email }) => {
  const errors = {};
  if (!email) {
    errors.email = "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  return errors;
};
