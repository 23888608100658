import MoonLoader from "react-spinners/MoonLoader";

// styles
import { PendingVerificationStyles } from "./pending-verification.styles";
import { css } from "@emotion/react";

const override = css`
  display: block;
  border-color: #d2e6f2;
  box-sizing: content-box;
`;

const PendingVerification = () => {
  return (
    <PendingVerificationStyles>
      <div className="container">
        <div style={{ display: "block" }}>
          <MoonLoader size={22} css={override} color="#74BDE9" />
        </div>

        <div style={{ marginLeft: "12px" }}>
          <h2 className="title">Pending Verification</h2>
          <p className="text">
            This process usually doesn’t take a lot of time
          </p>
        </div>
      </div>
    </PendingVerificationStyles>
  );
};

export default PendingVerification;
