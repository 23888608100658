import styled from "styled-components";

export const StaffManagementStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

    .searchButtonContainer {
      display: flex;
      align-items: center;

      .searchContainer {
        height: 100%;
        width: 250px;
        margin-right: 10px;
      }

      .addStaff {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.1px;
        color: #fafafb;
      }
    }
  }

  .tableHeader {
    position: relative;
    z-index: 2;
    padding: 16px 24px 7px 24px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);

    .listSubtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #202650;
      margin-bottom: 32px;
    }

    .tableHead {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .name {
    width: 20%;
    margin-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #171725;
  }

  .role {
    width: 15%;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #44444f;
  }

  .contact {
    width: 20%;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #44444f;
  }

  .email {
    width: 30%;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #44444f;
  }

  .actions {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .deleteButton {
      margin-left: 8px;
      height: 39px;
    }

    .deleteButton:hover path {
      fill: #ffffff;
    }
  }

  .head {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #9fadc3;
  }

  .body {
    padding: 0;
    position: relative;
    z-index: 1;
  }

  .modalCard {
    width: 620px !important;
    overflow: unset;
  }
`;
