import styled from "styled-components";

export const PlanStyles = styled.div`
  background: #56dd8c;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  .container {
    display: flex;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #fff;
    }

    .smallText {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      margin-top: 8px;
    }

    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-top: 2px;
    }
  }
`;
