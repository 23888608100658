import { useState, useRef } from "react";

// Hooks
import useClickOutside from "../../../hooks/useClickOutside";

// Icons
import { Dots, Trash } from "../../../SVGS";

// Styles
import { ServiceCommentStyles } from "./serviceComment.styles";

// Utils
import {
  convertTimeStampToDateWithSlash,
  convertTimeStampToTime
} from "../../../utils/timeStampToDate";

const ServiceComment = ({ serviceComment, deleteComment }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const exportRef = useRef(null);

  useClickOutside(
    exportRef,
    () => setIsPopUpOpen(false),
    document.getElementsByClassName("modalContainer")[0]
  );

  return (
    <ServiceCommentStyles>
      <div className="userContainer">
        <div className="user">
          <div className="userDetails">
            <div className="avatar">
              <img
                src={serviceComment.user.profileImage.url}
                alt="profile"
                className="profileImage"
              />
            </div>
            <div className="userDetailsContainer">
              <h5 className="userName">{`${serviceComment.user.firstName} ${serviceComment.user.lastName}`}</h5>
            </div>
          </div>
        </div>
        <div
          ref={exportRef}
          className="dotsContainer"
          onClick={() => setIsPopUpOpen(prevState => !prevState)}
        >
          <Dots />
          {isPopUpOpen && (
            <div className="popup" onClick={e => e.stopPropagation()}>
              <div className="pointer" />
              <div className="inputContainer" onClick={() => deleteComment()}>
                <Trash />
                <p className="deleteText">Delete</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="commentContainer">
        <p className="comment">{serviceComment.comment}</p>
      </div>
      <div className="dateContainer">
        <p className="date">
          {new Date(serviceComment.createdAt * 1000).setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0)
            ? convertTimeStampToTime(serviceComment.createdAt)
            : convertTimeStampToDateWithSlash(serviceComment.createdAt)}
        </p>
      </div>
    </ServiceCommentStyles>
  );
};

export default ServiceComment;
