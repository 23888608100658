const BluePin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="13"
      fill="none"
      viewBox="0 0 10 13"
    >
      <path
        fill="#7FCAFA"
        d="M4.32 12.266c.21.328.703.328.914 0C8.656 7.344 9.289 6.828 9.289 5c0-2.484-2.016-4.5-4.5-4.5A4.486 4.486 0 00.289 5c0 1.828.609 2.344 4.03 7.266z"
      ></path>
    </svg>
  );
};

export default BluePin;
