import styled from "styled-components";

export const IconTextTooltipStyles = styled.div`
  display: flex;
  align-items: center;

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
  }

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    margin-left: 12px;
    color: #202650;
  }

  .tooltip {
    background: #ffffff;
    border-radius: 3px;
  }

  .tooltip::after {
    border-top-color: #ffffff !important;
  }

  .tooltip::before {
    border-top-color: #ffffff !important;
  }

  .tooltipText {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #202650;
  }
`;
