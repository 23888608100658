import styled from "styled-components";
import * as color from "../../constants/color.constants";

const Heading1Styles = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  /* word-break: break-all; */
  color: ${color.PRIMARY_COLOR};
`;

const Heading1 = ({ children, className, style }) => (
  <Heading1Styles className={className} style={{ ...style }}>
    {children}
  </Heading1Styles>
);

export default Heading1;
