const CircledManagementStaffIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="#202650"></circle>
      <path
        fill="#fff"
        d="M9.46 11.54A2.464 2.464 0 007 14c0 .712.304 1.354.788 1.803a1.944 1.944 0 011.138-.895 1.055 1.055 0 111.07 0c.475.139.886.458 1.138.895.484-.45.788-1.091.788-1.803a2.464 2.464 0 00-2.461-2.46z"
      ></path>
      <path
        fill="#fff"
        d="M9.461 15.534c-.459 0-.882.262-1.091.67a2.445 2.445 0 002.183 0 1.233 1.233 0 00-1.092-.67zM9.46 14.352a.352.352 0 100-.704.352.352 0 000 .704zM14.866 12.264a1.943 1.943 0 011.138-.895 1.055 1.055 0 111.07 0c.475.139.886.458 1.138.895.484-.45.788-1.091.788-1.803A2.464 2.464 0 0016.54 8a2.464 2.464 0 00-2.462 2.46c0 .713.304 1.354.788 1.804z"
      ></path>
      <path
        fill="#fff"
        d="M15.448 12.666a2.446 2.446 0 002.183 0 1.233 1.233 0 00-1.092-.67c-.458 0-.881.26-1.091.67zM16.54 10.813a.352.352 0 10-.001-.704.352.352 0 000 .704zM16.54 15.078a2.464 2.464 0 00-2.462 2.461c0 .712.304 1.354.788 1.803a1.944 1.944 0 011.138-.895 1.055 1.055 0 111.07 0c.475.139.886.458 1.138.895.484-.45.788-1.091.788-1.803a2.464 2.464 0 00-2.46-2.46z"
      ></path>
      <path
        fill="#fff"
        d="M16.54 19.073c-.46 0-.882.262-1.092.671a2.446 2.446 0 002.183 0 1.233 1.233 0 00-1.092-.67zM16.54 17.89a.352.352 0 10-.001-.702.352.352 0 000 .703zM12.008 12.124c.14.188.258.392.353.608l1.38-.797a3.14 3.14 0 01-.259-.662l-1.474.851zM12.008 15.876l1.474.85c.062-.232.15-.453.26-.662l-1.381-.797c-.095.217-.214.42-.353.609z"
      ></path>
    </svg>
  );
};

export default CircledManagementStaffIcon;
