// Domain Models
import { StaffMemeber } from "../../app/domain";

export class StaffMemebersResponse {
  constructor(response = {}) {
    this.staffMembers = response.data.staff_members.map(
      member => new StaffMemeber(member)
    );
  }
}
