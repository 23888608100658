import styled from "styled-components";

export const GeneralSettingsListModalStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      text-transform: uppercase;
    }
  }

  .input {
    margin-top: 2px;
  }

  .list {
    margin-top: 4px;
    min-height: 150px;
    max-height: 344px;
    padding-top: 1px;
    overflow: auto;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .workerName {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #171725;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .submitButton {
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      padding: 10px 18px;
    }
  }
`;
