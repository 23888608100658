import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router";
import { useDispatch } from "react-redux";

// Components
import BranchForm from "../branchForm/branch.form";

// Form Model
import { BranchFormModel } from "../../../../models/app/forms";

// Calls
import { fetchBranchRequest } from "../../../../api/calls/branch.call";

// Actions
import { updateBranch } from "../../../../redux/actions/app.actions";

// Services
import { branchValidation } from "../../../../services/validations/branch.validation";

// Styles
import { EditBranchFormStyles } from "./editBranchForm.styles";

const EditBranchFrom = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [initialBranchValue, setInitialBranchValue] = useState(
    new BranchFormModel()
  );
  const { id } = useParams();

  const updateBranchHandler = values => {
    dispatch(updateBranch(values, initialBranchValue, id));
    setTimeout(() => {
      history.push("/branches");
    }, 100);
  };

  const fetchBranch = useCallback(async () => {
    const response = await fetchBranchRequest(id);
    if (response.hasError) {
    }
    setInitialBranchValue(
      new BranchFormModel(response.branch.generateBranchForm())
    );
  }, [id]);

  useEffect(() => {
    fetchBranch();
  }, [fetchBranch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialBranchValue,
    onSubmit: updateBranchHandler,
    validate: branchValidation
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    setValues,
    handleBlur
  } = formik;

  return (
    <EditBranchFormStyles>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <BranchForm
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          handleChange={handleChange}
          setValues={setValues}
          handleBlur={handleBlur}
          submitText="Save Changes"
        />
      </form>
    </EditBranchFormStyles>
  );
};

export default EditBranchFrom;
