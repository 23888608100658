import styled from "styled-components";

export const DashboardStyles = styled.section`
  min-height: calc(100vh - 42px);

  .userContainer {
    width: 100%;
    margin-top: 24px;
    height: 104px;
    display: flex;

    .user {
      width: 100%;
      height: 100%;
      padding: 0 24px;
      background: #202650;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .userDetails {
        display: flex;
        align-items: center;

        .avatar {
          width: 48px;
          height: 48px;
          margin-right: 12px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          overflow: hidden;

          .profileImage {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #ffffff;
        }

        .position {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          margin-top: 4px;
        }
      }

      .dateContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .date {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 32px;
          color: #ffffff;
        }

        .day {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-top: 4px;
          color: #ffffff;
        }
      }
    }

    .chip {
      min-width: 323px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }
  }

  .cardsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    gap: 24px;

    .card {
      width: 100%;

      .servicesList {
        height: 280px;
        padding: 8px 12px;
      }

      .hasMore {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8c8c95;
        padding-bottom: 8px;
        text-align: center;
      }
    }
  }
`;
