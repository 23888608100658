// constants
import * as filtersTypes from "../constants/services.constants";

export const filters = [
  {
    title: "All",
    value: filtersTypes.FILTERS_ALL
  },
  {
    title: "Accepted",
    value: filtersTypes.FILTERS_ACCEPTED
  },
  {
    title: "In Progress",
    value: filtersTypes.FILTERS_IN_PROGRESS
  },
  {
    title: "Paused",
    value: filtersTypes.FILTERS_PAUSED
  }
];
