import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Card, Header, Body } from "../components/UI";
import { Heading2 } from "../components/typography";

// Page specific components
import { PlanCard } from "../components/PageSpecificComponents/pagesComponents/subscriptionPlans";

// Styles
import { SubscriptionPlansStyles } from "../pagesStyles/subscriptionPlans.styles";

// Calls
import {
  fetchSubscriptionPlansRequest,
  subscribeToPlanRequest,
  cancelSubscribeRequest,
  resumeSubscribeRequest,
  changeSubscriptionPlanRequest
} from "../api/calls/subscription.call";

// Actions
import { fetchActiveSubscription } from "../redux/actions/business.actions";

// Utils
import { isEmpty } from "../utils";

const SubscriptionPlans = () => {
  const dispatch = useDispatch();
  const { activeSubscription } = useSelector(state => state.businessProfile);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const fetchSubscriptionPlans = async () => {
    const response = await fetchSubscriptionPlansRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setSubscriptionPlans(response.subscriptionPlans);
  };

  useEffect(() => {
    fetchSubscriptionPlans();
    if (isEmpty(activeSubscription)) {
      dispatch(fetchActiveSubscription());
    }
  }, [dispatch, activeSubscription]);

  const subscribeToPlan = async subscriptionPlan => {
    const response = await subscribeToPlanRequest(subscriptionPlan);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    window.location.href = response.redirectUrl;
  };

  const cancelSubscribe = async () => {
    const response = await cancelSubscribeRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success(response.message);
    setTimeout(() => {
      dispatch(fetchActiveSubscription());
    }, 1000);
  };

  const resumeSubscription = async () => {
    const response = await resumeSubscribeRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success(response.message);
    setTimeout(() => {
      dispatch(fetchActiveSubscription());
    }, 1000);
  };

  const changeSubscriptionPlan = async subscriptionPlan => {
    const response = await changeSubscriptionPlanRequest(subscriptionPlan);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success(response.message);
    setTimeout(() => {
      dispatch(fetchActiveSubscription());
    }, 1000);
  };

  return (
    <SubscriptionPlansStyles>
      <PageHeader title="Choose a Plan" />
      <Card className="card">
        <Header>
          <Heading2>CHOOSE A PLAN</Heading2>
        </Header>
        <Body className="plansContainer">
          {subscriptionPlans.map(plan => (
            <PlanCard
              key={plan.id}
              {...plan}
              activeSubscription={activeSubscription}
              activePlanExists={!isEmpty(activeSubscription)}
              changeSubscriptionPlan={() => changeSubscriptionPlan(plan)}
              subscribeToPlan={() => subscribeToPlan(plan)}
              cancelSubscribe={cancelSubscribe}
              resumeSubscription={resumeSubscription}
              isSelected={
                plan.id === activeSubscription?.item?.subscriptionPlan?.id
              }
            />
          ))}
        </Body>
      </Card>
    </SubscriptionPlansStyles>
  );
};

export default SubscriptionPlans;
