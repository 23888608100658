// components
import { Card, Header, Body } from "../components/UI";
import { Heading2 } from "../components/typography";
// containers
import ResetPasswordForm from "../containers/forms/resetPassword/ResetPassword.form";
// styles
import { ResetPasswordStyles } from "../pagesStyles/resetPassword.styles";

const ResetPassword = () => {
  return (
    <ResetPasswordStyles>
      <Card className="card">
        <Header>
          <Heading2>RESET PASSWORD</Heading2>
        </Header>
        <Body>
          <ResetPasswordForm />
        </Body>
      </Card>
    </ResetPasswordStyles>
  );
};

export default ResetPassword;
