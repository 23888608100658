// AUTH ACTION TYPES
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const FETCH_AUTHENTICATED_USER_REQUEST =
  "FETCH_AUTHENTICATED_USER_REQUEST";
export const FETCH_AUTHENTICATED_USER_SUCCESS =
  "FETCH_AUTHENTICATED_USER_SUCCESS";
export const FETCH_AUTHENTICATED_USER_FAILED =
  "FETCH_AUTHENTICATED_USER_FAILED";

// USER/PROFILE ACTION TYPES
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

// SYSTEM COMPONENTS ACTION TYPES
export const FETCH_SYSTEM_COMPONENTS = "FETCH_SYSTEM_COMPONENTS";
export const FETCH_SYSTEM_COMPONENTS_SUCCESS =
  "FETCH_SYSTEM_COMPONENTS_SUCCESS";
export const FETCH_SYSTEM_COMPONENTS_FAILED = "FETCH_SYSTEM_COMPONENTS_FAILED";

// BRANCHES ACTION TYPES
export const CREATE_BRANCH_REQUEST = "CREATE_BRANCH_REQUEST";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILED = "CREATE_BRANCH_FAILED";
export const FETCH_BRANCHES_REQUEST = "FETCH_BRANCHES_REQUEST";
export const FETCH_BRANCHES_SUCCESS = "FETCH_BRANCHES_SUCCESS";
export const FETCH_BRANCHES_FAILED = "FETCH_BRANCHES_FAILED";
export const UPDATE_BRANCH_REQUEST = "UPDATE_BRANCH_REQUEST";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILED = "UPDATE_BRANCH_FAILED";
export const SET_ACTIVE_BRANCH = "SET_ACTIVE_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";

// SOCKET ACTION TYPES
export const FETCH_CHAT_AUTH_TOKEN_REQUEST = "FETCH_CHAT_AUTH_TOKEN_REQUEST";
export const FETCH_CHAT_AUTH_TOKEN_FAILED = "FETCH_CHAT_AUTH_TOKEN_FAILED";
export const CONNECT_TO_SOCKET_SUCCESS = "CONNECT_TO_SOCKET_SUCCESS";
