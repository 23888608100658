const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#172B4D"
        d="M6.61 13.892c3.543 0 6.475-2.94 6.475-6.475 0-3.542-2.939-6.475-6.48-6.475C3.068.942.135 3.875.135 7.417c0 3.536 2.94 6.475 6.475 6.475zm0-4.31c-1.903 0-3.376.679-4.017 1.44a5.362 5.362 0 01-1.371-3.605 5.364 5.364 0 015.382-5.396c2.997 0 5.396 2.4 5.402 5.396a5.359 5.359 0 01-1.37 3.612c-.642-.762-2.115-1.447-4.025-1.447zm0-1.08c1.22.013 2.178-1.028 2.178-2.393 0-1.282-.958-2.348-2.177-2.348S4.427 4.827 4.434 6.109c.006 1.365.958 2.38 2.177 2.393z"
      ></path>
    </svg>
  );
};

export default ProfileIcon;
