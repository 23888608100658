import { useHistory } from "react-router";
import { memo } from "react";
import { useFormik } from "formik";

// Components
import {
  Label,
  Paragraph,
  Span,
  Heading1
} from "../../../components/typography";
import { TextField, Button } from "../../../components/UI";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Services
import { registerStepOneValidation } from "../../../services/validations/register.validations";

// Styles
import { RegisterStepOneFormStyles } from "./registerStepOneForm.styles";

const RegisterStepOneForm = ({ accountForm, handleNext }) => {
  const history = useHistory();

  const handleCreateAccount = async values => {
    handleNext(values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: accountForm,
    onSubmit: handleCreateAccount,
    validate: registerStepOneValidation
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;
  return (
    <RegisterStepOneFormStyles>
      <div className="tabs">
        <Span
          className="tab"
          style={{
            marginRight: 32,
            color: "#172B4D"
          }}
        >
          Create an account
        </Span>
        <Span className="tab">Set Up business profile</Span>
      </div>
      <Heading1>Create an account to continue.</Heading1>
      <form onSubmit={handleSubmit} className="form">
        <div className="firstAndLastNameContainer">
          <div className="inputContainer">
            <Label className="label">First Name</Label>
            <TextField
              value={values.firstName}
              touched={touched.firstName}
              error={errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="First Name"
              name="firstName"
            />
          </div>
          <div className="inputContainer">
            <Label className="label">Last Name</Label>
            <TextField
              value={values.lastName}
              touched={touched.lastName}
              error={errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              name="lastName"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div>
          <Label className="label">Email</Label>
          <TextField
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            name="email"
            placeholder="Email"
          />
        </div>
        <div>
          <Label className="label">Password</Label>
          <TextField
            value={values.password}
            touched={touched.password}
            error={errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            name="password"
            type="password"
            placeholder="Password"
          />
        </div>
        <div>
          <Label className="label">Confirm Password</Label>
          <TextField
            value={values.passwordConfirmation}
            touched={touched.passwordConfirmation}
            error={errors.passwordConfirmation}
            onBlur={handleBlur}
            onChange={handleChange}
            name="passwordConfirmation"
            type="password"
            placeholder="Confirm Password"
          />
        </div>
        <Button
          type="submit"
          className="nextButton"
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Next
        </Button>
        <Paragraph className="bottomText">
          Already a memeber?{" "}
          <Span className="link" onClick={() => history.push("/")}>
            Log In
          </Span>
        </Paragraph>
      </form>
    </RegisterStepOneFormStyles>
  );
};

export default memo(RegisterStepOneForm);
