const SmileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#B5B5BE"
        fillRule="evenodd"
        d="M11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm-4.593-4.18a1 1 0 011.199-1.601c1.823 1.364 5.022 1.373 6.786.01a1 1 0 111.223 1.582c-2.482 1.918-6.672 1.907-9.208.009zM7.5 10a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm7 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default SmileIcon;
