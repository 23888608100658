import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Card, Header, Body, Button, CentralModal } from "../components/UI";
import { Heading2 } from "../components/typography";

// Page specific components
import {
  SubscriptionPlaceholder,
  SubscriptionPlan,
  BusinessProfileDetails,
  SubscriptionPlanInformation,
  EditBusinesProfile
} from "../components/PageSpecificComponents/pagesComponents/businessProfileComponents";

// Builders
import * as buttonDesignType from "../builders/buttonDesign.types";

// Constants
import * as bussinessConsts from "../constants/business-profile/business-profile.constants";

// Calls
import {
  setUpBillingRequest,
  billingRequest
  // getSubscriptionPlans
} from "../api/calls/subscription.call";

// Actions
import {
  openModal,
  closeModal,
  fetchBusinessData
} from "../redux/actions/business.actions";

// Styles
import { BusinessProfileStyles } from "../pagesStyles/businessProfile.styles";
import { isEmpty } from "../utils";

const BusinessProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { business, location, activeSubscription, modalType } = useSelector(
    state => state.businessProfile
  );

  const openSubscriptionPlanInfo = useCallback(
    () => dispatch(openModal(bussinessConsts.SUBSCRIPTION_DETAILS)),
    [dispatch]
  );

  const openEditBusinessProfileModal = useCallback(
    () => dispatch(openModal(bussinessConsts.EDIT_BUSINESS_PROFILE_MODAL)),
    [dispatch]
  );

  const closeModalHandler = useCallback(
    () => dispatch(closeModal()),
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchBusinessData());
  }, [dispatch]);

  const setUpBilling = async () => {
    const response = await setUpBillingRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    window.location.href = response.redirectUrl;
  };

  const billing = async () => {
    const response = await billingRequest();
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    window.location.href = response.redirectUrl;
  };

  const isActiveSubscription =
    !isEmpty(activeSubscription) &&
    isEmpty(activeSubscription.canceledAt) &&
    isEmpty(activeSubscription.cancelAt);

  return (
    <BusinessProfileStyles isActiveSubscription={isActiveSubscription}>
      <PageHeader title="Business Profile" />
      <Card className="card">
        <Header className="header">
          <Heading2>BUSINESS PROFILE</Heading2>
          <Button
            onClick={openEditBusinessProfileModal}
            buttonDesignType={buttonDesignType.SECONDARY}
          >
            Edit Business Profile
          </Button>
        </Header>
        <Body>
          <BusinessProfileDetails business={business} location={location} />
        </Body>
      </Card>
      <Card className={`card ${activeSubscription && "subscribedCard"}`}>
        <Header
          className={`header ${activeSubscription && "subscribedHeader"}`}
        >
          <Heading2>SUBSCRIPTION</Heading2>
          {business?.paymentInformation ? (
            <div className="buttonsContainer">
              <Button
                style={{ padding: "10px 20px 10px 20px" }}
                onClick={billing}
                buttonDesignType={buttonDesignType.PRIMARY}
              >
                Billing
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => history.push("/subscription-plans")}
                buttonDesignType={buttonDesignType.PRIMARY}
              >
                Subscription plan
              </Button>
            </div>
          ) : (
            <Button
              onClick={setUpBilling}
              buttonDesignType={buttonDesignType.PRIMARY}
            >
              Set Up Billing
            </Button>
          )}
        </Header>
        <Body className={`${activeSubscription && "subscribedBody"}`}>
          {activeSubscription ? (
            <SubscriptionPlan
              isActiveSubscription={isActiveSubscription}
              activeSubscription={activeSubscription}
              openSubscribeInformation={openSubscriptionPlanInfo}
            />
          ) : (
            <SubscriptionPlaceholder placeholder="Select a subscription plan to unlock features of our application" />
          )}
        </Body>
      </Card>
      {modalType === bussinessConsts.SUBSCRIPTION_DETAILS && (
        <CentralModal
          closeModal={closeModalHandler}
          className="planInformationCard"
        >
          <SubscriptionPlanInformation
            activeSubscription={activeSubscription}
            closeModal={closeModalHandler}
          />
        </CentralModal>
      )}
      {modalType === bussinessConsts.EDIT_BUSINESS_PROFILE_MODAL && (
        <CentralModal
          closeModal={closeModalHandler}
          className="editBusinesProfileCard"
        >
          <EditBusinesProfile closeModal={closeModalHandler} />
        </CentralModal>
      )}
    </BusinessProfileStyles>
  );
};

export default BusinessProfile;
