import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
// components
import { PageHeader } from "../components/PageSpecificComponents";
import { Heading2 } from "../components/typography";
import { Body, Card, Header } from "../components/UI";
// Page Specific components
import {
  Filters,
  Graph,
  GraphData
} from "../components/PageSpecificComponents/pagesComponents/analytics";
// constants
import * as analyticsContants from "../constants/analytics.constants";

// Calls
import {
  fetchAnalythicsRequest,
  exportAnalythicsPDF
} from "../api/calls/analythics.call";

// styles
import { AnalyticsStyles } from "../pagesStyles/analytics.styles";

const Analytics = () => {
  const [analyticsState, setAnalyticsState] = useState({
    filters: {
      branchFilter: analyticsContants.ALL_BRANCHES,
      branchId: "",
      branchName: "",
      timeFrame: "",
      dateFrom: null,
      dateTo: null
    },
    graphsData: {},
    chosenGraphs: []
  });

  const splitAnalyticsDataIntoGraphData = data => {
    let ringOne = [];
    let ringTwo = [];
    let ringThree = [];

    if (data) {
      ringOne = Array.isArray(data) ? [...data] : [data];

      ringOne.forEach(el => {
        if (el.children?.length > 0) {
          const ringTwoSplitValue = el.value / el.children.length;
          el.children.forEach(ele => {
            ringTwo.push({ ...ele, value: ringTwoSplitValue });
          });
        }
      });

      ringTwo.forEach(e => {
        if (e.children?.length > 0) {
          const ringThreeSplitValue = e.value / e.children.length;
          e.children.forEach(ele => {
            ringThree.push({ ...ele, value: ringThreeSplitValue });
          });
        } else {
          ringThree.push({});
        }
      });
    }

    return {
      ringOne,
      ringTwo,
      ringThree
    };
  };

  const convertDateToString = date => {
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = `${date.getFullYear()}`;

      const formatedDay = day < 10 ? `0${day}` : `${day}`;
      const formatedMonth = month < 10 ? `0${month}` : `${month}`;

      return `${year}-${formatedMonth}-${formatedDay}`;
    }
    return "";
  };

  const exportAnalyticsPDF = async () => {
    const response = await exportAnalythicsPDF(
      analyticsState.filters.branchId,
      convertDateToString(analyticsState.filters.dateFrom),
      convertDateToString(analyticsState.filters.dateTo)
    );

    const fileURL = window.URL.createObjectURL(response.data);
    const alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = "analytics.pdf";
    alink.click();
  };

  const getAnalytics = useCallback(async () => {
    const response = await fetchAnalythicsRequest(
      analyticsState.filters.branchId,
      convertDateToString(analyticsState.filters.dateFrom),
      convertDateToString(analyticsState.filters.dateTo)
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }

    setAnalyticsState(prevValues => ({
      ...prevValues,
      graphsData:
        response.analytics.length !== 0
          ? splitAnalyticsDataIntoGraphData(response.analytics)
          : null,
      chosenGraphs:
        response.analytics.length !== 0
          ? [splitAnalyticsDataIntoGraphData(response.analytics)]
          : []
    }));
  }, [
    analyticsState.filters.branchId,
    analyticsState.filters.dateFrom,
    analyticsState.filters.dateTo
  ]);

  const selectGraph = data => {
    if (
      data.name !==
      analyticsState.chosenGraphs[analyticsState.chosenGraphs.length - 1]
        .ringOne[0].name
    ) {
      setAnalyticsState(prevValues => ({
        ...prevValues,
        graphsData: splitAnalyticsDataIntoGraphData(data),
        chosenGraphs: [
          ...prevValues.chosenGraphs,
          splitAnalyticsDataIntoGraphData(data)
        ]
      }));
    }
  };

  const choseRandomGraph = (graph, index) => {
    setAnalyticsState(prevValues => ({
      ...prevValues,
      graphsData: graph,
      chosenGraphs: prevValues.chosenGraphs.filter((val, i) => i <= index)
    }));
  };

  const backOneGraph = () => {
    if (analyticsState.chosenGraphs.length > 1) {
      setAnalyticsState(prevValues => ({
        ...prevValues,
        graphsData: prevValues.chosenGraphs[prevValues.chosenGraphs.length - 2],
        chosenGraphs: prevValues.chosenGraphs.slice(0, -1)
      }));
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [getAnalytics]);

  return (
    <AnalyticsStyles>
      <PageHeader title="Analytics" />
      <Card className="headerCard">
        <Header>
          <Heading2>GENERAL ANALYTIC INFO</Heading2>
        </Header>
        <Body className="filters">
          <Filters
            filters={analyticsState.filters}
            setAnalyticsState={setAnalyticsState}
          />
        </Body>
      </Card>
      {analyticsState.graphsData ? (
        <div className="analyticsContainer">
          <Graph
            label={
              analyticsState.filters.branchName
                ? analyticsState.filters.branchName
                : "All branches"
            }
            graphsData={analyticsState.graphsData}
            graphCellClick={selectGraph}
          />
          <GraphData
            backOneGraph={backOneGraph}
            choseRandomGraph={choseRandomGraph}
            chosenGraphs={analyticsState.chosenGraphs}
            label={"label"}
            graphsData={analyticsState.graphsData}
            setGraphsData={selectGraph}
            exportAnalyticsPDF={exportAnalyticsPDF}
          />
        </div>
      ) : (
        <div className="analyticsContainer">
          <p className="analyticsEmpty">No data</p>
        </div>
      )}
    </AnalyticsStyles>
  );
};

export default Analytics;
