import styled from "styled-components";

export const PrivacyPolicyStyles = styled.section`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 750px;
  }

  .mainTitle {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.116667px;
    color: #171725;
    margin-bottom: 40px;
  }

  .title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.116667px;
    color: #171725;
    text-decoration: underline;
    margin: 40px 0;
    text-align: left;
    width: 100%;
  }

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #44444f;
    margin: 20px 0;
    width: 100%;
  }

  .listContainers {
    padding: 0 10px;
    width: 100%;
  }

  .listText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #44444f;
    margin: 15px 0;
  }

  @media only screen and (max-width: 840px) {
    .container {
      width: 600px;
    }
  }

  @media only screen and (max-width: 650px) {
    .container {
      width: 90%;
    }
  }
`;
