// Action types
import * as actionTypes from "../actionTypes/services.types";

// Utils
import { updateObject, isEmpty } from "../../utils/global.utils";

// Constants
import * as modalTypes from "../../constants/serviceRequests.constants";
import { removeItem, setItem } from "../../utils";
import { OPENED_SERVICE_DETAILS_BRANCH } from "../../constants/localStorage/localStorage.constants";

const initialState = {
  services: [],
  lastService: undefined,
  hasMore: false,
  selectedService: undefined,
  modalType: undefined,
  shouldRefetchServicesAfterAction: undefined,
  workersFormData: [],
  serviceId: undefined,
  centralModalType: undefined,
  tiketDetailsModalType: undefined,
  // schedule state
  employees: [],
  workDay: undefined
};

export const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERVICES_SUCCESS:
      return setServices(state, action);
    case actionTypes.FETCH_MORE_SERVICES_SUCCESS:
      return setAdditionalServices(state, action);
    case actionTypes.OPEN_SERVICE_DETAILS_SUCCESS:
      return openTicketDetails(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);
    case actionTypes.SERVICES_CLOSE_CENTRAL_MODAL:
      return closeCentralModal(state, action);
    case actionTypes.CANCEL_SERVICE_SUCCESS:
      return updateServicesAfterActionTrigger(state, action);
    case actionTypes.START_SERVICE_SUCCESS:
      return updateServicesAfterActionTrigger(state, action);
    case actionTypes.PAUSE_SERVICE_SUCCESS:
      return updateServicesAfterActionTrigger(state, action);
    case actionTypes.RESUME_SERVICE_SUCCESS:
      return updateServicesAfterActionTrigger(state, action);
    case actionTypes.FINISH_SERVICE_SUCCESS:
      return updateServicesAfterActionTrigger(state, action);
    case actionTypes.SERVICES_SEE_NEW_MESSAGES:
      return seeNewMessages(state, action);
    case actionTypes.SERVICES_SET_NEW_MESSAGES:
      return setNewMessageInService(state, action);
    case actionTypes.OPEN_WORKERS_FORM:
      return openWorkersForm(state, action);
    case actionTypes.UPDATE_SERVICE_SUCCESS:
      return updateService(state, action);
    case actionTypes.SERVICES_SET_TIKET_DETAILS_MODAL:
      return setTiketDetailsModalType(state, action);
    case actionTypes.SERVICES_CLEAR_SHOULD_REFETCH:
      return clearShouldRefresh(state, action);
    case actionTypes.FETCH_EMPLOYEES_SCHEDULE_SUCCESS:
      return setEmployees(state, action);
    case actionTypes.RESET_SCHEDULE_ON_PAGE_LEAVE:
      return resetScheduleOnPageLeave(state, action);
    default:
      return state;
  }
};

const setServices = (state, { services, pagination }) => {
  return updateObject(state, {
    services,
    page: pagination.page,
    lastService: isEmpty(services)
      ? undefined
      : services[services.length - 1].id,
    totalServices: pagination.totalRecords,
    hasMore: pagination.hasMore,
    shouldRefetchServicesAfterAction: undefined
  });
};

const setAdditionalServices = (state, { services, pagination }) => {
  return updateObject(state, {
    services: [...state.services, ...services],
    lastService: isEmpty(services)
      ? undefined
      : services[services.length - 1].id,
    hasMore: pagination.hasMore,
    shouldRefetchServicesAfterAction: undefined
  });
};

const openTicketDetails = (state, { service }) => {
  setItem(OPENED_SERVICE_DETAILS_BRANCH, service.branchId);
  return updateObject(state, {
    selectedService: service,
    modalType: modalTypes.TIKET_DETAILS
  });
};

const updateServicesAfterActionTrigger = (state, action) => {
  return updateObject(state, {
    shouldRefetchServicesAfterAction: "refetch",
    selectedService: undefined,
    modalType: undefined
  });
};

const openWorkersForm = (state, { serviceId, workers }) => {
  return updateObject(state, {
    centralModalType: modalTypes.WORKERS,
    workersFormData: workers.map(worker => worker.id),
    serviceId
  });
};

const updateService = (state, { service }) => {
  return updateObject(state, {
    shouldRefetchServicesAfterAction: "refetch",
    selectedService: !isEmpty(state.selectedService) ? service : undefined,
    centralModalType: undefined,
    workersFormData: [],
    serviceId: undefined,
    tiketDetailsModalType: undefined
  });
};

const setTiketDetailsModalType = (state, { tiketDetailsModalType }) => {
  return updateObject(state, {
    tiketDetailsModalType
  });
};

const clearShouldRefresh = (state, action) => {
  return updateObject(state, {
    shouldRefetchServicesAfterAction: undefined
  });
};

const closeCentralModal = (state, action) => {
  return updateObject(state, {
    centralModalType: undefined,
    workersFormData: [],
    serviceId: undefined
  });
};

const closeModal = (state, action) => {
  removeItem(OPENED_SERVICE_DETAILS_BRANCH);
  return updateObject(state, {
    selectedService: undefined,
    modalType: undefined,
    serviceId: undefined
  });
};

const setNewMessageInService = (state, { serviceId }) => {
  return updateObject(state, {
    services: state.services.map(service =>
      service.id === serviceId
        ? {
            ...service,
            newMessagesCount: service.newMessagesCount + 1
          }
        : service
    ),
    selectedService: state.selectedService && {
      ...state.selectedService,
      newMessagesCount: state.selectedService.newMessagesCount + 1
    }
  });
};

const seeNewMessages = (state, { serviceId }) => {
  return updateObject(state, {
    services: state.services.map(service =>
      service.id === serviceId
        ? { ...service, newMessagesCount: 0, isSeen: true }
        : service
    ),
    selectedService: {
      ...state.selectedService,
      newMessagesCount: 0,
      isSeen: true
    }
  });
};

// SCHEDULE
const setEmployees = (state, { employees, workDay }) => {
  return updateObject(state, {
    employees,
    shouldRefetchServicesAfterAction: undefined,
    workDay: workDay
  });
};

const resetScheduleOnPageLeave = (state, action) => {
  return updateObject(state, {
    employees: [],
    workDay: undefined
  });
};
