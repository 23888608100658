// Action types
import * as actionTypes from "../actionTypes/bussiness.types";

// Utils
import { updateObject } from "../../utils/global.utils";

const initialState = {
  business: undefined,
  location: undefined,
  activeSubscription: undefined,
  businessFormError: undefined,
  modalType: undefined
};

export const businessProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BUSINESS_SUCCESS:
      return setBusiness(state, action);
    case actionTypes.UPDATE_BUSINESS_PROFILE_SUCCESS:
      return setBusiness(state, action);
    case actionTypes.UPDATE_BUSINESS_PROFILE_FAILED:
      return setBusinessFormFailed(state, action);
    case actionTypes.OPEN_MODAL:
      return openModal(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);
    case actionTypes.FETCH_ACTIVE_SUBSCRIPTION_SUCCESS:
      return setActiveSubscription(state, action);
    default:
      return state;
  }
};

const setBusiness = (state, { businessResponseData }) => {
  return updateObject(state, {
    business: businessResponseData.business,
    location: businessResponseData.location,
    activeSubscription: businessResponseData.activeSubscription,
    modalType: undefined
  });
};

const openModal = (state, { modalType }) => {
  return updateObject(state, {
    modalType
  });
};

const closeModal = (state, action) => {
  return updateObject(state, {
    modalType: undefined
  });
};

const setBusinessFormFailed = (state, { errorResponse }) => {
  return updateObject(state, {
    businessFormError: errorResponse
  });
};

const setActiveSubscription = (state, { activeSubscription }) => {
  return updateObject(state, {
    activeSubscription
  });
};
