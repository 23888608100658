import styled from "styled-components";

export const ServiceCommentStyles = styled.div`
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  padding: 4px 8px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  .userContainer {
    width: 100%;
    margin-top: 5px;
    display: flex;

    .user {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .userDetails {
        display: flex;
        align-items: center;

        .avatar {
          width: 30px;
          height: 30px;
          margin-right: 12px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          overflow: hidden;

          .profileImage {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }

  .comment {
    text-align: left;
    margin-top: 10px;
    margin-right: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #748094;
  }

  .dateContainer {
    position: relative;
    left: -5px;

    .date {
      text-align: right;
      margin-top: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #748094;
    }
  }

  .dotsContainer {
    position: relative;
    display: flex;
    align-items: center;

    .popup {
      position: absolute;
      background: #ee3a36;
      padding: 12px;
      width: 154px;
      top: 20px;
      right: 5px;
      border-radius: 10px;

      .pointer {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 20px 20px;
        border-color: transparent transparent #ee3a36 transparent;
        top: -10px;
        right: 0;
      }

      .deleteText {
        margin-left: 5px;
        color: #ffffff;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .inputContainer {
    position: relative;
    left: 20%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;
