// Domain Models
import { SpecialDay } from "../../app/domain";

export class SpecialDaysResponse {
  constructor(response = {}) {
    this.specialDays = response.data.special_days.map(
      day => new SpecialDay(day)
    );
  }
}
