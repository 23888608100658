import styled from "styled-components";

export const EditBusinesProfileFormStyles = styled.div`
  .formContainer {
    display: flex;
    justify-content: space-between;

    .leftInputs {
      width: 390px;
    }

    .rightInputs {
      width: 390px;

      .stateDropdown {
        background: #fafbfc;
        border: 1px solid #dfe1e6;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
`;
