import styled from "styled-components";
// constants
import * as statuses from "../../../../../../constants/status.constants";

const activeInProgress = "#D7FDE9";
const inProgress = "#E3F8ED";
const activePaused = "#FFF0B3";
const paused = "#FEF4D3";
const activePending = "#DCDCDC";
const pending = "#F0F0F0";

export const ServiceTimeFrameStyles = styled.div`
  position: absolute;
  height: 68px;
  width: ${({ progressLength }) =>
    progressLength ? `calc(105px * ${progressLength})` : "0px"};
  left: ${({ startPosition }) =>
    startPosition ? `calc(105px * ${startPosition})` : "0px"};

  .header {
    display: flex;
    align-items: center;
    width: 101px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #172b4d;
    position: absolute;
    cursor: pointer;
    z-index: ${({ isActive }) => (isActive ? "4" : "2")};

    .moreOptions {
      position: absolute;
      z-index: 1;
      width: 33px;
      right: 0;
      white-space: nowrap;
      background: ${({ type }) => {
        switch (type) {
          case statuses.IN_PROGRESS:
            return "#5FD999";
          case statuses.PAUSED:
            return "#F3C72A";
          case statuses.PENDING:
            return "#B5B5B5";
          default:
            return "#5FD999";
        }
      }};
      padding: 2px 8px;
      display: flex;
      justify-content: flex-end;
      border: 1px solid
        ${({ type }) => {
          switch (type) {
            case statuses.IN_PROGRESS:
              return "#5FD999";
            case statuses.PAUSED:
              return "#F3C72A";
            case statuses.PENDING:
              return "#B5B5B5";
            default:
              return "#5FD999";
          }
        }};
      border-left: none;
      border-top-right-radius: 3px;
    }
  }

  .moreOptions:hover {
      background: ${({ type }) => {
        switch (type) {
          case statuses.IN_PROGRESS:
            return "#6BF2AB";
          case statuses.PAUSED:
            return "#FFD84C";
          case statuses.PENDING:
            return "#C8C8C8";
          default:
            return "#C8C8C8";
        }
      }};
    }

   


    .headerId {
      width: 73px;
      white-space: nowrap;
      padding: 2px 6px;
      position: relative;
      z-index: ${({ isActive }) => (isActive ? "4" : "2")};
      background: ${({ type, isActive }) => {
        switch (type) {
          case statuses.IN_PROGRESS:
            return isActive ? activeInProgress : inProgress;
          case statuses.PAUSED:
            return isActive ? activePaused : paused;
          case statuses.PENDING:
            return isActive ? activePending : pending;
          default:
            return isActive ? activeInProgress : inProgress;
        }
      }};
      border: 1px solid
        ${({ type }) => {
          switch (type) {
            case statuses.IN_PROGRESS:
              return "#5FD999";
            case statuses.PAUSED:
              return "#F3C72A";
            case statuses.PENDING:
              return "#B5B5B5";
            default:
              return "#5FD999";
          }
        }};
      border-bottom: 1px solid transparent;
      border-radius: 3px 3px 0px 0px;
    }

    .headerIdBeforeHovered::before {
        background: ${({ type }) => {
          switch (type) {
            case statuses.IN_PROGRESS:
              return "#6BF2AB";
            case statuses.PAUSED:
              return "#FFD84C";
            case statuses.PENDING:
              return "#C8C8C8";
            default:
              return "#C8C8C8";
          }
        }} !important;
    }

    .headerId::before {
      content: "";
      position: absolute;
      bottom: -1px;
      right: -7px;
      height: 6px;
      width: 6px;
      border-left: 1px solid
        ${({ type }) => {
          switch (type) {
            case statuses.IN_PROGRESS:
              return "#5FD999";
            case statuses.PAUSED:
              return "#F3C72A";
            case statuses.PENDING:
              return "#B5B5B5";
            default:
              return "#5FD999";
          }
        }};
      border-bottom: 1px solid
        ${({ type }) => {
          switch (type) {
            case statuses.IN_PROGRESS:
              return "#5FD999";
            case statuses.PAUSED:
              return "#F3C72A";
            case statuses.PENDING:
              return "#B5B5B5";
            default:
              return "#5FD999";
          }
        }};
      border-bottom-left-radius: 6px;
      background: ${({ type, doesHaveMore }) => {
        if (doesHaveMore) {
          if (type === statuses.IN_PROGRESS) {
            return "#5FD999";
          }

          if (type === statuses.PAUSED) {
            return "#F3C72A";
          }

          if (type === statuses.PENDING) {
            return "#B5B5B5";
          }
        }
        return "#fff";
      }};
      z-index: ${({ isActive }) => (isActive ? "4" : "2")};
    }

    

    .headerId::after {
      content: "";
      position: absolute;
      bottom: -1px;
      right: -6px;
      height: 7px;
      width: 10px;
      border-bottom-left-radius: 3px;
      background: ${({ type, isActive }) => {
        if (type === statuses.IN_PROGRESS) {
          return isActive ? "#D7FDE9" : inProgress;
        }

        if (type === statuses.PAUSED) {
          return isActive ? "#FFF0B3" : "#FEF4D3";
        }

        if (type === statuses.PENDING) {
          return isActive ? "#DCDCDC" : "#F0F0F0";
        }
        return "#fff";
      }}};
    }

    
  .progressBar {
    cursor: pointer;
    background: ${({ type, isActive }) => {
      switch (type) {
        case statuses.IN_PROGRESS:
          return isActive ? activeInProgress : inProgress;
        case statuses.PAUSED:
          return isActive ? activePaused : paused;
        case statuses.PENDING:
          return isActive ? activePending : pending;
        default:
          return isActive ? activeInProgress : inProgress;
      }
    }};
    border: 1px solid
      ${({ type }) => {
        switch (type) {
          case statuses.IN_PROGRESS:
            return "#5FD999";
          case statuses.PAUSED:
            return "#F3C72A";
          case statuses.PENDING:
            return "#B5B5B5";
          default:
            return "#5FD999";
        }
      }};
    position: absolute;
    height: 48px;
    top: 20px;
    border-radius: 3px;
    border-top-left-radius: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 12px;
    z-index: ${({ isActive }) => (isActive ? "3" : "1")};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    .serviceID {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
    }
  }

  .cardHover {
    background: ${({ type }) => {
      switch (type) {
        case statuses.IN_PROGRESS:
          return "#E3FEF0";
        case statuses.PAUSED:
          return "#FFF5CA";
        case statuses.PENDING:
          return "#EAEAEA";
        default:
          return "#E3FEF0";
      }
    }} !important;
  }

  .serviceCardModal {
    width: 400px;
    .activeServiceHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .service {
      padding: 10px 8px;
      border: 1px solid #e2e2ea;
      margin-top: -1px;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
      position: relative;
      cursor: pointer;
    }

    .activeService {
      background: #f1f8fd;
      border: 1px solid #74bde9;
      z-index: 1;
    }
  }
`;
