// Domain Models
import { Business, Location, Subscription } from "../../app/domain";

export class BussinessResponse {
  constructor(response = {}) {
    this.business = new Business(response.data.business);
    this.location = new Location(response.data.business.location);
    this.activeSubscription = response.data?.business?.active_subscription
      ? new Subscription(response.data.business.active_subscription)
      : undefined;
  }
}
