export class UpdateProfileRequest {
  constructor(data = {}) {
    this.email = data?.email || "";
    this.first_name = data?.firstName || "";
    this.last_name = data?.lastName || "";
    this.contact_number = data?.contactNumber || "";
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (initValuesArr.includes(value)) {
        delete this[key];
      }
    });
  }
}
