// Components
import { PageHeader } from "../components/PageSpecificComponents";
import { Header, Card } from "../components/UI";
import { Heading2 } from "../components/typography";

// Containers
import EditBranchFrom from "../containers/forms/manageBranches/editBranch/EditBranch.form";

// Styles
import { EdtBranchStyles } from "../pagesStyles/editBranch.styles";

const EditBranch = () => {
  return (
    <EdtBranchStyles>
      <PageHeader title="Edit Branch" />
      <Card className="card">
        <Header>
          <Heading2>EDIT BRANCH</Heading2>
        </Header>
        <EditBranchFrom />
      </Card>
    </EdtBranchStyles>
  );
};

export default EditBranch;
