// Action types
import * as actionTypes from "../actionTypes/service-comments.types";

// Utils
import { updateObject } from "../../utils/global.utils";

const initialState = {
  comments: []
};

export const serviceCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_COMMENTS_SUCCESS:
      return setServiceComments(state, action);
    case actionTypes.DELETE_SERVICE_COMMENT_SUCCESS:
      return deleteServiceComment(state, action);
    case actionTypes.CREATE_SERVICE_COMMENT_SUCCESS:
      return addServiceComment(state, action);
    default:
      return state;
  }
};

const setServiceComments = (state, { comments }) => {
  return updateObject(state, {
    comments
  });
};

const addServiceComment = (state, { comment }) => {
  return updateObject(state, {
    comments: [...state.comments, comment]
  });
};

const deleteServiceComment = (state, { id }) => {
  return updateObject(state, {
    comments: state.comments.filter(comment => comment.id !== id)
  });
};
