import styled from "styled-components";

// Constants
import * as businessProfileConsts from "../../../constants/business-profile/business-profile.constants";

export const PillStyles = styled.div`
  padding: 4px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;

  ${({ rounded }) => {
    if (rounded) {
      return "border-radius: 32px;";
    }
  }}

  ${({ status }) => {
    if (status === businessProfileConsts.BUSINESS_STATUS_VERIFIED) {
      return `
      background: #74E9BF; 
      color: #ffffff;`;
    }
    if (status === businessProfileConsts.BUSINESS_STATUS_PENDING) {
      return `
      background: #8C8C8C; 
      color: #ffffff;`;
    }
    if (status === businessProfileConsts.BUSINESS_STATUS_NOT_VERIFIED) {
      return `
      background: #EE3A36; 
      color: #ffffff;`;
    }
  }}
`;
