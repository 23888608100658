import { useState, useEffect, useCallback } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";

// Components
import { Header, Button, Body, CentralModal, Card } from "../../../../UI";
import { Heading2 } from "../../../../typography";
import ServiceMenuItem from "../../../../lists/generalBranchSettings/ServiceMenu.item";
import AddService from "../../../../../containers/forms/generalBranchSettingsForms/ServiceMenu/AddService/AddService";
import EditService from "../../../../../containers/forms/generalBranchSettingsForms/ServiceMenu/EditService/EditService";

// Builders
import * as buttonDesignType from "../../../../../builders/buttonDesign.types";
import { serviceMenuFiltersBuilder } from "../../../../../builders/generalBranchSettings.builder";

// Constants
import * as modalTypes from "../../../../../constants/serviceMenu.constants";

// Calls
import {
  fetchServiceMenuRequest,
  deleteServiceMenuRequest
} from "../../../../../api/calls/general-branch-settings.call";

// Styles
import { ServiceMenuStyles } from "./serviceMenu.styles";

const ServiceMenu = ({ branchId }) => {
  const [serviceMenuState, setServiceMenuState] = useState({
    serviceMenu: [],
    chosenService: undefined,
    serviceType: undefined,
    modalType: undefined
  });

  const fetchServiceMenu = useCallback(async () => {
    const response = await fetchServiceMenuRequest(
      branchId,
      serviceMenuState.serviceType
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    setServiceMenuState(prevValues => ({
      ...prevValues,
      serviceMenu: response.serviceMenu
    }));
  }, [branchId, serviceMenuState.serviceType]);

  useEffect(() => {
    fetchServiceMenu();
  }, [fetchServiceMenu]);

  const deleteService = async serviceId => {
    const result = await swal({
      title: "Delete Service?",
      text: "Are you sure you want to delete this service?",
      buttons: true,
      dangerMode: true
    });
    if (!result) {
      return;
    }
    const response = await deleteServiceMenuRequest(branchId, serviceId);
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Service successfully deleted.");
    setServiceMenuState(prevValues => ({
      ...prevValues,
      serviceMenu: response.serviceMenu
    }));
  };

  const setServiceType = type => {
    setServiceMenuState(prevValues => ({
      ...prevValues,
      serviceType: type
    }));
  };

  const setServiceMenuStateAfterCreateOrEdit = serviceMenu => {
    setServiceMenuState(prevValues => ({
      ...prevValues,
      serviceMenu,
      chosenService: undefined,
      modalType: undefined
    }));
  };

  const setModalType = type => {
    setServiceMenuState(prevValues => ({
      ...prevValues,
      modalType: type
    }));
  };

  const editService = service => {
    setServiceMenuState(prevValues => ({
      ...prevValues,
      chosenService: service,
      modalType: modalTypes.EDIT_SERVICE_MENU
    }));
  };

  const closeModal = () => {
    setServiceMenuState(prevValues => ({
      ...prevValues,
      chosenService: undefined,
      modalType: undefined
    }));
  };

  return (
    <ServiceMenuStyles>
      <Header className="header">
        <Heading2>SERVICE MENU</Heading2>
        <Button
          onClick={() => setModalType(modalTypes.ADD_SERVICE_MENU)}
          className="addServiceMenu"
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Add Service Menu
        </Button>
      </Header>
      <Header className="filters">
        {serviceMenuFiltersBuilder.map(({ value, title }, i) => (
          <button
            key={i}
            type="button"
            onClick={() => setServiceType(value)}
            className={`tab ${
              serviceMenuState.serviceType === value && "activeTab"
            }`}
          >
            {title}
          </button>
        ))}
      </Header>
      <Body className="body">
        {serviceMenuState.serviceMenu.map((serviceItem, i) => (
          <ServiceMenuItem
            key={i}
            {...serviceItem}
            deleteAction={() => deleteService(serviceItem.id)}
            editAction={() => editService(serviceItem)}
          />
        ))}
      </Body>
      {serviceMenuState.modalType && (
        <CentralModal closeModal={closeModal} className="modalCard">
          <Card>
            {serviceMenuState.modalType === modalTypes.ADD_SERVICE_MENU && (
              <AddService
                branchId={branchId}
                closeModal={closeModal}
                setServiceMenuStateAfterCreateOrEdit={
                  setServiceMenuStateAfterCreateOrEdit
                }
              />
            )}
            {serviceMenuState.modalType === modalTypes.EDIT_SERVICE_MENU && (
              <EditService
                chosenService={serviceMenuState.chosenService}
                branchId={branchId}
                setServiceMenuStateAfterCreateOrEdit={
                  setServiceMenuStateAfterCreateOrEdit
                }
                closeModal={closeModal}
              />
            )}
          </Card>
        </CentralModal>
      )}
    </ServiceMenuStyles>
  );
};

export default ServiceMenu;
