// icons
import { Close } from "../../../SVGS";
// styles
import styled from "styled-components";

const BranchesItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nameAndAddress {
    display: flex;
    align-items: center;
    width: 100%;

    .branchName {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
      width: 20%;
    }

    .address {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #44444f;
      width: 70%;
    }
  }
`;

const BranchesItem = ({
  branchName,
  address,
  hasRemoveAction = true,
  removeBranch
}) => {
  return (
    <BranchesItemStyles>
      <div className="nameAndAddress">
        <p className="branchName">{branchName}</p>
        <p className="address">{address}</p>
      </div>
      {hasRemoveAction && <Close onClick={removeBranch} />}
    </BranchesItemStyles>
  );
};

export default BranchesItem;
