import styled from "styled-components";

export const ScheduleStyles = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  height: calc(100vh - 370px);
  overflow: hidden;

  .oneRow {
    height: calc(276px - 2 * 68px) !important;
  }

  .twoRows {
    height: calc(276px - 68px) !important;
  }

  .threeRows {
    height: 276px !important;
  }

  .firstColumn {
    margin-top: 2px !important;
  }

  .firstTable {
    width: 85px;

    .firstTableBody {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .firstTableBody::-webkit-scrollbar {
      display: none;
    }
  }

  .nameCol {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-left: 1px solid #ebebeb;
    height: 276px;
    margin-top: 22px;

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #171725;
      padding: 0 12px;
      word-break: unset;
    }
  }

  .col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table {
    overflow-x: auto;
    overflow-y: hidden;
    width: calc(100% - 85px);

    .tableHead {
      display: flex;

      .tableHeadText {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;

        color: #171725;
        word-break: normal;
        width: 105px;
        text-align: center;
      }
    }

    .listContent {
      height: 100%;
      width: ${({ timesSlotsLength }) =>
        timesSlotsLength ? `calc(105px * ${timesSlotsLength} )` : "3265px"};
      overflow-y: auto;
      overflow-x: hidden;
      .tableBody {
        display: flex;
        margin-top: 22px;
        position: relative;

        .column {
          background: #ffffff;
          border-left: 1px solid #ebebeb;
          height: 276px;
        }

        .scheduleContent {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 22px 0 26px 0;

          .inProgressContainer {
            width: 100%;
            margin-bottom: 12px;
            display: flex;
            position: relative;
            height: 68px;
          }

          .pausedContainer {
            width: 100%;
            margin-bottom: 12px;
            display: flex;
            position: relative;
            height: 68px;
          }

          .pendingContainer {
            width: 100%;
            display: flex;
            position: relative;
            height: 68px;
          }
        }
      }
    }
  }

  .palceholder {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      color: #bcbcbc;
    }
  }
`;
