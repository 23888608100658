// Utils
import { isEmpty } from "../../../utils";

export class ServiceMenuRequest {
  constructor(serviceMenuFormData = {}) {
    this.service_type_key = serviceMenuFormData.serviceTypeKey;
    this.title = serviceMenuFormData.title;
    this.price = serviceMenuFormData.price;
    this.available = serviceMenuFormData.available;
    this.description = serviceMenuFormData.description;
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (initValuesArr.includes(value) || isEmpty(value)) {
        delete this[key];
      }
    });
  }
}
