import styled from "styled-components";

export const ListHeaderStyles = styled.div`
  width: 100%;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #f1f1f5;

  .number {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #0093ec;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #202650;
    margin-top: 4px;
  }

  .viewAllContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    .viewAll {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #202650;
    }

    .arrowContainer {
      margin-left: 12px;
      transform: rotate(-270deg);
    }
  }
`;
