import GeneralSettings from "./GeneralSettings/GeneralSettings";
import ServiceTypeAndCapacity from "./ServiceTypeAndCapacity/ServiceTypeAndCapacity";
import ManageSchedule from "./ManageSchedule/ManageSchedule";
import ServiceMenu from "./ServiceMenu/ServiceMenu";
import StaffManagement from "./StaffManagement/StaffManagement";
import RolesAndPermissions from "./RolesAndPermissions/RolesAndPermissions";

export {
  GeneralSettings,
  ServiceTypeAndCapacity,
  ManageSchedule,
  ServiceMenu,
  StaffManagement,
  RolesAndPermissions
};
