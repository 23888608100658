import styled from "styled-components";

export const ViewBranchesStyles = styled.div`
  .viewBranchesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dataContainer {
      display: flex;

      .manager {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #172b4d;
        margin-bottom: 2px;
      }

      .managerName {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #171725;
      }

      .managerEmail {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #44444f;
        margin-bottom: 5px;
      }

      .managerPhoneContact {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #44444f;
      }
    }

    .close {
      align-self: flex-start;
    }
  }

  .branchesList {
    margin-top: 5px;
  }
`;
