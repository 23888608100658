export class ProfileForm {
  constructor(data = {}) {
    this.email = data?.email || "";
    this.firstName = data?.firstName || "";
    this.lastName = data?.lastName || "";
    this.contactNumber = data?.contactNumber || "";
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);
    Object.entries(this).forEach(([key, value]) => {
      if (initValuesArr.includes(value)) {
        delete this[key];
      }
    });
  }
}
