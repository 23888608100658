import { apiService } from "../api.service";

// Requests
import {
  LoginRequest,
  RegisterRequest,
  UpdateProfileRequest,
  ForgotPasswordRequest,
  ChangePasswordRequest
} from "../../models/api/requests";

// Responses
import {
  AuthenticationResponse,
  UserResponse,
  MessageResponse
} from "../../models/api/responses";

/**
 * Login user Request
 * @param loginFormValues - LoginFormModel
 * @return - AuthenticationResponse || ErrorResponse
 */
export const loginUserRequest = async loginFormValues => {
  const payload = new LoginRequest(loginFormValues);
  const response = await apiService.post(
    "/auth/login?with_additional_data=true&with_dashboard_data=true",
    payload
  );
  if (response.hasError) {
	  console.log("000000000000000")
    return response;
  }
  return new AuthenticationResponse(response);
};

/**
 * Register user Request
 * @param registerFormValues - {account, business}
 * @return - ErrorResponse || AuthenticationResponse
 */
export const registerUserRequest = async registerFormValues => {
  const payload = new RegisterRequest(registerFormValues);
  payload.business.removeEmptyValues();
  const response = await apiService.post(
    "/auth/registration?with_additional_data=true&with_dashboard_data=true",
    payload
  );
  if (response.hasError) {
    return response;
  }
  return new AuthenticationResponse(response);
};

/**
 * Fetch user data with credentials in local storage
 * @return - AuthenticationResponse || ErrorResponse
 */
export const fetchAuthenticatedUserRequest = async () => {
  const response = await apiService.get(
    "/users?with_additional_data=true&with_dashboard_data=true"
  );
  if (response.hasError) {
    return response;
  }
  return new AuthenticationResponse(response);
};

/**
 * Update user profile request
 * @param profileFormValues - ProfileForm updated values
 * @param initialProfileFormValues - ProfileForm initial data
 * @return - UserResponse || ErrorResponse
 */
export const updateProfileRequest = async (
  profileFormValues,
  initialProfileFormValues
) => {
  const payload = new UpdateProfileRequest(profileFormValues);
  payload.removeSameValues(initialProfileFormValues);
  const response = await apiService.patch("/users", payload);
  if (response.hasError) {
    return response;
  }
  return new UserResponse(response);
};

/**
 * Forgot password request
 * @param forgotPasswrodValues - ForgotPasswrodFormModel
 * @return - MessageResponse || ErrorResponse
 */
export const forgotPasswordRequest = async forgotPasswrodValues => {
  const payload = new ForgotPasswordRequest(forgotPasswrodValues);
  const response = await apiService.post("/auth/password/forgot", payload);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

/**
 * Change user password request
 * @param changePasswordValues - ChangePasswordRequest
 * @return - MessageResponse || ErrorResponse
 */
export const changePasswordRequest = async changePasswordValues => {
  const payload = new ChangePasswordRequest(changePasswordValues);
  const response = await apiService.post("/auth/password/change", payload);
  if (response.hasError) {
    return response;
  }
  return new MessageResponse(response);
};

export const fetchChatToken = async () => {
  const response = await apiService.get("/chat/auth");
  if (response.hasError) {
    return response;
  }
  return response.data;
};
