import styled from "styled-components";

const FooterStyles = styled.footer`
  padding: 24px;
  border-top: 1px solid #f1f1f5;
`;

const Footer = ({ children, style, className }) => (
  <FooterStyles style={{ ...style }} className={className}>
    {children}
  </FooterStyles>
);

export default Footer;
