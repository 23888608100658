// Domain Models
import { ServiceComment } from "../../app/domain";

export class ServiceCommentsResponse {
  constructor(response = {}) {
    this.comments = response.data.comments.map(
      serviceComment => new ServiceComment(serviceComment)
    );
  }
}
