import validator from "validator";

export const loginValidate = ({ email, password }) => {
  const errors = {};
  if (!email) {
    errors.email = "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  if (!password) {
    errors.password = "* Required";
  }

  return errors;
};
