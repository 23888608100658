// icons
import { SearchIcon } from "../../../SVGS";
// styles
import styled from "styled-components";

const SearchStyles = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 13px;

  .iconContainer {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchInput {
    position: absolute;
    padding: 0 10px 0 35px;
    top: 0;
    left: 0;
    background: #ffffff;
    border: 1px solid #f2f6f9;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #000000;
    outline: none;
  }

  .searchInput::placeholder {
    color: #92929d;
  }
`;

const Search = ({ onChange, value, placeholder }) => {
  return (
    <SearchStyles>
      <div className="iconContainer">
        <SearchIcon />
      </div>

      <input
        placeholder={placeholder}
        className="searchInput"
        type="text"
        onChange={onChange}
        value={value}
      />
    </SearchStyles>
  );
};

export default Search;
