const ManagerIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="18"
      fill="none"
      viewBox="0 0 15 18"
      className={className}
    >
      <path
        fill="#202650"
        d="M7.5 8.906c2.367 0 4.286-1.993 4.286-4.453S9.867 0 7.5 0C5.133 0 3.214 1.993 3.214 4.453S5.133 8.906 7.5 8.906zm3.208 1.134l-1.6 6.66-1.072-4.732 1.071-1.948H5.893l1.071 1.948-1.071 4.731-1.6-6.659C1.904 10.16 0 12.187 0 14.695v1.448c0 .921.72 1.67 1.607 1.67h11.786c.887 0 1.607-.748 1.607-1.67v-1.448c0-2.508-1.905-4.536-4.292-4.655z"
      ></path>
    </svg>
  );
};

export default ManagerIcon;
