import { isEmpty } from "../../utils";

export const branchServiceTypeValidation = values => {
  const errors = {};

  if (isEmpty(values)) {
    errors.values = "* You need to have at least one service type";
  }
  values.forEach(type => {
    if (isEmpty(type.capacity)) {
      errors.values = "* Capacity must be filled";
    }

    if (type.capacity > 9999) {
      errors.values = "* Capacity must be less then 9999";
    }
  });

  return errors;
};
