import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { memo } from "react";
import { useFormik } from "formik";

// Components
import { TextField, Button } from "../../../components/UI";
import { Label, Paragraph, Span } from "../../../components/typography";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Form Models
import { LoginFormModel } from "../../../models/app/forms";

// Actions
import { login } from "../../../redux/actions/app.actions";

// Services
import { loginValidate } from "../../../services/validations/login.validations";

// Styles
import { LoginFormStyles } from "./loginForm.styles";

const LoginForm = ({ openForgotPassword }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogin = async values => {
    dispatch(login(values));
  };

  const formik = useFormik({
    initialValues: new LoginFormModel(),
    onSubmit: handleLogin,
    validate: loginValidate
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <LoginFormStyles>
      <form onSubmit={handleSubmit} className="form">
        <div>
          <Label style={{ marginBottom: 2 }}>Email</Label>
          <TextField
            name="email"
            type="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <div className="passwordLabelContainer">
            <Label>Password</Label>
            <Span onClick={openForgotPassword} className="link">
              Forgot Password
            </Span>
          </div>
          <TextField
            name="password"
            type="password"
            value={values.password}
            touched={touched.password}
            error={errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <Button
          type="submit"
          buttonDesignType={buttonDesignType.PRIMARY}
          style={{ width: "100%" }}
        >
          Log In
        </Button>
        <Paragraph className="signUp">
          Don't have an account?{" "}
          <Span className="link" onClick={() => history.push("/register")}>
            Sing Up
          </Span>
        </Paragraph>
      </form>
    </LoginFormStyles>
  );
};

export default memo(LoginForm);
