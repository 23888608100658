import React from "react";
import Select from "react-dropdown-select";
import styled from "styled-components";
// components
import contentRenderer from "./ContentRenderer";
import dropDownRenderer from "./DropDownRenderer";

const SelectStyles = styled.div`
  width: 100%;

  .select {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    height: ${({ height }) => height};
    min-height: ${({ height }) => height};
    max-height: ${({ height }) => height};
    background: #fafbfc;
    border: 1px solid #202650;
    border-radius: 3px;
    box-shadow: none !important;
    padding: 8px 9px;
    width: 100%;
    outline: none;
    resize: none;
    position: relative;
  }

  .selected {
    background: #f2f9fd;
    border: 1px solid #74bde9;
  }

  .select:focus {
    border: 1px solid #202650;
  }

  .select:hover {
    border: 1px solid #202650;
  }

  .error {
    border: 1px solid #ca2c2c !important;
  }

  .errorMessage {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ca2c2c;
    margin-top: 4px;
    height: 24px;
  }

  .react-dropdown-select-dropdown-handle {
    display: none;
  }

  .react-dropdown-select-dropdown {
    background: #ffffff;
    border: 1px solid #dfe1e6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    padding: 12px;
  }

  .unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const optionsPlaceholder = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

const CustomSelect = ({
  touched,
  error,
  placeholder,
  size,
  src,
  withImage = false,
  isCircle,
  dropdownPosition = "auto",
  searchable = false,
  value,
  options = optionsPlaceholder,
  onChange,
  handleTouched,
  valueKey = "id",
  labelKey = "name",
  Icon,
  clearable,
  className,
  alwayClearInput = false,
  shouldHaveError = true,
  disabled
}) => {
  return (
    <SelectStyles>
      <Select
        className={`select ${value && "selected"} ${className} ${
          touched && error && "error"
        }`}
        valueKey={valueKey}
        labelKey={labelKey}
        options={options}
        values={options.filter(option => option.value === value)}
        contentRenderer={contentRenderer}
        dropdownRenderer={dropDownRenderer}
        placeholder={placeholder}
        size={size}
        src={src}
        onDropdownOpen={handleTouched}
        backspaceDelete={false}
        withImage={withImage}
        Icon={Icon}
        clearable={clearable}
        isCircle={isCircle}
        dropdownPosition={dropdownPosition}
        searchable={searchable}
        onChange={onChange}
        alwayClearInput={alwayClearInput}
        disabled={disabled}
      />
      {shouldHaveError && (
        <p className="errorMessage">{touched && error && error}</p>
      )}
    </SelectStyles>
  );
};

export default CustomSelect;
