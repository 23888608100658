// Constants
import {
  TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXPIRATION_DATE
} from "../../../constants/localStorage/localStorage.constants";

// Utils
import { getItem } from "../../../utils";

export class Auth {
  constructor(auth = {}) {
    this.token = auth.access_token || getItem(TOKEN);
    this.refreshToken = auth.refresh_token || getItem(REFRESH_TOKEN);
    this.expiresAt = auth.expires_at || getItem(TOKEN_EXPIRATION_DATE);
    this.createdAt = auth.created_at;
  }
}
