import { useHistory } from "react-router";
import { memo } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";

// Components
import {
  Label,
  Paragraph,
  Span,
  Heading1
} from "../../../components/typography";
import {
  NumberFormatterInput,
  TextField,
  Button,
  FileUpload,
  GooglePlacesAutoComplete
} from "../../../components/UI";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// UI models
import { BussinesForm } from "../../../models/app/forms";

// Icons
import { DropDownArrow } from "../../../SVGS";

// Actions
import { register } from "../../../redux/actions/app.actions";

// Services
import { registerStepTwoValidation } from "../../../services/validations/register.validations";

// Styles
import { RegisterStepTwoFormStyles } from "./resiterStepTwoForm.styles";

const RegisterStepTwoForm = ({ accountForm, bussinessForm, handleBack }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRegister = values => {
    dispatch(register({ account: accountForm, business: values }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: bussinessForm,
    onSubmit: handleRegister,
    validate: registerStepTwoValidation
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleBlur
  } = formik;

  const handleAddressChange = address => {
    setFieldValue("address", address);
  };

  const prefillFieldsBasedOnAddress = async (chosenAddress, placeId) => {
    const [place] = await geocodeByPlaceId(placeId);
    const latLng = await getLatLng(place);

    const { long_name: postalCode = "" } =
      place.address_components.find(c => c.types.includes("postal_code")) || {};
    const { long_name: state = "" } =
      place.address_components.find(c => c.types.includes("country")) || {};
    const { long_name: city = "" } =
      place.address_components.find(c => c.types.includes("locality")) || {};
    const address = place.formatted_address;
    const longitude = latLng.lng;
    const latitude = latLng.lat;

    setValues(
      new BussinesForm({
        ...values,
        city,
        state,
        address,
        latitude,
        longitude,
        postalCode
      })
    );
  };

  return (
    <RegisterStepTwoFormStyles>
      <Button onClick={() => handleBack(values)} className="backButton">
        <div className="arrowContainer">
          <DropDownArrow />
        </div>
        Back
      </Button>
      <div className="tabs">
        <Span
          className="tab"
          style={{
            marginRight: 32
          }}
        >
          Create an account
        </Span>
        <Span className="tab" style={{ color: "#172B4D" }}>
          Set Up business profile
        </Span>
      </div>
      <Heading1>Set Up your business profile</Heading1>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <Label className="label">Business Name</Label>
          <TextField
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
            name="name"
            placeholder="Business Name"
          />
        </div>
        <div>
          <Label className="label">Address</Label>
          <GooglePlacesAutoComplete
            value={values.address}
            touched={touched.address}
            error={errors.address}
            onBlur={handleBlur}
            handleChange={handleAddressChange}
            handleSelect={prefillFieldsBasedOnAddress}
            name="address"
            placeholder="Address"
          />
        </div>
        <div className="alignedInputs">
          <div className="inputContainer">
            <Label className="label">State</Label>
            <TextField
              value={values.state}
              touched={touched.state}
              error={errors.state}
              onBlur={handleBlur}
              onChange={handleChange}
              name="state"
              placeholder="State"
            />
          </div>
          <div className="inputContainer">
            <Label className="label">City</Label>
            <TextField
              value={values.city}
              placeholder="City"
              onBlur={handleBlur}
              onChange={handleChange}
              name="city"
              touched={touched.city}
              error={errors.city}
            />
          </div>
        </div>
        <div className="alignedInputs">
          <div className="inputContainer">
            <Label className="label">Postal Code</Label>
            <TextField
              value={values.postalCode}
              touched={touched.postalCode}
              error={errors.postalCode}
              onBlur={handleBlur}
              onChange={handleChange}
              name="postalCode"
              placeholder="Enter postal code"
            />
          </div>
          <div className="inputContainer">
            <Label className="label">Tax Identification Number</Label>
            <NumberFormatterInput
              value={values.taxId}
              touched={touched.taxId}
              error={errors.taxId}
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              name="taxId"
              placeholder="Tax number"
            />
          </div>
        </div>
        <div>
          <Label className="label">Business Proof</Label>
          <FileUpload
            id="proofDocumentationUrl"
            name="proofDocumentationUrl"
            value={values.proofDocumentationUrl}
            setFieldValue={setFieldValue}
            touched={touched.proofDocumentationUrl}
            error={errors.proofDocumentationUrl}
          />
        </div>
        <div>
          <Label className="label">Business Website</Label>
          <TextField
            value={values.websiteUrl}
            touched={touched.websiteUrl}
            error={errors.websiteUrl}
            onBlur={handleBlur}
            onChange={handleChange}
            name="websiteUrl"
            placeholder="Website link"
          />
        </div>
        <Button
          type="submit"
          className="submitButton"
          buttonDesignType={buttonDesignType.PRIMARY}
        >
          Register
        </Button>
        <Paragraph className="bottomText">
          By continuing, you accept our{" "}
          <Span
            className="link"
            onClick={() => history.push("/privacy-policy")}
          >
            {" "}
            Terms of Service
          </Span>
          .
        </Paragraph>
      </form>
    </RegisterStepTwoFormStyles>
  );
};

export default memo(RegisterStepTwoForm);
