import styled from "styled-components";

export const ChatStyles = styled.div`
  height: 100%;
  width: 468px;
  position: fixed;
  right: 410px;
  top: 0;
  animation: showChat 0.4s ease-in-out;

  .chatBox {
    width: 100%;
    height: ${({ isInputVisible }) =>
      isInputVisible ? "calc(100% - 65px)" : "100%"};
    background: #f7f9fd;
    position: relative;
    overflow: hidden;

    .notificationPresetContainer {
      height: 100%;
      width: 100%;
      /* background: #00000040; */
      position: absolute;
      top: 0;
    }
  }

  .inputContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
    background: #ebf0f9;
  }

  @keyframes showChat {
    from {
      transform: translateX(410px);
    }
    to {
      transform: translateX(0);
    }
  }
`;
