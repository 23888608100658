import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Body, Button, Footer, Textarea } from "../../../../components/UI";
import { Label, Paragraph } from "../../../../components/typography";

// Builders
import * as buttonDesignType from "../../../../builders/buttonDesign.types";

// Services
import { declineValidation } from "../../../../services/validations/decline.validation";

// Actions
import { declineServiceRequests } from "../../../../redux/actions/service-requests.actions";

// Styles
import { DeclineFormStyles } from "./declineForm.styles";

const DeclineForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { requestId, branchId } = useSelector(
    state => state.serviceRequestsPage
  );

  const handleDecline = values => {
    dispatch(declineServiceRequests(requestId, values, branchId));
  };

  const formik = useFormik({
    initialValues: {
      declineReason: ""
    },
    onSubmit: handleDecline,
    validate: declineValidation
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    formik;

  return (
    <DeclineFormStyles>
      <form autoComplete="new-password" onSubmit={handleSubmit}>
        <Body className="formBody">
          <h3 className="modalTitle">
            Are you sure you want to decline this request?
          </h3>
          <Paragraph className="description" style={{ wordBreak: "unset" }}>
            If you are sure you want to decline this request, you need to
            provide a reason.
          </Paragraph>
          <Label>Decline Reason</Label>
          <Textarea
            name="declineReason"
            value={values.declineReason}
            error={errors.declineReason}
            touched={touched.declineReason}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Reason..."
            className="textareaInput"
          />
        </Body>
        <Footer className="footer">
          <Button
            onClick={closeModal}
            className="button"
            buttonDesignType={buttonDesignType.SECONDARY}
          >
            Close
          </Button>
          <Button
            type="submit"
            className="button"
            style={{ marginLeft: 24 }}
            buttonDesignType={buttonDesignType.PRIMARY}
          >
            Decline
          </Button>
        </Footer>
      </form>
    </DeclineFormStyles>
  );
};

export default DeclineForm;
