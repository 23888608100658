import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete";

// Components
import { Label } from "../../../components/typography";
import {
  TextField,
  Button,
  FileUpload,
  NumberFormatterInput,
  GooglePlacesAutoComplete
} from "../../../components/UI";

// Builders
import * as buttonDesignType from "../../../builders/buttonDesign.types";

// Form Models
import { BussinesForm } from "../../../models/app/forms/Register-form.model";

// Actions
import { updateBusinessProfile } from "../../../redux/actions/business.actions";

// Services
import { editBusinesProfileValidation } from "../../../services/validations/editBusinessProfile.validations";

// Styles
import { EditBusinesProfileFormStyles } from "./editBusinessProfileForm.styles";

const EditBusinesProfileForm = () => {
  const dispatch = useDispatch();
  const { business, location } = useSelector(state => state.businessProfile);
  const initialFormValues = new BussinesForm({ ...business, ...location });

  const handleUpdateBusinessProfile = values => {
    dispatch(updateBusinessProfile(values, initialFormValues));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: handleUpdateBusinessProfile,
    validate: editBusinesProfileValidation
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur,
    setValues,
    setFieldTouched
  } = formik;

  const handleAddressChange = address => {
    setFieldValue("address", address);
  };

  const prefillFieldsBasedOnAddress = async (chosenAddress, placeId) => {
    const [place] = await geocodeByPlaceId(placeId);
    const latLng = await getLatLng(place);

    const { long_name: postalCode = "" } =
      place.address_components.find(c => c.types.includes("postal_code")) || {};
    const { long_name: state = "" } =
      place.address_components.find(c => c.types.includes("country")) || {};
    const { long_name: city = "" } =
      place.address_components.find(c => c.types.includes("locality")) || {};
    const address = place.formatted_address;
    const longitude = latLng.lng;
    const latitude = latLng.lat;

    setValues(
      new BussinesForm({
        ...values,
        city,
        state,
        address,
        latitude,
        longitude,
        postalCode
      })
    );
  };

  return (
    <EditBusinesProfileFormStyles>
      <form onSubmit={handleSubmit}>
        <div className="formContainer">
          <div className="leftInputs">
            <div>
              <Label>Business Name</Label>
              <TextField
                value={values.name}
                touched={touched.name}
                error={errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                name="name"
                placeholder="Business Name"
              />
            </div>
            <div>
              <Label>Business Address</Label>
              <GooglePlacesAutoComplete
                value={values.address}
                touched={touched.address}
                error={errors.address}
                onBlur={handleBlur}
                handleChange={handleAddressChange}
                handleSelect={prefillFieldsBasedOnAddress}
                name="address"
                placeholder="Address"
              />
            </div>
            <div>
              <Label>City</Label>
              <TextField
                value={values.city}
                placeholder="City"
                onBlur={handleBlur}
                onChange={handleChange}
                name="city"
                touched={touched.city}
                error={errors.city}
              />
            </div>
            <div>
              <Label>Business Proof</Label>
              <FileUpload
                id="proofDocumentationUrl"
                name="proofDocumentationUrl"
                value={values.proofDocumentationUrl}
                setFieldValue={setFieldValue}
                touched={touched.proofDocumentationUrl}
                error={errors.proofDocumentationUrl}
              />
            </div>
          </div>
          <div className="rightInputs">
            <div>
              <Label>State</Label>
              <TextField
                value={values.state}
                placeholder="Choose a state"
                onBlur={handleBlur}
                onChange={handleChange}
                name="state"
                touched={touched.state}
                error={errors.state}
              />
            </div>
            <div>
              <Label>ZIP/Postal Code</Label>
              <TextField
                value={values.postalCode}
                touched={touched.postalCode}
                error={errors.postalCode}
                onBlur={handleBlur}
                onChange={handleChange}
                name="postalCode"
                placeholder="Enter postal code"
              />
            </div>
            <div>
              <Label>Tax Identification Number</Label>
              <NumberFormatterInput
                value={values.taxId}
                touched={touched.taxId}
                error={errors.taxId}
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                name="taxId"
                placeholder="Tax number"
              />
            </div>
            <div>
              <Label>Business Website</Label>
              <TextField
                value={values.websiteUrl}
                touched={touched.websiteUrl}
                error={errors.websiteUrl}
                onBlur={handleBlur}
                onChange={handleChange}
                name="websiteUrl"
                placeholder="Website link"
              />
            </div>
          </div>
        </div>
        <div className="buttonContainer">
          <Button type="submit" buttonDesignType={buttonDesignType.PRIMARY}>
            Save Changes
          </Button>
        </div>
      </form>
    </EditBusinesProfileFormStyles>
  );
};

export default EditBusinesProfileForm;
