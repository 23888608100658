// Components
import Button from "../Button/Button";

// Styles
import { TextFileWithButtonStyles } from "../TextField/textFieldWithButton.styles";

const TextFieldWithButton = ({
  id,
  name,
  type = "text",
  autoComplete = "off",
  placeholder = "Create a comment",
  onChange,
  value,
  error,
  touched,
  onBlur,
  pattern,
  onClick,
  disabled
}) => {
  return (
    <TextFileWithButtonStyles value={value}>
      <div className="inputContainer">
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          className={`input ${touched && error && "error"}`}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          pattern={pattern}
        />
        <p className="errorMessage">{touched && error && error}</p>
        <Button className="button" onClick={onClick} disabled={disabled}>
          Add
        </Button>
      </div>
    </TextFileWithButtonStyles>
  );
};

export default TextFieldWithButton;
