import { useFormik } from "formik";
import { toast } from "react-toastify";

// Components
import { Heading2 } from "../../../../../components/typography";
import { Header, Body } from "../../../../../components/UI";
import ServiceForm from "../ServiceForm/ServiceForm";

// Icons
import { Close } from "../../../../../SVGS";

// Form Models
import { ServiceMenuFormModel } from "../../../../../models/app/forms";

// Calls
import { updateServiceMenuRequest } from "../../../../../api/calls/general-branch-settings.call";

// Services
import { serviceValidation } from "../../../../../services/validations/service.validation";

// Styles
import { EditServiceStyles } from "./editService.styles";

const EditService = ({
  closeModal,
  chosenService,
  branchId,
  setServiceMenuStateAfterCreateOrEdit
}) => {
  const initialValues = new ServiceMenuFormModel(
    chosenService.generateServiceForm()
  );
  const updateServiceHandler = async values => {
    const response = await updateServiceMenuRequest(
      branchId,
      values,
      initialValues,
      chosenService.id
    );
    if (response.hasError) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Service successfully updated.");
    setServiceMenuStateAfterCreateOrEdit(response.serviceMenu);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: updateServiceHandler,
    validate: serviceValidation
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange
  } = formik;

  return (
    <EditServiceStyles>
      <Header className="formHeader">
        <Heading2>EDIT SERVICE</Heading2>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <ServiceForm
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            buttonText="Save Changes"
          />
        </form>
      </Body>
    </EditServiceStyles>
  );
};

export default EditService;
