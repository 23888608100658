// Domain Models
import { EmployeeScheduleService } from ".";

// Utils
import { isEmpty } from "../../../utils";

export class WorkerSchedule {
  constructor(worker = {}) {
    this.id = worker.id;
    this.firstName = worker.first_name || worker.firstName;
    this.lastName = worker.last_name || worker.lastName;
    this.acceptedServices = !isEmpty(worker.accepted_services)
      ? worker.accepted_services.map(
          service => new EmployeeScheduleService(service)
        )
      : !isEmpty(worker.acceptedServices)
      ? worker.acceptedServices.map(
          service => new EmployeeScheduleService(service)
        )
      : null;
    this.inProgressServices = !isEmpty(worker.in_progress_services)
      ? worker.in_progress_services.map(
          service => new EmployeeScheduleService(service)
        )
      : !isEmpty(worker.inProgressServices)
      ? worker.inProgressServices.map(
          service => new EmployeeScheduleService(service)
        )
      : null;
    this.pausedServices = !isEmpty(worker.paused_services)
      ? worker.paused_services.map(
          service => new EmployeeScheduleService(service)
        )
      : !isEmpty(worker.pausedServices)
      ? worker.pausedServices.map(
          service => new EmployeeScheduleService(service)
        )
      : null;
  }
}
