export class ManagerRequest {
  constructor(data = {}) {
    this.email = data?.email;
    this.first_name = data?.firstName;
    this.last_name = data?.lastName;
    this.contact_number = data?.contactNumber;
    this.permissions = data?.permissions;
    this.branches = data?.branches.map(branch => branch.id);
  }

  removeSameValues(initValues) {
    const initValuesArr = Object.values(initValues);

    Object.entries(this).forEach(([key, value]) => {
      if (
        key !== "branches" &&
        key !== "permissions" &&
        initValuesArr.includes(value)
      ) {
        delete this[key];
      }
    });
  }
}
