import { apiService } from "../api.service";

// Responses
import { DashboardResponse } from "../../models/api/responses";

export const fetchDashboardRequest = async () => {
  const response = await apiService.get("/services/latest?amount=4");
  if (response.hasError) {
    return response;
  }
  return new DashboardResponse(response);
};
