// Action types
import * as actionTypes from "../actionTypes/manager.types";

// Utils
import { updateObject } from "../../utils/global.utils";

const initialState = {
  managers: [],
  chosenManager: undefined,
  modalType: undefined
};

export const managersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MANAGERS_SUCCESS:
      return setManagers(state, action);
    case actionTypes.ADD_MANAGER_SUCCESS:
      return setManagers(state, action);
    case actionTypes.EDIT_MANAGER:
      return editManager(state, action);
    case actionTypes.UPDATE_MANAGER_SUCCESS:
      return setManagers(state, action);
    case actionTypes.DELETE_MANAGER_SUCCESS:
      return setManagers(state, action);
    case actionTypes.VIEW_MANAGER_BRANCHES:
      return viewManagerBranches(state, action);
    case actionTypes.OPEN_MODAL:
      return openModal(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);
    default:
      return state;
  }
};

const setManagers = (state, { managers }) => {
  return updateObject(state, {
    managers,
    modalType: undefined,
    chosenManager: undefined
  });
};

const viewManagerBranches = (state, { chosenManager, modalType }) => {
  return updateObject(state, {
    chosenManager,
    modalType
  });
};

const editManager = (state, { chosenManager, modalType }) => {
  return updateObject(state, {
    chosenManager,
    modalType
  });
};

const openModal = (state, { modalType }) => {
  return updateObject(state, {
    modalType
  });
};

const closeModal = (state, action) => {
  return updateObject(state, {
    modalType: undefined,
    chosenManager: undefined
  });
};
