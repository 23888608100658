import styled from "styled-components";

export const DeclineFormStyles = styled.div`
  .formBody {
    padding: 24px 24px 10px 24px;
  }

  .modalTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #172b4d;
    margin-bottom: 12px;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #5c5c5c;
    margin-bottom: 24px;
  }

  .textareaInput {
    min-height: 110px !important;
    max-height: 110px !important;
    height: 110px !important;
    margin-top: 3px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button {
      width: 100px;
      border-radius: 6px;
    }
  }
`;
