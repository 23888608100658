import { ChatMessage, Pagination } from "../../app/domain";

export class ChatMessagesResponse {
  constructor(response = {}) {
    this.messages = response.data.messages.map(
      message => new ChatMessage(message)
    );
    this.pagination = new Pagination(response.data.pagination);
  }
}
