const TrailerIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="15"
      fill="none"
      viewBox="0 0 23 15"
      style={{ ...style }}
    >
      <path
        fill="#445570"
        d="M13.843 0c.032 0 .063.002.095.005V0h4.924c.452 0 .818.366.818.818v10.736h2.41v1.357h-4.217a2.198 2.198 0 01-2.158 1.816 2.198 2.198 0 01-2.158-1.815h-3.57a2.25 2.25 0 01-2.198 1.815c-.81 0-1.52-.435-1.914-1.086a2.236 2.236 0 01-1.913 1.086 2.246 2.246 0 01-2.175-1.722l-.02-.094H0v-1.356h.572V.879c0-.464.359-.845.811-.877L1.445 0h12.398zM3.962 11.368c-.604 0-1.093.494-1.093 1.102 0 .608.49 1.103 1.093 1.103s1.095-.495 1.095-1.103a1.1 1.1 0 00-1.095-1.102zm3.827 0c-.603 0-1.093.494-1.093 1.102 0 .608.49 1.103 1.093 1.103.604 0 1.096-.495 1.096-1.103a1.1 1.1 0 00-1.096-1.102zm7.961.086a1.023 1.023 0 100 2.046 1.023 1.023 0 000-2.046z"
      ></path>
    </svg>
  );
};

export default TrailerIcon;
