const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="#172B4D"
        d="M5.856 6.255c0 .273.229.502.496.502h6.519l.952-.038-.45.431-1.03.959a.463.463 0 00-.152.343c0 .266.19.463.451.463.133 0 .235-.05.33-.146l2.07-2.145c.127-.127.17-.242.17-.369s-.043-.24-.17-.368l-2.07-2.145a.45.45 0 00-.33-.153c-.26 0-.45.197-.45.457 0 .127.05.26.152.35l1.028.964.45.432-.951-.044H6.35a.511.511 0 00-.495.507zM.886 2.612v7.28c0 1.327.66 1.994 1.968 1.994h6.335c1.308 0 1.968-.667 1.968-1.993V8.122h-1.022v1.752c0 .628-.343.99-1.003.99h-6.22c-.661 0-1.004-.362-1.004-.99v-7.25c0-.634.343-.983 1.003-.983h6.22c.661 0 1.004.349 1.004.984v1.758h1.022V2.612c0-1.327-.66-1.993-1.968-1.993H2.854C1.546.619.886 1.285.886 2.612z"
      ></path>
    </svg>
  );
};

export default LogoutIcon;
