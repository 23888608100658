const CircledBell = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#F3A957"
        d="M36 18c0-9.941-8.059-18-18-18S0 8.059 0 18s8.059 18 18 18 18-8.059 18-18z"
      ></path>
      <path
        fill="#fff"
        d="M26.748 22.643l-2.303-2.303a1.7 1.7 0 01-.494-1.2v-3.298a6.113 6.113 0 00-5.764-6.198 5.952 5.952 0 00-6.138 5.951v3.546a1.7 1.7 0 01-.502 1.199l-2.296 2.303a.85.85 0 00.596 1.454h16.306a.85.85 0 00.596-1.454z"
      ></path>
      <path
        fill="#fff"
        d="M14.718 25.798a3.4 3.4 0 006.564 0h-6.564z"
        opacity="0.6"
      ></path>
    </svg>
  );
};

export default CircledBell;
